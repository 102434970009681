import { Grid, Typography } from "@material-ui/core";
import { Button, Link } from "@components/common";
import { styled } from "@material-ui/styles";

export const WrapperStyled = styled(Grid)({
  padding: "40px 16px 46px",

  "@media (min-width:768px)": {
    paddingBottom: "80px",
    minHeight: "100vh",
  },
});

export const LogoStyled = styled("div")({
  marginBottom: 46,
});

export const FormStyled = styled("form")({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  width: "100%",

  "@media (min-width:768px)": {
    maxWidth: "316px",
  },
});

export const TitleStyled = styled(Typography)({
  marginBottom: "30px",
});

export const SubmitButtonStyled = styled(Button)({
  alignSelf: "center",
  width: "100%",
  marginBottom: "16px",

  "@media (min-width:768px)": {
    width: "auto",
    marginBottom: 46,
  },
});

export const SecondaryButtonStyled = styled(Button)({
  alignSelf: "center",
  width: "auto",
  margin: "0 auto 16px",

  "@media (min-width:768px)": {
    margin: "0 0 46px 0",
  },
});

export const LinkStyled = styled(Link)({
  marginLeft: 6,
});

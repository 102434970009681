import styled from "styled-components";
import { Grid, Typography } from "@material-ui/core";

export const ProfileTopNumbers = styled(Grid)`
  margin-bottom: 28px;

  @media (min-width: 992px) {
    margin-bottom: 58px;
  }

  > div {
    flex: 1 0 50%;
    margin-bottom: 20px;

    @media (min-width: 992px) {
      flex: 1 0 auto;
      margin-bottom: 0;
    }
  }
`;

export const TransactionListTitle = styled(Typography)`
  margin-bottom: 16px;
`;

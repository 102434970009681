import styled from "styled-components";

export const WrapperStyled = styled.div`
  display: flex;
  justify-content: center;
  min-height: 100vh;
`;

export const FormContainerStyled = styled.div`
  flex: 1;
  text-align: center;
  padding: 8px;

  @media (min-width: 992px) {
    padding: 50px 24px 90px;
  }
`;

export const FormLogoContainerStyled = styled.div`
  display: block;
  margin: 0 auto 24px;
  width: 320px;
  height: 100px;
  max-width: 100%;
`;

export const FormLogoPreviewStyled = styled.div`
  width: 100% !important;
  height: 100% !important;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;

  &.hide-preview {
    img {
      display: none !important;
    }
  }
`;

export const FormBodyStyled = styled.div`
  background-color: transparent;
  border: 1px solid transparent;
  padding: 16px;
  border-radius: 4px;
  margin: 0 auto 32px;

  @media (min-width: 992px) {
    padding: 46px;
  }
`;

export const FormTitleStyled = styled.h3`
  font-size: 40px;
  margin-top: 0;
  margin-bottom: 20px;
`;

export const FormTextRowStyled = styled.div`
  margin-bottom: 16px;
`;

export const FormTextSmallStyled = styled.p`
  font-size: 16px;
  margin: 0 0 24px;
`;

export const IconWrapper = styled.a`
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  background-color: #fff;
  cursor: pointer;
  text-decoration: none;

  span {
    padding-left: 8px;
    color: #3c4043;
  }

  & + a {
    margin-top: 28px;
  }
`;

export const QRContainer = styled.div`
  display: table;
  padding: 8px;
  background-color: #fff;
  margin: 10px auto;
`;

import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { injectIntl } from "react-intl";
import { accountSelectors } from "../../selectors";
import { mainSelectors } from "../../../main/selectors";
import { authSelectors } from "../../../auth/selectors";
import * as customersActions from "../../actions";
import Component from "./components";

const mapStateToProps = (state) => ({
  userInfo: authSelectors.getUser(state),
  selectedCompany: mainSelectors.getSelectedCompany(state),
  integrationList: accountSelectors.getIntegrationList(state),
  currentIntegration: accountSelectors.getCurrentIntegration(state),
  integrationsAdded: accountSelectors.getIntegrationsAdded(state),
  integrationsUpdated: accountSelectors.getIntegrationsUpdated(state),
  hasError: accountSelectors.getError(state),
  status: accountSelectors.getStatus(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      addIntegration: customersActions.addIntegration,
      updateIntegration: customersActions.updateIntegration,
      fetchIntegrationList: customersActions.fetchIntegrationList,
      resetIntegrationForm: customersActions.resetIntegrationForm,
    },
    dispatch
  );

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default injectIntl(ConnectedComponent);

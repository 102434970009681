import React, { useState, useEffect } from "react";
import { withRouter, RouteComponentProps, useParams } from "react-router";
import { useForm, FormProvider } from "react-hook-form";
import { WrappedComponentProps } from "react-intl";
import { Alert, ContentLoader, Error } from "@components/common";
import { COMMON } from "@constants";
import {
  StatusType,
  InviteDesignType,
  LoyaltyCustomerProfileType,
} from "@types";
import {
  InviteLoyaltyType,
  FetchInvitationDesignsType,
} from "../../../../types";
import ContentFormPreview from "./InviteForm";
import { getFilesFromS3 } from "../../../../../../s3";

interface InviteCustomerProps {
  inviteLoyalty(data: InviteLoyaltyType): void;
  fetchInvitationDesigns(data: FetchInvitationDesignsType): void;
  invitationDesigns: InviteDesignType;
  invited: LoyaltyCustomerProfileType;
  status: StatusType;
  hasError: boolean;
}

interface RouteParamsType {
  id: string;
}

const InvitePage = ({
  inviteLoyalty,
  fetchInvitationDesigns,
  invitationDesigns,
  hasError,
  invited,
}: InviteCustomerProps & RouteComponentProps & WrappedComponentProps) => {
  const methods = useForm();
  const { handleSubmit, reset, errors, formState } = methods;
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams<RouteParamsType>();
  const [logoUrl, setLogoUrl] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);

  const logoFormData = [
    {
      key: COMMON.ASSETS_INVITATION_FOLDER,
      fileName:
        invitationDesigns?.images.logo_id || COMMON.DEFAULT_ASSET_PLACEHOLDER,
      filePreviewData: null,
      filePreviewCallback: setLogoUrl,
    },
  ];

  const onSubmit = ({ fullName, email, segments }) => {
    setIsLoading(true);
    setShowSuccess(false);

    inviteLoyalty({
      invitation_id: id,
      name: fullName,
      email,
      registration_note: segments,
      registration_source: "web_admin",
    });
  };

  useEffect(() => {
    if (formState.isDirty && invited) {
      setShowSuccess(false);
    }
  }, [formState]);

  useEffect(() => {
    if (invited) {
      reset({ fullName: "", email: "", terms: false });
      setIsLoading(false);
      setShowSuccess(true);
    }
  }, [invited]);

  useEffect(() => {
    if (id) {
      fetchInvitationDesigns({ invitation_id: id });
    }
  }, [id]);

  useEffect(() => {
    if (invitationDesigns?.images) {
      getFilesFromS3(logoFormData);
    }
  }, [invitationDesigns]);

  if (hasError) {
    return <Error container />;
  }

  if (!invitationDesigns) {
    return <ContentLoader />;
  }

  return (
    <>
      {hasError && (
        <Alert variant="error" sticky>
          There was an error. Try later, please.
        </Alert>
      )}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ContentFormPreview
            bodyBgColor={invitationDesigns.background_color_hex}
            containerBgColor={invitationDesigns.container_background_color_hex}
            borderColor={invitationDesigns.container_border_color_hex}
            buttonBgColor={invitationDesigns.send_button_background_color_hex}
            buttonTextColor={invitationDesigns.send_button_label_color_hex}
            titleColor={invitationDesigns.title_label_color_hex}
            textColor={invitationDesigns.content_label_color_hex}
            showBorder={invitationDesigns.display_container_border}
            // content
            formTitle={invitationDesigns.strings.title_label_text}
            formText={invitationDesigns.strings.description_label_text}
            contentName={invitationDesigns.strings.name_label_text}
            contentEmail={invitationDesigns.strings.email_label_text}
            buttonText={invitationDesigns.strings.send_button_label_text}
            contentTermsText={
              invitationDesigns.strings.terms_of_service_label_text
            }
            contentTermsUrl="https://www.notion.so/Terms-and-Privacy-c5621892e00d4fffb271f552ec000889"
            contentSuccessMsg={
              invitationDesigns.strings.success_message_label_text ||
              "You have successfully joined! Check your email."
            }
            logoUrl={logoUrl}
            errors={errors}
            onSubmit={handleSubmit(onSubmit)}
            isLoading={isLoading}
            showSuccess={showSuccess}
            contentSegments={
              invitationDesigns.strings.segments_label_text ||
              "Additional field"
            }
          />
        </form>
      </FormProvider>
    </>
  );
};

export default withRouter(InvitePage);

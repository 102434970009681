import { styled } from "@material-ui/core/styles";
import { Container, Typography } from "@material-ui/core";
import { CheckCircleOutline } from "@material-ui/icons";
import WelcomeImage from "-!react-svg-loader!../../../../../assets/icons/welcome.svg";

export const ContainerStyled = styled(Container)({
  paddingTop: "65px",
});

export const HeadingStyled = styled(Typography)({
  marginBottom: "58px",
});

export const BlockStyled = styled(Container)(() => ({
  border: "1px solid #ececec",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
  borderRadius: 8,
  minHeight: 480,
  padding: 0,
  height: "100%",
}));

export const BlockRowStyled = styled(Container)(() => ({
  borderBottom: "1px solid #ececec",
  padding: "18px 34px 16px",
  minHeight: 76,

  "&:last-child": {
    borderBottom: 0,
  },
}));

export const BlockInfoStyled = styled(Container)({
  padding: "38px 56px",
});

export const BlockInfoTitleStyled = styled(Typography)({
  marginBottom: 16,
});

export const BlockInfoLinkStyled = styled("div")({
  marginBottom: 44,
  fontSize: 15,
});

export const StatusIconStyled = styled(CheckCircleOutline)(({ fill }) => ({
  marginRight: 14,
  fill,
}));

export const StepMainTitleStyled = styled(Typography)({
  marginTop: 12,
});

type StepType = {
  completed?: 1 | 0;
};

export const StepTitleStyled = styled(Typography)(
  ({ completed }: StepType) => ({
    margin: 0,
    fontSize: 15,
    color: completed ? "#00CF8F" : "#B9B9B9",
  })
);

export const StepTimeStyled = styled(Typography)(({ completed }: StepType) => ({
  margin: 0,
  color: completed ? "#00CF8F" : "#000",
}));

export const InfoImage = styled(WelcomeImage)({
  width: "90%",
});

import React, { useState, useEffect } from "react";
import { BaseTextFieldProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFormContext, Controller, FieldError } from "react-hook-form";
import { WrapperStyled, LabelStyled, TextFieldStyled } from "./styled";

const useStyles = ({ inputError }) =>
  makeStyles((theme) => ({
    root: () => ({
      "&.MuiInput-input": {
        borderColor: inputError ? theme.palette.error.main : "transparent",
      },
    }),
  }));

interface PatternInterface {
  value: RegExp;
  message: string;
}

interface TextFieldComponentProps extends Omit<BaseTextFieldProps, "error"> {
  name: string;
  pattern?: PatternInterface;
  minLength?: number;
  min?: number;
  max?: number;
  valueAsNumber?: boolean;
  error?: FieldError;
  hooked?: boolean;
  secondary?: boolean;
  hideRequiredMsg?: boolean;
  value?: string;
  validate?: any;
  validateMessage?: string;
  offsetbottom?: "true" | "false";
}

const TextFieldComponent: React.FC<TextFieldComponentProps> = ({
  name,
  label,
  disabled,
  error,
  required,
  pattern,
  type,
  minLength,
  placeholder,
  defaultValue = "",
  min,
  max,
  id,
  valueAsNumber,
  autoComplete = "on",
  helperText,
  hooked = true,
  hideRequiredMsg = false,
  value,
  style,
  variant,
  secondary,
  validate,
  validateMessage,
  offsetbottom = "true",
}) => {
  const [errorMsg, setErrorMsg] = useState("");
  const classes = useStyles({ inputError: !!error })();

  // Input label
  const FieldLabel = () => (
    <LabelStyled htmlFor={id}>
      {label} {required && !hideRequiredMsg && <span>(required)</span>}
    </LabelStyled>
  );

  // Show input validation errors
  useEffect(() => {
    if (error?.type === "minLength") {
      setErrorMsg(`${label} must be at least ${minLength} character long`);
      return;
    }

    if (error?.type === "validate") {
      setErrorMsg(validateMessage || "");
      return;
    }
    setErrorMsg(error?.message || "");
  }, [error]);

  return (
    <WrapperStyled fullWidth error={!!error} offsetbottom={offsetbottom}>
      <FieldLabel />
      {hooked ? (
        <Controller
          as={
            <TextFieldStyled
              inputProps={{ className: classes.root, min, max }}
              // eslint-disable-next-line
              InputProps={{
                disableUnderline: true,
                autoComplete,
              }}
              fullWidth
              error={!!errorMsg}
              helperText={errorMsg || helperText}
              secondary={secondary}
              variant={variant}
            />
          }
          name={name}
          control={useFormContext().control}
          disabled={disabled}
          type={type}
          defaultValue={defaultValue}
          placeholder={placeholder}
          id={id}
          rules={{
            required,
            minLength,
            min,
            max,
            valueAsNumber,
            pattern,
            validate,
          }}
          className={classes.root}
        />
      ) : (
        <TextFieldStyled
          inputProps={{ className: classes.root, min, max, style }}
          // eslint-disable-next-line
          InputProps={{
            disableUnderline: true,
            autoComplete,
          }}
          fullWidth
          error={!!errorMsg}
          helperText={errorMsg || helperText}
          value={value}
          style={style}
          secondary={secondary}
          variant={variant}
        />
      )}
    </WrapperStyled>
  );
};

export default TextFieldComponent;

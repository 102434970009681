import { OnboardingTypes, OnboardingActionTypes } from "./types";
import {
  CreateCompanyType,
  CompanyType,
  CreateLoyaltyType,
  LoyaltyType,
} from "../../types";

export const createCompany = (
  payload: CreateCompanyType
): OnboardingActionTypes => ({
  type: OnboardingTypes.CREATE_COMPANY,
  payload,
});

export const createCompanySucceeded = (
  payload: CompanyType
): OnboardingActionTypes => ({
  type: OnboardingTypes.CREATE_COMPANY_SUCCEEDED,
  payload,
});

export const createCompanyFailed = (): OnboardingActionTypes => ({
  type: OnboardingTypes.CREATE_COMPANY_FAILED,
});

export const createLoyalty = (
  payload: CreateLoyaltyType
): OnboardingActionTypes => ({
  type: OnboardingTypes.CREATE_LOYALTY,
  payload,
});

export const createLoyaltySucceeded = (
  payload: LoyaltyType
): OnboardingActionTypes => ({
  type: OnboardingTypes.CREATE_LOYALTY_SUCCEEDED,
  payload,
});

export const createLoyaltyFailed = (): OnboardingActionTypes => ({
  type: OnboardingTypes.CREATE_LOYALTY_FAILED,
});

export const resetOnboarding = (): OnboardingActionTypes => ({
  type: OnboardingTypes.RESET_ONBOARDING,
});

import React, { useRef } from "react";
import QRCode from "react-qr-code";
import { exportComponentAsJPEG } from "react-component-export-image";
import { Typography } from "@material-ui/core";
import { IconWrapper, QRContainer } from "./styled";
import SaveAsIcon from "-!react-svg-loader!../../../../../../assets/icons/download.svg";

interface GeneratePassImageProps {
  number: string;
  name: string;
  title: string;
}

const GeneratePassImage: React.FC<GeneratePassImageProps> = ({
  title,
  name,
  number,
}) => {
  const cardRef = useRef(null);
  return (
    <>
      <IconWrapper
        onClick={() =>
          exportComponentAsJPEG(cardRef, {
            fileName: "MyPass",
          })
        }
      >
        <SaveAsIcon />
        <span>{title}</span>
      </IconWrapper>
      <div
        ref={cardRef}
        style={{
          position: "absolute",
          left: "-10000px",
          padding: "30px",
        }}
      >
        <Typography>{name}</Typography>
        <Typography>Card number: {number}</Typography>
        <QRContainer>
          <QRCode value={number.toString()} size={86} />
        </QRContainer>
      </div>
    </>
  );
};

export default GeneratePassImage;

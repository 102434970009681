import { accountActionTypes, AccountStateType, AccountTypes } from "./types";

export const defaultState: AccountStateType = {
  integrations: undefined,
  currentIntegration: undefined,
  integrationsAdded: false,
  integrationsUpdated: false,
  status: "idle",
  error: false,
};

export default (
  state = defaultState,
  action: accountActionTypes
): AccountStateType => {
  switch (action.type) {
    case AccountTypes.ADD_INTEGRATION:
      return {
        ...state,
        status: "loading",
        integrationsAdded: false,
        integrationsUpdated: false,
        error: false,
      };

    case AccountTypes.ADD_INTEGRATION_SUCCEEDED:
      return {
        ...state,
        currentIntegration: action.payload,
        integrationsAdded: true,
        status: "succeeded",
        error: false,
      };

    case AccountTypes.ADD_INTEGRATION_FAILED:
      return {
        ...state,
        integrationsAdded: false,
        status: "failed",
        error: true,
      };

    case AccountTypes.UPDATE_INTEGRATION:
      return {
        ...state,
        status: "loading",
        integrationsAdded: false,
        integrationsUpdated: false,
        error: false,
      };

    case AccountTypes.UPDATE_INTEGRATION_SUCCEEDED:
      return {
        ...state,
        currentIntegration: action.payload,
        integrationsUpdated: true,
        status: "succeeded",
        error: false,
      };

    case AccountTypes.UPDATE_INTEGRATION_FAILED:
      return {
        ...state,
        integrationsUpdated: false,
        status: "failed",
        error: true,
      };

    case AccountTypes.FETCH_INTEGRATION_LIST:
      return {
        ...state,
        error: false,
      };

    case AccountTypes.FETCH_INTEGRATION_LIST_FAILED:
      return {
        ...state,
        integrations: undefined,
        currentIntegration: undefined,
        error: true,
      };

    case AccountTypes.FETCH_INTEGRATION_LIST_SUCCEEDED:
      return {
        ...state,
        integrations: action.payload,
        currentIntegration: action.payload[0],
        error: false,
      };

    case AccountTypes.RESET_INTEGRATION_FORM:
      return {
        ...state,
        error: false,
        integrationsUpdated: false,
      };

    default:
      return state;
  }
};

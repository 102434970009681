import React, { ComponentType } from "react";
import ReactDOM from "react-dom";
import { AppContainer } from "react-hot-loader";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "./app";

Sentry.init({
  dsn:
    "https://b88929951cdb41f09b24c59707eb3e1d@o509876.ingest.sentry.io/5604911",
  environment: process.env.NODE_ENV,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const render = (Component: ComponentType) => {
  const renderEl = (
    <AppContainer>
      <Component />
    </AppContainer>
  );

  ReactDOM.render(renderEl, document.getElementById("root"));
};

render(App);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept("./app", () => {
    render(App);
  });
}

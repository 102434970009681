export const DEFAULT_LANGUAGE = "en-GB";

export const LANGUAGES = {
  "nl-NL": "nl-NL",
  "en-GB": "en-GB",
  "en-CA": "en-CA",
  "fr-CA": "fr-CA",
} as const;

export const MESSAGES_LANGUAGES = {
  en: "en",
  nl: "nl",
  fr: "fr",
} as const;

export const DEFAULT_INPUT_DEBOUNCE_MILLISECONDS = 500;
export const DEFAULT_LOADING_ITEMS_COUNT = 10;
export const DEFAULT_DATE_FORMAT = "DD.MM.YYYY HH:mm";
export const DEFAULT_DATE_FORMAT_SHORT = "DD.MM.YYYY";
export const DEFAULT_TABLE_SORTING_ORDER = "asc";
export const FILTER_FORM_VALUES_LOCAL_STORAGE_KEY =
  "ctrlAlertsFilterFormValues";

export const MAIN_TABLE_INFINITE_SCROLL_OFFSET = 100;

export const PAGINATION_ROWS_PER_PAGE = 10;
export const PAGINATION_ROWS_PER_PAGE_VARIANTS = [10, 20, 50] as const;

export const AUTH_RESEND_CODE_TIMER = 60;

// AWS S3
export const ASSETS_FORMAT = "png";
export const DEFAULT_ASSET_PLACEHOLDER = "default-logo-horizontal";
export const ASSETS_DEFAULT_FOLDER = "default";
export const ASSETS_ROOT_FOLDER = "user-assets";
export const ASSETS_PASS_FOLDER = `${ASSETS_ROOT_FOLDER}/passes`;
export const ASSETS_INVITATION_FOLDER = `${ASSETS_ROOT_FOLDER}/invitations`;
export const ASSETS_EMAIL_FOLDER = `${ASSETS_ROOT_FOLDER}/emails`;

// sub navigation (tabs)
export const CUSTOMERS_NAV = [
  {
    title: "Overview",
    link: "/customers/overview",
  },
  {
    title: "Add Customer",
    link: "/customers/add",
  },
  {
    title: "Customers Segments",
    link: "/customers/segments",
  },
];

export const ACCOUNT_NAV = [
  {
    title: "Settings & Security",
    link: "/settings/personal-settings",
  },
  {
    title: "Company settings",
    link: "/settings/company-settings",
  },
  {
    title: "Integrations",
    link: "/settings/integrations",
  },
];

export const TRANSACTION_NAV = [
  {
    title: "Overview",
    link: "/transactions/overview",
  },
];

export const LOYALTY_CUSTOMER_NAV = (id: string, cards) => [
  {
    title: "Overview",
    link: `/customers/${id}/overview`,
    section: "overview",
  },
  {
    title: "Cards",
    link: null,
    section: "card",
    sub: cards
      ? [
          ...cards.map((card) => {
            return {
              id: card.id,
              title: `${card.program.name} (#${card.pass_number})`,
              link: `/customers/${id}/${card.id}/card-transactions`,
              section: "card",
            };
          }),
          {
            id: "add-card",
            title: `+ Add new card`,
            link: `/customers/${id}/add-card`,
            section: "card",
            primary: true,
          },
        ]
      : null,
  },
  {
    title: "Customer settings",
    link: `/customers/${id}/settings`,
    section: "card-settings",
  },
];

export const LOYALTY_CUSTOMER_SUB_NAV = (userId: string, loyaltyId: string) => [
  {
    title: "Transactions",
    link: `/customers/${userId}/${loyaltyId}/card-transactions`,
  },
  {
    title: "Card settings",
    link: `/customers/${userId}/${loyaltyId}/card-settings`,
  },
  {
    title: "+ Add transaction",
    link: `/customers/${userId}/${loyaltyId}/add-card-transaction`,
  },
];

export const LOYALTIES_NAV = (programId: string) => [
  {
    title: "Core settings",
    link: `/programs/${programId}/settings`,
    disabled: true,
  },
  {
    title: "Pass design",
    link: `/programs/${programId}/pass-design`,
    disabled: false,
  },
  {
    title: "Invite form design",
    link: `/programs/${programId}/invite-design`,
    disabled: false,
  },
  {
    title: "Email design",
    link: `/programs/${programId}/email-design`,
    disabled: false,
  },
  {
    title: "Customer acquisition",
    link: `/programs/${programId}/acquisition`,
    disabled: true,
  },
  {
    title: "Points management",
    link: `/programs/${programId}/points-management`,
    disabled: true,
  },
];

export const HEADER_NAV = (programList) => [
  {
    text: "Transactions",
    link: "/transactions/overview",
    section: "transactions",
  },
  {
    text: "Customers",
    link: "/customers/overview",
    section: "customers",
  },
  {
    text: "Programs",
    link: null,
    section: "programs",
    sub: programList?.length
      ? [
          ...programList.map((item) => {
            return {
              id: item.id,
              text: item.name,
              link: `/programs/${item.id}/pass-design`,
              section: "programs",
              disabled: false,
            };
          }),
          {
            text: "+ Add new program",
            link: "/add-program",
            section: "programs",
            primary: true,
          },
        ]
      : null,
  },
];

import styled from "styled-components";

interface BadgeProps {
  type: "success" | "danger" | "warning" | "primary";
}

export default styled.div<BadgeProps>`
  display: inline-block;
  padding: 0 16px;
  border-radius: 4px;
  font-size: 10px;
  font-weight: 500;
  line-height: 27px;
  text-transform: uppercase;

  ${(props) => {
    switch (props.type) {
      case "success":
        return `
          background-color: #eff6f0;
          color: #388e3c;
        `;

      case "danger":
        return `
          background-color: #fbefef;
          color: #d32f2f;
        `;

      case "warning":
        return `
          background-color: #fef5ed;
          color: #f57f17;
        `;

      case "primary":
        return `
          background-color: #ecf2fb;
          color: #115ccc;
        `;

      default:
        return `
          background-color: #eff0f2;
          color: #35435c;
        `;
    }
  }};
`;

import { TransactionType } from "../../types";

export type TransactionsStateType = {
  transactions?: TransactionType[];
  error: boolean;
};

export type FetchTransactionsType = {
  tenant_id: string;
  company_id: string;
  program_id: string;
  limit?: number;
  offset?: number;
};

export const TransactionsTypes = {
  FETCH_TRANSACTION_LIST: "transactions/FETCH_TRANSACTION_LIST",
  FETCH_TRANSACTION_LIST_SUCCEEDED:
    "transactions/FETCH_TRANSACTION_LIST_SUCCEEDED",
  FETCH_TRANSACTION_LIST_FAILED: "transactions/FETCH_TRANSACTION_LIST_FAILED",
} as const;

// fetch transaction list
export type fetchLoyaltyCustomerListActionType = {
  type: typeof TransactionsTypes.FETCH_TRANSACTION_LIST;
  payload: FetchTransactionsType;
};

export type fetchLoyaltyCustomerListSucceededActionType = {
  type: typeof TransactionsTypes.FETCH_TRANSACTION_LIST_SUCCEEDED;
  payload: TransactionType[];
};

export type fetchLoyaltyCustomerListFailedActionType = {
  type: typeof TransactionsTypes.FETCH_TRANSACTION_LIST_FAILED;
};

export type customersActionTypes =
  | fetchLoyaltyCustomerListActionType
  | fetchLoyaltyCustomerListSucceededActionType
  | fetchLoyaltyCustomerListFailedActionType;

import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { injectIntl } from "react-intl";
import { customersSelectors } from "../../../selectors";
import * as customersActions from "../../../actions";
import Component from "./InvitePage";

const mapStateToProps = (state) => ({
  invited: customersSelectors.getLoyaltyInvited(state),
  invitationDesigns: customersSelectors.getInvitationDesigns(state),
  hasError: customersSelectors.hasError(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      inviteLoyalty: customersActions.inviteLoyalty,
      fetchInvitationDesigns: customersActions.fetchInvitationDesigns,
    },
    dispatch
  );

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default injectIntl(ConnectedComponent);

import { call, put, takeLatest } from "redux-saga/effects";
import { MainTypes } from "./types";
import * as actions from "./actions";
import * as api from "./api";

export function* fetchCompanyList(action) {
  try {
    const { payload } = action;

    const res = yield call(api.fetchCompanyList, payload);

    yield put(actions.fetchCompanyListSucceeded(res.data.items));

    if (res.data.items.length) {
      const loyaltyPayload = {
        ...payload,
        company_id: res.data.items[0]?.id,
      };

      yield put(actions.fetchLoyaltyList(loyaltyPayload));
    }
  } catch (ex) {
    yield put(actions.fetchCompanyListFailed());
  }
}

export function* fetchLoyaltyList(action) {
  try {
    const { payload } = action;

    const res = yield call(api.fetchLoyaltyList, payload);

    yield put(actions.fetchLoyaltyListSucceeded(res.data.items));
  } catch (ex) {
    yield put(actions.fetchLoyaltyListFailed());
  }
}

export function* updateCompany(action) {
  try {
    const { payload } = action;

    const res = yield call(api.updateCompany, payload);

    yield put(actions.updateCompanySucceeded(res.data));
  } catch (ex) {
    yield put(actions.updateCompanyFailed());
  }
}

export default [
  takeLatest(MainTypes.FETCH_COMPANY_LIST, fetchCompanyList),
  takeLatest(MainTypes.UPDATE_COMPANY, updateCompany),
  takeLatest(MainTypes.FETCH_LOYALTY_LIST, fetchLoyaltyList),
];

// @ts-nocheck
import {
  CognitoUser,
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUserAttribute,
  ICognitoUserAttributeData,
} from "amazon-cognito-identity-js";

const config = {
  userPool: {
    UserPoolId: process.env.REACT_AWS_APP_CLIENT_ID,
    ClientId: process.env.REACT_AWS_POOL_ID,
  },
};

class User {
  constructor() {
    this.userPool = new CognitoUserPool(config.userPool);
    this.cognitoUser = this.userPool.getCurrentUser();
    this.email = "";
    this.password = "";
  }

  setCognitoUser(email: string) {
    this.email = email;

    const userData = {
      Username: this.email,
      Pool: this.userPool,
    };

    this.cognitoUser = new CognitoUser(userData);

    return this.cognitoUser;
  }

  getUserPool() {
    return this.userPool;
  }

  getCognitoUser() {
    return this.cognitoUser;
  }

  authenticateUser(email, password, success, failure) {
    this.email = email;
    this.password = password;

    const authenticationDetails = new AuthenticationDetails({
      Username: this.email,
      Password: this.password,
    });

    const userData = {
      Username: this.email,
      Pool: this.userPool,
    };

    this.cognitoUser = new CognitoUser(userData);

    this.cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess(result) {
        success(result);
      },

      onFailure(err) {
        failure(err);
      },
    });
  }

  userSignOut() {
    if (this.cognitoUser) {
      this.cognitoUser.signOut();
      this.email = "";
      this.password = "";
    }
  }

  updateAttributes(
    attr: ICognitoUserAttributeData,
    error: (message: string) => void,
    success: (message?: string) => void
  ) {
    const attributeList: ICognitoUserAttributeData[] = [];
    const attribute = new CognitoUserAttribute(attr);
    attributeList.push(attribute);

    if (this.cognitoUser) {
      this.cognitoUser.updateAttributes(attributeList, (err, result) => {
        if (err) {
          error("Something went wrong");
          return;
        }
        success(result);
      });
    }
  }

  getUserAttributes() {
    return this.cognitoUser.getUserAttributes((err, result) => {
      if (err) {
        return err;
      }
      return result;
    });
  }
}

const cognitoUser = new User();

export default cognitoUser;

import styled from "styled-components";

interface AlertProps {
  variant?: "success" | "warning" | "error";
  sticky?: boolean;
  fullWidth?: boolean;
  hideAlert?: boolean;
}

export const AlertStyled = styled.div<AlertProps>`
  border-radius: 4px;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  padding: 12px 24px;
  margin-bottom: 16px;
  align-self: center;
  justify-content: center;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  display: ${({ hideAlert }) => (hideAlert ? "none" : "inline-flex")};
  ${({ sticky }) => `
    position: ${sticky ? "fixed" : "static"};
    bottom: 32px;
    left: 32px;
    z-index: 100;
  `};

  ${({ variant }) => {
    switch (variant) {
      case "success":
        return `
          background-color: #B8FFEA;
          color: #263238;
        `;
      case "warning":
        return `
          background-color: #FFF741;
          color: #263238;
        `;
      case "error":
        return `
          background-color: #E72076;
          color: #fff;
        `;
      default:
        return `
          background-color: #B8FFEA;
          color: #263238;
        `;
    }
  }};
`;

import axios, { AxiosPromise } from "axios";
import CONSTANTS from "@constants";

const { API } = CONSTANTS;

export const fetchCompanyList = ({ tenant_id }): AxiosPromise =>
  axios.get(`${API.TENANTS}/${tenant_id}/companies`);

export const fetchLoyaltyList = ({ tenant_id, company_id }): AxiosPromise =>
  axios.get(`${API.TENANTS}/${tenant_id}/companies/${company_id}/programs`);

export const updateCompany = ({
  tenant_id,
  company_id,
  ...data
}): AxiosPromise =>
  axios.post(
    `${API.TENANTS}/${tenant_id}/companies/${company_id}/programs`,
    data
  );

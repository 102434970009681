import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { customersSelectors } from "../../../../selectors";
import { mainSelectors } from "../../../../../main/selectors";
import * as customersActions from "../../../../actions";
import Component from "./components";

const mapStateToProps = (state) => ({
  selectedCustomer: customersSelectors.getSelectedCustomer(state),
  loyaltyAccount: customersSelectors.getLoyaltyAccount(state),
  loyaltyAccounts: customersSelectors.getLoyaltyAccounts(state),
  selectedLoyalty: mainSelectors.getSelectedLoyalty(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      selectLoyalty: customersActions.selectLoyalty,
    },
    dispatch
  );

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default ConnectedComponent;

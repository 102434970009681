import React, { useState, useEffect, useMemo } from "react";
import dayjs from "dayjs";
import { WrappedComponentProps, FormattedMessage, useIntl } from "react-intl";
import { RouteComponentProps, useParams } from "react-router";
import { ContentLoader, Alert, Pagination } from "@components/common";
import useTable from "@components/common/TableHook";
import { COMMON } from "@constants";
import { formatMoney } from "@utils/common";
import {
  TransactionListType,
  CompanyType,
  LoyaltyType,
  UserProfileType,
} from "@types";
import { FetchLoyaltyTransactionListType } from "../../../../../types";

interface RouteProps {
  successMsg?: string;
}
interface TransactionsProps {
  transactionList: TransactionListType;
  fetchLoyaltyTransactionList(data: FetchLoyaltyTransactionListType): void;
  limit?: number;
  userInfo: UserProfileType;
  selectedLoyalty: LoyaltyType;
  selectedCompany: CompanyType;
  isTransactionAdded: boolean;
  resetAddTransactionForm(): void;
}

interface CustomersRouteProps {
  id: string;
  loyaltyId: string;
}

const Transactions: React.FC<
  TransactionsProps &
    RouteComponentProps<{}, {}, RouteProps> &
    WrappedComponentProps
> = ({
  transactionList,
  fetchLoyaltyTransactionList,
  userInfo,
  selectedLoyalty,
  selectedCompany,
  isTransactionAdded,
  resetAddTransactionForm,
}) => {
  const { loyaltyId } = useParams<CustomersRouteProps>();
  const transactions = transactionList?.items;
  const total_count = transactionList?.total_count;
  const [paginationOffset, setPaginationOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();

  const tableHeadData = useMemo(
    () => [
      {
        field: "date",
        title: intl.formatMessage({
          id: "transactions.list.date",
        }),
        sortable: true,
      },
      {
        field: "change",
        title: intl.formatMessage({
          id: "transactions.list.points.change",
        }),
        sortable: true,
      },
      {
        field: "amount",
        title: intl.formatMessage({
          id: "transactions.list.amount",
        }),
        sortable: true,
      },
      {
        field: "source",
        title: intl.formatMessage({
          id: "transactions.list.source",
        }),
        sortable: true,
      },
      {
        field: "diff",
        title: intl.formatMessage({
          id: "transactions.list.points.diff",
        }),
        sortable: false,
      },
    ],
    []
  );

  const tableBodyData = useMemo(() => {
    if (transactions) {
      return transactions.reduceRight((acc, transaction) => {
        return [
          {
            date: {
              title: dayjs(transaction.created_at).format(
                COMMON.DEFAULT_DATE_FORMAT
              ),
            },
            change: {
              title:
                transaction.points.balance_after -
                  transaction.points.balance_before >
                0
                  ? `+${
                      transaction.points.balance_after -
                      transaction.points.balance_before
                    }`
                  : transaction.points.balance_after -
                    transaction.points.balance_before,
            },
            amount: {
              title: transaction.sales_value
                ? formatMoney(transaction.sales_value)
                : "N/A",
            },
            source: { title: transaction.source },
            diff: {
              title: `${transaction.points.balance_before}/${transaction.points.balance_after}`,
            },
          },
          ...acc,
        ];
      }, []);
    }
    return [];
  }, [transactions]);

  const [RenderTable] = useTable({
    tableHeadData,
    tableBodyData,
    searchValue: "",
    isLoading,
    emptyMessage: intl.formatMessage({
      id: "transactions.list.empty",
    }),
  });

  useEffect(() => {
    setIsLoading(true);
    fetchLoyaltyTransactionList({
      tenant_id: userInfo.id,
      company_id: selectedCompany?.id,
      program_id: selectedLoyalty?.id,
      account_id: loyaltyId,
      offset: paginationOffset,
    });
  }, [paginationOffset, isTransactionAdded, loyaltyId]);

  useEffect(() => {
    if (transactionList) {
      setIsLoading(false);
    }
  }, [transactionList]);

  useEffect(() => {
    return () => {
      resetAddTransactionForm();
    };
  }, []);

  if (!transactionList) {
    return <ContentLoader />;
  }

  return (
    <>
      <RenderTable />
      <Pagination total={total_count} onChange={setPaginationOffset} />
      {isTransactionAdded && (
        <Alert sticky>
          <FormattedMessage id="customers.profile.transactions.add.form.success" />
        </Alert>
      )}
    </>
  );
};

export default Transactions;

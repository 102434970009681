import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { withRouter, RouteComponentProps, useHistory } from "react-router";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { useIntl, WrappedComponentProps, FormattedMessage } from "react-intl";
import {
  Button,
  TextField,
  Alert,
  Radio,
  ContentLoader,
} from "@components/common";
import {
  UserProfileType,
  LoyaltyCustomerProfileType,
  LoyaltyType,
  CompanyType,
  AddTransactionType,
  CustomerType,
} from "@types";
import { HeadingStyled, FormStyled } from "./styled";

const POINTS_ADD = "add";
const POINTS_REMOVE = "remove";
const POINTS_DEFAULT = 1;

interface AddPointTransactionProps {
  userInfo: UserProfileType;
  loyaltyList: LoyaltyType[];
  selectedLoyalty: LoyaltyType;
  selectedCustomer: CustomerType;
  selectedCompany: CompanyType;
  addLoyaltyTransaction: (data: AddTransactionType) => void;
  loyaltyAccount: LoyaltyCustomerProfileType;
  isTransactionAdded: boolean;
  hasError: boolean;
}

const AddPointTransaction: React.FC<
  AddPointTransactionProps & RouteComponentProps & WrappedComponentProps
> = ({
  userInfo,
  selectedCustomer,
  selectedCompany,
  addLoyaltyTransaction,
  isTransactionAdded,
  loyaltyAccount,
  hasError,
}) => {
  const methods = useForm({
    defaultValues: {
      points: POINTS_DEFAULT,
      pointsType: POINTS_ADD,
      note: "",
    },
  });
  const { handleSubmit, errors, watch, control } = methods;
  const [isLoading, setIsLoading] = useState(false);
  const formValues = watch(["points", "pointsType"]);
  const intl = useIntl();
  const history = useHistory();

  const showDeductError =
    formValues.pointsType === POINTS_REMOVE &&
    formValues.points > loyaltyAccount.balance;

  const onSubmit = ({ pointsType, points, note }) => {
    setIsLoading(true);
    addLoyaltyTransaction({
      tenant_id: userInfo.id,
      company_id: selectedCompany.id,
      points: {
        add: pointsType === POINTS_ADD ? parseInt(points, 10) : 0,
        remove: pointsType === POINTS_REMOVE ? parseInt(points, 10) : 0,
      },
      pass_number: loyaltyAccount.pass_number,
      source: "web_admin",
      note,
    });
  };

  useEffect(() => {
    if (isTransactionAdded) {
      history.push({
        pathname: `/customers/${selectedCustomer.id}/${loyaltyAccount.id}/card-transactions`,
      });
    }
  }, [isTransactionAdded]);

  useEffect(() => {
    setIsLoading(false);
  }, [hasError]);

  if (!loyaltyAccount) {
    return <ContentLoader />;
  }

  return (
    <Grid item md={6} lg={4}>
      <HeadingStyled variant="h3">
        <FormattedMessage id="customers.profile.transactions.add.title" />
      </HeadingStyled>
      <HeadingStyled variant="h3">
        Current points: {loyaltyAccount.balance}
      </HeadingStyled>
      <FormProvider {...methods}>
        <FormStyled onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            as={
              <Radio
                items={[
                  {
                    label: intl.formatMessage({
                      id:
                        "customers.profile.transactions.add.form.pointsType.add",
                    }),
                    value: POINTS_ADD,
                  },
                  {
                    label: intl.formatMessage({
                      id:
                        "customers.profile.transactions.add.form.pointsType.remove",
                    }),
                    value: POINTS_REMOVE,
                  },
                ]}
                defaultValue={POINTS_ADD}
                row
              />
            }
            name="pointsType"
          />
          <TextField
            name="points"
            placeholder={intl.formatMessage({
              id: "customers.profile.transactions.add.form.points.placeholder",
            })}
            label={intl.formatMessage({
              id: "customers.profile.transactions.add.form.points.label",
            })}
            error={errors.points}
            required
            type="number"
            valueAsNumber
            min={1}
            max={1000}
          />
          <TextField
            name="note"
            placeholder={intl.formatMessage({
              id: "customers.profile.transactions.add.form.note.placeholder",
            })}
            label={intl.formatMessage({
              id: "customers.profile.transactions.add.form.note.label",
            })}
            error={errors.note}
          />
          <Button
            loading={isLoading}
            onClick={handleSubmit(onSubmit)}
            type="submit"
            disabled={showDeductError}
            fullWidth
          >
            {formValues.pointsType === POINTS_ADD ? (
              <FormattedMessage id="customers.profile.transactions.add.form.submit.add" />
            ) : (
              <FormattedMessage id="customers.profile.transactions.add.form.submit.remove" />
            )}{" "}
            <FormattedMessage
              values={{
                points: formValues.points,
              }}
              id="customers.profile.transactions.add.form.submit.point"
            />
          </Button>
        </FormStyled>
      </FormProvider>
      {showDeductError && (
        <Alert variant="warning">
          <FormattedMessage
            id="customers.profile.transactions.add.form.error"
            values={{
              points: formValues.points,
              balance: loyaltyAccount.balance,
            }}
          />
        </Alert>
      )}
      {hasError && (
        <Alert variant="error">
          <FormattedMessage id="general.notifications.error" />
        </Alert>
      )}
    </Grid>
  );
};

export default withRouter(AddPointTransaction);

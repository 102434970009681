import {
  Grid,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import styled from "styled-components";
import CodeIcon from "-!react-svg-loader!../../../../../assets/icons/code.svg";

export const FormStyled = styled.div`
  padding: 0;
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
  border-radius: 4px;
  margin-bottom: 24px;

  @media (min-width: 992px) {
    margin-right: 24px;
  }

  @media (min-width: 1200px) {
    margin-right: 90px;
  }
`;

export const FormSubmitStyled = styled.div`
  padding: 0 24px 24px;
`;

export const GridStickyStyled = styled(Grid)`
  position: sticky;
  top: 30px;
`;

// Card preview iOS/Android
export const CardContainerStyled = styled.div`
  margin: 0 15px;
`;

export const CardStickyStyled = styled.div`
  @media (min-width: 768px) {
    align-self: flex-start;
    display: flex;
  }
`;

export const CardStyled = styled.div`
  width: 262px;
  min-height: 338px;
  border-radius: 10px;
  padding: 10px;
  background-color: #8fe9cd;
  will-change: background-color;
  transform: translate3d(0, 0, 0);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
`;

export const CardVersionStyled = styled.div`
  font-size: 14px;
  margin-bottom: 16px;
  font-weight: 500;
`;

export const CardHeadStyled = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const CardTitleStyled = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #fff;
`;

export const CardNumberStyled = styled.div`
  font-size: 18px;
  margin-left: auto;
  text-align: right;
`;

export const CardLogoMainStyled = styled.div`
  height: 100px;
  margin: 0 -10px 16px;
  display: block;
  width: calc(100% + 20px);
`;

export const CardInfoTitleStyled = styled.div`
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: #000;
  will-change: color;
`;

export const CardInfoValueLargeStyled = styled.div`
  font-weight: 300;
  font-size: 20px;
  margin-bottom: 16px;
  color: #000;
  will-change: color;
`;

export const CardInfoValueSmallStyled = styled.div`
  font-weight: 300;
  font-size: 15px;
  color: #000;
  will-change: color;
`;

export const CardLogoStyled = styled.div`
  width: 44px;
  height: 44px;
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
  background-color: #fff;

  ${CardInfoValueSmallStyled} {
    margin-bottom: 0;
  }
`;

export const AndroidHeadStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 10px 0;

  ${CardLogoStyled} {
    margin: 0 auto 10px;
  }
`;

export const CardLogoHorizontalStyled = styled.div`
  width: 160px;
  height: 50px;
  align-self: center;
`;

export const CardInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CardRow = styled(Grid)`
  display: flex;
`;

export const CardCodePlaceholder = styled(CodeIcon)`
  display: block;
  margin: 30px auto 0;
`;

export const CardInfoTitleCenteredStyled = styled.div`
  text-align: center;
  margin-top: 14px;
`;

export const CardInfoTitleAdditionalStyled = styled.div`
  margin: 10px 0;

  b {
    font-weight: 500;
  }
`;

export const CardLogoPreview = styled.div`
  width: 100% !important;
  height: 100% !important;
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;

  &.hide-preview {
    img {
      display: none !important;
    }
  }
`;

interface StepButtonStyledProps {
  active: 1 | 0;
}

export const StepButtonStyled = styled(Button)<StepButtonStyledProps>`
  min-width: 34px;
  margin: 0 2px;
  font-weight: normal;
  padding: 5px 0;
  border-color: ${({ active }) => (active ? "#5850EC" : "#F8F6F4")};
`;

export const StepsSwitchContainer = styled(Grid)`
  padding: 0 15px;
  margin-bottom: 28px;

  p {
    margin-bottom: 12px;
  }
`;

export const AccordionStyled = styled(Accordion)`
  &.MuiPaper-root {
    box-shadow: none;
    margin: 0;

    &:before {
      display: none;
    }
  }
`;
export const AccordionSummaryStyled = styled(AccordionSummary)`
  &.MuiAccordionSummary-root {
    min-height: 36px;
    border-bottom: 1px solid #ececec;
    flex-direction: row-reverse;
    padding: 0;
  }

  .MuiAccordionSummary-content,
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }

  .MuiAccordionSummary-expandIcon {
    color: #000;
    margin: 0 0 0 -12px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .MuiAccordionSummary-expandIcon.Mui-expanded {
    transform: rotate(90deg);
  }
`;
export const AccordionDetailsStyled = styled(AccordionDetails)`
  border: 1px solid #ececec;
  border-top: 0;
  padding: 10px;
  display: flex;
  justify-content: center;
  min-height: 106px;

  > .MuiFormControl-root {
    margin: 0;
    align-self: center;
  }
`;

export const FormStepsContainerStyled = styled("div")`
  margin-bottom: 24px;
`;

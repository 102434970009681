import axios, { AxiosPromise } from "axios";
import CONSTANTS from "@constants";

const { API } = CONSTANTS;

export const fetchIntegrationList = ({ tenant_id, company_id }): AxiosPromise =>
  axios.get(`${API.TENANTS}/${tenant_id}/companies/${company_id}/integrations`);

export const addIntegration = ({
  tenant_id,
  company_id,
  ...data
}): AxiosPromise =>
  axios.post(
    `${API.TENANTS}/${tenant_id}/companies/${company_id}/integrations`,
    data
  );

export const updateIntegration = ({
  tenant_id,
  company_id,
  integration_id,
  ...data
}): AxiosPromise =>
  axios.patch(
    `${API.TENANTS}/${tenant_id}/companies/${company_id}/integrations/${integration_id}`,
    data
  );

import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { injectIntl } from "react-intl";
import { customersSelectors } from "../../../../../../selectors";
import { mainSelectors } from "../../../../../../../main/selectors";
import { authSelectors } from "../../../../../../../auth/selectors";
import { hideModal } from "../../../../../../../modal/actions";
import * as customersActions from "../../../../../../actions";
import Component from "./components";

const mapStateToProps = (state) => ({
  userInfo: authSelectors.getUser(state),
  isSegmentRemoved: customersSelectors.isSegmentRemoved(state),
  selectedCompany: mainSelectors.getSelectedCompany(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      removeSegment: customersActions.removeLoyaltySegment,
      hideModal,
    },
    dispatch
  );

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default injectIntl(ConnectedComponent);

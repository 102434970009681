import React from "react";
import { CircularProgress, Container } from "@material-ui/core";
import { GridStyled } from "./styled";

const ContentLoader = () => (
  <Container>
    <GridStyled container alignItems="center" justifyContent="center">
      <CircularProgress />
    </GridStyled>
  </Container>
);

export default ContentLoader;

import React, { useState, useEffect } from "react";
import Pagination from "@material-ui/lab/Pagination";
import styled from "styled-components";
import { COMMON } from "@constants";

const { PAGINATION_ROWS_PER_PAGE } = COMMON;

const PaginationStyled = styled(Pagination)`
  margin-bottom: 24px;
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
`;

const PaginationComponent = ({ total, onChange, page = 1, ...props }) => {
  const [currentPage, setCurrentPage] = useState(page);
  const handlePaginationChange = (_, value) => {
    onChange(value * PAGINATION_ROWS_PER_PAGE - PAGINATION_ROWS_PER_PAGE);
    setCurrentPage(value);
  };

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  if (total <= PAGINATION_ROWS_PER_PAGE) {
    return null;
  }

  return (
    <PaginationStyled
      shape="round"
      page={currentPage}
      onChange={handlePaginationChange}
      count={Math.ceil(total / PAGINATION_ROWS_PER_PAGE)}
      {...props}
    />
  );
};

export default PaginationComponent;

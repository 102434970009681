import React from "react";
import { Button, ButtonProps } from "@material-ui/core";
import { Link, LinkProps as RouterLinkProps } from "react-router-dom";

const ButtonLink: React.FC<RouterLinkProps & ButtonProps> = ({
  variant = "contained",
  color = "primary",
  ...props
}) => {
  return (
    <Button
      variant={variant}
      color={color}
      component={Link}
      disableRipple
      {...props}
    />
  );
};

export default ButtonLink;

// import { RootStateType } from "../reducer";

const getIsUserVerified = (state: any) => state.auth.user?.verified;
const getIsUserSynced = (state: any) => state.auth.isSynced || false;
const getIsUserAuthenticated = (state: any) => !!state.auth.isAuthenticated;
const getUser = (state: any) => state.auth.user;

export const authSelectors = {
  getIsUserVerified,
  getIsUserSynced,
  getIsUserAuthenticated,
  getUser,
};

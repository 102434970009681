import React, { useState, useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { useForm, FormProvider } from "react-hook-form";
import { WrappedComponentProps } from "react-intl";
import { Grid, Typography } from "@material-ui/core";
import { v4 as uuid } from "uuid";
import {
  Button,
  ButtonRow,
  TextField,
  Checkbox,
  Alert,
  ColorPicker,
  InputFile,
  ContentLoader,
  Tabs,
  Error,
} from "@components/common";
import { checkHexColor, setImageName } from "@utils/common";
import { COMMON } from "@constants";
import {
  CompanyType,
  LoyaltyType,
  UserProfileType,
  ImagePreviewType,
  InviteDesignListItemType,
} from "@types";
import { getFilesFromS3, uploadFilesToS3 } from "../../../../../s3";
import InviteFormPreview from "./InviteFormPreview";
import { UpdateInviteDesignType, FetchInviteDesignType } from "../../../types";
import { FormStyled, FormSubmitStyled } from "./styled";

const logo_uuid = uuid();

interface CardDesignProps {
  selectedLoyalty: LoyaltyType;
  selectedCompany: CompanyType;
  userInfo: UserProfileType;
  inviteDesign: InviteDesignListItemType;
  inviteDesigns: InviteDesignListItemType[];
  updateInviteDesign(data: UpdateInviteDesignType): void;
  fetchInviteDesign(data: FetchInviteDesignType): void;
  hasError: boolean;
  inviteDesignUpdated: boolean;
}

const InviteDesign = ({
  userInfo,
  selectedLoyalty,
  selectedCompany,
  inviteDesign,
  fetchInviteDesign,
  updateInviteDesign,
  inviteDesignUpdated,
  hasError,
}: CardDesignProps & RouteComponentProps & WrappedComponentProps) => {
  const methods = useForm({
    defaultValues: {
      // share
      publicDomain: `${
        window.location.origin
      }/#/join/${selectedLoyalty?.invitation_id?.toLowerCase()}`,
      // design
      bodyBgColor: "#fff",
      containerBgColor: "#fff",
      headingColor: "#000",
      textColor: "#000",
      borderColor: "#fff",
      buttonBgColor: "#000",
      buttonTextColor: "#fff",
      showBorder: true,
      // content
      contentHeading: "",
      contentText: "",
      contentName: "",
      contentEmail: "",
      contentButtonText: "",
      contentTerms: "",
      contentSuccess: "",
      showSegments: true,
      segmentRequired: true,
      contentSegments: "Additional field",
    },
    shouldUnregister: false,
  });
  const { handleSubmit, errors, watch, setValue, formState } = methods;
  const formValues = watch([
    "bodyBgColor",
    "containerBgColor",
    "headingColor",
    "textColor",
    "borderColor",
    "buttonBgColor",
    "buttonTextColor",
    "showBorder",
    "contentHeading",
    "contentText",
    "contentName",
    "contentEmail",
    "contentTerms",
    "contentButtonText",
    "contentSuccess",
    "contentSegments",
    "showSegments",
    "segmentRequired",
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [logoSmallPreview, setLogoSmallPreview] =
    useState<ImagePreviewType>(null);
  const [logoSmallUrl, setLogoSmallUrl] = useState("");

  const logoFormData = [
    {
      key: COMMON.ASSETS_INVITATION_FOLDER,
      fileName: setImageName(
        "invite",
        logo_uuid,
        !!logoSmallPreview,
        inviteDesign?.images?.logo_id
      ),
      filePreviewData: logoSmallPreview,
      filePreviewCallback: setLogoSmallUrl,
    },
  ];

  const formData = {
    tenant_id: userInfo.id,
    company_id: selectedCompany.id,
    program_id: selectedLoyalty.id,
    invitation_design_id: inviteDesign?.id,
    background_color_hex: checkHexColor(formValues.bodyBgColor),
    container_background_color_hex: checkHexColor(formValues.containerBgColor),
    title_label_color_hex: checkHexColor(formValues.headingColor),
    content_label_color_hex: checkHexColor(formValues.textColor),
    send_button_background_color_hex: checkHexColor(formValues.buttonBgColor),
    send_button_label_color_hex: checkHexColor(formValues.buttonTextColor),
    display_container_border: formValues.showBorder,
    container_border_color_hex: checkHexColor(formValues.borderColor),
    terms_of_service_url: inviteDesign?.terms_of_service_url,
    strings: {
      title_label_text: formValues.contentHeading,
      description_label_text: formValues.contentText,
      send_button_label_text: formValues.contentButtonText,
      name_label_text: formValues.contentName,
      email_label_text: formValues.contentEmail,
      terms_of_service_label_text: formValues.contentTerms,
      success_message_label_text: formValues.contentSuccess,
      segments_label_text: formValues.contentSegments,
    },
    images: {
      logo_id: logoFormData[0].fileName,
    },
  };

  // upload to s3 state
  const [uploadPhotoError, setUploadPhotoError] = useState(false);
  const [logosUploaded, setLogosUploaded] = useState(false);

  function initFormValues() {
    setValue("bodyBgColor", inviteDesign.background_color_hex);
    setValue("containerBgColor", inviteDesign.container_background_color_hex);
    setValue("headingColor", inviteDesign.title_label_color_hex);
    setValue("contentText", inviteDesign.strings.description_label_text);
    setValue("textColor", inviteDesign.content_label_color_hex);
    setValue("contentHeading", inviteDesign.strings.title_label_text);
    setValue("contentEmail", inviteDesign.strings.email_label_text);
    setValue("contentTerms", inviteDesign.strings.terms_of_service_label_text);
    setValue("contentName", inviteDesign.strings.name_label_text);
    setValue("borderColor", inviteDesign.container_border_color_hex);
    setValue("buttonBgColor", inviteDesign.send_button_background_color_hex);
    setValue("buttonTextColor", inviteDesign.send_button_label_color_hex);
    setValue("contentButtonText", inviteDesign.strings.send_button_label_text);
    // setValue("contentSegments", inviteDesign.strings.segments_text);
    setValue(
      "contentSuccess",
      inviteDesign.strings.success_message_label_text ||
        "You have successfully joined! Check your email."
    );
    setValue("showBorder", inviteDesign.display_container_border);
  }

  const resetForm = () => {
    initFormValues();
    setLogoSmallPreview(null);
  }

  const onSubmit = () => {
    setIsLoading(true);

    const uploadLogos = uploadFilesToS3(logoFormData);
    uploadLogos
      .then(() => {
        setLogosUploaded(true);
        setLogoSmallPreview(null);
      })
      .catch(() => {
        setLogosUploaded(false);
        setUploadPhotoError(true);
      });
  };

  // call api once logos are uploaded
  useEffect(() => {
    if (logosUploaded && !uploadPhotoError) {
      updateInviteDesign(formData);
    }
  }, [logosUploaded, uploadPhotoError]);

  useEffect(() => {
    fetchInviteDesign({
      tenant_id: userInfo.id,
      company_id: selectedCompany.id,
      program_id: selectedLoyalty.id,
    });
  }, []);

  useEffect(() => {
    if (inviteDesign || hasError) {
      setIsLoading(false);
      setLogosUploaded(false);
    }
  }, [hasError, inviteDesign]);

  useEffect(() => {
    if (inviteDesign) {
      initFormValues();
      getFilesFromS3(logoFormData).then(() => setLogoSmallPreview(null));
    }
  }, [inviteDesign]);

  if (hasError) {
    return <Error />;
  }

  if (!inviteDesign) {
    return <ContentLoader />;
  }

  return (
    <>
      {uploadPhotoError && (
        <Alert variant="error">
          There was an error while uploading logos. Try later.
        </Alert>
      )}
      {inviteDesignUpdated && (
        <Alert variant="success" sticky>
          Invite form design successfully updated
        </Alert>
      )}
      <Grid container>
        <Grid item sm={12} md={5} lg={5}>
          <FormProvider {...methods}>
            <FormStyled onSubmit={handleSubmit(onSubmit)}>
              <Tabs
                value={activeTab}
                // @ts-expect-error
                onChange={(_, newValue: number) => setActiveTab(newValue)}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tabs.Item disableRipple label="Publish" />
                <Tabs.Item disableRipple label="Content" />
                <Tabs.Item disableRipple label="Style" />
              </Tabs>
              <Tabs.Panel value={activeTab} index={0}>
                <TextField
                  name="publicDomain"
                  placeholder="Public domain"
                  label="Public domain"
                  id="form-input-public-domain"
                  disabled
                />
                <Typography component="p" variant="body1">
                  Your form is publicly available on Talisman domain. Send
                  people to the form and they can easily join your loyalty
                  program.
                </Typography>
              </Tabs.Panel>
              <Tabs.Panel value={activeTab} index={1}>
                <TextField
                  name="contentHeading"
                  placeholder="Heading"
                  label="Heading"
                  id="form-input-invite-heading"
                />
                <TextField
                  name="contentText"
                  placeholder="Paragraph"
                  label="Paragraph"
                  id="form-input-invite-text"
                />
                <TextField
                  name="contentName"
                  placeholder="Name and surname label"
                  label="Name and surname label"
                  id="form-input-name"
                  error={errors.contentName}
                  required
                />
                <TextField
                  name="contentEmail"
                  placeholder="Email label"
                  label="Email label"
                  id="form-input-email"
                  error={errors.contentEmail}
                  required
                />
                <TextField
                  name="contentTerms"
                  placeholder="Terms of Service message"
                  label="Terms of Service message"
                  id="form-input-terms"
                  error={errors.contentTerms}
                  required
                />
                <TextField
                  name="contentButtonText"
                  placeholder="Button text"
                  label="Button text"
                  id="form-input-button-text"
                  error={errors.contentButtonText}
                  required
                />
                <TextField
                  name="contentSuccess"
                  placeholder="Success message"
                  label="Success message"
                  id="form-input-success-text"
                  error={errors.contentSuccess}
                  required
                />
                <Typography component="p" variant="subtitle2">
                  Additional field
                </Typography>
                <Checkbox
                  name="showSegments"
                  value={formValues.showSegments}
                  label="Show additional field"
                />
                {formValues.showSegments && (
                  <>
                    <TextField
                      name="contentSegments"
                      placeholder="Additional field"
                      label="Additional field"
                      id="form-input-success-text"
                      error={errors.contentSegments}
                      offsetbottom="false"
                      required
                    />
                    <Checkbox
                      name="segmentRequired"
                      value={formValues.segmentRequired}
                      label="Additional field is required"
                    />
                  </>
                )}
              </Tabs.Panel>
              <Tabs.Panel value={activeTab} index={2}>
                <ColorPicker
                  value={formValues.bodyBgColor}
                  name="bodyBgColor"
                  label="Body background color"
                />
                <ColorPicker
                  value={formValues.containerBgColor}
                  name="containerBgColor"
                  label="Container background color"
                />
                <ColorPicker
                  value={formValues.headingColor}
                  name="headingColor"
                  label="Title color"
                />
                <ColorPicker
                  value={formValues.textColor}
                  name="textColor"
                  label="Text color"
                />
                <Checkbox
                  name="showBorder"
                  value={formValues.showBorder}
                  label="Show border"
                />
                {formValues.showBorder && (
                  <ColorPicker
                    value={formValues.borderColor}
                    name="borderColor"
                    label="Border color"
                  />
                )}
                <ColorPicker
                  value={formValues.buttonBgColor}
                  name="buttonBgColor"
                  label="Button background color"
                />
                <ColorPicker
                  value={formValues.buttonTextColor}
                  name="buttonTextColor"
                  label="Button text color"
                />
                <InputFile
                  label="Logo (horizontal)"
                  helpText="Your picture should be a square image with dimensions of at least 80px. PNG, JPG, and SVG file types are acceptable."
                  name="form-logo"
                  setPreviewClb={setLogoSmallPreview}
                  aspectRatio={160 / 50}
                  previewClassName="cropped-form-logo"
                  preview={logoSmallPreview}
                />
              </Tabs.Panel>
              <FormSubmitStyled>
                <ButtonRow>
                  <Button
                    loading={isLoading}
                    onClick={handleSubmit(onSubmit)}
                    type="submit"
                    disabled={!formState.isDirty}
                  >
                    Save changes
                  </Button>
                  <Button
                    disabled={isLoading || !formState.isDirty}
                    type="reset"
                    color="default"
                    onClick={resetForm}
                  >
                    Reset
                  </Button>
                </ButtonRow>
              </FormSubmitStyled>
            </FormStyled>
          </FormProvider>
        </Grid>
        <Grid item md={7} lg={7}>
          <InviteFormPreview
            bodyBgColor={checkHexColor(formValues.bodyBgColor)}
            containerBgColor={checkHexColor(formValues.containerBgColor)}
            headingColor={checkHexColor(formValues.headingColor)}
            textColor={checkHexColor(formValues.textColor)}
            borderColor={checkHexColor(formValues.borderColor)}
            buttonBgColor={checkHexColor(formValues.buttonBgColor)}
            buttonTextColor={checkHexColor(formValues.buttonTextColor)}
            showBorder={formValues.showBorder}
            logoPreview={logoSmallPreview}
            logoUrl={logoSmallUrl}
            // content
            formTitle={formValues.contentHeading}
            formText={formValues.contentText}
            contentName={formValues.contentName}
            contentEmail={formValues.contentEmail}
            buttonText={formValues.contentButtonText}
            contentTermsText={formValues.contentTerms}
            contentTermsUrl="https://www.notion.so/Terms-and-Privacy-c5621892e00d4fffb271f552ec000889"
            contentSuccessMsg={formValues.contentSuccess}
            contentSegments={formValues.contentSegments}
            segmentRequired={formValues.segmentRequired}
            showSegments={formValues.showSegments}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(InviteDesign);

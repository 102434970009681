import React from "react";
import { TabsProps as MUTabsProps } from "@material-ui/core";
import { TabPanelStyled, TabsStyled, TabStyled } from "./styled";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...rest } = props;

  return (
    <TabPanelStyled
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...rest}
    >
      {children}
    </TabPanelStyled>
  );
};

interface TabsProps {
  Panel: typeof TabPanel;
  Item: typeof TabStyled;
}

const Tabs: React.FC<MUTabsProps> & TabsProps = ({ children, ...props }) => (
  <TabsStyled {...props}>{children}</TabsStyled>
);

Tabs.Panel = TabPanel;
Tabs.Item = TabStyled;

export default Tabs;

import React, { useEffect } from "react";
import {
  withRouter,
  Switch,
  Route,
  useParams,
  RouteComponentProps,
} from "react-router";
import { Redirect } from "react-router-dom";
import { ContentLoader, SectionNav } from "@components/common";
import { COMMON, ROUTES } from "@constants";
import {
  LoyaltyType,
  LoyaltyCustomerProfileType,
  SelectLoyaltyType,
  CustomerType,
} from "@types";
import Transactions from "../../LoyaltiesTransactions";
import AddPointTransaction from "../../AddPointTransaction";
import AddStampTransaction from "../../AddStampTransaction";
import CardSettings from "../../CardSettings";

interface CardOverviewProps {
  selectedLoyalty: LoyaltyType;
  selectedCustomer: CustomerType;
  loyaltyAccount: LoyaltyCustomerProfileType;
  loyaltyAccounts: LoyaltyCustomerProfileType[];
  selectLoyalty(data: SelectLoyaltyType): void;
  profile: LoyaltyCustomerProfileType;
  isProfileUpdated: boolean;
}

type RouterType = {
  loyaltyId: string;
};

const CardOverview: React.FC<CardOverviewProps & RouteComponentProps> = ({
  selectedCustomer,
  loyaltyAccount,
  loyaltyAccounts,
  selectLoyalty,
}) => {
  const { loyaltyId } = useParams<RouterType>();

  const AddTransactionRoute =
    loyaltyAccount?.program.type === "points"
      ? AddPointTransaction
      : AddStampTransaction;

  useEffect(() => {
    selectLoyalty({ id: loyaltyId });
  }, [loyaltyId, loyaltyAccounts]);

  if (!selectedCustomer || !loyaltyAccount) {
    return <ContentLoader />;
  }

  return (
    <>
      <SectionNav
        variant="secondary"
        navItems={COMMON.LOYALTY_CUSTOMER_SUB_NAV(
          selectedCustomer.id,
          loyaltyAccount.id
        )}
        titleBefore={loyaltyAccount?.program.name}
        titleAfter={`Current balance: ${loyaltyAccount.balance}`}
      />
      <Switch>
        <Route
          exact
          path={ROUTES.CUSTOMERS_PROFILE_TRANSACTIONS}
          component={Transactions}
        />
        <Route
          exact
          path={ROUTES.CUSTOMERS_PROFILE_CARD_SETTINGS}
          component={CardSettings}
        />
        <Route
          exact
          path={ROUTES.CUSTOMERS_PROFILE_ADD_TRANSACTIONS}
          component={AddTransactionRoute}
        />
        <Redirect to={ROUTES.CUSTOMERS_PROFILE_TRANSACTIONS} />
      </Switch>
    </>
  );
};

export default withRouter(CardOverview);

import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { injectIntl } from "react-intl";
import { loyaltySelectors } from "../../selectors";
import { mainSelectors } from "../../../main/selectors";
import { authSelectors } from "../../../auth/selectors";
import * as loyaltyActions from "../../actions";
import Component from "./components";

const mapStateToProps = (state) => ({
  userInfo: authSelectors.getUser(state),
  selectedLoyalty: mainSelectors.getSelectedLoyalty(state),
  selectedCompany: mainSelectors.getSelectedCompany(state),
  emailDesign: loyaltySelectors.getEmailDesignItem(state),
  emailDesigns: loyaltySelectors.getEmailDesignList(state),
  emailDesignUpdated: loyaltySelectors.getEmailDesignUpdated(state),
  hasError: loyaltySelectors.getError(state),
  status: loyaltySelectors.getStatus(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      updateEmailDesign: loyaltyActions.updateEmailDesign,
      fetchEmailDesign: loyaltyActions.fetchEmailDesign,
    },
    dispatch
  );

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default injectIntl(ConnectedComponent);

import {
  StatusType,
  CardDesignType,
  CardDesignListItemType,
  InviteDesignType,
  InviteDesignListItemType,
  EmailDesignListItemType,
  EmailDesignType,
} from "@types";

export type LoyaltyStateType = {
  cardDesignList: CardDesignListItemType[];
  cardDesignItem?: CardDesignListItemType;
  inviteDesignList: InviteDesignListItemType[];
  inviteDesignItem?: InviteDesignListItemType;
  emailDesignList: EmailDesignListItemType[];
  emailDesignItem?: EmailDesignType;
  cardDesignUpdated: boolean;
  inviteDesignUpdated: boolean;
  emailDesignUpdated: boolean;
  status: StatusType;
  error: boolean;
};

// ============== PASS DESIGN ============ //

export interface FetchCardDesignType {
  tenant_id: string;
  company_id: string;
  program_id: string;
}

// TODO: remove Add action
// export interface AddCardDesignType extends CardDesignType {
//   tenant_id: string;
//   company_id: string;
//   program_id: string;
// }

export interface UpdateCardDesignType extends CardDesignType {
  tenant_id: string;
  company_id: string;
  program_id: string;
  pass_design_id: string;
}

// ============== INVITE DESIGN ============ //

export interface FetchInviteDesignType {
  tenant_id: string;
  company_id: string;
  program_id: string;
}

export interface UpdateInviteDesignType extends InviteDesignType {
  tenant_id: string;
  company_id: string;
  program_id: string;
  invitation_design_id: string;
}

// ============== Email DESIGN ============ //

export interface FetchEmailDesignType {
  tenant_id: string;
  company_id: string;
  program_id: string;
  // pass_email_design_id: string;
}

export interface UpdateEmailDesignType extends EmailDesignType {
  tenant_id: string;
  company_id: string;
  program_id: string;
  pass_email_design_id: string;
}

export const LoyaltiesTypes = {
  // Pass Design
  // ADD_CARD_DESIGN: "loyalties/ADD_CARD_DESIGN",
  // ADD_CARD_DESIGN_SUCCEEDED: "loyalties/ADD_CARD_DESIGN_SUCCEEDED",
  // ADD_CARD_DESIGN_FAILED: "loyalties/ADD_CARD_DESIGN_FAILED",

  CLEAR_STATE: "loyalties/CLEAR_STATE",

  UPDATE_CARD_DESIGN: "loyalties/UPDATE_CARD_DESIGN",
  UPDATE_CARD_DESIGN_SUCCEEDED: "loyalties/UPDATE_CARD_DESIGN_SUCCEEDED",
  UPDATE_CARD_DESIGN_FAILED: "loyalties/UPDATE_CARD_DESIGN_FAILED",

  FETCH_CARD_DESIGN_LIST: "loyalties/FETCH_CARD_DESIGN_LIST",
  FETCH_CARD_DESIGN_LIST_SUCCEEDED:
    "loyalties/FETCH_CARD_DESIGN_LIST_SUCCEEDED",
  FETCH_CARD_DESIGN_LIST_FAILED: "loyalties/FETCH_CARD_DESIGN_LIST_FAILED",

  // Invite Design
  UPDATE_INVITE_DESIGN: "loyalties/UPDATE_INVITE_DESIGN",
  UPDATE_INVITE_DESIGN_SUCCEEDED: "loyalties/UPDATE_INVITE_DESIGN_SUCCEEDED",
  UPDATE_INVITE_DESIGN_FAILED: "loyalties/UPDATE_INVITE_DESIGN_FAILED",

  FETCH_INVITE_DESIGN_LIST: "loyalties/FETCH_INVITE_DESIGN_LIST",
  FETCH_INVITE_DESIGN_LIST_SUCCEEDED:
    "loyalties/FETCH_INVITE_DESIGN_LIST_SUCCEEDED",
  FETCH_INVITE_DESIGN_LIST_FAILED: "loyalties/FETCH_INVITE_DESIGN_LIST_FAILED",

  // Email Design
  UPDATE_EMAIL_DESIGN: "loyalties/UPDATE_EMAIL_DESIGN",
  UPDATE_EMAIL_DESIGN_SUCCEEDED: "loyalties/UPDATE_EMAIL_DESIGN_SUCCEEDED",
  UPDATE_EMAIL_DESIGN_FAILED: "loyalties/UPDATE_EMAIL_DESIGN_FAILED",

  FETCH_EMAIL_DESIGN_LIST: "loyalties/FETCH_EMAIL_DESIGN_LIST",
  FETCH_EMAIL_DESIGN_LIST_SUCCEEDED:
    "loyalties/FETCH_EMAIL_DESIGN_LIST_SUCCEEDED",
  FETCH_EMAIL_DESIGN_LIST_FAILED: "loyalties/FETCH_EMAIL_DESIGN_LIST_FAILED",
} as const;

// ============== PASS DESIGN ============ //

// Add Card design
// export type addCardDesignActionType = {
//   type: typeof LoyaltiesTypes.ADD_CARD_DESIGN;
//   payload: AddCardDesignType;
// };

// export type addCardDesignSucceededActionType = {
//   type: typeof LoyaltiesTypes.ADD_CARD_DESIGN_SUCCEEDED;
//   payload: CardDesignListItemType[];
// };

// export type addCardDesignFailedActionType = {
//   type: typeof LoyaltiesTypes.ADD_CARD_DESIGN_FAILED;
// };

// Update Card design
export type updateCardDesignActionType = {
  type: typeof LoyaltiesTypes.UPDATE_CARD_DESIGN;
  payload: UpdateCardDesignType;
};

export type updateCardDesignSucceededActionType = {
  type: typeof LoyaltiesTypes.UPDATE_CARD_DESIGN_SUCCEEDED;
  payload: CardDesignListItemType;
};

export type updateCardDesignFailedActionType = {
  type: typeof LoyaltiesTypes.UPDATE_CARD_DESIGN_FAILED;
};

// Fetch Card design
export type fetchCardDesignActionType = {
  type: typeof LoyaltiesTypes.FETCH_CARD_DESIGN_LIST;
  payload: FetchCardDesignType;
};

export type fetchCardDesignSucceededActionType = {
  type: typeof LoyaltiesTypes.FETCH_CARD_DESIGN_LIST_SUCCEEDED;
  payload: CardDesignListItemType[];
};

export type fetchCardDesignFailedActionType = {
  type: typeof LoyaltiesTypes.FETCH_CARD_DESIGN_LIST_FAILED;
};

// =============== INVITE DESIGNS ============ //

// Update Invite design
export type updateInviteDesignActionType = {
  type: typeof LoyaltiesTypes.UPDATE_INVITE_DESIGN;
  payload: UpdateInviteDesignType;
};

export type updateInviteDesignSucceededActionType = {
  type: typeof LoyaltiesTypes.UPDATE_INVITE_DESIGN_SUCCEEDED;
  payload: InviteDesignListItemType;
};

export type updateInviteDesignFailedActionType = {
  type: typeof LoyaltiesTypes.UPDATE_INVITE_DESIGN_FAILED;
};

// Fetch Invite design
export type fetchInviteDesignActionType = {
  type: typeof LoyaltiesTypes.FETCH_INVITE_DESIGN_LIST;
  payload: FetchInviteDesignType;
};

export type fetchInviteDesignSucceededActionType = {
  type: typeof LoyaltiesTypes.FETCH_INVITE_DESIGN_LIST_SUCCEEDED;
  payload: InviteDesignListItemType[];
};

export type fetchInviteDesignFailedActionType = {
  type: typeof LoyaltiesTypes.FETCH_INVITE_DESIGN_LIST_FAILED;
};

// =============== EMAIL DESIGNS ============ //

// Fetch Email design
export type fetchEmailDesignActionType = {
  type: typeof LoyaltiesTypes.FETCH_EMAIL_DESIGN_LIST;
  payload: FetchEmailDesignType;
};

export type fetchEmailDesignSucceededActionType = {
  type: typeof LoyaltiesTypes.FETCH_EMAIL_DESIGN_LIST_SUCCEEDED;
  payload: EmailDesignListItemType[];
};

export type fetchEmailDesignFailedActionType = {
  type: typeof LoyaltiesTypes.FETCH_EMAIL_DESIGN_LIST_FAILED;
};

// Update Invite design
export type updateEmailDesignActionType = {
  type: typeof LoyaltiesTypes.UPDATE_EMAIL_DESIGN;
  payload: UpdateEmailDesignType;
};

export type updateEmailDesignSucceededActionType = {
  type: typeof LoyaltiesTypes.UPDATE_EMAIL_DESIGN_SUCCEEDED;
  payload: EmailDesignListItemType;
};

export type updateEmailDesignFailedActionType = {
  type: typeof LoyaltiesTypes.UPDATE_EMAIL_DESIGN_FAILED;
};

export type clearStateActionType = {
  type: typeof LoyaltiesTypes.CLEAR_STATE;
};

export type loyaltyActionTypes =
  // Pass
  // | addCardDesignActionType
  // | addCardDesignSucceededActionType
  // | addCardDesignFailedActionType
  | clearStateActionType
  | updateCardDesignActionType
  | updateCardDesignSucceededActionType
  | updateCardDesignFailedActionType
  | fetchCardDesignActionType
  | fetchCardDesignSucceededActionType
  | fetchCardDesignFailedActionType

  // Invite
  | updateInviteDesignActionType
  | updateInviteDesignSucceededActionType
  | updateInviteDesignFailedActionType
  | fetchInviteDesignActionType
  | fetchInviteDesignSucceededActionType
  | fetchInviteDesignFailedActionType

  // Email
  | updateEmailDesignActionType
  | updateEmailDesignSucceededActionType
  | updateEmailDesignFailedActionType
  | fetchEmailDesignActionType
  | fetchEmailDesignSucceededActionType
  | fetchEmailDesignFailedActionType;

import {
  TransactionType,
  CustomerType,
  InviteDesignListItemType,
  SegmentType,
  SelectLoyaltyType,
} from "@types";
import {
  CustomersTypes,
  FetchCustomerListType,
  FetchLoyaltyAccountsListType,
  AddLoyaltyAccountType,
  InviteLoyaltyType,
  FetchLoyaltyTransactionListType,
  ResendLoyaltyAccountPassType,
  UpdateCustomerType,
  FetchInvitationDesignsType,
  FetchSegmentsType,
  AddSegmentType,
  RemoveSegmentType,
  SelectCustomerType,
  customersActionTypes,
  FetchCustomerByEmail,
  FetchLoyaltyAccountType,
  PassesType,
  LoyaltyAccountType,
} from "./types";

// Add Loyalty
export const addLoyalty = (
  payload: AddLoyaltyAccountType
): customersActionTypes => ({
  type: CustomersTypes.ADD_LOYALTY,
  payload,
});

export const addLoyaltySucceeded = (
  payload: CustomerType
): customersActionTypes => ({
  type: CustomersTypes.ADD_LOYALTY_SUCCEEDED,
  payload,
});

export const addLoyaltyFailed = (): customersActionTypes => ({
  type: CustomersTypes.ADD_LOYALTY_FAILED,
});

// Invite Loyalty
export const inviteLoyalty = (
  payload: InviteLoyaltyType
): customersActionTypes => ({
  type: CustomersTypes.INVITE_LOYALTY,
  payload,
});

export const inviteLoyaltySucceeded = (
  payload: CustomerType
): customersActionTypes => ({
  type: CustomersTypes.INVITE_LOYALTY_SUCCEEDED,
  payload,
});

export const inviteLoyaltyFailed = (): customersActionTypes => ({
  type: CustomersTypes.INVITE_LOYALTY_FAILED,
});

// Update Customer
export const updateCustomer = (
  payload: UpdateCustomerType
): customersActionTypes => ({
  type: CustomersTypes.UPDATE_CUSTOMER,
  payload,
});

export const updateCustomerSucceeded = (
  payload: CustomerType
): customersActionTypes => ({
  type: CustomersTypes.UPDATE_CUSTOMER_SUCCEEDED,
  payload,
});

export const updateCustomerFailed = (): customersActionTypes => ({
  type: CustomersTypes.UPDATE_CUSTOMER_FAILED,
});

// Add Loyalty Transaction
export const addLoyaltyTransaction = (
  payload: FetchCustomerListType
): customersActionTypes => ({
  type: CustomersTypes.ADD_LOYALTY_TRANSACTION,
  payload,
});

export const addLoyaltyTransactionSucceeded = (
  payload: TransactionType
): customersActionTypes => ({
  type: CustomersTypes.ADD_LOYALTY_TRANSACTION_SUCCEEDED,
  payload,
});

export const addLoyaltyTransactionFailed = (): customersActionTypes => ({
  type: CustomersTypes.ADD_LOYALTY_TRANSACTION_FAILED,
});

// Fetch Loyalty Customer Profile
// export const fetchLoyalties = (
//   payload: FetchLoyaltyCustomerProfileType
// ): customersActionTypes => ({
//   type: CustomersTypes.FETCH_LOYALTIES,
//   payload,
// });
//
// export const fetchLoyaltiesSucceeded = (
//   payload: CustomerType[]
// ): customersActionTypes => ({
//   type: CustomersTypes.FETCH_LOYALTIES_SUCCEEDED,
//   payload,
// });
//
// export const fetchLoyaltiesFailed = () => ({
//   type: CustomersTypes.FETCH_LOYALTIES_FAILED,
// });

// Fetch Customer Transaction List
export const fetchCustomerTransactionList = (
  payload: FetchLoyaltyTransactionListType
): customersActionTypes => ({
  type: CustomersTypes.FETCH_CUSTOMER_TRANSACTION_LIST,
  payload,
});

export const fetchCustomerTransactionListSucceeded = (
  payload: TransactionType[]
): customersActionTypes => ({
  type: CustomersTypes.FETCH_CUSTOMER_TRANSACTION_LIST_SUCCEEDED,
  payload,
});

export const fetchCustomerTransactionListFailed = () => ({
  type: CustomersTypes.FETCH_CUSTOMER_TRANSACTION_LIST_FAILED,
});

// Fetch Loyalty Transaction List
export const fetchLoyaltyTransactionList = (
  payload: FetchLoyaltyTransactionListType
): customersActionTypes => ({
  type: CustomersTypes.FETCH_LOYALTY_TRANSACTION_LIST,
  payload,
});

export const fetchLoyaltyTransactionListSucceeded = (
  payload: TransactionType[]
): customersActionTypes => ({
  type: CustomersTypes.FETCH_LOYALTY_TRANSACTION_LIST_SUCCEEDED,
  payload,
});

export const fetchLoyaltyTransactionListFailed = () => ({
  type: CustomersTypes.FETCH_LOYALTY_TRANSACTION_LIST_FAILED,
});

// Fetch Loyalty Segment List
export const fetchLoyaltySegmentList = (
  payload: FetchSegmentsType
): customersActionTypes => ({
  type: CustomersTypes.FETCH_CUSTOMER_SEGMENT_LIST,
  payload,
});

export const fetchLoyaltySegmentListSucceeded = (
  payload: SegmentType[]
): customersActionTypes => ({
  type: CustomersTypes.FETCH_CUSTOMER_SEGMENT_LIST_SUCCEEDED,
  payload,
});

export const fetchLoyaltySegmentListFailed = () => ({
  type: CustomersTypes.FETCH_CUSTOMER_SEGMENT_LIST_FAILED,
});

// Add Loyalty Segment
export const addLoyaltySegment = (
  payload: AddSegmentType
): customersActionTypes => ({
  type: CustomersTypes.ADD_CUSTOMER_SEGMENT,
  payload,
});

export const addLoyaltySegmentSucceeded = (
  payload: SegmentType
): customersActionTypes => ({
  type: CustomersTypes.ADD_CUSTOMER_SEGMENT_SUCCEEDED,
  payload,
});

export const addLoyaltySegmentFailed = () => ({
  type: CustomersTypes.ADD_CUSTOMER_SEGMENT_FAILED,
});

// Remove Loyalty Segment
export const removeLoyaltySegment = (
  payload: RemoveSegmentType
): customersActionTypes => ({
  type: CustomersTypes.REMOVE_LOYALTY_SEGMENT,
  payload,
});

export const removeLoyaltySegmentSucceeded = (): customersActionTypes => ({
  type: CustomersTypes.REMOVE_LOYALTY_SEGMENT_SUCCEEDED,
});

export const removeLoyaltySegmentFailed = () => ({
  type: CustomersTypes.REMOVE_LOYALTY_SEGMENT_FAILED,
});

// Fetch Customer list
export const fetchCustomerList = (
  payload: FetchCustomerListType
): customersActionTypes => ({
  type: CustomersTypes.FETCH_CUSTOMER_LIST,
  payload,
});

export const fetchCustomerListSucceeded = (
  payload: CustomerType[]
): customersActionTypes => ({
  type: CustomersTypes.FETCH_CUSTOMER_LIST_SUCCEEDED,
  payload,
});

export const fetchCustomerListFailed = (): customersActionTypes => ({
  type: CustomersTypes.FETCH_CUSTOMER_LIST_FAILED,
});

// Fetch Loyalty Customer list
export const fetchLoyaltyList = (
  payload: FetchLoyaltyAccountsListType
): customersActionTypes => ({
  type: CustomersTypes.FETCH_LOYALTIES,
  payload,
});

export const fetchLoyaltyListListSucceeded = (
  payload: CustomerType[]
): customersActionTypes => ({
  type: CustomersTypes.FETCH_LOYALTIES_SUCCEEDED,
  payload,
});

export const fetchLoyaltyListFailed = (): customersActionTypes => ({
  type: CustomersTypes.FETCH_LOYALTIES_FAILED,
});

// Resend Loyalty Customer Pass
export const resendLoyaltyPass = (
  payload: ResendLoyaltyAccountPassType
): customersActionTypes => ({
  type: CustomersTypes.RESEND_LOYALTY_PASS,
  payload,
});

export const resendLoyaltyPassSucceeded = (): customersActionTypes => ({
  type: CustomersTypes.RESEND_LOYALTY_PASS_SUCCEEDED,
});

export const resendLoyaltyPassFailed = (): customersActionTypes => ({
  type: CustomersTypes.RESEND_LOYALTY_PASS_FAILED,
});

// Fetch Invitation Designs
export const fetchInvitationDesigns = (
  payload: FetchInvitationDesignsType
): customersActionTypes => ({
  type: CustomersTypes.FETCH_INVITATION_DESIGNS,
  payload,
});

export const fetchInvitationDesignsSucceeded = (
  payload: InviteDesignListItemType
): customersActionTypes => ({
  type: CustomersTypes.FETCH_INVITATION_DESIGNS_SUCCEEDED,
  payload,
});

export const fetchInvitationDesignsFailed = (): customersActionTypes => ({
  type: CustomersTypes.FETCH_INVITATION_DESIGNS_FAILED,
});

// fetch Customer by Email
export const fetchCustomerByEmail = (
  payload: FetchCustomerByEmail
): customersActionTypes => ({
  type: CustomersTypes.FETCH_CUSTOMER_BY_EMAIL,
  payload,
});

export const fetchCustomerByEmailSucceeded = (
  payload: CustomerType[]
): customersActionTypes => ({
  type: CustomersTypes.FETCH_CUSTOMER_BY_EMAIL_SUCCEEDED,
  payload,
});

export const fetchCustomerByEmailFailed = (): customersActionTypes => ({
  type: CustomersTypes.FETCH_CUSTOMER_BY_EMAIL_FAILED,
});

export const selectCustomer = (
  payload: SelectCustomerType
): customersActionTypes => ({
  type: CustomersTypes.SELECT_CUSTOMER,
  payload,
});

export const selectLoyalty = (
  payload: SelectLoyaltyType
): customersActionTypes => ({
  type: CustomersTypes.SELECT_LOYALTY,
  payload,
});

export const resetAddTransactionForm = (): customersActionTypes => ({
  type: CustomersTypes.RESET_ADD_TRANSACTION_FORM,
});

export const resetAddCustomerForm = (): customersActionTypes => ({
  type: CustomersTypes.RESET_ADD_CUSTOMER_FORM,
});

export const resetAddSegmentForm = (): customersActionTypes => ({
  type: CustomersTypes.RESET_ADD_SEGMENT_FORM,
});

export const resetRemoveSegmentForm = (): customersActionTypes => ({
  type: CustomersTypes.RESET_REMOVE_SEGMENT_FORM,
});

export const resetLoyaltySettingsForm = (): customersActionTypes => ({
  type: CustomersTypes.RESET_LOYALTY_SETTINGS_FORM,
});

export const cleanFormError = () => ({
  type: CustomersTypes.CLEAN_FORM_ERROR,
});

// Fetch Passes
export const fetchPasses = (
  payload: FetchLoyaltyAccountType
): customersActionTypes => ({
  type: CustomersTypes.FETCH_PASSES,
  payload,
});

export const fetchPassesSucceeded = (
  payload: PassesType[]
): customersActionTypes => ({
  type: CustomersTypes.FETCH_PASSES_SUCCEEDED,
  payload,
});

export const fetchPassesFailed = (): customersActionTypes => ({
  type: CustomersTypes.FETCH_PASSES_FAILED,
});

// Fetch Loyalty Account Download
export const fetchLoyaltyAccount = (
  payload: FetchLoyaltyAccountType
): customersActionTypes => ({
  type: CustomersTypes.FETCH_LOYALTY_ACCOUNT,
  payload,
});

export const fetchLoyaltyAccountSucceeded = (
  payload: LoyaltyAccountType[]
): customersActionTypes => ({
  type: CustomersTypes.FETCH_LOYALTY_ACCOUNT_SUCCEEDED,
  payload,
});

export const fetchLoyaltyAccountFailed = (): customersActionTypes => ({
  type: CustomersTypes.FETCH_LOYALTY_ACCOUNT_FAILED,
});

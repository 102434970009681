// import { RootStateType } from "../reducer";

const getIntegrationList = (state: any) => state.account.integrations;
const getCurrentIntegration = (state: any) => state.account.currentIntegration;
const getIntegrationsUpdated = (state: any) =>
  state.account.integrationsUpdated;
const getIntegrationsAdded = (state: any) => state.account.integrationsAdded;
const getError = (state: any) => state.account.error;
const getStatus = (state: any) => state.account.status;

export const accountSelectors = {
  getIntegrationList,
  getCurrentIntegration,
  getIntegrationsUpdated,
  getIntegrationsAdded,
  getError,
  getStatus,
};

import React from "react";
import { Box, BoxProps } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

interface ButtonRowProps {
  direction?: "row" | "column";
}

const ButtonRowStyled = withStyles((theme) => ({
  root: {
    "& > *": {
      marginRight: theme.spacing(2),
    },
  },
}))(Box);

export const ButtonRow: React.FC<ButtonRowProps & BoxProps> = ({
  direction = "row",
  children,
  ...props
}) => (
  <ButtonRowStyled display="flex" flexDirection={direction} {...props}>
    {children}
  </ButtonRowStyled>
);

export default ButtonRow;

import React from "react";
import { Button, ButtonProps, CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

interface ButtonComponentProps {
  loading?: boolean;
  component?: any;
  to?: string;
}

const BorderLinearProgress = withStyles((theme) => ({
  colorPrimary: {
    color: theme.palette.grey[200],
  },
}))(CircularProgress);

const ButtonComponent: React.FC<ButtonComponentProps & ButtonProps> = ({
  variant = "contained",
  color = "primary",
  loading,
  disabled,
  ...props
}) => (
  <Button
    startIcon={loading && <BorderLinearProgress size={16} />}
    variant={variant}
    color={color}
    disabled={disabled || loading}
    {...props}
  />
);

export default ButtonComponent;

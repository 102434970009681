export type ModalDataType = {
  [key: string]: unknown;
};
export type ModalStateType = {
  type: null | string;
  data: null | ModalDataType;
};

export const ModalTypes = {
  SHOW_MODAL: "modal/SHOW_MODAL",
  HIDE_MODAL: "modal/HIDE_MODAL",
} as const;

export type ShowModalType = {
  type: typeof ModalTypes.SHOW_MODAL;
  payload: {
    type: string;
    data: ModalDataType;
  };
};

export type HideModalType = {
  type: typeof ModalTypes.HIDE_MODAL;
};

export type ModalActionTypes = ShowModalType | HideModalType;

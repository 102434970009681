import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { injectIntl } from "react-intl";
import * as loyaltyActions from "../../actions";
import { mainSelectors } from "../../../main/selectors";
import { onboardingSelectors } from "../../../onboarding/selectors";
import * as modalActions from "../../../modal/actions";
import * as mainActions from "../../../main/actions";
import Component from "./components";

const mapStateToProps = (state) => ({
  selectedLoyalty: mainSelectors.getSelectedLoyalty(state),
  loyaltyList: mainSelectors.getLoyaltyList(state),
  createdLoyalty: onboardingSelectors.getCreatedLoyalty(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      selectLoyalty: mainActions.selectLoyalty,
      clearState: loyaltyActions.clearState,
      showModal: modalActions.showModal,
    },
    dispatch
  );

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default injectIntl(ConnectedComponent);

import React, { useEffect, useState } from "react";
import { TextField } from "@components/common";
import { VALIDATION } from "@constants";
import { Typography } from "@material-ui/core";
import {
  FormattedMessage,
  injectIntl,
  useIntl,
  WrappedComponentProps,
} from "react-intl";
import { RouteComponentProps } from "react-router";
import { useForm, FormProvider } from "react-hook-form";
import { AuthRouterStateType } from "@types";
import cognitoId from "../../../../cognitoId";
import {
  WrapperStyled,
  FormStyled,
  TitleStyled,
  SubmitButtonStyled,
  LogoStyled,
} from "../styled";

const ResetPassword = ({
  history,
  location,
}: RouteComponentProps<{}, {}, AuthRouterStateType> &
  WrappedComponentProps) => {
  const methods = useForm();
  const { handleSubmit, errors } = methods;
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [showEmailInput, setShowEmailInput] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    if (!location.state?.email) {
      setShowEmailInput(true);
    }
  }, [location.state?.email]);

  const onSubmit = ({ password, verifyCode, email }) => {
    setIsLoading(true);

    const cognitoUser = cognitoId.setCognitoUser(
      location.state?.email || email
    );

    if (cognitoUser) {
      cognitoUser.confirmPassword(verifyCode, password, {
        onSuccess() {
          // redirect to login form
          history.push({
            pathname: "./",
            state: {
              successMsg: intl.formatMessage({
                id: "reset.success",
              }),
            },
          });
        },
        onFailure(err) {
          setErrorMsg(err.message);
          setIsLoading(false);
        },
      });
      return;
    }
    history.push({
      pathname: "./",
      state: {
        errorMsg: intl.formatMessage({
          id: "general.notifications.error",
        }),
      },
    });
  };

  return (
    <WrapperStyled
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <LogoStyled />
      <TitleStyled variant="h2">
        <FormattedMessage id="reset.heading" />
      </TitleStyled>
      <FormProvider {...methods}>
        <FormStyled>
          {showEmailInput && (
            <TextField
              name="email"
              type="email"
              placeholder={intl.formatMessage({
                id: "input.email.placeholder",
              })}
              pattern={{
                value: VALIDATION.EMAIL_VALIDATION_REGEXP,
                message: intl.formatMessage({
                  id: "input.email.pattern.error",
                }),
              }}
              label={intl.formatMessage({ id: "input.email.label" })}
              error={errors.email}
              required
            />
          )}
          <TextField
            name="verifyCode"
            type="text"
            placeholder={intl.formatMessage({
              id: "input.verifyCode.placeholder",
            })}
            label={intl.formatMessage({ id: "input.verifyCode.label" })}
            error={errors.verifyCode}
            required
          />
          <TextField
            name="password"
            type="password"
            placeholder={intl.formatMessage({
              id: "input.password.placeholder",
            })}
            label={intl.formatMessage({ id: "input.password.label" })}
            error={errors.password}
            required
          />
          <SubmitButtonStyled
            onClick={handleSubmit(onSubmit)}
            disabled={isLoading}
            loading={isLoading}
            type="submit"
          >
            <FormattedMessage id="reset.submit" />
          </SubmitButtonStyled>
        </FormStyled>
      </FormProvider>
      {errorMsg && (
        <Typography variant="caption" paragraph color="error">
          {errorMsg}
        </Typography>
      )}
    </WrapperStyled>
  );
};

export default injectIntl(ResetPassword);

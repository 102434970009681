import React from "react";
import { TextField, Button, Checkbox } from "@components/common";
import { Typography } from "@material-ui/core";
import { VALIDATION } from "@constants";
import { useIntl } from "react-intl";
import { ImagePreviewType } from "@types";
import {
  FormContainerStyled,
  FormBodyStyled,
  FormTitleStyled,
  FormTextStyled,
  FormInnerStyled,
  FormLogoContainerStyled,
  FormLogoPreviewStyled,
  BodyStyled,
} from "./styled";

interface ContentFormPreviewProps {
  bodyBgColor: string;
  containerBgColor: string;
  borderColor: string;
  titleColor: string;
  textColor: string;
  buttonBgColor: string;
  buttonTextColor: string;
  showBorder?: boolean;

  formTitle: string;
  formText: string;
  contentName: string;
  contentEmail: string;
  buttonText: string;
  contentTermsText: string;
  contentTermsUrl: string;
  logoPreview?: ImagePreviewType;
  logoUrl: string;
  contentSuccessMsg: string;
  onSubmit?: () => void;
  isLoading?: boolean;
  contentSegments: string;
  showSuccess?: boolean;
  errors: any;
}

const ContentFormPreview: React.FC<ContentFormPreviewProps> = ({
  // styles
  bodyBgColor,
  containerBgColor,
  borderColor,
  buttonBgColor,
  buttonTextColor,
  showBorder,
  titleColor,
  textColor,

  // content
  formTitle,
  formText,
  contentName,
  contentEmail,
  buttonText,
  contentTermsText,
  contentTermsUrl,
  logoUrl,
  contentSuccessMsg,
  contentSegments,

  onSubmit,
  isLoading,
  errors,
  showSuccess = false,
}) => {
  const intl = useIntl();

  return (
    <BodyStyled style={{ backgroundColor: bodyBgColor }}>
      <FormContainerStyled>
        <FormBodyStyled
          style={{
            backgroundColor: containerBgColor,
            borderColor: showBorder ? borderColor : "transparent",
          }}
        >
          <FormLogoContainerStyled>
            <FormLogoPreviewStyled
              style={{
                backgroundImage: `url('data:image/png;base64,${logoUrl}')`,
              }}
            />
          </FormLogoContainerStyled>
          <FormTitleStyled style={{ color: titleColor }}>
            {formTitle}
          </FormTitleStyled>
          <FormTextStyled style={{ color: textColor }}>
            {formText}
          </FormTextStyled>
          <FormInnerStyled style={{ color: textColor }}>
            <TextField
              name="fullName"
              id="form-invite-name"
              label={contentName}
              placeholder={contentName}
              variant="filled"
              type="name"
              error={errors.fullName}
              hideRequiredMsg
              required
            />
            <TextField
              name="email"
              id="form-invite-email"
              label={contentEmail}
              placeholder={contentEmail}
              variant="filled"
              error={errors.email}
              pattern={{
                value: VALIDATION.EMAIL_VALIDATION_REGEXP,
                message: intl.formatMessage({
                  id: "input.email.pattern.error",
                }),
              }}
              hideRequiredMsg
              type="email"
              required
            />
            {contentSegments && (
              <TextField
                name="segments"
                id="form-invite-segments"
                label={contentSegments}
                placeholder={contentSegments}
                variant="filled"
                error={errors.segments}
                hideRequiredMsg
                type="text"
                required
              />
            )}
            <Checkbox
              label={
                <a target="_blank" rel="noreferrer" href={contentTermsUrl}>
                  {contentTermsText}
                </a>
              }
              name="terms"
              required
              error={errors.terms}
            />
            <Button
              style={{
                backgroundColor: buttonBgColor,
                color: buttonTextColor,
                marginBottom: 16,
              }}
              fullWidth
              size="large"
              onClick={onSubmit}
              loading={isLoading}
            >
              {buttonText}
            </Button>
          </FormInnerStyled>
          {showSuccess && (
            <Typography
              style={{ color: textColor }}
              variant="h6"
              align="center"
            >
              {contentSuccessMsg}
            </Typography>
          )}
        </FormBodyStyled>
      </FormContainerStyled>
    </BodyStyled>
  );
};

export default ContentFormPreview;

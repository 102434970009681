import React, { useState, useEffect } from "react";
import { Grid, MenuItem } from "@material-ui/core";
import { withRouter, RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { useIntl, WrappedComponentProps, FormattedMessage } from "react-intl";
import {
  Button,
  ButtonRow,
  TextField,
  Checkbox,
  Alert,
  Select,
} from "@components/common";
import { VALIDATION, ROUTES } from "@constants";
import {
  LoyaltyType,
  UserProfileType,
  LoyaltyCustomerProfileType,
  CompanyType,
  CustomerType,
} from "@types";
import {
  AddLoyaltyAccountType,
  FetchCustomerByEmail,
} from "../../../../../types";
import { SubHeadingStyled, HeaderStyled, FormSubmitStyled } from "./styled";

interface AddCustomerProps {
  programs: LoyaltyType[];
  selectedLoyalty: LoyaltyType;
  selectedCompany: CompanyType;
  userInfo: UserProfileType;
  addLoyalty(data: AddLoyaltyAccountType): void;
  fetchCustomerByEmail(data: FetchCustomerByEmail): void;
  loyalties: LoyaltyCustomerProfileType[];
  loyaltyInvited: LoyaltyCustomerProfileType;
  customer: CustomerType;
  retrievedByEmail: CustomerType[] | null;
  handleBack: () => void;
  hasError: boolean;
  resetAddCustomerForm(): void;
}

const AddCustomer = ({
  programs,
  selectedCompany,
  userInfo,
  addLoyalty,
  loyaltyInvited,
  hasError,
  customer,
  loyalties,
  resetAddCustomerForm,
}: AddCustomerProps & RouteComponentProps & WrappedComponentProps) => {
  const methods = useForm();
  const intl = useIntl();
  const { handleSubmit, errors, reset } = methods;
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = ({ fullName, email, programId }) => {
    setIsLoading(true);

    addLoyalty({
      tenant_id: userInfo.id,
      company_id: selectedCompany.id,
      program_id: programId,
      name: fullName,
      email,
    });
  };

  useEffect(() => {
    if (loyaltyInvited || hasError) {
      reset({
        programId: "",
        acknowledge: false,
      });
      setIsLoading(false);
    }
  }, [loyaltyInvited, hasError]);

  useEffect(() => {
    return resetAddCustomerForm();
  }, []);

  return (
    <>
      {loyaltyInvited && <Alert>Card was successfully added</Alert>}
      {hasError && (
        <Alert variant="error">
          <FormattedMessage id="general.notifications.error" />
        </Alert>
      )}
      <HeaderStyled>
        {/* @ts-ignore-start */}
        <SubHeadingStyled component="h3" variant="h3">
          Add New Card
        </SubHeadingStyled>
        {/* @ts-ignore-end */}
      </HeaderStyled>
      <Grid item md={6} lg={4}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              name="email"
              placeholder={intl.formatMessage({
                id: "input.email.placeholder",
              })}
              label={intl.formatMessage({ id: "input.email.label" })}
              type="email"
              error={errors.email}
              pattern={{
                value: VALIDATION.EMAIL_VALIDATION_REGEXP,
                message: intl.formatMessage({
                  id: "input.email.pattern.error",
                }),
              }}
              defaultValue={customer?.email}
              id="form-input-email"
              disabled
              required
            />
            <Select
              name="programId"
              label="Choose loyalty program"
              id="select-loyalty"
              defaultValue=""
              required
              error={errors.programId}
            >
              <MenuItem selected disabled value="">
                Choose loyalty
              </MenuItem>
              {programs?.map((program) => {
                const isMember = loyalties.find(
                  (item) => item.program.id === program.id
                );
                return (
                  <MenuItem
                    disabled={isMember}
                    key={program.id}
                    value={program.id}
                  >
                    {program.name}
                    {isMember && " (Already a member)"}
                  </MenuItem>
                );
              })}
            </Select>
            <Checkbox
              required
              name="acknowledge"
              error={errors.acknowledge}
              label={intl.formatMessage({
                id: "customers.add.form.acknowledge",
              })}
            />
            <FormSubmitStyled>
              <ButtonRow>
                <Button
                  disabled={isLoading}
                  loading={isLoading}
                  onClick={handleSubmit(onSubmit)}
                  type="submit"
                >
                  Add card
                </Button>
                <Button
                  disabled={isLoading}
                  type="button"
                  color="default"
                  component={Link}
                  to={ROUTES.CUSTOMERS_OVERVIEW}
                >
                  <FormattedMessage id="customers.add.form.back" />
                </Button>
              </ButtonRow>
            </FormSubmitStyled>
          </form>
        </FormProvider>
      </Grid>
    </>
  );
};

export default withRouter(AddCustomer);

import S3 from "aws-sdk/clients/s3";
import { Buffer } from "buffer";
import { uploadFile } from "react-s3";
import { encodeBlob } from "@utils/common";
import { COMMON } from "@constants";
import { AssetType } from "@types";

// react-s3 fix
global.Buffer = global.Buffer || Buffer;

// TODO: Replace this logic once API will be updated
const isLogoDefault = (logoName: string) =>
  logoName?.includes("default") || logoName?.includes("talisman");

const config = {
  region: process.env.REACT_AWS_REGION,
  bucketName: process.env.REACT_AWS_BUCKET_NAME,
  accessKeyId: process.env.REACT_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_AWS_SECRET_ACCESS_KEY,
};

export const uploadFilesToS3 = (files: AssetType[], type = "image") => {
  const uploads: any[] = [];

  files.forEach((file) => {
    if (!file.filePreviewData) return;
    if (isLogoDefault(file.fileName)) return;

    const asset = new File(
      [file.filePreviewData],
      `${file.fileName}.${COMMON.ASSETS_FORMAT}`,
      {
        type: `${type}/${COMMON.ASSETS_FORMAT}`,
      }
    );

    const uploadConfig = {
      bucketName: config.bucketName,
      dirName: file.key,
      region: config.region,
      accessKeyId: config.accessKeyId,
      secretAccessKey: config.secretAccessKey,
      ACL: file.public ? "public-read" : "",
    };

    const logoUpload = uploadFile(asset, uploadConfig);
    uploads.push(logoUpload);
  });

  return Promise.all(uploads);
};

export const getFilesFromS3 = (files: AssetType[]) => {
  const pr = [];

  files.forEach((file) => {
    if (!file.fileName) return;
    const key = isLogoDefault(file.fileName)
      ? `${COMMON.ASSETS_DEFAULT_FOLDER}/${file.fileName}.${COMMON.ASSETS_FORMAT}`
      : `${file.key}/${file.fileName}.${COMMON.ASSETS_FORMAT}`;

    const s3 = new S3(config);
    const res = s3
      .getObject({
        Bucket: config.bucketName || "",
        Key: key,
      })
      .promise()
      .then((data) => {
        if (file.filePreviewCallback) {
          file.filePreviewCallback(encodeBlob(data.Body));
        }
      });

    pr.push(res);
  });

  return Promise.all(pr);
};

export const getFileFromS3 = (fileName: string, key: string) => {
  const Key = isLogoDefault(fileName)
    ? `${COMMON.ASSETS_DEFAULT_FOLDER}/${fileName}.${COMMON.ASSETS_FORMAT}`
    : `${key}/${fileName}.${COMMON.ASSETS_FORMAT}`;

  const s3 = new S3(config);
  const res = s3
    .getObject({
      Bucket: config.bucketName || "",
      Key,
    })
    .promise()
    .then((data) => encodeBlob(data.Body));
  return res;
};

import React from "react";
import { Box } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { ImagePreviewType } from "@types";
import {
  CardStyled,
  CardLogoPreview,
  CardInfoValueLargeStyled,
  CardInfoValueSmallStyled,
  CardInfoTitleCenteredStyled,
  CardVersionStyled,
  CardContainerStyled,
  AndroidHeadStyled,
  CardCodePlaceholder,
  CardLogoStyled,
  CardLogoMainStyled,
  CardInfoTitleStyled,
  CardInfoTitleAdditionalStyled,
} from "./styled";

interface CardAndroidPreviewProps {
  bgColor: string;
  textColor: string;
  labelColor: string;
  companyName: string;
  loyaltyName: string;
  logoSmallPreview: ImagePreviewType;
  logoLargePreview: ImagePreviewType;
  hidePoints: boolean;
  logoSmallUrl?: string;
  logoLargeUrl?: string;
  cardNumberTitle: string;
  cardNameTitle: string;
  cardPointsTitle: string;
  assetsLoading: boolean;
}

const CardAndroidPreview: React.FC<CardAndroidPreviewProps> = ({
  bgColor,
  logoSmallPreview,
  logoLargePreview,
  companyName,
  loyaltyName,
  hidePoints,
  logoSmallUrl,
  logoLargeUrl,
  cardNumberTitle,
  cardNameTitle,
  cardPointsTitle,
  assetsLoading,
}) => {
  return (
    <CardContainerStyled>
      <CardVersionStyled>Android version</CardVersionStyled>
      <CardStyled
        style={{
          backgroundColor: bgColor,
          minHeight: "448px",
        }}
      >
        <AndroidHeadStyled>
          {assetsLoading ? (
            <Skeleton variant="circle" width={44} height={44} />
          ) : (
            <CardLogoStyled>
              <CardLogoPreview
                style={
                  logoSmallPreview
                    ? undefined
                    : {
                        backgroundImage: `url('data:image/png;base64,${logoSmallUrl}')`,
                      }
                }
                className={
                  logoSmallPreview ? "cropped-logo-square" : "hide-preview"
                }
              />
            </CardLogoStyled>
          )}
          <CardInfoValueSmallStyled>{loyaltyName}</CardInfoValueSmallStyled>
          <CardInfoValueLargeStyled>{companyName}</CardInfoValueLargeStyled>
        </AndroidHeadStyled>
        {assetsLoading ? (
          <Skeleton variant="text" height={100} />
        ) : (
          <CardLogoMainStyled>
            <CardLogoPreview
              style={
                logoLargePreview
                  ? undefined
                  : {
                      backgroundImage: `url('data:image/png;base64,${logoLargeUrl}')`,
                    }
              }
              className={
                logoLargePreview ? "cropped-logo-cover" : "hide-preview"
              }
            />
          </CardLogoMainStyled>
        )}
        {!hidePoints && (
          <Box
            display="flex"
            flexDirection="column"
            alignContent="center"
            alignItems="center"
          >
            <CardInfoTitleStyled>{cardPointsTitle}</CardInfoTitleStyled>
            <CardInfoValueSmallStyled>100</CardInfoValueSmallStyled>
          </Box>
        )}
        <CardCodePlaceholder width="147" height="74" />
        <CardInfoTitleCenteredStyled>
          <CardInfoValueSmallStyled>01</CardInfoValueSmallStyled>
        </CardInfoTitleCenteredStyled>
      </CardStyled>
      <div>
        <CardInfoTitleAdditionalStyled>
          <CardInfoValueSmallStyled>
            <b>{cardNameTitle}</b>
          </CardInfoValueSmallStyled>
          <CardInfoValueSmallStyled>
            Alexandra Nováková
          </CardInfoValueSmallStyled>
        </CardInfoTitleAdditionalStyled>
        <CardInfoTitleAdditionalStyled>
          <CardInfoValueSmallStyled>
            <b>{cardNumberTitle}</b>
          </CardInfoValueSmallStyled>
          <CardInfoValueSmallStyled>01</CardInfoValueSmallStyled>
        </CardInfoTitleAdditionalStyled>
      </div>
    </CardContainerStyled>
  );
};

export default CardAndroidPreview;

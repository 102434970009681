import React from "react";
import { Provider } from "react-redux";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import CssBaseline from "@material-ui/core/CssBaseline";
import IntlProvider from "./i18n/IntlProvider";
import store from "./utils/store";
import theme from "./styles/theme";
import Routes from "./routes";

const App: React.FC = () => (
  <Provider store={store} key="provider">
    <IntlProvider>
      <MuiThemeProvider theme={theme}>
        <StyledThemeProvider theme={theme}>
          <CssBaseline>
            <Routes />
          </CssBaseline>
        </StyledThemeProvider>
      </MuiThemeProvider>
    </IntlProvider>
  </Provider>
);

export default App;

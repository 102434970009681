import React, { useState, useEffect } from "react";
import { Grid, MenuItem, Typography } from "@material-ui/core";
import { withRouter, RouteComponentProps } from "react-router";
import { useForm, FormProvider } from "react-hook-form";
import { useIntl, WrappedComponentProps, FormattedMessage } from "react-intl";
import {
  Button,
  ButtonLink,
  ButtonRow,
  TextField,
  Checkbox,
  Alert,
  Select,
  Link,
} from "@components/common";
import { VALIDATION, ROUTES } from "@constants";
import {
  LoyaltyType,
  UserProfileType,
  LoyaltyCustomerProfileType,
  CompanyType,
  CustomerType,
} from "@types";
import {
  AddLoyaltyAccountType,
  FetchCustomerByEmail,
} from "../../../../../types";
import {
  SubHeadingStyled,
  HeaderStyled,
  FormSubmitStyled,
} from "../../../styled";

interface AddCustomerProps {
  loyaltyList: LoyaltyType[];
  selectedLoyalty: LoyaltyType;
  selectedCompany: CompanyType;
  userInfo: UserProfileType;
  addLoyalty(data: AddLoyaltyAccountType): void;
  fetchCustomerByEmail(data: FetchCustomerByEmail): void;
  addedCustomer: LoyaltyCustomerProfileType;
  retrievedByEmail: CustomerType[] | null;
  handleBack: () => void;
  resetAddCustomerForm: () => void;
  hasError: boolean;
  customerExist: boolean;
}

const AddCustomer = ({
  loyaltyList,
  selectedCompany,
  userInfo,
  addLoyalty,
  // fetchCustomerByEmail,
  resetAddCustomerForm,
  addedCustomer,
  customerExist,
  retrievedByEmail,
  hasError,
}: AddCustomerProps & RouteComponentProps & WrappedComponentProps) => {
  const methods = useForm();
  const intl = useIntl();
  const { handleSubmit, errors, reset, watch } = methods;
  const [showFullForm, setShowFullForm] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const formValues = watch(["email"]);
  const { email } = formValues;

  const onSubmit = ({ fullName, email, programId }) => {
    setIsLoading(true);

    addLoyalty({
      tenant_id: userInfo.id,
      company_id: selectedCompany.id,
      program_id: programId,
      name: fullName,
      email,
    });
  };

  useEffect(() => {
    // TODO: waiting for API implementation
    // if (email && email.match(VALIDATION.EMAIL_VALIDATION_REGEXP)) {
    //   fetchCustomerByEmail({
    //     tenant_id: userInfo.id,
    //     company_id: selectedCompany.id,
    //     email: formValues.email,
    //   });
    // }
  }, [email]);

  useEffect(() => {
    if (retrievedByEmail?.length) {
      setShowFullForm(false);
    }
  }, [retrievedByEmail]);

  useEffect(() => {
    if (addedCustomer || hasError) {
      reset({
        fullName: "",
        email: "",
        permissions: false,
        acknowledge: false,
        programId: "",
      });
      setIsLoading(false);
    }
  }, [addedCustomer, hasError]);

  useEffect(() => {
    return resetAddCustomerForm();
  }, []);

  return (
    <>
      {addedCustomer && (
        <Alert>
          {customerExist
            ? `${addedCustomer.customer.name} has been already registered in this loyalty program `
            : `${addedCustomer.customer.name} was successfully added to your audience `}
          <Link
            style={{ paddingLeft: "6px" }}
            to={`/customers/${addedCustomer.customer.id}/overview`}
          >
            <FormattedMessage id="customers.add.profileLink" />
          </Link>
        </Alert>
      )}
      {hasError && (
        <Alert variant="error">
          <FormattedMessage id="general.notifications.error" />
        </Alert>
      )}
      <HeaderStyled>
        {/* @ts-ignore-start */}
        <SubHeadingStyled component="h3" variant="h3">
          <FormattedMessage id="customers.add.title" />
        </SubHeadingStyled>
        {/* @ts-ignore-end */}
      </HeaderStyled>
      <Grid item md={6} lg={4}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {showFullForm && (
              <TextField
                name="fullName"
                placeholder={intl.formatMessage({
                  id: "customers.add.form.name",
                })}
                label={intl.formatMessage({ id: "customers.add.form.name" })}
                error={errors.fullName}
                required
                id="form-input-full-name"
              />
            )}
            <TextField
              name="email"
              placeholder={intl.formatMessage({
                id: "input.email.placeholder",
              })}
              label={intl.formatMessage({ id: "input.email.label" })}
              type="email"
              error={errors.email}
              pattern={{
                value: VALIDATION.EMAIL_VALIDATION_REGEXP,
                message: intl.formatMessage({
                  id: "input.email.pattern.error",
                }),
              }}
              id="form-input-email"
              required
              helperText={
                !showFullForm &&
                `Existing customer: ${retrievedByEmail?.[0].name}`
              }
            />
            {!showFullForm && (
              <Typography color="error">
                The Customer already exists. Go to Customer and add a card
                there.{" "}
                <Link
                  to={`/customers/${retrievedByEmail?.[0].id}/add-card`}
                  color="error"
                  underline="always"
                >
                  Go to Customer
                </Link>
              </Typography>
            )}
            {showFullForm && (
              <>
                <Select
                  name="programId"
                  label="Choose loyalty program"
                  id="select-loyalty"
                  defaultValue=""
                  required
                  error={errors.programId}
                >
                  <MenuItem selected disabled value="">
                    Choose loyalty
                  </MenuItem>
                  {loyaltyList?.map((loyalty) => (
                    <MenuItem key={loyalty.id} value={loyalty.id}>
                      {loyalty.name}
                    </MenuItem>
                  ))}
                </Select>
                <Checkbox
                  required
                  name="permissions"
                  error={errors.permissions}
                  label={intl.formatMessage({
                    id: "customers.add.form.permissions",
                  })}
                />
                <Checkbox
                  required
                  name="acknowledge"
                  error={errors.acknowledge}
                  label={intl.formatMessage({
                    id: "customers.add.form.acknowledge",
                  })}
                />
                <FormSubmitStyled>
                  <ButtonRow>
                    <Button
                      disabled={isLoading}
                      loading={isLoading}
                      onClick={handleSubmit(onSubmit)}
                      type="submit"
                    >
                      <FormattedMessage id="customers.add.form.submit" />
                    </Button>
                    <ButtonLink
                      disabled={isLoading}
                      type="button"
                      color="default"
                      to={ROUTES.CUSTOMERS_OVERVIEW}
                    >
                      <FormattedMessage id="customers.add.form.back" />
                    </ButtonLink>
                  </ButtonRow>
                </FormSubmitStyled>
              </>
            )}
          </form>
        </FormProvider>
      </Grid>
    </>
  );
};

export default withRouter(AddCustomer);

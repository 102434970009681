import React from "react";
import RemoveSegmentModal from "../../../../customers/components/Customers/components/Segments/components/RemoveSegmentModal";
import ProgramWelcomeModal from "../../../../loyalties/components/Wrapper/components/WelcomeModal";

const MODAL_COMPONENTS = {
  REMOVE_SEGMENT_MODAL: RemoveSegmentModal,
  PROGRAM_WELCOME_MODAL: ProgramWelcomeModal,
};

const ModalRoot = ({ type, ...props }) => {
  if (!type) {
    return null;
  }

  const SpecificModal = MODAL_COMPONENTS[type];

  return <SpecificModal {...props} />;
};

export default ModalRoot;

import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { hideModal } from "../../../../../modal/actions";
import { resetOnboarding } from "../../../../../onboarding/actions";
import Component from "./components";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      hideModal,
      resetOnboarding,
    },
    dispatch
  );

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default ConnectedComponent;

import { Button } from "@material-ui/core";
import React from "react";
import ButtonLink from "../ButtonLink";
import { BoxStyled, HeadingStyled, SubHeadingStyled } from "./styled";

interface CTABoxProps {
  icon?: React.ReactElement;
  heading: string;
  subHeading: string;
  link: string;
  linkTitle: string;
  onClick?: () => void;
  btnTitle?: string;
}

const CTABox: React.FC<CTABoxProps> = ({
  icon,
  heading,
  subHeading,
  link,
  onClick,
  linkTitle,
  btnTitle,
}) => {
  return (
    <BoxStyled
      maxWidth={660}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      {icon && icon}
      <HeadingStyled variant="h3">{heading}</HeadingStyled>
      <SubHeadingStyled>{subHeading}</SubHeadingStyled>
      {link ? (
        <ButtonLink to={link}>{linkTitle}</ButtonLink>
      ) : (
        <Button onClick={onClick}>{btnTitle}</Button>
      )}
    </BoxStyled>
  );
};

export default CTABox;

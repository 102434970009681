import { AccountTypes, accountActionTypes } from "./types";

import {
  AddIntegrationType,
  UpdateIntegrationType,
  FetchIntegrationType,
  IntegrationType,
} from "../../types";

// Add Integration
export const addIntegration = (
  payload: AddIntegrationType
): accountActionTypes => ({
  type: AccountTypes.ADD_INTEGRATION,
  payload,
});

export const addIntegrationSucceeded = (
  payload: IntegrationType
): accountActionTypes => ({
  type: AccountTypes.ADD_INTEGRATION_SUCCEEDED,
  payload,
});

export const addIntegrationFailed = (): accountActionTypes => ({
  type: AccountTypes.ADD_INTEGRATION_FAILED,
});

// Update Integration
export const updateIntegration = (
  payload: UpdateIntegrationType
): accountActionTypes => ({
  type: AccountTypes.UPDATE_INTEGRATION,
  payload,
});

export const updateIntegrationSucceeded = (
  payload: IntegrationType
): accountActionTypes => ({
  type: AccountTypes.UPDATE_INTEGRATION_SUCCEEDED,
  payload,
});

export const updateIntegrationFailed = (): accountActionTypes => ({
  type: AccountTypes.UPDATE_INTEGRATION_FAILED,
});

// Fetch integration List
export const fetchIntegrationList = (
  payload: FetchIntegrationType
): accountActionTypes => ({
  type: AccountTypes.FETCH_INTEGRATION_LIST,
  payload,
});

export const fetchIntegrationListSucceeded = (
  payload: IntegrationType[]
): accountActionTypes => ({
  type: AccountTypes.FETCH_INTEGRATION_LIST_SUCCEEDED,
  payload,
});

export const fetchIntegrationListFailed = (): accountActionTypes => ({
  type: AccountTypes.FETCH_INTEGRATION_LIST_FAILED,
});

export const resetIntegrationForm = (): accountActionTypes => ({
  type: AccountTypes.RESET_INTEGRATION_FORM,
});

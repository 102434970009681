import { Tab, Tabs } from "@material-ui/core";
import styled from "styled-components";

export const TabsStyled = styled(Tabs)`
  border-bottom: 1px solid #eee;
  padding: 0 24px;
`;

export const TabStyled = styled(Tab)`
  min-width: auto;
  // padding: 0;
  font-size: 15px;
  font-weight: 500;
  text-transform: none;
  margin-bottom: -1px;
  min-height: 54px;

  &.MuiTab-textColorPrimary:not(.Mui-selected) {
    color: #000;
  }
`;

export const TabPanelStyled = styled.div`
  padding: 24px 24px 0;
`;

/* eslint-disable */
import React from "react";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import dayjs from "dayjs";
import localeData from "./messages";
import getMessages from "./utils/getMessagesByLang";
import { IntelProviderComponentProps } from "./types";

export const mapStateToProps = () => {
  const lang = "en-GB";
  const messages = getMessages(localeData, lang);

  dayjs.locale(lang);

  return {
    messages,
    locale: lang,
  };
};

const IntlProviderComponent: React.FC<IntelProviderComponentProps> = (
  props
) => {
  const { locale, children } = props;

  return (
    <IntlProvider key={locale} {...props}>
      {children}
    </IntlProvider>
  );
};

export default connect(mapStateToProps)(IntlProviderComponent);
/* eslint-enable */

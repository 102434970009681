import { ButtonBase, Menu } from "@material-ui/core";
import styled from "styled-components";

export const ButtonBaseStyled = styled(ButtonBase)`
  font-family: inherit;
`;

export const MenuStyled = styled(Menu)`
  margin-top: ${({ menuoverlapped }) =>
    menuoverlapped === "true" ? "-8px" : "8px"};
`;

import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { injectIntl } from "react-intl";
import { customersSelectors } from "../../../../selectors";
import Component from "./components";

const mapStateToProps = (state) => ({
  selectedCustomer: customersSelectors.getSelectedCustomer(state),
  transactionList: customersSelectors.getCustomerTransactions(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default injectIntl(ConnectedComponent);

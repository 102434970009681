import {
  CustomersStateType,
  CustomersTypes,
  customersActionTypes,
} from "./types";

export const defaultState: CustomersStateType = {
  customers: undefined,
  selectedCustomer: undefined,
  loyaltyAccount: undefined,
  loyaltyAccounts: undefined,
  customerUpdated: false,
  addedCustomer: null,
  customerExist: false,
  retrievedByEmail: undefined,
  customerTransactions: undefined,
  customerTransactionAdded: false,
  segments: undefined,
  segmentAdded: false,
  segmentRemoved: false,
  customerInviteResend: false,
  invitationDesigns: undefined,
  loyaltyInvited: false,
  passes: undefined,
  error: false,
  loyaltyAccountDownloadPass: undefined,
};

export default (
  state = defaultState,
  action: customersActionTypes
): CustomersStateType => {
  switch (action.type) {
    case CustomersTypes.ADD_LOYALTY:
      return {
        ...state,
        addedCustomer: undefined,
        customerExist: false,
        error: false,
      };

    case CustomersTypes.ADD_LOYALTY_SUCCEEDED:
      return {
        ...state,
        addedCustomer: action.payload.data,
        customerExist: action.payload.status === 200,
        error: false,
      };

    case CustomersTypes.ADD_LOYALTY_FAILED:
      return {
        ...state,
        addedCustomer: undefined,
        error: true,
      };
    case CustomersTypes.INVITE_LOYALTY:
      return {
        ...state,
        loyaltyInvited: false,
        error: false,
      };

    case CustomersTypes.INVITE_LOYALTY_SUCCEEDED:
      return {
        ...state,
        loyaltyInvited: true,
        error: false,
      };

    case CustomersTypes.INVITE_LOYALTY_FAILED:
      return {
        ...state,
        loyaltyInvited: false,
        error: true,
      };

    case CustomersTypes.UPDATE_CUSTOMER:
      return {
        ...state,
        customerUpdated: false,
        error: false,
      };

    case CustomersTypes.UPDATE_CUSTOMER_SUCCEEDED:
      return {
        ...state,
        customerUpdated: true,
        error: false,
      };

    case CustomersTypes.UPDATE_CUSTOMER_FAILED:
      return {
        ...state,
        customerUpdated: false,
        error: true,
      };

    case CustomersTypes.ADD_LOYALTY_TRANSACTION:
      return {
        ...state,
        error: false,
        customerTransactionAdded: undefined,
      };

    case CustomersTypes.ADD_LOYALTY_TRANSACTION_SUCCEEDED:
      return {
        ...state,
        customerTransactionAdded: true,
      };

    case CustomersTypes.ADD_LOYALTY_TRANSACTION_FAILED:
      return {
        ...state,
        error: true,
        customerTransactionAdded: false,
      };
    case CustomersTypes.FETCH_CUSTOMER_LIST:
      return {
        ...state,
        error: false,
      };

    case CustomersTypes.FETCH_CUSTOMER_LIST_SUCCEEDED:
      return {
        ...state,
        customers: action.payload,
        error: false,
      };

    case CustomersTypes.FETCH_CUSTOMER_LIST_FAILED:
      return {
        ...state,
        customers: undefined,
        error: true,
      };

    case CustomersTypes.FETCH_LOYALTIES:
      return {
        ...state,
        error: false,
      };

    case CustomersTypes.FETCH_LOYALTIES_SUCCEEDED:
      return {
        ...state,
        loyaltyAccounts: action.payload,
        error: false,
      };

    case CustomersTypes.FETCH_LOYALTIES_FAILED:
      return {
        ...state,
        loyaltyAccounts: undefined,
        error: true,
      };

    //    case CustomersTypes.FETCH_LOYALTY_CUSTOMER_PROFILE_SUCCEEDED:
    //      return {
    //        ...state,
    //        customerProfile: action.payload,
    //        error: false,
    //      };
    //
    //    case CustomersTypes.FETCH_LOYALTY_CUSTOMER_PROFILE_FAILED:
    //      return {
    //        ...state,
    //        customerProfile: undefined,
    //        error: true,
    //      };

    case CustomersTypes.FETCH_CUSTOMER_TRANSACTION_LIST_SUCCEEDED:
      return {
        ...state,
        customerTransactions: action.payload,
      };

    case CustomersTypes.FETCH_CUSTOMER_TRANSACTION_LIST_FAILED:
      return {
        ...state,
        customerTransactions: undefined,
        error: true,
      };

    case CustomersTypes.FETCH_LOYALTY_TRANSACTION_LIST:
      return {
        ...state,
      };

    case CustomersTypes.FETCH_LOYALTY_TRANSACTION_LIST_SUCCEEDED:
      return {
        ...state,
        loyaltyTransactions: action.payload,
      };

    case CustomersTypes.FETCH_LOYALTY_TRANSACTION_LIST_FAILED:
      return {
        ...state,
        loyaltyTransactions: undefined,
        error: true,
      };

    case CustomersTypes.FETCH_CUSTOMER_SEGMENT_LIST:
      return {
        ...state,
        error: false,
      };

    case CustomersTypes.FETCH_CUSTOMER_SEGMENT_LIST_SUCCEEDED:
      return {
        ...state,
        segments: action.payload,
        error: false,
      };

    case CustomersTypes.FETCH_CUSTOMER_SEGMENT_LIST_FAILED:
      return {
        ...state,
        segments: undefined,
        error: true,
      };

    case CustomersTypes.ADD_CUSTOMER_SEGMENT:
      return {
        ...state,
        segmentAdded: false,
        segmentRemoved: false,
        error: false,
      };

    case CustomersTypes.ADD_CUSTOMER_SEGMENT_SUCCEEDED:
      return {
        ...state,
        segmentAdded: true,
        error: false,
      };

    case CustomersTypes.ADD_CUSTOMER_SEGMENT_FAILED:
      return {
        ...state,
        segmentAdded: false,
        error: true,
      };

    case CustomersTypes.REMOVE_LOYALTY_SEGMENT:
      return {
        ...state,
        segmentAdded: false,
        segmentRemoved: false,
        error: false,
      };

    case CustomersTypes.REMOVE_LOYALTY_SEGMENT_SUCCEEDED:
      return {
        ...state,
        segmentRemoved: true,
        error: false,
      };

    case CustomersTypes.REMOVE_LOYALTY_SEGMENT_FAILED:
      return {
        ...state,
        segmentRemoved: false,
      };

    case CustomersTypes.RESEND_LOYALTY_PASS:
      return {
        ...state,
        customerInviteResend: false,
        error: false,
      };

    case CustomersTypes.RESEND_LOYALTY_PASS_SUCCEEDED:
      return {
        ...state,
        customerInviteResend: true,
        error: false,
      };

    case CustomersTypes.RESEND_LOYALTY_PASS_FAILED:
      return {
        ...state,
        customerInviteResend: false,
        error: true,
      };

    case CustomersTypes.FETCH_INVITATION_DESIGNS:
      return {
        ...state,
        error: false,
      };

    case CustomersTypes.FETCH_INVITATION_DESIGNS_SUCCEEDED:
      return {
        ...state,
        invitationDesigns: action.payload,
        error: false,
      };

    case CustomersTypes.FETCH_INVITATION_DESIGNS_FAILED:
      return {
        ...state,
        error: true,
      };

    case CustomersTypes.FETCH_CUSTOMER_BY_EMAIL:
      return {
        ...state,
        retrievedByEmail: null,
        error: false,
      };

    case CustomersTypes.FETCH_CUSTOMER_BY_EMAIL_SUCCEEDED:
      return {
        ...state,
        retrievedByEmail: action.payload.items,
        error: false,
      };

    case CustomersTypes.FETCH_CUSTOMER_BY_EMAIL_FAILED:
      return {
        ...state,
        retrievedByEmail: null,
        error: true,
      };

    case CustomersTypes.SELECT_CUSTOMER:
      return {
        ...state,
        selectedCustomer: state.customers?.items?.find(
          (customer) => customer.id === action.payload.id
        ),
      };

    case CustomersTypes.SELECT_LOYALTY:
      return {
        ...state,
        loyaltyAccount: state.loyaltyAccounts?.find(
          (loyalty) => loyalty.id === action.payload.id
        ),
      };

    case CustomersTypes.RESET_ADD_CUSTOMER_FORM:
      return {
        ...state,
        addedCustomer: null,
      };

    case CustomersTypes.RESET_ADD_TRANSACTION_FORM:
      return {
        ...state,
        customerTransactionAdded: false,
      };

    case CustomersTypes.RESET_ADD_SEGMENT_FORM:
      return {
        ...state,
        segmentAdded: false,
      };

    case CustomersTypes.RESET_REMOVE_SEGMENT_FORM:
      return {
        ...state,
        segmentRemoved: false,
      };

    case CustomersTypes.RESET_LOYALTY_SETTINGS_FORM:
      return {
        ...state,
        customerUpdated: false,
      };

    case CustomersTypes.FETCH_PASSES:
      return {
        ...state,
      };

    case CustomersTypes.FETCH_PASSES_SUCCEEDED:
      return {
        ...state,
        passes: action.payload,
      };

    case CustomersTypes.FETCH_PASSES_FAILED:
      return {
        ...state,
        passes: undefined,
      };

    case CustomersTypes.CLEAN_FORM_ERROR:
      return {
        ...state,
        error: false,
      };

    case CustomersTypes.FETCH_LOYALTY_ACCOUNT:
      return {
        ...state,
      };

    case CustomersTypes.FETCH_LOYALTY_ACCOUNT_SUCCEEDED:
      return {
        ...state,
        loyaltyAccountDownloadPass: action.payload,
      };

    case CustomersTypes.FETCH_LOYALTY_ACCOUNT_FAILED:
      return {
        ...state,
        error: true,
      };

    default:
      return state;
  }
};

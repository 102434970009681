import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { injectIntl } from "react-intl";
import { RootStateType } from "../../../modules/reducer";
import { authSelectors } from "../../../modules/auth";
import { mainSelectors } from "../../../modules/main";
import * as authActions from "../../../modules/auth/actions";
import * as mainActions from "../../../modules/main/actions";
import Component from "./components";

const mapStateToProps = (state: RootStateType) => ({
  user: authSelectors.getUser(state),
  loyaltyList: mainSelectors.getLoyaltyList(state),
  companyList: mainSelectors.getCompanyList(state),
  isSynced: authSelectors.getIsUserSynced(state),
  isAuthenticated: authSelectors.getIsUserAuthenticated(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      signedInSucceed: authActions.signedInSucceeded,
      signedInFailed: authActions.signedInFailed,
      fetchCompanyList: mainActions.fetchCompanyList,
    },
    dispatch
  );

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default injectIntl(ConnectedComponent);

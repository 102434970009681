import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { injectIntl } from "react-intl";
import { customersSelectors } from "../../../../selectors";
import { mainSelectors } from "../../../../../main/selectors";
import { authSelectors } from "../../../../../auth/selectors";
import * as customersActions from "../../../../actions";
import Component from "./components";

const mapStateToProps = (state) => ({
  selectedCompany: mainSelectors.getSelectedCompany(state),
  selectedLoyalty: mainSelectors.getSelectedLoyalty(state),
  programs: mainSelectors.getLoyaltyList(state),
  userInfo: authSelectors.getUser(state),
  customerList: customersSelectors.getCustomerList(state),
  hasError: customersSelectors.hasError(state),
  segments: customersSelectors.getSegments(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchCustomerList: customersActions.fetchCustomerList,
      resetAddCustomerForm: customersActions.resetAddCustomerForm,
      fetchSegments: customersActions.fetchLoyaltySegmentList,
    },
    dispatch
  );

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default injectIntl(ConnectedComponent);

const getCardDesignList = (state: any) => state.loyalties.cardDesignList;
const getCardDesignItem = (state: any) => state.loyalties.cardDesignItem;
const getCardDesignUpdated = (state: any) => state.loyalties.cardDesignUpdated;

const getInviteDesignList = (state: any) => state.loyalties.inviteDesignList;
const getInviteDesignItem = (state: any) => state.loyalties.inviteDesignItem;
const getInviteDesignUpdated = (state: any) =>
  state.loyalties.inviteDesignUpdated;

const getEmailDesignList = (state: any) => state.loyalties.emailDesignList;
const getEmailDesignItem = (state: any) => state.loyalties.emailDesignItem;
const getEmailDesignUpdated = (state: any) =>
  state.loyalties.emailDesignUpdated;

const getError = (state: any) => state.loyalties.error;
const getStatus = (state: any) => state.loyalties.status;

export const loyaltySelectors = {
  getCardDesignList,
  getCardDesignItem,
  getInviteDesignList,
  getInviteDesignItem,
  getCardDesignUpdated,
  getInviteDesignUpdated,
  getEmailDesignList,
  getEmailDesignItem,
  getEmailDesignUpdated,
  getError,
  getStatus,
};

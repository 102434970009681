import {
  CardDesignListItemType,
  InviteDesignListItemType,
  EmailDesignListItemType,
} from "@types";
import {
  LoyaltiesTypes,
  loyaltyActionTypes,
  UpdateInviteDesignType,
  UpdateCardDesignType,
  FetchCardDesignType,
  FetchInviteDesignType,
  UpdateEmailDesignType,
  FetchEmailDesignType,
} from "./types";

// ============= PASS DESIGN ============== //

export const fetchCardDesign = (
  payload: FetchCardDesignType
): loyaltyActionTypes => ({
  type: LoyaltiesTypes.FETCH_CARD_DESIGN_LIST,
  payload,
});

export const fetchCardDesignSucceeded = (
  payload: CardDesignListItemType[]
): loyaltyActionTypes => ({
  type: LoyaltiesTypes.FETCH_CARD_DESIGN_LIST_SUCCEEDED,
  payload,
});

export const fetchCardDesignFailed = (): loyaltyActionTypes => ({
  type: LoyaltiesTypes.FETCH_CARD_DESIGN_LIST_FAILED,
});

export const updateCardDesign = (
  payload: UpdateCardDesignType
): loyaltyActionTypes => ({
  type: LoyaltiesTypes.UPDATE_CARD_DESIGN,
  payload,
});

export const updateCardDesignSucceeded = (
  payload: CardDesignListItemType
): loyaltyActionTypes => ({
  type: LoyaltiesTypes.UPDATE_CARD_DESIGN_SUCCEEDED,
  payload,
});

export const updateCardDesignFailed = (): loyaltyActionTypes => ({
  type: LoyaltiesTypes.UPDATE_CARD_DESIGN_FAILED,
});

// ============= INVITE DESIGN ============== //

export const updateInviteDesign = (
  payload: UpdateInviteDesignType
): loyaltyActionTypes => ({
  type: LoyaltiesTypes.UPDATE_INVITE_DESIGN,
  payload,
});

export const updateInviteDesignSucceeded = (
  payload: InviteDesignListItemType
): loyaltyActionTypes => ({
  type: LoyaltiesTypes.UPDATE_INVITE_DESIGN_SUCCEEDED,
  payload,
});

export const updateInviteDesignFailed = (): loyaltyActionTypes => ({
  type: LoyaltiesTypes.UPDATE_INVITE_DESIGN_FAILED,
});

export const fetchInviteDesign = (
  payload: FetchInviteDesignType
): loyaltyActionTypes => ({
  type: LoyaltiesTypes.FETCH_INVITE_DESIGN_LIST,
  payload,
});

export const fetchInviteDesignSucceeded = (
  payload: InviteDesignListItemType[]
): loyaltyActionTypes => ({
  type: LoyaltiesTypes.FETCH_INVITE_DESIGN_LIST_SUCCEEDED,
  payload,
});

export const fetchInviteDesignFailed = (): loyaltyActionTypes => ({
  type: LoyaltiesTypes.FETCH_INVITE_DESIGN_LIST_FAILED,
});

// ============= INVITE DESIGN ============== //

export const updateEmailDesign = (
  payload: UpdateEmailDesignType
): loyaltyActionTypes => ({
  type: LoyaltiesTypes.UPDATE_EMAIL_DESIGN,
  payload,
});

export const updateEmailDesignSucceeded = (
  payload: EmailDesignListItemType
): loyaltyActionTypes => ({
  type: LoyaltiesTypes.UPDATE_EMAIL_DESIGN_SUCCEEDED,
  payload,
});

export const updateEmailDesignFailed = (): loyaltyActionTypes => ({
  type: LoyaltiesTypes.UPDATE_EMAIL_DESIGN_FAILED,
});

export const fetchEmailDesign = (
  payload: FetchEmailDesignType
): loyaltyActionTypes => ({
  type: LoyaltiesTypes.FETCH_EMAIL_DESIGN_LIST,
  payload,
});

export const fetchEmailDesignSucceeded = (
  payload: EmailDesignListItemType[]
): loyaltyActionTypes => ({
  type: LoyaltiesTypes.FETCH_EMAIL_DESIGN_LIST_SUCCEEDED,
  payload,
});

export const fetchEmailDesignFailed = (): loyaltyActionTypes => ({
  type: LoyaltiesTypes.FETCH_EMAIL_DESIGN_LIST_FAILED,
});

export const clearState = (): loyaltyActionTypes => ({
  type: LoyaltiesTypes.CLEAR_STATE,
});

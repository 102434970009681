import React from "react";
import { Typography } from "@material-ui/core";
import { TextField, Button, Checkbox } from "@components/common";
import { ImagePreviewType } from "@types";
import {
  FormContainerStyled,
  FormBodyStyled,
  FormTitleStyled,
  FormTextStyled,
  FormInnerStyled,
  FormLogoContainerStyled,
  FormLogoPreviewStyled,
} from "./styled";

interface InviteFormPreviewProps {
  bodyBgColor: string;
  containerBgColor: string;
  borderColor: string;
  headingColor: string;
  textColor: string;
  buttonBgColor: string;
  buttonTextColor: string;
  showBorder: boolean;

  formTitle: string;
  formText: string;
  contentName: string;
  contentEmail: string;
  buttonText: string;
  contentTermsText: string;
  contentTermsUrl: string;
  logoPreview: ImagePreviewType;
  logoUrl: string;
  contentSuccessMsg: string;
  contentSegments: string;
  segmentRequired: boolean;
  showSegments: boolean;
}

const InviteFormPreview: React.FC<InviteFormPreviewProps> = ({
  // styles
  bodyBgColor,
  containerBgColor,
  borderColor,
  buttonBgColor,
  buttonTextColor,
  showBorder,
  headingColor,
  textColor,

  // content
  formTitle,
  formText,
  contentName,
  contentEmail,
  buttonText,
  contentTermsText,
  contentTermsUrl,
  logoPreview,
  logoUrl,
  contentSuccessMsg,
  showSegments,
  contentSegments,
  segmentRequired,
}) => {
  return (
    <FormContainerStyled style={{ backgroundColor: bodyBgColor }}>
      <FormBodyStyled
        style={{
          backgroundColor: containerBgColor,
          borderColor: showBorder ? borderColor : "transparent",
        }}
      >
        {(logoPreview || logoUrl) && (
          <FormLogoContainerStyled>
            <FormLogoPreviewStyled
              style={
                logoPreview
                  ? undefined
                  : {
                      backgroundImage: `url('data:image/png;base64,${logoUrl}')`,
                    }
              }
              className={logoPreview ? "cropped-form-logo" : "hide-preview"}
            />
          </FormLogoContainerStyled>
        )}
        <FormTitleStyled style={{ color: headingColor }}>
          {formTitle}
        </FormTitleStyled>
        <FormTextStyled style={{ color: textColor }}>{formText}</FormTextStyled>
        <FormInnerStyled style={{ color: textColor }}>
          <TextField
            name="name"
            label={contentName}
            placeholder={contentName}
            disabled
            hooked={false}
            value={contentName}
            variant="filled"
          />
          <TextField
            name="email"
            label={contentEmail}
            placeholder={contentEmail}
            value={contentEmail}
            disabled
            hooked={false}
            variant="filled"
          />
          {showSegments && (
            <TextField
              name="company"
              label={contentSegments}
              placeholder={contentSegments}
              value={contentSegments}
              disabled
              hooked={false}
              required={segmentRequired}
              variant="filled"
            />
          )}
          <Checkbox
            hooked={false}
            label={
              <a target="_blank" rel="noreferrer" href={contentTermsUrl}>
                {contentTermsText}
              </a>
            }
            name="terms"
            checked
            disabled
          />
          <Button
            style={{ backgroundColor: buttonBgColor, color: buttonTextColor }}
            fullWidth
            size="large"
          >
            {buttonText}
          </Button>
        </FormInnerStyled>
        <Typography style={{ color: textColor }} variant="h6" align="center">
          {contentSuccessMsg}
        </Typography>
      </FormBodyStyled>
    </FormContainerStyled>
  );
};

export default InviteFormPreview;

// @ts-nocheck
import React from "react";
import {
  TableCell,
  MenuItem,
  TableProps,
  SvgIcon,
  Select,
} from "@material-ui/core";
import { ICONS } from "@constants";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { TableStyled } from "./styled";

interface TableCustomProps {
  TableCellFiltered: typeof TableCellFiltered;
  TableHeadCellWithFilter: typeof TableHeadCellWithFilter;
}

const useStyles = makeStyles({
  root: {
    display: "inline-flex",
    flexDirection: "row-reverse",
    paddingRight: "8px!important",
    paddingLeft: 32,
  },
  select: {
    border: 0,
    backgroundColor: "transparent",
  },
  icon: {
    left: 6,
    right: "auto",
  },
  iconOpen: {
    transform: "none",
  },
});

const Table: React.FC<TableProps> & TableCustomProps = TableStyled;

// Show only selected cell on mobile
export const TableCellFiltered = ({ isShown, ...props }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <TableCell
      padding="none"
      {...props}
      style={
        isMobile ? { display: isShown ? "table-cell" : "none" } : undefined
      }
    />
  );
};

// Show filter dropdown on Mobile
export const TableHeadCellWithFilter = ({ tableCells, value, onChange }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const classes = useStyles();

  return isMobile ? (
    <TableCell align="center">
      <Select
        IconComponent={React.forwardRef((props, ref) => (
          <SvgIcon
            {...props}
            aria-label="Filter"
            fill="none"
            viewBox="0 0 15 14"
            fontSize="small"
            component={(componentProps) => (
              <svg {...componentProps}>
                <path
                  d={ICONS.FILTER}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  stroke="#000"
                  strokeWidth="1"
                  fill="none"
                />
              </svg>
            )}
            ref={ref}
          />
        ))}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        displayEmpty
        disableUnderline
        classes={classes}
      >
        {tableCells.map((cell) => (
          <MenuItem key={cell} value={cell}>
            {cell}
          </MenuItem>
        ))}
      </Select>
    </TableCell>
  ) : (
    tableCells.map((cell) => (
      <TableCellFiltered key={cell} isShown={cell === value}>
        {cell}
      </TableCellFiltered>
    ))
  );
};

Table.TableHeadCellWithFilter = TableHeadCellWithFilter;

Table.TableCellFiltered = TableCellFiltered;

export default Table;

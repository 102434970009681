import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { injectIntl } from "react-intl";
import { customersSelectors } from "../../../../selectors";
import { mainSelectors } from "../../../../../main/selectors";
import { authSelectors } from "../../../../../auth/selectors";
import * as customersActions from "../../../../actions";
import Component from "./components";

const mapStateToProps = (state) => ({
  userInfo: authSelectors.getUser(state),
  loyaltyList: mainSelectors.getLoyaltyList(state),
  selectedCompany: mainSelectors.getSelectedCompany(state),
  selectedCustomer: customersSelectors.getSelectedCustomer(state),
  loyaltyAccount: customersSelectors.getLoyaltyAccount(state),
  hasError: customersSelectors.hasError(state),
  isTransactionAdded: customersSelectors.isTransactionAdded(state),
  selectedLoyalty: mainSelectors.getSelectedLoyalty(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      addLoyaltyTransaction: customersActions.addLoyaltyTransaction,
    },
    dispatch
  );

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default injectIntl(ConnectedComponent);

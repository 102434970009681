import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { injectIntl } from "react-intl";
import { loyaltySelectors } from "../../selectors";
import { mainSelectors } from "../../../main/selectors";
import { authSelectors } from "../../../auth/selectors";
import { onboardingSelectors } from "../../../onboarding/selectors";
import * as loyaltyActions from "../../actions";
import Component from "./components";

const mapStateToProps = (state) => ({
  userInfo: authSelectors.getUser(state),
  selectedLoyalty: mainSelectors.getSelectedLoyalty(state),
  selectedCompany: mainSelectors.getSelectedCompany(state),
  cardDesign: loyaltySelectors.getCardDesignItem(state),
  cardDesigns: loyaltySelectors.getCardDesignList(state),
  cardDesignsUpdated: loyaltySelectors.getCardDesignUpdated(state),
  hasError: loyaltySelectors.getError(state),
  createdLoyalty: onboardingSelectors.getCreatedLoyalty(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      updateCardDesign: loyaltyActions.updateCardDesign,
      fetchCardDesign: loyaltyActions.fetchCardDesign,
      clearState: loyaltyActions.clearState,
    },
    dispatch
  );

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default injectIntl(ConnectedComponent);

import React, { useState } from "react";
import { TextField, Link } from "@components/common";
import { useForm, FormProvider } from "react-hook-form";
import { Link as LinkIcon } from "@material-ui/icons";
import { Grid, Typography } from "@material-ui/core";
import { FormattedMessage, useIntl, WrappedComponentProps } from "react-intl";
import {
  CompanyType,
  UserProfileType,
  CreateLoyaltyType,
  ProgramCardType,
} from "@types";
import StampCardIcon from "-!react-svg-loader!../../../../../../../assets/icons/stamp-card.svg";
import {
  FormStyled,
  TitleStyled,
  SubmitButtonStyled,
  ButtonStyled,
  WrapperStyled,
  LinearProgressStyled,
} from "../../../../styled";
import {
  CardTypeButtonStyled,
  CardTypeButtonTitleStyled,
  CardTypeButtonSubTitleStyled,
  CardTypeButtonIconStyled,
  CardTypeExternalLink,
  FormBodyStyled,
  FormContainerStyled,
} from "./styled";

interface AddProgramFormProps {
  stepBack(): void;
  userData: UserProfileType;
  selectedCompany: CompanyType;
  addProgram(data: CreateLoyaltyType): void;
  cardType: ProgramCardType;
}
const AddProgramForm: React.FC<AddProgramFormProps & WrappedComponentProps> = ({
  selectedCompany,
  addProgram,
  userData,
  stepBack,
  cardType,
}) => {
  const intl = useIntl();
  const methods = useForm();
  const { handleSubmit, errors } = methods;
  const [isLoading, setIsLoading] = useState(false);
  const [stamps, setStamps] = useState<5 | 7 | 9>(5);

  const onSubmit = ({ programName }) => {
    setIsLoading(true);
    addProgram({
      tenant_id: userData.id,
      company_id: selectedCompany.id,
      name: programName,
      type: cardType,
      maximum_stamps_count: stamps,
    });
  };

  return (
    <WrapperStyled container direction="column" alignItems="center">
      <LinearProgressStyled variant="determinate" value={75} />
      <Grid item xs={12}>
        <TitleStyled align="center" variant="h3">
          What will be the program called?
        </TitleStyled>
      </Grid>
      <Grid item xs={12} sm={8} md={6} lg={5}>
        <Typography align="center">
          Companies usually choose “Loyalty program, Loyaly club or lorem ipsum
          dolor sit amet. This name will appear on all the communication with
          your customers.
        </Typography>
      </Grid>
      <FormContainerStyled item xs={12} sm={12} md={6}>
        <FormProvider {...methods}>
          <FormStyled>
            <FormBodyStyled>
              <TextField
                name="programName"
                type="text"
                placeholder={intl.formatMessage({
                  id: "input.loyalty.placeholder",
                })}
                label={intl.formatMessage({ id: "input.loyalty.label" })}
                error={errors.programName}
                required
              />
              {cardType === "stamps" && (
                <>
                  <Typography style={{ marginBottom: 0 }}>
                    How many stamps should your program have?
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    Note: You are will not be able to change this in the future.
                    Only by creating a new program.
                  </Typography>
                  <Grid
                    container
                    direction="row"
                    wrap="nowrap"
                    justifyContent="space-between"
                  >
                    {[5, 7, 9].map((item) => (
                      <CardTypeButtonStyled
                        key={item.toString()}
                        selected={item === stamps}
                        direction="column"
                        onClick={() => setStamps(item)}
                        disableRipple
                      >
                        <CardTypeButtonIconStyled>
                          <StampCardIcon />
                        </CardTypeButtonIconStyled>
                        <div>
                          <CardTypeButtonTitleStyled>
                            {item} stamps
                          </CardTypeButtonTitleStyled>
                          <CardTypeButtonSubTitleStyled>
                            {item - 1} + 1 free
                          </CardTypeButtonSubTitleStyled>
                        </div>
                      </CardTypeButtonStyled>
                    ))}
                  </Grid>
                </>
              )}
              {cardType === "points" && (
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <ButtonStyled
                    onClick={stepBack}
                    color="default"
                    disabled={isLoading}
                  >
                    <FormattedMessage id="general.back" />
                  </ButtonStyled>
                  <SubmitButtonStyled
                    onClick={handleSubmit(onSubmit)}
                    loading={isLoading}
                    disabled={isLoading}
                  >
                    Save and continue
                    {/* <FormattedMessage id="general.continue" /> */}
                  </SubmitButtonStyled>
                </Grid>
              )}
            </FormBodyStyled>
            {cardType === "stamps" && (
              <>
                <CardTypeExternalLink>
                  <Typography align="center">
                    <Link to="./">
                      <LinkIcon /> Learn more about Stamp Card Program
                    </Link>
                  </Typography>
                </CardTypeExternalLink>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <ButtonStyled
                    onClick={stepBack}
                    color="default"
                    disabled={isLoading}
                  >
                    <FormattedMessage id="general.back" />
                  </ButtonStyled>
                  <SubmitButtonStyled
                    onClick={handleSubmit(onSubmit)}
                    loading={isLoading}
                    disabled={isLoading}
                  >
                    Save and continue
                    {/* <FormattedMessage id="general.continue" /> */}
                  </SubmitButtonStyled>
                </Grid>
              </>
            )}
          </FormStyled>
        </FormProvider>
      </FormContainerStyled>
    </WrapperStyled>
  );
};

export default AddProgramForm;

import { SelectLoyaltyType } from "@types";

export type MainStateType = {
  companies?: CompanyType[] | null;
  loyalties?: LoyaltyType[] | null;
  selectedCompany?: CompanyType | null;
  selectedLoyalty?: LoyaltyType | null;
};

export type LoyaltyType = {
  href: string;
  id: string;
  name: string;
  number_of_users: number;
  pass_prefix: string;
};

export type CompanyType = {
  href: string;
  id: string;
  name: string;
  website_url: string;
};

export type UpdateCompanyType = {
  tenant_id: string;
  company_id: string;
  name: string;
  email: string;
};

export type FetchCompanyListType = {
  tenant_id: string;
};

export type FetchLoyaltyListType = {
  tenant_id: string;
  company_id: string;
};

export const MainTypes = {
  FETCH_COMPANY_LIST: "main/FETCH_COMPANY_LIST",
  FETCH_COMPANY_LIST_SUCCEEDED: "main/FETCH_COMPANY_LIST_SUCCEEDED",
  FETCH_COMPANY_LIST_FAILED: "main/FETCH_COMPANY_LIST_FAILED",

  FETCH_LOYALTY_LIST: "main/FETCH_LOYALTY_LIST",
  FETCH_LOYALTY_LIST_SUCCEEDED: "main/FETCH_LOYALTY_LIST_SUCCEEDED",
  FETCH_LOYALTY_LIST_FAILED: "main/FETCH_LOYALTY_LIST_FAILED",

  SELECT_LOYALTY: "main/SELECT_LOYALTY",

  UPDATE_COMPANY: "main/UPDATE_COMPANY",
  UPDATE_COMPANY_SUCCEEDED: "main/UPDATE_COMPANY_SUCCEEDED",
  UPDATE_COMPANY_FAILED: "main/UPDATE_COMPANY_FAILED",
} as const;

// Fetch Company List
export type fetchCompanyListActionType = {
  type: typeof MainTypes.FETCH_COMPANY_LIST;
  payload: FetchCompanyListType;
};

export type fetchCompanyListSucceededActionType = {
  type: typeof MainTypes.FETCH_COMPANY_LIST_SUCCEEDED;
  payload: CompanyType[];
};

export type fetchCompanyListFailedActionType = {
  type: typeof MainTypes.FETCH_COMPANY_LIST_FAILED;
};

// Update Company Info
export type updateCompanyActionType = {
  type: typeof MainTypes.UPDATE_COMPANY;
  payload: UpdateCompanyType;
};

export type updateCompanySucceededActionType = {
  type: typeof MainTypes.UPDATE_COMPANY_SUCCEEDED;
  payload: CompanyType;
};

export type updateCompanyFailedActionType = {
  type: typeof MainTypes.UPDATE_COMPANY_FAILED;
};

// Fetch Loyalty List
export type fetchLoyaltyActionType = {
  type: typeof MainTypes.FETCH_LOYALTY_LIST;
  payload: FetchLoyaltyListType;
};

export type fetchLoyaltySucceededActionType = {
  type: typeof MainTypes.FETCH_LOYALTY_LIST_SUCCEEDED;
  payload: LoyaltyType[];
};

export type fetchLoyaltyFailedActionType = {
  type: typeof MainTypes.FETCH_LOYALTY_LIST_FAILED;
};

export type selectLoyaltyActionType = {
  type: typeof MainTypes.SELECT_LOYALTY;
  payload: SelectLoyaltyType;
};

export type mainActionTypes =
  | fetchCompanyListActionType
  | fetchCompanyListSucceededActionType
  | fetchCompanyListFailedActionType
  | updateCompanyActionType
  | updateCompanySucceededActionType
  | updateCompanyFailedActionType
  | fetchLoyaltyActionType
  | fetchLoyaltySucceededActionType
  | fetchLoyaltyFailedActionType
  | selectLoyaltyActionType;

import React, { useEffect } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { useHistory } from "react-router";
import { ROUTES } from "@constants";
import { ContentLoader, Error } from "@components/common";
import { CompanyType, LoyaltyType, UserProfileType } from "@types";
import Customers from "../../../../customers/components/Customers/components/Wrapper";
import { Loyalties } from "../../../../customers/components/Loyalties";
import Transactions from "../../../../transactions/components/Transactions";
import AddCompany from "../../../../onboarding/components/AddCompany";
import AddProgram from "../../../../onboarding/components/AddProgram";
import Programs from "../../../../loyalties/components/Wrapper";
import Account from "../../../../account/components/Wrapper";
import Welcome from "../../Dashboard";
import { FetchCompanyListType } from "../../../types";

interface MainProps {
  companyList: CompanyType[];
  loyaltyList: LoyaltyType[];
  createdLoyalty: LoyaltyType;
  fetchCompanyList(data: FetchCompanyListType): void;
  userInfo: UserProfileType;
}

const Main = ({
  companyList,
  loyaltyList,
  fetchCompanyList,
  userInfo,
  createdLoyalty,
}: MainProps) => {
  const history = useHistory();
  // Fetch Company then Loyalty lists
  useEffect(() => {
    fetchCompanyList({
      tenant_id: userInfo.id,
    });
  }, []);

  useEffect(() => {
    if (createdLoyalty) {
      // redirect to program
      history.push(`/programs/${createdLoyalty.id}/pass-design`);
    }
  }, [createdLoyalty]);

  if (companyList === null || loyaltyList === null) {
    return <Error container />;
  }

  // show loader for fetching Company
  if (!companyList) {
    return <ContentLoader />;
  }

  if (companyList.length === 0)
    return (
      <Switch>
        <Route exact path={ROUTES.MAIN_WELCOME} component={Welcome} />
        <Route exact path={ROUTES.MAIN_ADD_COMPANY} component={AddCompany} />
        <Redirect to={ROUTES.MAIN_ADD_COMPANY} />
      </Switch>
    );

  return (
    <Switch>
      <Route exact path={ROUTES.MAIN_WELCOME} component={Welcome} />
      <Route exact path={ROUTES.MAIN_SETTINGS} component={Account} />
      <Route exact path={ROUTES.MAIN_CUSTOMERS} component={Customers} />
      <Route exact path={ROUTES.MAIN_PROGRAMS} component={Programs} />
      <Route exact path={ROUTES.MAIN_PROGRAMS_ADD} component={AddProgram} />
      <Route path={ROUTES.MAIN_CUSTOMER_PROFILE} component={Loyalties} />
      <Route exact path={ROUTES.MAIN_TRANSACTIONS} component={Transactions} />
      <Redirect to={ROUTES.MAIN_WELCOME} />
    </Switch>
  );
};

export default Main;

import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { mainSelectors } from "../../selectors";
import { customersSelectors } from "../../../customers/selectors";
import { loyaltySelectors } from "../../../loyalties/selectors";
import { authSelectors } from "../../../auth/selectors";
import * as mainActions from "../../actions";
import { fetchCustomerList } from "../../../customers/actions";
import {
  fetchCardDesign,
  fetchInviteDesign,
  fetchEmailDesign,
} from "../../../loyalties/actions";
import Component from "./components";

const mapStateToProps = (state) => ({
  selectedLoyalty: mainSelectors.getSelectedLoyalty(state),
  cardDesign: loyaltySelectors.getCardDesignItem(state),
  inviteDesign: loyaltySelectors.getInviteDesignItem(state),
  emailDesign: loyaltySelectors.getEmailDesignItem(state),
  programs: mainSelectors.getLoyaltyList(state),
  companies: mainSelectors.getCompanyList(state),
  selectedCompany: mainSelectors.getSelectedCompany(state),
  customers: customersSelectors.getCustomerList(state),
  userInfo: authSelectors.getUser(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchCompanyList: mainActions.fetchCompanyList,
      fetchLoyaltyList: mainActions.fetchLoyaltyList,
      selectLoyalty: mainActions.selectLoyalty,
      fetchCardDesign,
      fetchCustomerList,
      fetchInviteDesign,
      fetchEmailDesign,
    },
    dispatch
  );

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default ConnectedComponent;

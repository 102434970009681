import { IconButton, DialogContent } from "@material-ui/core";
import styled from "styled-components";

export const IconButtonStyled = styled(IconButton)`
  position: absolute;
  top: 4px;
  right: 10px;
`;

export const DialogContentStyled = styled(DialogContent)`
  min-width: 480px;
  &.MuiDialogContent-root:first-child {
    padding: 46px 35px 35px;
  }
`;

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { ROUTES } from "@constants";
import { CompanyType, LoyaltyType, UserProfileType } from "@types";
import { Header as HeaderComponent } from "@components/common";
import cognitoId from "../../../../cognitoId";

interface HeaderProps {
  signedOutSucceeded(): void;
  userData: UserProfileType;
  selectedCompany: CompanyType;
  selectedLoyalty: LoyaltyType;
  loyaltyList: LoyaltyType[];
}

const getRouteTitle = (appRoutes, currentRoute) =>
  appRoutes.find((item) => item.link === currentRoute.split("/")[1])?.title;

const Header: React.FC<HeaderProps> = ({
  signedOutSucceeded,
  userData,
  selectedCompany,
  selectedLoyalty,
  loyaltyList,
}) => {
  const [navTitle, setNavTitle] = useState("");

  const location = useLocation();

  const handleSignOut = () => {
    cognitoId.userSignOut();
    signedOutSucceeded();
  };

  useEffect(() => {
    setNavTitle(getRouteTitle(ROUTES.APP_ROUTES_TITLES, location.pathname));
  }, [location]);

  return (
    <HeaderComponent
      handleSignOut={handleSignOut}
      userData={userData}
      companyDetails={selectedCompany}
      program={selectedLoyalty}
      navTitle={navTitle}
      programs={loyaltyList}
    />
  );
};

export default Header;

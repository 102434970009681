import { call, put, takeLatest } from "redux-saga/effects";
import { TransactionsTypes } from "./types";
import * as actions from "./actions";
import * as api from "./api";

export function* fetchTransactionList(action) {
  try {
    const { payload } = action;

    const res = yield call(api.fetchTransactionList, payload);

    yield put(actions.fetchTransactionListSucceeded(res.data));
  } catch (ex) {
    yield put(actions.fetchTransactionListFailed());
  }
}

export default [
  takeLatest(TransactionsTypes.FETCH_TRANSACTION_LIST, fetchTransactionList),
];

import React from "react";
import styled from "styled-components";
import { SvgIcon } from "@material-ui/core";
import { ICONS } from "@constants";

const LogoStyled = styled(SvgIcon)`
  fill: ${({ theme }) => theme.palette.primary.main};
  width: 108px;
  height: 18px;
`;

const Logo: React.FC = (props) => (
  <LogoStyled {...props} width="108" height="17" viewBox="0 0 108 17">
    <path d={ICONS.LOGO} />
  </LogoStyled>
);

export default Logo;

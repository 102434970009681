// @ts-nocheck
// eslint-disable
import React, { useEffect, useState } from "react";
import { Grid, FormLabel } from "@material-ui/core";
import { withRouter, RouteComponentProps } from "react-router";
import { useForm, FormProvider } from "react-hook-form";
import { useIntl, WrappedComponentProps } from "react-intl";
import {
  Button,
  TextField,
  Alert,
  ContentLoader,
  Checkbox,
} from "@components/common";
import {
  CompanyType,
  LoyaltyType,
  UserProfileType,
  LoyaltyCustomerProfileType,
  SegmentType,
} from "@types";
import {
  UpdateLoyaltyCustomerProfileType,
  ResendLoyaltyCustomerPassType,
  FetchSegmentsType,
} from "../../../../../types";
import { HeadingStyled, TopFormStyled } from "./styled";

interface AddCustomerProps {
  userInfo: UserProfileType;
  profileInfo: LoyaltyCustomerProfileType;
  isProfileUpdated: boolean;
  resendLoyaltyCustomerPass(data: ResendLoyaltyCustomerPassType): void;
  updateCustomer(data: UpdateLoyaltyCustomerProfileType): void;
  segments: SegmentType[];
  fetchSegments(data: FetchSegmentsType): void;
  selectedCompany: CompanyType;
  selectedLoyalty: LoyaltyType;
  hasError: boolean;
  inviteResend: boolean;
  resetLoyaltySettingsForm(): void;
}

const Settings = ({
  userInfo,
  customer,
  isProfileUpdated,
  updateCustomer,
  selectedCompany,
  hasError,
  fetchSegments,
  segments,
  resetLoyaltySettingsForm,
}: AddCustomerProps & RouteComponentProps & WrappedComponentProps) => {
  const methods = useForm();
  const intl = useIntl();
  const { handleSubmit, errors, setValue } = methods;
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = ({ fullName, email, ...segments }) => {
    const selectedSegments: [] = Object.keys(segments).reduce(
      (acc: [], key: string) => (segments[key] ? [...acc, { id: key }] : acc),
      []
    );

    setIsLoading(true);
    updateCustomer({
      tenant_id: userInfo.id,
      company_id: selectedCompany.id,
      account_id: customer.id,
      segments: selectedSegments,
    });
  };

  useEffect(() => {
    if (!segments) {
      fetchSegments({
        tenant_id: userInfo.id,
        company_id: selectedCompany.id,
      });
    }
  }, [segments]);

  useEffect(() => {
    if (segments && customer?.segments) {
      customer.segments.forEach((segment) => {
        setValue(segment.id, true);
      });
    }
  }, [customer, segments]);

  useEffect(() => {
    setIsLoading(false);
  }, [isProfileUpdated]);

  useEffect(() => {
    return () => resetLoyaltySettingsForm();
  }, []);

  if (!customer) {
    return <ContentLoader />;
  }

  return (
    <>
      <Grid item md={6} lg={4}>
        <HeadingStyled variant="h3">Settings</HeadingStyled>
        <FormProvider {...methods}>
          <TopFormStyled onSubmit={handleSubmit(onSubmit)}>
            <TextField
              name="fullName"
              label="Full name"
              placeholder="Full name"
              error={errors.fullName}
              required
              defaultValue={customer.name}
              disabled
            />
            <TextField
              name="email"
              placeholder={intl.formatMessage({
                id: "input.email.placeholder",
              })}
              type="email"
              label={intl.formatMessage({ id: "input.email.label" })}
              disabled
              defaultValue={customer.email}
            />
            <div>
              {segments?.length > 0 && (
                <>
                  <FormLabel>Customer segment</FormLabel>
                  <div>
                    {segments.map((segment) => (
                      <Checkbox
                        key={segment.id}
                        name={segment.id}
                        label={segment.name}
                      />
                    ))}
                  </div>
                </>
              )}
              {!segments && <ContentLoader />}
            </div>
            <Button
              loading={isLoading}
              onClick={handleSubmit(onSubmit)}
              type="submit"
              disabled={!segments?.length}
            >
              Save changes
            </Button>
          </TopFormStyled>
        </FormProvider>
        {!!isProfileUpdated && <Alert sticky>Changes were saved</Alert>}
        {hasError && (
          <Alert variant="warning">
            Something went wrong, try again later.
          </Alert>
        )}
      </Grid>
    </>
  );
};

export default withRouter(Settings);

import React, { useEffect } from "react";
import { ConnectedRouter } from "connected-react-router";
import { Route, Switch, Redirect } from "react-router-dom";
import { FullPageLoader } from "@components/common";
import { ROUTES } from "@constants";
import { UserProfileType } from "@types";
import history from "@utils/history";
import axios from "axios";
import {
  SignIn,
  SignUp,
  ResetPassword,
  ForgotPassword,
  Verification,
} from "../../../../modules/auth";
import { Main } from "../../../../modules/main";
import { Header } from "../../../../modules/header";
import { Modal } from "../../../../modules/modal";
import { InviteCustomer, DownloadPass } from "../../../../modules/customers";
import cognitoId from "../../../../cognitoId";

function getCognitoSession(cognito, success, failure) {
  const cognitoUser = cognito.getCognitoUser();

  if (cognitoUser) {
    cognitoUser.getSession((err, result) => {
      if (err) {
        failure();
      }
      const { email, name, sub, email_verified } = result.idToken.payload;
      const { jwtToken } = result.idToken;
      axios.defaults.headers.common.Authorization = jwtToken;
      success({
        id: sub,
        verified: email_verified,
        email,
        name,
      });
    });
    return;
  }
  failure();
}

interface RootRoutesProps {
  signedInSucceed(data: UserProfileType): void;
  signedInFailed(): void;
  isAuthenticated: boolean;
  isSynced: boolean;
}

const RootRoutes: React.FC<RootRoutesProps> = ({
  signedInSucceed,
  signedInFailed,
  isAuthenticated,
  isSynced,
}) => {
  useEffect(() => {
    getCognitoSession(cognitoId, signedInSucceed, signedInFailed);
  }, []);

  if (!isSynced) {
    return <FullPageLoader />;
  }

  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route path={ROUTES.INVITE_FORM} component={InviteCustomer} />
        <Route path={ROUTES.DOWNLOAD_PASS} component={DownloadPass} />
        <Route path="/">
          {!isAuthenticated ? (
            <>
              <Route exact path={ROUTES.USER_SIGN_IN} component={SignIn} />
              <Route exact path={ROUTES.USER_SIGN_UP} component={SignUp} />
              <Route
                exact
                path={ROUTES.USER_FORGOT_PASSWORD}
                component={ForgotPassword}
              />
              <Route
                exact
                path={ROUTES.USER_RESET_PASSWORD}
                component={ResetPassword}
              />
              <Route exact path={ROUTES.USER_VERIFY} component={Verification} />
              <Redirect to="/" />
            </>
          ) : (
            <>
              <Header />
              <Main />
              <Modal />
            </>
          )}
        </Route>
      </Switch>
    </ConnectedRouter>
  );
};

export default RootRoutes;

import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { mainSelectors } from "../../selectors";
import { authSelectors } from "../../../auth/selectors";
import { onboardingSelectors } from "../../../onboarding/selectors";
import * as mainActions from "../../actions";
import Component from "./components";

const mapStateToProps = (state) => ({
  userInfo: authSelectors.getUser(state),
  companyList: mainSelectors.getCompanyList(state),
  loyaltyList: mainSelectors.getLoyaltyList(state),
  selectedLoyalty: mainSelectors.getSelectedLoyalty(state),
  selectedCompany: mainSelectors.getSelectedCompany(state),
  createdCompany: onboardingSelectors.getCreatedLoyalty(state),
  createdLoyalty: onboardingSelectors.getCreatedLoyalty(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchCompanyList: mainActions.fetchCompanyList,
      fetchLoyaltyList: mainActions.fetchLoyaltyList,
      selectLoyalty: mainActions.selectLoyalty,
    },
    dispatch
  );

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default ConnectedComponent;

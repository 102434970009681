import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { injectIntl } from "react-intl";
import { customersSelectors } from "../../../../selectors";
import { mainSelectors } from "../../../../../main/selectors";
import { authSelectors } from "../../../../../auth/selectors";
import * as customersActions from "../../../../actions";
import Component from "./components";

const mapStateToProps = (state) => ({
  userInfo: authSelectors.getUser(state),
  addedCustomer: customersSelectors.getAddedCustomer(state),
  customerExist: customersSelectors.getCustomerExist(state),
  retrievedByEmail: customersSelectors.getRetrievedLoyaltyByEmail(state),
  hasError: customersSelectors.hasError(state),
  selectedCompany: mainSelectors.getSelectedCompany(state),
  loyaltyList: mainSelectors.getLoyaltyList(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      addLoyalty: customersActions.addLoyalty,
      fetchCustomerByEmail: customersActions.fetchCustomerByEmail,
      resetAddCustomerForm: customersActions.resetAddCustomerForm,
    },
    dispatch
  );

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default injectIntl(ConnectedComponent);

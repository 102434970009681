import React from "react";
import {
  CheckboxProps,
  FormControl,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { useFormContext, Controller, FieldError } from "react-hook-form";
import { checkboxStyles } from "./styled";

interface CheckBoxComponentProps {
  label: React.ReactNode;
  error?: FieldError;
  name: string;
  hooked?: boolean;
}

const CheckBoxComponent = ({
  disabled,
  color = "primary",
  label,
  name,
  error,
  required,
  defaultValue = "",
  checked = false,
  hooked = true,
  ...rest
}: CheckBoxComponentProps & CheckboxProps) => {
  const classes = checkboxStyles({ error: !!error })();

  return (
    <FormControl component="div" error={!!error}>
      {hooked ? (
        <FormControlLabel
          control={
            <Controller
              render={(props) => (
                <Checkbox
                  onChange={(e) => props.onChange(e.target.checked)}
                  checked={!!props.value || checked}
                  color={color}
                  name={name}
                  className={classes.invalid}
                />
              )}
              name={name}
              control={useFormContext().control}
              fullWidth
              InputProps={{ disableUnderline: true }}
              disabled={disabled}
              defaultValue={defaultValue}
              rules={{ required }}
            />
          }
          label={label}
          className={classes.invalid}
        />
      ) : (
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => !e.target.checked}
              color={color}
              name={name}
              className={classes.invalid}
              checked={checked}
              {...rest}
            />
          }
          label={label}
          className={classes.invalid}
        />
      )}
    </FormControl>
  );
};

export default CheckBoxComponent;

// @ts-nocheck
import React, { useState, useEffect, useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { AttachFile } from "@material-ui/icons";
import { FormLabel } from "@material-ui/core";
import Cropper from "react-cropper";
import { dataURLtoBlob } from "@utils/common";
import { ImagePreviewType } from "@types";
import {
  FormControlStyled,
  ButtonStyled,
  TypographyStyled,
  ButtonClearStyled,
  ButtonRowStyled,
} from "./styled";
import "cropperjs/dist/cropper.css";

interface InputFileProps {
  setPreviewClb(imagePreview: ImagePreviewType): void;
  preview?: ImagePreviewType;
  label?: string;
  helpText?: string;
  name: string;
  aspectRatio?: number;
  previewClassName?: string;
  required?: boolean;
  disabled?: boolean;
}

const InputFile: React.FC<InputFileProps> = ({
  setPreviewClb,
  label,
  helpText,
  name = "file-input",
  aspectRatio,
  previewClassName = ".crop-preview",
  required,
  preview,
  disabled = false,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [filePreview, setFilePreview] = useState();
  const [cropInitialized, setCropInitialized] = useState(false);
  const { control } = useFormContext();
  const cropper = useRef(null);

  const handleCrop = () => {
    const data = cropper?.current?.cropper?.getCroppedCanvas()?.toDataURL();
    setPreviewClb(dataURLtoBlob(data));
  };

  const removeFile = () => {
    setFile(null);
    setPreviewClb(null);
    setFilePreview(null);
  };

  useEffect(() => {
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setFilePreview(reader.result);
        setPreviewClb(dataURLtoBlob(reader.result));
      };

      reader.readAsDataURL(file);
    }
  }, [file]);

  useEffect(() => {
    if (cropInitialized) {
      handleCrop();
    }
  }, [cropInitialized]);

  return (
    <FormControlStyled>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      {helpText && (
        <TypographyStyled color="textSecondary" variant="body1">
          {helpText}
        </TypographyStyled>
      )}
      <ButtonRowStyled>
        <ButtonStyled
          startIcon={<AttachFile />}
          fullWidth={false}
          color="default"
          component="label"
          disabled={disabled}
        >
          <FormattedMessage id="input.file.label" />
          <Controller
            control={control}
            render={({ onChange, value, name }) => (
              <input
                type="file"
                onChange={(event: React.FormEvent<HTMLInputElement>) => {
                  if (event.target.files.length) {
                    setFile(event.target.files[0]);
                    onChange(event.target.value);
                  }
                }}
                onClick={() => onChange("")}
                accept="image/png"
                id={name}
                name={name}
                value={value}
                hidden
                disabled={disabled}
              />
            )}
            defaultValue=""
            disabled={disabled}
            name={name}
            rules={{
              required,
            }}
          />
        </ButtonStyled>
        {preview && (
          <ButtonClearStyled
            type="button"
            onClick={removeFile}
            color="default"
            disabled={disabled}
          >
            <FormattedMessage id="input.file.delete" />
          </ButtonClearStyled>
        )}
      </ButtonRowStyled>
      {preview && (
        <Cropper
          preview={`.${previewClassName}`}
          initialAspectRatio={aspectRatio}
          aspectRatio={aspectRatio}
          checkOrientation={false}
          style={{ minHeight: 200, width: "100%" }}
          viewMode={1}
          guides={false}
          cropend={handleCrop}
          zoom={handleCrop}
          onInitialized={() =>
            setTimeout(() => {
              setCropInitialized(true);
            }, 0)
          }
          ref={cropper}
          highlight={false}
          modal={false}
          src={filePreview}
        />
      )}
    </FormControlStyled>
  );
};

export default InputFile;

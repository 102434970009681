import React, { useEffect } from "react";
import { Grid, Chip } from "@material-ui/core";
import {
  withRouter,
  Switch,
  Route,
  useParams,
  RouteComponentProps,
} from "react-router";
import { ContentLoader, SectionNav } from "@components/common";
import { COMMON, ROUTES } from "@constants";
import {
  CompanyType,
  LoyaltyType,
  UserProfileType,
  CustomerType,
  SelectCustomerType,
} from "@types";
import {
  SubHeadingStyled,
  HeaderTopStyled,
  WrapperStyled,
  SubHeadingLeftStyled,
} from "../../../../Customers/styled";
import Overview from "../../Overview";
import Settings from "../../Settings";
import CardOverview from "../../CardOverview";
import AddLoyalty from "../../AddLoyalty";
import {
  LinkStyled,
  SegmentsRowStyled,
  SubHeadingWrapperStyled,
} from "./styled";
import {
  FetchCustomerLoyaltyAccountsType,
  FetchCustomerListType,
} from "../../../../../types";

interface CustomersProps {
  userInfo: UserProfileType;
  selectedCompany: CompanyType;
  loyaltyList: LoyaltyType[];
  selectedLoyalty: LoyaltyType;
  fetchLoyaltyList(data: FetchCustomerLoyaltyAccountsType): void;
  selectedCustomer: CustomerType;
  isProfileUpdated: boolean;
  isTransactionAdded: boolean;
  selectedProgram: LoyaltyType;
  addedCustomer: CustomerType;
  customerList: CustomerType[];
  loyaltyAccounts: CustomerType[];
  fetchCustomerList(data: FetchCustomerListType): any;
  selectCustomer(data: SelectCustomerType): any;
}

interface CustomersRouteProps {
  id: string;
}

const Wrapper: React.FC<CustomersProps & RouteComponentProps> = ({
  userInfo,
  selectedCompany,
  fetchLoyaltyList,
  fetchCustomerList,
  selectCustomer,
  loyaltyAccounts,
  selectedCustomer,
  customerList,
  addedCustomer,
  loyaltyList,
  isProfileUpdated,
  isTransactionAdded,
}) => {
  const { id } = useParams<CustomersRouteProps>();
  const isLoading =
    !loyaltyList ||
    !loyaltyAccounts ||
    !selectedCustomer ||
    selectedCustomer.id !== id;

  useEffect(() => {
    if (!customerList) {
      fetchCustomerList({
        tenant_id: userInfo.id,
        company_id: selectedCompany.id,
        offset: 0,
        limit: 9999,
      });
    }
  }, [customerList]);

  useEffect(() => {
    if (addedCustomer || isProfileUpdated) {
      fetchCustomerList({
        tenant_id: userInfo.id,
        company_id: selectedCompany.id,
        offset: 0,
        limit: 9999,
      });
    }
  }, [addedCustomer, isProfileUpdated]);

  useEffect(() => {
    if (selectedCustomer) {
      fetchLoyaltyList({
        tenant_id: userInfo.id,
        company_id: selectedCompany.id,
        customer_id: id,
      });
    }
  }, [selectedCustomer, isTransactionAdded]);

  useEffect(() => {
    if (customerList) {
      selectCustomer({ id });
    }
  }, [id, customerList]);

  if (isLoading) {
    return <ContentLoader />;
  }

  return (
    <WrapperStyled>
      <Grid container justify="space-between" alignItems="center">
        <HeaderTopStyled>
          <SubHeadingLeftStyled>
            <LinkStyled back to={ROUTES.CUSTOMERS_OVERVIEW} color="secondary">
              Back to customers
            </LinkStyled>
            <SubHeadingWrapperStyled>
              <SubHeadingStyled variant="h2">
                {selectedCustomer.name}
              </SubHeadingStyled>
              <SegmentsRowStyled>
                {selectedCustomer.segments.map((item) => (
                  <Chip key={item.id} label={item.name} />
                ))}
              </SegmentsRowStyled>
            </SubHeadingWrapperStyled>
          </SubHeadingLeftStyled>
        </HeaderTopStyled>
      </Grid>
      <SectionNav
        navItems={COMMON.LOYALTY_CUSTOMER_NAV(
          selectedCustomer.id,
          loyaltyAccounts
        )}
      />
      <Switch>
        <Route
          exact
          path={ROUTES.CUSTOMERS_PROFILE_OVERVIEW}
          component={Overview}
        />
        <Route
          exact
          path={ROUTES.CUSTOMERS_PROFILE_SETTINGS}
          component={Settings}
        />
        <Route
          exact
          path={ROUTES.CUSTOMERS_PROFILE_CARD_OVERVIEW}
          component={CardOverview}
        />
        <Route
          exact
          path={ROUTES.CUSTOMERS_ADD_LOYALTY_CARD}
          component={AddLoyalty}
        />
      </Switch>
    </WrapperStyled>
  );
};

export default withRouter(Wrapper);

import styled from "styled-components";
import { Link } from "@components/common";

export const LinkStyled = styled(Link)`
  margin-bottom: 24px;
`;

export const SegmentsRowStyled = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 992px) {
    margin-left: 10px;
  }
`;

export const SubHeadingWrapperStyled = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  max-width: 80%;

  @media (min-width: 992px) {
    flex-direction: row;
  }
`;

import { call, put, takeLatest } from "redux-saga/effects";
import { OnboardingTypes } from "./types";
import * as actions from "./actions";
import { actions as mainActions } from "../main";
import * as api from "./api";

export function* createCompany(action) {
  try {
    const { payload } = action;

    const res = yield call(api.createCompany, { ...payload });

    yield put(actions.createCompanySucceeded(res.data));
    yield put(mainActions.fetchCompanyList(payload));
  } catch (ex) {
    yield put(actions.createCompanyFailed());
  }
}

export function* createLoyalty(action) {
  try {
    const { payload } = action;

    const res = yield call(api.createLoyalty, { ...payload });

    yield put(actions.createLoyaltySucceeded(res.data));
    yield put(mainActions.fetchLoyaltyList(payload));
  } catch (ex) {
    yield put(actions.createLoyaltyFailed());
  }
}

export default [
  takeLatest(OnboardingTypes.CREATE_COMPANY, createCompany),
  takeLatest(OnboardingTypes.CREATE_LOYALTY, createLoyalty),
];

import axios, { AxiosPromise } from "axios";
import CONSTANTS from "@constants";

const { API } = CONSTANTS;

// Company
export const createCompany = ({ tenant_id, ...rest }): AxiosPromise =>
  axios.post(`${API.TENANTS}/${tenant_id}/companies`, rest);

export const fetchCompanyList = ({ tenant_id }): AxiosPromise =>
  axios.get(`${API.TENANTS}/${tenant_id}/companies`);

// Loyalties
export const createLoyalty = ({
  tenant_id,
  company_id,
  ...rest
}): AxiosPromise =>
  axios.post(
    `${API.TENANTS}/${tenant_id}/companies/${company_id}/programs`,
    rest
  );

// import { RootStateType } from "../reducer";

const getCreatedCompany = (state: any) => state.onboarding.createdCompany;
const getCreatedLoyalty = (state: any) => state.onboarding.createdLoyalty;
const getError = (state: any) => state.onboarding.error;

export const onboardingSelectors = {
  getCreatedCompany,
  getCreatedLoyalty,
  getError,
};

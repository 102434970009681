const getCompanyList = (state: any) => state.main.companies;
const getLoyaltyList = (state: any) => state.main.loyalties;
const getSelectedCompany = (state: any) => state.main.selectedCompany;
const getSelectedLoyalty = (state: any) => state.main.selectedLoyalty;

export const mainSelectors = {
  getCompanyList,
  getLoyaltyList,
  getSelectedCompany,
  getSelectedLoyalty,
};

import React from "react";
import { Dialog } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { IconButtonStyled, DialogContentStyled } from "./styled";
import ModalRoot from "../../ModalRoot";

export default function SimpleModal({ type, hideModal, ...props }) {
  return (
    <Dialog
      open={!!type}
      onClose={hideModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <DialogContentStyled>
        <IconButtonStyled
          aria-label="close modal"
          type="button"
          onClick={hideModal}
        >
          <Close fontSize="small" />
        </IconButtonStyled>
        <ModalRoot {...props} />
      </DialogContentStyled>
    </Dialog>
  );
}

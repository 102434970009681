import styled from "styled-components";
import { Typography } from "@material-ui/core";

export const HeadingStyled = styled(Typography)`
  margin-bottom: 24px;
`;

export const BottomFormStyled = styled.form`
  margin-bottom: 16px;
`;

export const QRContainer = styled.div`
  display: table;
  padding: 8px 8px 26px;
  background-color: #fff;
  margin: 10px auto;
`;

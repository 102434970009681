import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { RouteComponentProps, useParams } from "react-router";
import { WrappedComponentProps } from "react-intl";
import { Grid } from "@material-ui/core";
import { SectionNav, ContentLoader } from "@components/common";
import { COMMON, ROUTES } from "@constants";
import { LoyaltyType, SelectLoyaltyType } from "@types";
import { WrapperStyled, HeaderTopStyled, SubHeading } from "./styled";
import PointsCardDesign from "../../PointsCardDesign";
import StampsCardDesign from "../../StampsCardDesign";
import InviteDesign from "../../InviteDesign";
import EmailDesign from "../../EmailDesign";

interface ProgramProps {
  selectedLoyalty: LoyaltyType;
  selectLoyalty(data: SelectLoyaltyType): void;
  showModal(data: any): void;
  clearState(): void;
  createdLoyalty: LoyaltyType;
  loyaltyList: LoyaltyType[];
}

interface LoyaltiesRouteProps {
  id: string;
}

const Programs = ({
  selectedLoyalty,
  createdLoyalty,
  loyaltyList,
  selectLoyalty,
  showModal,
  clearState,
}: ProgramProps & RouteComponentProps & WrappedComponentProps) => {
  const { id } = useParams<LoyaltiesRouteProps>();

  const CardDesignComponent =
    selectedLoyalty?.type === "stamps" ? StampsCardDesign : PointsCardDesign;

  useEffect(() => {
    if (createdLoyalty) {
      showModal({
        type: "PROGRAM_WELCOME_MODAL",
        data: createdLoyalty.name,
      });
    }
  }, []);

  useEffect(() => {
    if (
      (loyaltyList && id && !selectedLoyalty) ||
      (loyaltyList && id && selectedLoyalty?.id !== id)
    ) {
      clearState();
      selectLoyalty({ id });
    }
  }, [id, selectedLoyalty, loyaltyList]);

  if (!loyaltyList || selectedLoyalty?.id !== id) {
    return <ContentLoader />;
  }

  return (
    <>
      <WrapperStyled>
        <Grid container justifyContent="space-between" alignItems="center">
          <HeaderTopStyled>
            <SubHeading variant="h2">{selectedLoyalty?.name}</SubHeading>
          </HeaderTopStyled>
        </Grid>
        <SectionNav navItems={COMMON.LOYALTIES_NAV(id)} />
        <Switch>
          <Route
            exact
            path={ROUTES.PROGRAMS_PASS_DESIGNS}
            component={CardDesignComponent}
          />
          <Route
            exact
            path={ROUTES.PROGRAMS_INVITE_DESIGNS}
            component={InviteDesign}
          />
          <Route
            exact
            path={ROUTES.PROGRAMS_EMAIL_DESIGNS}
            component={EmailDesign}
          />
          <Redirect to={`/programs/${selectedLoyalty?.id}/pass-design`} />
        </Switch>
      </WrapperStyled>
    </>
  );
};

export default Programs;

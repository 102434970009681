import axios, { AxiosPromise } from "axios";
import CONSTANTS from "@constants";

const { API } = CONSTANTS;

export const fetchTransactionList = ({
  tenant_id,
  company_id,
  limit = 10,
  offset = 0,
}): AxiosPromise =>
  axios.get(
    `${API.TENANTS}/${tenant_id}/companies/${company_id}/transactions?limit=${limit}&offset=${offset}`
  );

import React from "react";
import { FormattedMessage } from "react-intl";
import { Container, Grid } from "@material-ui/core";
import { TypographyStyled } from "./styled";

interface ErrorComponentProps {
  container?: boolean;
  message?: string;
}

const ErrorComponent: React.FC<ErrorComponentProps> = ({
  container,
  message,
}) => {
  return container ? (
    <Container>
      <Grid container>
        <TypographyStyled variant="h5" color="error">
          {message || <FormattedMessage id="general.notifications.error" />}
        </TypographyStyled>
      </Grid>
    </Container>
  ) : (
    <Grid container>
      <TypographyStyled variant="h5" color="error">
        {message || <FormattedMessage id="general.notifications.error" />}
      </TypographyStyled>
    </Grid>
  );
};

export default ErrorComponent;

import styled from "styled-components";

export const FormStyled = styled.div`
  padding: 0;
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
  border-radius: 4px;
  margin-bottom: 24px;

  @media (min-width: 992px) {
    margin-right: 24px;
  }

  @media (min-width: 1200px) {
    margin-right: 90px;
  }
`;

export const FormSubmitStyled = styled.div`
  padding: 0 24px 24px;
`;

// Form Preview

export const PreviewContainerStyled = styled.div`
  position: sticky;
  padding: 16px;
  top: 32px;
`;

export const FormContainerStyled = styled.div`
  text-align: center;
  margin-bottom: 24px;
  padding: 8px;

  @media (min-width: 992px) {
    padding: 50px 24px 90px;
  }
`;

export const FormLogoContainerStyled = styled.div`
  display: block;
  margin: 0 auto 24px;
  width: 320px;
  height: 100px;
  max-width: 100%;
`;

export const FormLogoPreviewStyled = styled.div`
  width: 100% !important;
  height: 100% !important;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;

  &.hide-preview {
    img {
      display: none !important;
    }
  }
`;

export const FormBodyStyled = styled.div`
  background-color: transparent;
  border: 1px solid transparent;
  padding: 16px;
  border-radius: 4px;
  margin: 0 auto 32px;

  @media (min-width: 992px) {
    padding: 46px;
  }
`;

export const FormTitleStyled = styled.h3`
  font-size: 40px;
  margin-top: 0;
  margin-bottom: 20px;
`;

export const FormTextRowStyled = styled.div`
  margin-bottom: 16px;
`;

export const FormTextStyled = styled.p`
  font-size: 14px;
  margin: 0;
`;

export const FormTextSmallStyled = styled.p`
  font-size: 16px;
  margin: 0;
`;

export const FormInnerStyled = styled.div`
  label,
  label span,
  label span.Mui-checked {
    color: currentColor;
  }

  label span {
    opacity: 0.9;
  }

  label a {
    color: currentColor !important;
  }
`;

export const IconWrapper = styled.div`
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  background-color: #fff;
  cursor: pointer;

  span {
    padding-left: 8px;
    color: #3c4043;
  }

  & + div {
    margin-top: 28px;
  }
`;

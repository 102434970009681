import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { injectIntl } from "react-intl";
import { accountSelectors } from "../../selectors";
import { mainSelectors } from "../../../main/selectors";
import { authSelectors } from "../../../auth";
import * as mainActions from "../../../main/actions";
import Component from "./components";

const mapStateToProps = (state) => ({
  userInfo: authSelectors.getUser(state),
  selectedLoyalty: mainSelectors.getSelectedLoyalty(state),
  selectedCompany: mainSelectors.getSelectedCompany(state),
  hasError: accountSelectors.getError(state),
  status: accountSelectors.getStatus(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      updateCompany: mainActions.updateCompany,
    },
    dispatch
  );

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default injectIntl(ConnectedComponent);

import { Messages } from "../types";
import en from "./en.json";
import sk from "./sk.json";

const messages: Messages = {
  en,
  sk,
};

export default messages;

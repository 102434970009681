import React, { useState, useEffect } from "react";
import { MenuItem, ListSubheader } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withRouter, RouteComponentProps, useHistory } from "react-router";
import { Link } from "react-router-dom";
import Dropdown from "../Dropdown";
import {
  SelectStyled,
  TabLinkStyled,
  TabHeaderStyled,
  TabHeaderItemStyled,
  TabLinkDisabledStyled,
  TabButtonStyled,
  NavTitleBefore,
  NavTitleAfter,
  NavSubItemStyled,
} from "./styled";

type SectionNavSubItemType = {
  title: string;
  link: string;
  id: string;
  section: string;
  primary?: boolean;
};

type SectionNavItemType = {
  title: string;
  link: string | null;
  disabled?: boolean;
  sub?: SectionNavSubItemType[] | null;
  section: string;
};

interface SectionNavProps {
  navItems: SectionNavItemType[];
  variant?: "primary" | "secondary";
  titleBefore?: string;
  titleAfter?: string;
  disabled?: boolean;
}

// Section Navigation (looks like tabs), transformed into dropdown on mobile
const SectionNav: React.FC<SectionNavProps & RouteComponentProps> = ({
  navItems,
  variant = "primary",
  titleBefore,
  titleAfter,
  disabled = false,
}) => {
  const [navSelectedValue, setNavSelectedValue] = useState(navItems[0].link);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const { location } = history;

  const handleNavSelectChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    history.push(event.target.value as string);
  };

  useEffect(() => {
    const path = location.pathname;
    setNavSelectedValue(
      navItems.find((item) => item?.link?.includes(location.pathname))?.link ||
        navItems
          .find((item) => item.sub)
          ?.sub?.find((item) => item?.link?.includes(path))?.link ||
        navItems[0].link
    );
  }, [location]);

  return isMobile ? (
    <SelectStyled
      onChange={handleNavSelectChange}
      value={navSelectedValue}
      displayEmpty
      disableUnderline
    >
      {navItems.map((item: SectionNavItemType) =>
        item.link ? (
          <MenuItem
            key={item.title}
            value={item.link}
            disabled={item.disabled || disabled}
          >
            {item.title}
          </MenuItem>
        ) : (
          [
            <ListSubheader>{item.title}</ListSubheader>,
            item.sub?.map((subLink) => (
              <MenuItem key={subLink.id} value={subLink.link}>
                {subLink.title}
              </MenuItem>
            )),
          ]
        )
      )}
    </SelectStyled>
  ) : (
    <TabHeaderStyled variant={variant}>
      {titleBefore && <NavTitleBefore>{titleBefore}</NavTitleBefore>}
      {navItems.map((item: SectionNavItemType) => (
        <TabHeaderItemStyled key={item.title} variant={variant}>
          {item.link &&
            (item.disabled || disabled ? (
              <TabLinkDisabledStyled key={item.title}>
                {item.title}
              </TabLinkDisabledStyled>
            ) : (
              <TabLinkStyled variant={variant} key={item.title} to={item.link}>
                {item.title}
              </TabLinkStyled>
            ))}
          {item.sub && (
            <Dropdown
              key={item.title}
              toggleComponent={
                <TabButtonStyled
                  active={location.pathname?.includes(item.section)}
                >
                  {item.title}
                </TabButtonStyled>
              }
              toggleButtonBase
              dropdownAlign="left"
              isActive={location.pathname?.includes(item.section)}
            >
              {item.sub.map((subItem) => (
                <NavSubItemStyled
                  key={subItem.id}
                  component={Link}
                  to={subItem.link}
                  selected={location.pathname.includes(subItem.link.split("/").slice(0, 4).join("/"))}
                  color={subItem.primary ? "primary" : "secondary"}
                >
                  {subItem.title}
                </NavSubItemStyled>
              ))}
            </Dropdown>
          )}
        </TabHeaderItemStyled>
      ))}
      {titleAfter && <NavTitleAfter>{titleAfter}</NavTitleAfter>}
    </TabHeaderStyled>
  );
};

export default withRouter(SectionNav);

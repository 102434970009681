const getLoyaltyAccounts = (state: any) => state.customers.loyaltyAccounts;
const getLoyaltyAccount = (state: any) => state.customers.loyaltyAccount;
const getLoyaltyInvited = (state: any) => state.customers.loyaltyInvited;
const getRetrievedLoyaltyByEmail = (state) => state.customers.retrievedByEmail;

const getCustomerList = (state: any) => state.customers.customers;
const getCustomerTransactions = (state: any) =>
  state.customers.customerTransactions;
const getSelectedCustomer = (state: any) => state.customers.selectedCustomer;
const getCustomerUpdated = (state: any) => state.customers.customerUpdated;
const getAddedCustomer = (state: any) => state.customers.addedCustomer;
const getCustomerExist = (state: any) => state.customers.customerExist;
const getLoyaltyTransactionList = (state: any) =>
  state.customers.loyaltyTransactions;

const isTransactionAdded = (state: any) =>
  state.customers.customerTransactionAdded;

const getSegments = (state: any) => state.customers.segments;
const isSegmentAdded = (state: any) => state.customers.segmentAdded;
const isSegmentRemoved = (state: any) => state.customers.segmentRemoved;

const hasError = (state: any) => state.customers.error;
const getInviteResend = (state: any) => state.customers.customerInviteResend;
const getInvitationDesigns = (state: any) => state.customers.invitationDesigns;
const getPasses = (state: any) => state.customers.passes;
const getLoyaltyAccountDownloadPass = (state: any) =>
  state.customers.loyaltyAccountDownloadPass;

export const customersSelectors = {
  getCustomerList,
  getSelectedCustomer,
  getCustomerUpdated,
  getAddedCustomer,
  getCustomerExist,
  isTransactionAdded,
  getLoyaltyAccounts,
  getLoyaltyAccount,
  getLoyaltyTransactionList,
  getSegments,
  isSegmentAdded,
  isSegmentRemoved,
  hasError,
  getInviteResend,
  getInvitationDesigns,
  getCustomerTransactions,
  getLoyaltyInvited,
  getRetrievedLoyaltyByEmail,
  getPasses,
  getLoyaltyAccountDownloadPass,
};

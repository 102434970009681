import { Typography, Container } from "@material-ui/core";
import styled from "styled-components";

export const WrapperStyled = styled(Container)`
  padding-bottom: 20px;
`;

export const HeaderTopStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 45px 0 30px;
  flex: 1;
`;

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  min-height: 40px;
  position: relative;
`;

export const SubHeading = styled(Typography)`
  display: flex;
  align-items: center;
`;

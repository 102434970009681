import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import * as actions from "../../actions";
import * as selectors from "../../selectors";
import Component from "./components";

export const mapStateToProps = (state) => ({
  type: selectors.getType(state),
  data: selectors.getData(state),
});

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      hideModal: actions.hideModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Component);

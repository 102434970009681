import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ColorPicker } from "material-ui-color";
import { FormLabel } from "@material-ui/core";
import { ColorPickerStyled } from "./styled";

interface ColorPickerComponentProps {
  name: string;
  label: string;
  value: string;
  defaultValue?: string;
  required?: boolean;
  disabled?: boolean;
}

const ColorPickerComponent: React.FC<ColorPickerComponentProps> = ({
  name,
  label,
  value,
  defaultValue,
  required,
  disabled,
}) => {
  const { control } = useFormContext();

  return (
    <ColorPickerStyled>
      <FormLabel>{label}</FormLabel>
      <Controller
        render={({ ref, ...props }) => (
          <ColorPicker
            {...props}
            disableAlpha
            disablePlainColor
            value={value}
          />
        )}
        name={name}
        value={value}
        control={control}
        defaultValue={defaultValue}
        disabled={disabled}
        rules={{
          required,
        }}
      />
    </ColorPickerStyled>
  );
};

export default ColorPickerComponent;

import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import { ButtonRow, Button } from "@components/common";
import styled from "styled-components";
import { UserProfileType, CompanyType } from "@types";
import { RemoveSegmentType } from "../../../../../../../types";

const TitleStyled = styled(Typography)`
  margin-bottom: 18px;
`;

interface RemoveSegmentModalProps {
  userInfo: UserProfileType;
  removeSegment: (data: RemoveSegmentType) => void;
  hideModal: any;
  data: any;
  selectedCompany: CompanyType;
  isSegmentRemoved: boolean;
  hasError: boolean;
}

const RemoveSegmentModal: React.FC<RemoveSegmentModalProps> = ({
  userInfo,
  selectedCompany,
  hideModal,
  data,
  removeSegment,
  isSegmentRemoved,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleRemoveSegment = () => {
    setIsLoading(true);
    removeSegment({
      tenant_id: userInfo.id,
      company_id: selectedCompany.id,
      id: data.id,
    });
  };
  useEffect(() => {
    setIsLoading(false);
    if (isSegmentRemoved) {
      hideModal();
    }
  }, [isSegmentRemoved]);
  return (
    <div>
      <TitleStyled component="h3" variant="h3">
        Do you really want to delete customer segment
        <br />
        {`"${data.name}"?`}
      </TitleStyled>
      <TitleStyled>Customers assigned with this tag will lose it.</TitleStyled>
      <ButtonRow>
        <Button
          onClick={handleRemoveSegment}
          loading={isLoading}
          color="secondary"
        >
          Delete
        </Button>
        <Button color="default" onClick={hideModal} disabled={isLoading}>
          Cancel
        </Button>
      </ButtonRow>
    </div>
  );
};

export default RemoveSegmentModal;

import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import { withRouter, RouteComponentProps } from "react-router";
import { useForm, FormProvider } from "react-hook-form";
import dayjs from "dayjs";
import { WrappedComponentProps, FormattedMessage, useIntl } from "react-intl";
import {
  Button,
  TextField,
  Alert,
  ContentLoader,
  Checkbox,
  Error,
} from "@components/common";
import { COMMON } from "@constants";
import {
  UserProfileType,
  CompanyType,
  FetchIntegrationType,
  IntegrationType,
  IntegrationListItemType,
  AddIntegrationType,
  UpdateIntegrationType,
  StatusType,
} from "@types";
import { HeadingStyled, TopFormStyled } from "../../styled";

interface IntegrationsProps {
  userInfo: UserProfileType;
  selectedCompany: CompanyType;
  integrationList: IntegrationListItemType[];
  fetchIntegrationList(data: FetchIntegrationType): void;
  addIntegration(data: AddIntegrationType): void;
  updateIntegration(data: UpdateIntegrationType): void;
  resetIntegrationForm(): void;
  currentIntegration: IntegrationType;
  integrationsAdded: boolean;
  integrationsUpdated: boolean;
  hasError: boolean;
  status: StatusType;
}

const Integrations: React.FC<
  IntegrationsProps & RouteComponentProps & WrappedComponentProps
> = ({
  userInfo,
  selectedCompany,
  integrationList,
  currentIntegration,
  fetchIntegrationList,
  addIntegration,
  updateIntegration,
  resetIntegrationForm,
  integrationsAdded,
  integrationsUpdated,
  hasError,
}) => {
  const methods = useForm();
  const { handleSubmit, errors, watch, setValue } = methods;
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();
  const formValues = watch([
    "integrationField1",
    "integrationField2",
    "active",
  ]);

  const formDisabled =
    formValues.integrationField1 === currentIntegration?.credentials.name &&
    formValues.integrationField2 === currentIntegration?.credentials.password &&
    formValues.active === currentIntegration?.is_active;

  const onSubmit = ({ integrationField1, integrationField2, active }) => {
    setIsLoading(true);
    if (integrationList.length) {
      updateIntegration({
        tenant_id: userInfo.id,
        company_id: selectedCompany.id,
        integration_id: integrationList[0].id,
        type: "fiskalpro",
        credentials: {
          name: integrationField1,
          password: integrationField2,
        },
        is_active: active,
      });
    } else {
      addIntegration({
        tenant_id: userInfo.id,
        company_id: selectedCompany.id,
        type: "fiskalpro",
        credentials: {
          name: integrationField1,
          password: integrationField2,
        },
        is_active: active,
      });
    }
  };

  useEffect(() => {
    setIsLoading(false);
  }, [currentIntegration]);

  useEffect(() => {
    fetchIntegrationList({
      tenant_id: userInfo.id,
      company_id: selectedCompany.id,
    });
  }, [integrationsAdded, integrationsUpdated]);

  useEffect(() => {
    if (currentIntegration) {
      setValue("integrationField1", currentIntegration.credentials.name);
      setValue("integrationField2", currentIntegration.credentials.password);
      setValue("active", currentIntegration.is_active);
    }
  }, [currentIntegration]);

  useEffect(() => {
    return () => {
      resetIntegrationForm();
    };
  }, []);

  if (hasError) {
    return <Error />;
  }

  if (!integrationList) {
    return <ContentLoader />;
  }

  return (
    <>
      <HeadingStyled variant="h2">
        <FormattedMessage id="account.integrations.title" />
      </HeadingStyled>
      <Grid item md={6} lg={4}>
        <HeadingStyled variant="body1">
          <FormattedMessage id="account.integrations.subtitle" />
        </HeadingStyled>
        <FormProvider {...methods}>
          <TopFormStyled
            id="integration-form-search"
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="off"
          >
            <TextField
              name="integrationField1"
              label={intl.formatMessage({
                id: "account.integrations.form.name.label",
              })}
              placeholder={intl.formatMessage({
                id: "account.integrations.form.name.placeholder",
              })}
              error={errors.integrationField1}
              required
              defaultValue={currentIntegration?.credentials.name || ""}
              autoComplete="off"
              data-lpignore="true"
            />
            <TextField
              name="integrationField2"
              label={intl.formatMessage({
                id: "account.integrations.form.password.label",
              })}
              placeholder={intl.formatMessage({
                id: "account.integrations.form.password.placeholder",
              })}
              type="password"
              error={errors.integrationField2}
              defaultValue={currentIntegration?.credentials.password || ""}
              autoComplete="off"
              data-lpignore="true"
              required
            />
            <div>
              <Checkbox
                name="active"
                label="Activate integration"
                defaultValue=""
              />
            </div>
            <Button
              onClick={handleSubmit(onSubmit)}
              loading={isLoading}
              disabled={formDisabled}
            >
              {integrationList.length ? (
                <FormattedMessage id="account.integrations.form.save" />
              ) : (
                <FormattedMessage id="account.integrations.form.add" />
              )}
            </Button>
          </TopFormStyled>
          {currentIntegration && (
            <Typography
              color="textSecondary"
              variant="body1"
            >{`Last FiskalPRO update: ${
              currentIntegration.last_synchronisation_at
                ? dayjs(currentIntegration.last_synchronisation_at).format(
                    COMMON.DEFAULT_DATE_FORMAT
                  )
                : "N/A"
            }`}</Typography>
          )}
        </FormProvider>
        {integrationsAdded && (
          <Alert variant="success">Integration is added successfully</Alert>
        )}
        {integrationsUpdated && (
          <Alert variant="success">Integration is updated successfully</Alert>
        )}
        {hasError && (
          <Alert variant="warning">
            <FormattedMessage id="general.notifications.error" />
          </Alert>
        )}
      </Grid>
    </>
  );
};

export default withRouter(Integrations);

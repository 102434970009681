import styled from "styled-components";

export const NoDataStyled = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

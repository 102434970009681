import React, { useState, useEffect } from "react";
import { TextField } from "@components/common";
import { useForm, FormProvider } from "react-hook-form";
import { Grid, Typography } from "@material-ui/core";
import { useIntl, WrappedComponentProps } from "react-intl";
import { useHistory } from "react-router";
import { CompanyType, UserProfileType, CreateCompanyType } from "@types";
import { VALIDATION, ROUTES } from "@constants";
import CompanyIcon from "-!react-svg-loader!../../../../../assets/icons/company.svg";
import {
  FormStyled,
  TitleStyled,
  SubmitButtonStyled,
  WrapperStyled,
  LinearProgressStyled,
} from "../../styled";

interface AddCompanyProps {
  userData: UserProfileType;
  createCompany(data: CreateCompanyType): void;
  createdCompany: CompanyType;
}
const AddCompany: React.FC<AddCompanyProps & WrappedComponentProps> = ({
  createCompany,
  createdCompany,
  userData,
}) => {
  const intl = useIntl();
  const methods = useForm();
  const { handleSubmit, errors } = methods;
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const onSubmit = ({ name, website }) => {
    setIsLoading(true);
    createCompany({
      tenant_id: userData.id,
      name,
      website_url: website,
    });
  };

  useEffect(() => {
    if (createdCompany) {
      history.push(ROUTES.MAIN_WELCOME);
    }
  }, [createdCompany]);

  return (
    <WrapperStyled
      container
      justifyContent="center"
      direction="column"
      alignItems="center"
    >
      <LinearProgressStyled variant="determinate" value={50} />
      <Grid item xs={12} sm={8} md={6} lg={4}>
        <Typography align="center">
          <CompanyIcon />
        </Typography>
        <TitleStyled variant="h3">Let’s set up your Company</TitleStyled>
      </Grid>
      <Grid xs={12} sm={8} md={6} lg={5}>
        <Typography align="center">
          This name will appear on all the communication with your customers.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8} md={6} lg={4}>
        <FormProvider {...methods}>
          <FormStyled>
            <TextField
              name="name"
              type="text"
              placeholder={intl.formatMessage({
                id: "input.company.placeholder",
              })}
              label={intl.formatMessage({ id: "input.company.label" })}
              error={errors.name}
              required
            />
            <TextField
              name="website"
              type="text"
              placeholder={intl.formatMessage({
                id: "input.website.placeholder",
              })}
              pattern={{
                value: VALIDATION.URL_VALIDATION_REGEXP,
                message:
                  "URL is invalid (http://www.example.com or https://www.example.com)",
              }}
              label={intl.formatMessage({ id: "input.website.label" })}
              error={errors.website}
              required
            />
            <Grid container justifyContent="flex-end" alignItems="center">
              <SubmitButtonStyled
                onClick={handleSubmit(onSubmit)}
                loading={isLoading}
                disabled={isLoading}
              >
                Continue
              </SubmitButtonStyled>
            </Grid>
          </FormStyled>
        </FormProvider>
      </Grid>
    </WrapperStyled>
  );
};

export default AddCompany;

import React, { useState } from "react";
import { VALIDATION, ROUTES } from "@constants";
import { TextField } from "@components/common";
import { Typography } from "@material-ui/core";
import {
  FormattedMessage,
  injectIntl,
  useIntl,
  WrappedComponentProps,
} from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import { RouteComponentProps } from "react-router";
import { AuthRouterStateType } from "@types";
import cognitoId from "../../../../cognitoId";
import {
  WrapperStyled,
  FormStyled,
  TitleStyled,
  SubmitButtonStyled,
  LogoStyled,
} from "../styled";

const ForgotPassword = ({
  history,
}: RouteComponentProps<{}, {}, AuthRouterStateType> &
  WrappedComponentProps) => {
  const methods = useForm();
  const { handleSubmit, errors } = methods;

  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const intl = useIntl();

  const onSubmit = ({ email }) => {
    setIsLoading(true);

    const cognitoUser = cognitoId.setCognitoUser(email);

    cognitoUser.forgotPassword({
      onSuccess: () => history.push(ROUTES.USER_RESET_PASSWORD),
      onFailure: (err) => {
        setErrorMsg(err.message);
        setIsLoading(false);
      },
      inputVerificationCode: () => {
        history.push({
          pathname: ROUTES.USER_RESET_PASSWORD,
          state: {
            email,
            successMsg: intl.formatMessage({
              id: "forgot.success",
            }),
          },
        });
      },
    });
  };

  return (
    <WrapperStyled
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <LogoStyled />
      <TitleStyled variant="h2">
        <FormattedMessage id="forgot.heading" />
      </TitleStyled>
      <FormProvider {...methods}>
        <FormStyled>
          <TitleStyled variant="body1">
            <FormattedMessage id="forgot.subheading" />
          </TitleStyled>
          <TextField
            name="email"
            type="text"
            placeholder={intl.formatMessage({
              id: "input.email.placeholder",
            })}
            label={intl.formatMessage({
              id: "input.email.label",
            })}
            error={errors.email}
            pattern={{
              value: VALIDATION.EMAIL_VALIDATION_REGEXP,
              message: intl.formatMessage({ id: "input.email.pattern.error" }),
            }}
            required
          />
          <SubmitButtonStyled
            onClick={handleSubmit(onSubmit)}
            disabled={isLoading}
            loading={isLoading}
            type="submit"
          >
            <FormattedMessage id="forgot.submit" />
          </SubmitButtonStyled>
        </FormStyled>
      </FormProvider>
      {errorMsg && (
        <Typography variant="caption" paragraph color="error">
          {errorMsg}
        </Typography>
      )}
    </WrapperStyled>
  );
};

export default injectIntl(ForgotPassword);

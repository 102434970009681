import axios, { AxiosPromise } from "axios";
import CONSTANTS from "@constants";

const { API } = CONSTANTS;

export const addCardDesign = ({
  tenant_id,
  company_id,
  program_id,
  ...data
}): AxiosPromise =>
  axios.post(
    `${API.TENANTS}/${tenant_id}/companies/${company_id}/programs/${program_id}/pass-designs`,
    data
  );

// ============= PASS DESIGN ============== //

export const updateCardDesign = ({
  tenant_id,
  company_id,
  program_id,
  pass_design_id,
  ...data
}): AxiosPromise =>
  axios.put(
    `${API.TENANTS}/${tenant_id}/companies/${company_id}/programs/${program_id}/pass-designs/${pass_design_id}`,
    data
  );

export const fetchCardDesign = ({
  tenant_id,
  company_id,
  program_id,
}): AxiosPromise =>
  axios.get(
    `${API.TENANTS}/${tenant_id}/companies/${company_id}/programs/${program_id}/pass-designs`
  );

// ============= INVITE DESIGN ============== //

export const updateInviteDesign = ({
  tenant_id,
  company_id,
  program_id,
  invitation_design_id,
  ...data
}): AxiosPromise =>
  axios.put(
    `${API.TENANTS}/${tenant_id}/companies/${company_id}/programs/${program_id}/invitation-designs/${invitation_design_id}`,
    data
  );

export const fetchInviteDesign = ({
  tenant_id,
  company_id,
  program_id,
}): AxiosPromise =>
  axios.get(
    `${API.TENANTS}/${tenant_id}/companies/${company_id}/programs/${program_id}/invitation-designs`
  );

// ============= EMAIL DESIGN ============== //

export const updateEmailDesign = ({
  tenant_id,
  company_id,
  program_id,
  pass_email_design_id,
  ...data
}): AxiosPromise =>
  axios.put(
    `${API.TENANTS}/${tenant_id}/companies/${company_id}/programs/${program_id}/pass-email-designs/${pass_email_design_id}`,
    data
  );

export const fetchEmailDesign = ({
  tenant_id,
  company_id,
  program_id,
}): AxiosPromise =>
  axios.get(
    `${API.TENANTS}/${tenant_id}/companies/${company_id}/programs/${program_id}/pass-email-designs`
  );

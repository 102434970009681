import { call, put, takeLatest } from "redux-saga/effects";
import { AuthTypes } from "./types";
import * as actions from "./actions";
import * as api from "./api";

export function* createTenant(action) {
  try {
    const {
      payload: { id, email },
    } = action;

    yield call(api.createTenant, { id, email });

    yield put(actions.createTenantSucceeded());
  } catch (ex) {
    yield put(actions.createTenantFailed());
  }
}

export default [takeLatest(AuthTypes.CREATE_TENANT, createTenant)];

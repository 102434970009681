import { SelectLoyaltyType } from "@types";
import {
  MainTypes,
  mainActionTypes,
  CompanyType,
  FetchCompanyListType,
  LoyaltyType,
  FetchLoyaltyListType,
  UpdateCompanyType,
} from "./types";

// Fetch Company
export const fetchCompanyList = (
  payload: FetchCompanyListType
): mainActionTypes => ({
  type: MainTypes.FETCH_COMPANY_LIST,
  payload,
});

export const fetchCompanyListSucceeded = (
  payload: CompanyType[]
): mainActionTypes => ({
  type: MainTypes.FETCH_COMPANY_LIST_SUCCEEDED,
  payload,
});

export const fetchCompanyListFailed = () => ({
  type: MainTypes.FETCH_COMPANY_LIST_FAILED,
});

// Fetch Loyalty
export const fetchLoyaltyList = (
  payload: FetchLoyaltyListType
): mainActionTypes => ({
  type: MainTypes.FETCH_LOYALTY_LIST,
  payload,
});

export const fetchLoyaltyListSucceeded = (
  payload: LoyaltyType[]
): mainActionTypes => ({
  type: MainTypes.FETCH_LOYALTY_LIST_SUCCEEDED,
  payload,
});

export const fetchLoyaltyListFailed = (): mainActionTypes => ({
  type: MainTypes.FETCH_LOYALTY_LIST_FAILED,
});

// Update Company
export const updateCompany = (payload: UpdateCompanyType): mainActionTypes => ({
  type: MainTypes.UPDATE_COMPANY,
  payload,
});

export const updateCompanySucceeded = (
  payload: CompanyType
): mainActionTypes => ({
  type: MainTypes.UPDATE_COMPANY_SUCCEEDED,
  payload,
});

export const updateCompanyFailed = (): mainActionTypes => ({
  type: MainTypes.UPDATE_COMPANY_FAILED,
});

export const selectLoyalty = (payload: SelectLoyaltyType): mainActionTypes => ({
  type: MainTypes.SELECT_LOYALTY,
  payload,
});

import React, { useState, useEffect, useMemo } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { useForm, FormProvider } from "react-hook-form";
import { useIntl, WrappedComponentProps } from "react-intl";
import { Grid } from "@material-ui/core";
import { v4 as uuid } from "uuid";
import {
  Button,
  ButtonRow,
  TextField,
  Checkbox,
  Alert,
  ColorPicker,
  InputFile,
  ContentLoader,
  Tabs,
  Error,
} from "@components/common";
import { VALIDATION, COMMON } from "@constants";
import { checkHexColor, setImageName } from "@utils/common";
import {
  CompanyType,
  LoyaltyType,
  UserProfileType,
  ImagePreviewType,
  CardDesignListItemType,
} from "@types";
import { getFilesFromS3, uploadFilesToS3 } from "../../../../../s3";
import { FetchCardDesignType, UpdateCardDesignType } from "../../../types";
import CardIOS from "./CardIOS";
import CardAndroid from "./CardAndroid";
import {
  FormStyled,
  CardRow,
  CardStickyStyled,
  FormSubmitStyled,
} from "./styled";

const logo_uuid = uuid();
const missedDataPlaceholder = {
  geofence: {
    latitude: 25.123457,
    longitude: 145.09661,
    message: "Your store is near!",
  },
};

interface CardDesignProps {
  selectedLoyalty: LoyaltyType;
  selectedCompany: CompanyType;
  userInfo: UserProfileType;
  cardDesign: CardDesignListItemType;
  updateCardDesign(data: UpdateCardDesignType): void;
  fetchCardDesign(data: FetchCardDesignType): void;
  hasError: boolean;
  cardDesignsUpdated: boolean;
}

const CardDesign = ({
  userInfo,
  selectedLoyalty,
  selectedCompany,
  updateCardDesign,
  hasError,
  cardDesign,
  fetchCardDesign,
  cardDesignsUpdated,
}: CardDesignProps & RouteComponentProps & WrappedComponentProps) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [isAssetsLoading, setIsAssetsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const methods = useForm({
    defaultValues: {
      cardColor: "#ffffff",
      labelColor: "#000000",
      textColor: "#000000",
      hidePoints: false,
      cardNumberTitle: "Card number",
      cardNameTitle: "Name",
      cardPointsTitle: "My Points",
      email: userInfo.email,
    },
    shouldUnregister: false,
  });
  const { handleSubmit, errors, watch, setValue, formState } = methods;
  const formValues = watch([
    "cardColor",
    "labelColor",
    "textColor",
    "hidePoints",
    "cardNumberTitle",
    "cardNameTitle",
    "cardPointsTitle",
  ]);
  // pass preview state
  const [logoSmallPreview, setLogoSmallPreview] = useState<ImagePreviewType>(
    null
  );
  const [logoLargePreview, setLogoLargePreview] = useState<ImagePreviewType>(
    null
  );
  const [
    logoHorizontalPreview,
    setLogoHorizontalPreview,
  ] = useState<ImagePreviewType>(null);
  const [logoSmallUrl, setLogoSmallUrl] = useState("");
  const [logoLargeUrl, setLogoLargeUrl] = useState("");
  const [logoHorizontalUrl, setLogoHorizontalUrl] = useState("");
  const [uploadPhotoError, setUploadPhotoError] = useState(false);

  const logosNames = useMemo(() => {
    return {
      logo_square_id: setImageName(
        "logo_square",
        logo_uuid,
        !!logoSmallPreview,
        cardDesign?.images?.logo_square_id
      ),
      logo_horizontal_id: setImageName(
        "logo_horizontal",
        logo_uuid,
        !!logoHorizontalPreview,
        cardDesign?.images?.logo_horizontal_id
      ),
      hero_image_id: setImageName(
        "hero_image",
        logo_uuid,
        !!logoLargePreview,
        cardDesign?.images?.hero_image_id
      ),
    };
  }, [
    logo_uuid,
    cardDesign,
    logoSmallPreview,
    logoHorizontalPreview,
    logoLargePreview,
  ]);

  const assets = [
    {
      key: COMMON.ASSETS_PASS_FOLDER,
      fileName: logosNames.logo_square_id,
      filePreviewData: logoSmallPreview,
      filePreviewCallback: setLogoSmallUrl,
    },
    {
      key: COMMON.ASSETS_PASS_FOLDER,
      fileName: logosNames.logo_horizontal_id,
      filePreviewData: logoHorizontalPreview,
      filePreviewCallback: setLogoHorizontalUrl,
    },
    {
      key: COMMON.ASSETS_PASS_FOLDER,
      fileName: logosNames.hero_image_id,
      filePreviewData: logoLargePreview,
      filePreviewCallback: setLogoLargeUrl,
    },
  ];

  const formData = {
    tenant_id: userInfo.id,
    company_id: selectedCompany.id,
    program_id: selectedLoyalty.id,
    pass_design_id: cardDesign?.id,
    background_color_hex: checkHexColor(formValues.cardColor),
    label_color_hex: checkHexColor(formValues.labelColor),
    title_color_hex: checkHexColor(formValues.textColor),
    display_balance: !formValues.hidePoints,
    images: {
      ...logosNames,
    },
    strings: {
      name_title_text: formValues.cardNameTitle,
      pass_number_title_text: formValues.cardNumberTitle,
      balance_title_text: formValues.cardPointsTitle,
    },
    ...missedDataPlaceholder,
  };

  const resetPreviews = () => {
    setLogoSmallPreview(null);
    setLogoLargePreview(null);
    setLogoHorizontalPreview(null);
  };

  function initFormValues() {
    setValue("cardColor", cardDesign.background_color_hex);
    setValue("labelColor", cardDesign.label_color_hex);
    setValue("textColor", cardDesign.title_color_hex);
    setValue("cardNameTitle", cardDesign.strings?.name_title_text);
    setValue("cardNumberTitle", cardDesign.strings?.pass_number_title_text);
    setValue("cardPointsTitle", cardDesign.strings?.balance_title_text);
    setValue("hidePoints", !cardDesign.display_balance);
  }

  function resetForm() {
    resetPreviews();
    initFormValues();
  }

  const onSubmit = () => {
    setIsLoading(true);

    const fileUploaded = uploadFilesToS3(assets);

    fileUploaded
      .then(() => {
        updateCardDesign(formData);
      })
      .catch(() => {
        setUploadPhotoError(true);
      });
  };

  useEffect(() => {
    if (!cardDesign) {
      fetchCardDesign({
        tenant_id: userInfo.id,
        company_id: selectedCompany.id,
        program_id: selectedLoyalty.id,
      });
    }
  }, [cardDesign, selectedLoyalty]);

  useEffect(() => {
    if (cardDesign) {
      setIsAssetsLoading(true);
      initFormValues();
      getFilesFromS3(assets).then(() => {
        resetPreviews();
        setIsAssetsLoading(false);
      });
    }
  }, [cardDesign]);

  useEffect(() => {
    if (cardDesignsUpdated || hasError) {
      setIsLoading(false);
    }
  }, [cardDesignsUpdated, hasError]);

  if (hasError) {
    return <Error />;
  }

  if (!cardDesign) {
    return <ContentLoader />;
  }

  return (
    <>
      {uploadPhotoError && (
        <Alert variant="error">
          There was an error while uploading logos. Try later, please.
        </Alert>
      )}
      {cardDesignsUpdated && (
        <Alert sticky>Pass designs successfully updated</Alert>
      )}
      <Grid container>
        <Grid item sm={12} md={5} lg={5}>
          <FormProvider {...methods}>
            <FormStyled onSubmit={handleSubmit(onSubmit)}>
              <Tabs
                value={activeTab}
                // @ts-ignore-start
                onChange={(event, newValue) => setActiveTab(newValue)}
                // @ts-ignore-end
                indicatorColor="primary"
                textColor="primary"
              >
                <Tabs.Item disableRipple label="Content" />
                <Tabs.Item disableRipple label="Style" />
              </Tabs>
              <Tabs.Panel value={activeTab} index={0}>
                <TextField
                  name="cardNameTitle"
                  placeholder="Name"
                  label="Name"
                  error={errors.cardNameTitle}
                  id="form-input-card-name-title"
                  required
                />
                <TextField
                  name="cardNumberTitle"
                  placeholder="Pass number"
                  label="Pass number"
                  error={errors.cardNumberTitle}
                  id="form-input-card-number-title"
                  required
                />
                <TextField
                  name="cardPointsTitle"
                  placeholder="Balance title"
                  label="Balance title"
                  error={errors.cardPointsTitle}
                  id="form-input-card-points-title"
                  required
                />
              </Tabs.Panel>
              <Tabs.Panel value={activeTab} index={1}>
                <div>Card layout</div>
                <Checkbox
                  name="hidePoints"
                  error={errors.hidePoints}
                  label="Hide points from the card"
                />
                <ColorPicker
                  value={formValues.cardColor}
                  name="cardColor"
                  label="Card color"
                  required
                />
                <ColorPicker
                  value={formValues.labelColor}
                  name="labelColor"
                  label="Label color"
                  required
                />
                <ColorPicker
                  value={formValues.textColor}
                  name="textColor"
                  label="Text color"
                  required
                />
                <InputFile
                  label="Android Logo (square)"
                  helpText="Your picture should be a square image with dimensions of at least 80px. PNG, JPG, and SVG file types are acceptable."
                  name="logo-square"
                  setPreviewClb={setLogoSmallPreview}
                  aspectRatio={1}
                  previewClassName="cropped-logo-square"
                  required={!cardDesign}
                  preview={logoSmallPreview}
                />
                <InputFile
                  label="iOS Logo (horizontal)"
                  helpText="Your picture should be a square image with dimensions of at least 80px. PNG, JPG, and SVG file types are acceptable."
                  name="logo-horizontal"
                  setPreviewClb={setLogoHorizontalPreview}
                  aspectRatio={160 / 50}
                  previewClassName="cropped-logo-horizontal"
                  required={!cardDesign}
                  preview={logoHorizontalPreview}
                />
                <InputFile
                  label="Cover image"
                  helpText="Your picture should be a square image with dimensions of at least 80px. PNG, JPG, and SVG file types are acceptable."
                  name="logo-large"
                  setPreviewClb={setLogoLargePreview}
                  aspectRatio={375 / 144}
                  previewClassName="cropped-logo-cover"
                  required={!cardDesign}
                  preview={logoLargePreview}
                />
                <TextField
                  name="email"
                  placeholder={intl.formatMessage({
                    id: "input.email.placeholder",
                  })}
                  type="email"
                  label="Support email"
                  helperText="This email will be used for communication with the customers"
                  error={errors.email}
                  pattern={{
                    value: VALIDATION.EMAIL_VALIDATION_REGEXP,
                    message: intl.formatMessage({
                      id: "input.email.pattern.error",
                    }),
                  }}
                  id="form-input-email"
                />
              </Tabs.Panel>
              <FormSubmitStyled>
                <ButtonRow>
                  <Button
                    loading={isLoading}
                    onClick={handleSubmit(onSubmit)}
                    type="submit"
                    disabled={!formState.isDirty}
                  >
                    Save changes
                  </Button>
                  <Button
                    disabled={isLoading || !formState.isDirty}
                    onClick={resetForm}
                    type="reset"
                    color="default"
                  >
                    Reset
                  </Button>
                </ButtonRow>
              </FormSubmitStyled>
            </FormStyled>
          </FormProvider>
        </Grid>
        <CardRow item xs={12} md={6}>
          <CardStickyStyled>
            <CardIOS
              bgColor={checkHexColor(formValues.cardColor)}
              labelColor={checkHexColor(formValues.labelColor)}
              textColor={checkHexColor(formValues.textColor)}
              hidePoints={formValues.hidePoints}
              logoLargePreview={logoLargePreview}
              logoHorizontalPreview={logoHorizontalPreview}
              logoLargeUrl={logoLargeUrl}
              logoHorizontalUrl={logoHorizontalUrl}
              cardNumberTitle={formValues.cardNumberTitle}
              cardNameTitle={formValues.cardNameTitle}
              cardPointsTitle={formValues.cardPointsTitle}
              assetsLoading={isAssetsLoading}
            />
            <CardAndroid
              bgColor={checkHexColor(formValues.cardColor)}
              labelColor={checkHexColor(formValues.labelColor)}
              textColor={checkHexColor(formValues.textColor)}
              hidePoints={formValues.hidePoints}
              logoSmallPreview={logoSmallPreview}
              logoLargePreview={logoLargePreview}
              loyaltyName={selectedLoyalty.name}
              companyName={selectedCompany.name}
              logoSmallUrl={logoSmallUrl}
              logoLargeUrl={logoLargeUrl}
              cardNumberTitle={formValues.cardNumberTitle}
              cardNameTitle={formValues.cardNameTitle}
              cardPointsTitle={formValues.cardPointsTitle}
              assetsLoading={isAssetsLoading}
            />
          </CardStickyStyled>
        </CardRow>
      </Grid>
    </>
  );
};

export default withRouter(CardDesign);

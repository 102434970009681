import { AuthTypes, AuthActionTypes } from "./types";
import { UserProfileType, TenantType } from "../../types";

export const signedInSucceeded = (
  payload: UserProfileType
): AuthActionTypes => ({
  type: AuthTypes.USER_SIGNED_IN_SUCCEEDED,
  payload,
});

export const signedInFailed = (): AuthActionTypes => ({
  type: AuthTypes.USER_SIGNED_IN_FAILED,
});

export const signedOutSucceeded = (): AuthActionTypes => ({
  type: AuthTypes.USER_SIGNED_OUT_SUCCEEDED,
});

export const signedUpSucceeded = (
  payload: UserProfileType
): AuthActionTypes => ({
  type: AuthTypes.USER_SIGNED_UP_SUCCEEDED,
  payload,
});

export const userVerifiedSucceeded = (
  payload: UserProfileType
): AuthActionTypes => ({
  type: AuthTypes.USER_VERIFIED_SUCCEEDED,
  payload,
});

export const createTenant = (payload: TenantType): AuthActionTypes => ({
  type: AuthTypes.CREATE_TENANT,
  payload,
});

export const createTenantSucceeded = (): AuthActionTypes => ({
  type: AuthTypes.CREATE_TENANT_SUCCEEDED,
});

export const createTenantFailed = (): AuthActionTypes => ({
  type: AuthTypes.CREATE_TENANT_FAILED,
});

import React, { useEffect } from "react";
import { withRouter, RouteComponentProps, useParams } from "react-router";
import { WrappedComponentProps } from "react-intl";
import { Alert, ContentLoader, Error } from "@components/common";
import { WrapperStyled } from "./styled";
import {
  FetchLoyaltyAccountType,
  LoyaltyAccountType,
  PassesType,
} from "../../../../types";
import Body from "./Body";

interface InviteCustomerProps {
  fetchLoyaltyAccount(data: FetchLoyaltyAccountType): void;
  fetchPasses(data: FetchLoyaltyAccountType): void;
  loyaltyAccountPass: LoyaltyAccountType[];
  // passDesigns: PassesType;
  passes: PassesType[];
  hasError: boolean;
}
interface RouteParamsType {
  id: string;
}

const InvitePage = ({
  loyaltyAccountPass,
  fetchLoyaltyAccount,
  fetchPasses,
  // passDesigns,
  passes,
  hasError,
}: InviteCustomerProps & RouteComponentProps & WrappedComponentProps) => {
  // const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams<RouteParamsType>();

  useEffect(() => {
    if (id) {
      fetchLoyaltyAccount({ loyalty_account_id: id });
      fetchPasses({ loyalty_account_id: id });
    }
  }, [id]);

  if (hasError) {
    return <Error container />;
  }

  if (!passes || !loyaltyAccountPass) {
    return <ContentLoader />;
  }

  return (
    <WrapperStyled>
      {hasError && (
        <Alert variant="error" sticky>
          There was an error. Try later, please.
        </Alert>
      )}
      <Body
        bodyBgColor="#bbb"
        containerBgColor="#fff"
        textColor="#000"
        borderColor="#fff"
        // buttonBgColor="#fff"
        // buttonTextColor="#fff"
        showBorder={false}
        // logoPreview
        logoUrl="#fff"
        logoPreview={null}
        // content
        contentHelloText="Hello customer!"
        contentDownloadText="You can download your card here:"
        contentSaveAsImageText="Save as image"
        iosPassHref={passes[0]?.ios_href}
        androidPassHref={passes[0]?.android_href}
        saveAsInfo={loyaltyAccountPass[0]}
      />
    </WrapperStyled>
  );
};

export default withRouter(InvitePage);

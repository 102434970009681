import * as Sentry from "@sentry/react";
import { createStore, compose } from "redux";
import reducers from "../modules/reducer";
import middleware from "../middlewares";
import { runSaga } from "../middlewares/saga";

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const store = createStore(reducers, compose(middleware, sentryReduxEnhancer));

runSaga();

export default store;

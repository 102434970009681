import { all } from "redux-saga/effects";
// -- start importing sagas
import { saga as auth } from "./auth";
import { saga as onboarding } from "./onboarding";
import { saga as main } from "./main";
import { saga as customers } from "./customers";
import { saga as transactions } from "./transactions";
import { saga as account } from "./account";
import { saga as loyalties } from "./loyalties";
// -- end importing sagas

const rootSaga = function* () {
  return yield all([
    // -- start connecting sagas
    ...auth,
    ...onboarding,
    ...main,
    ...customers,
    ...transactions,
    ...account,
    ...loyalties,
    // -- end connecting sagas
  ]);
};

export default rootSaga;

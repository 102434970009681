import { ModalTypes, ModalActionTypes } from "./types";

export const showModal = (payload): ModalActionTypes => ({
  type: ModalTypes.SHOW_MODAL,
  payload,
});

export const hideModal = (): ModalActionTypes => ({
  type: ModalTypes.HIDE_MODAL,
});

import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { injectIntl } from "react-intl";
import { accountSelectors } from "../../selectors";
import { authSelectors } from "../../../auth";
import Component from "./components";

const mapStateToProps = (state) => ({
  userInfo: authSelectors.getUser(state),
  hasError: accountSelectors.getError(state),
  status: accountSelectors.getStatus(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default injectIntl(ConnectedComponent);

import React from "react";
import { COMMON, ROUTES } from "@constants";
import { Container, Grid } from "@material-ui/core";
import { WrappedComponentProps, useIntl } from "react-intl";
import {
  withRouter,
  Switch,
  Route,
  Redirect,
  RouteComponentProps,
} from "react-router";
import { SectionNav } from "@components/common";
import TransactionList from "../../TransactionList";
import { HeaderTopStyled, SubHeading } from "../../styled";
import { LoyaltyType } from "../../../../../types";

interface TransactionsProps {
  programs: LoyaltyType[];
}

const Transactions: React.FC<
  TransactionsProps & RouteComponentProps<{}, {}> & WrappedComponentProps
> = ({ programs }) => {
  const intl = useIntl();

  return (
    <Container>
      <Grid container justifyContent="space-between" alignItems="center">
        <HeaderTopStyled>
          <SubHeading variant="h2">
            {intl.formatMessage({
              id: "transactions.main.title",
            })}
          </SubHeading>
        </HeaderTopStyled>
      </Grid>

      <SectionNav
        disabled={programs?.length === 0}
        navItems={COMMON.TRANSACTION_NAV}
      />
      <Switch>
        <Route
          exact
          path={ROUTES.TRANSACTIONS_OVERVIEW}
          component={TransactionList}
        />
        <Redirect to={ROUTES.TRANSACTIONS_OVERVIEW} />
      </Switch>
    </Container>
  );
};

export default withRouter(Transactions);

import React from "react";

// for search filter component
export const getHighlightedText = (text, highlight) => {
  if (!highlight) return text;
  const textToString = text?.toString();
  const parts = textToString.split(new RegExp(`(${highlight})`, "gi"));
  return (
    <span>
      {parts.map((part, i) => (
        <span
          key={i.toString()}
          style={
            part.toLowerCase() === highlight.toLowerCase()
              ? { fontWeight: "bold" }
              : {}
          }
        >
          {part}
        </span>
      ))}
    </span>
  );
};

// IMG Upload/Preview utils
export const encodeBlob = (data) => {
  const buf = Buffer.from(data);
  const base64 = buf.toString("base64");
  return base64;
};

export const dataURLtoBlob = (dataURL) => {
  if (!dataURL) return false;

  const arr = dataURL.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new Blob([u8arr], { type: mime });
};

// util for color picker
export const checkHexColor = (value: any, defaultValue?: string): string =>
  value?.hex ? `#${value.hex}` : defaultValue || value;

export const formatMoney = (money: number) => {
  if (Number.isNaN(money)) return "0.00 \u20ac";
  return `${parseFloat(money.toString()).toFixed(2)} \u20ac`;
};

export const setImageName = (
  type: string,
  id: string,
  hasPreview: boolean,
  name: string
) => {
  if (hasPreview) {
    return `${type}_${id}`;
  }

  return name;
};

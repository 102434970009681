import { call, put, takeLatest } from "redux-saga/effects";
import { CustomersTypes } from "./types";
import * as actions from "./actions";
import * as api from "./api";

export function* fetchCustomerList(action) {
  try {
    const { payload } = action;

    const res = yield call(api.fetchCustomerList, payload);

    yield put(actions.fetchCustomerListSucceeded(res.data));
  } catch (ex) {
    yield put(actions.fetchCustomerListFailed());
  }
}

export function* fetchCustomerByEmail(action) {
  try {
    const { payload } = action;

    const res = yield call(api.fetchCustomerByEmail, payload);

    yield put(actions.fetchCustomerByEmailSucceeded(res.data));
  } catch (ex) {
    yield put(actions.fetchCustomerByEmailFailed());
  }
}

export function* fetchLoyaltyList(action) {
  try {
    const { payload } = action;

    const res = yield call(api.fetchLoyaltyList, payload);

    yield put(actions.fetchLoyaltyListListSucceeded(res.data.items));
  } catch (ex) {
    yield put(actions.fetchLoyaltyListFailed());
  }
}

// export function* fetchLoyaltyCustomerProfile(action) {
//  try {
//    const { payload } = action;
//
//    const res = yield call(api.fetchLoyaltyCustomerProfile, payload);
//
//    yield put(actions.fetchLoyaltyCustomerProfileSucceeded(res.data.items));
//  } catch (ex) {
//    yield put(actions.fetchLoyaltyCustomerProfileFailed());
//  }
//

export function* updateCustomer(action) {
  try {
    const { payload } = action;

    const res = yield call(api.updateCustomer, payload);

    yield put(actions.updateCustomerSucceeded(res.data.items));
  } catch (ex) {
    yield put(actions.updateCustomerFailed());
  }
}

export function* fetchCustomerTransactionList(action) {
  try {
    const { payload } = action;

    const res = yield call(api.fetchCustomerTransactionList, payload);

    yield put(actions.fetchCustomerTransactionListSucceeded(res.data));
  } catch (ex) {
    yield put(actions.fetchCustomerTransactionListFailed());
  }
}

export function* fetchLoyaltyTransactionList(action) {
  try {
    const { payload } = action;

    const res = yield call(api.fetchLoyaltyTransactionList, payload);

    yield put(actions.fetchLoyaltyTransactionListSucceeded(res.data));
  } catch (ex) {
    yield put(actions.fetchLoyaltyTransactionListFailed());
  }
}

export function* addLoyalty(action) {
  try {
    const { payload } = action;

    const res = yield call(api.addLoyaltyCustomer, payload);

    yield put(
      actions.addLoyaltySucceeded({
        data: res.data,
        status: res.status,
      })
    );
  } catch (ex) {
    yield put(actions.addLoyaltyFailed());
  }
}

export function* inviteLoyalty(action) {
  try {
    const { payload } = action;

    const res = yield call(api.inviteLoyaltyCustomer, payload);

    yield put(actions.inviteLoyaltySucceeded(res.data));
  } catch (ex) {
    yield put(actions.inviteLoyaltyFailed());
  }
}

export function* addLoyaltyCustomerTransaction(action) {
  try {
    const { payload } = action;

    const res = yield call(api.addCustomerTransaction, payload);

    yield put(actions.addLoyaltyTransactionSucceeded(res.data.customer));
  } catch (ex) {
    yield put(actions.addLoyaltyTransactionFailed());
  }
}

export function* resendLoyaltyCustomerPass(action) {
  try {
    const { payload } = action;

    yield call(api.resendLoyaltyAccountPass, payload);

    yield put(actions.resendLoyaltyPassSucceeded());
  } catch (ex) {
    yield put(actions.resendLoyaltyPassFailed());
  }
}

export function* fetchInvitationDesigns(action) {
  try {
    const { payload } = action;

    const res = yield call(api.fetchInvitationDesigns, payload);

    yield put(actions.fetchInvitationDesignsSucceeded(res.data.items[0]));
  } catch (ex) {
    yield put(actions.fetchInvitationDesignsFailed());
  }
}

export function* fetchSegments(action) {
  try {
    const { payload } = action;

    const res = yield call(api.fetchSegmentList, payload);

    yield put(actions.fetchLoyaltySegmentListSucceeded(res.data.items));
  } catch (ex) {
    yield put(actions.fetchLoyaltySegmentListFailed());
  }
}

export function* addSegment(action) {
  try {
    const { payload } = action;

    const res = yield call(api.addSegment, payload);

    yield put(actions.addLoyaltySegmentSucceeded(res.data));
  } catch (ex) {
    yield put(actions.addLoyaltySegmentFailed());
  }
}

export function* removeSegment(action) {
  try {
    const { payload } = action;

    yield call(api.removeSegment, payload);

    yield put(actions.removeLoyaltySegmentSucceeded());
  } catch (ex) {
    yield put(actions.removeLoyaltySegmentFailed());
  }
}

export function* fetchLoyaltyAccount(action) {
  try {
    const { payload } = action;

    const res = yield call(api.fetchLoyaltyAccount, payload);

    yield put(actions.fetchLoyaltyAccountSucceeded(res.data.items));
  } catch (ex) {
    yield put(actions.fetchLoyaltyAccountFailed());
  }
}

export function* fetchPasses(action) {
  try {
    const { payload } = action;

    const res = yield call(api.fetchPasses, payload);

    yield put(actions.fetchPassesSucceeded(res.data.items));
  } catch (ex) {
    yield put(actions.fetchPassesFailed());
  }
}

export default [
  takeLatest(CustomersTypes.ADD_LOYALTY, addLoyalty),
  takeLatest(
    CustomersTypes.ADD_LOYALTY_TRANSACTION,
    addLoyaltyCustomerTransaction
  ),
  takeLatest(CustomersTypes.INVITE_LOYALTY, inviteLoyalty),
  takeLatest(CustomersTypes.UPDATE_CUSTOMER, updateCustomer),
  takeLatest(CustomersTypes.FETCH_LOYALTIES, fetchLoyaltyList),
  takeLatest(CustomersTypes.FETCH_CUSTOMER_LIST, fetchCustomerList),
  takeLatest(CustomersTypes.FETCH_CUSTOMER_BY_EMAIL, fetchCustomerByEmail),
  takeLatest(
    CustomersTypes.FETCH_CUSTOMER_TRANSACTION_LIST,
    fetchCustomerTransactionList
  ),
  takeLatest(
    CustomersTypes.FETCH_LOYALTY_TRANSACTION_LIST,
    fetchLoyaltyTransactionList
  ),
  takeLatest(CustomersTypes.RESEND_LOYALTY_PASS, resendLoyaltyCustomerPass),
  takeLatest(CustomersTypes.FETCH_INVITATION_DESIGNS, fetchInvitationDesigns),
  takeLatest(CustomersTypes.FETCH_CUSTOMER_SEGMENT_LIST, fetchSegments),
  takeLatest(CustomersTypes.ADD_CUSTOMER_SEGMENT, addSegment),
  takeLatest(CustomersTypes.REMOVE_LOYALTY_SEGMENT, removeSegment),
  takeLatest(CustomersTypes.FETCH_PASSES, fetchPasses),
  takeLatest(CustomersTypes.FETCH_LOYALTY_ACCOUNT, fetchLoyaltyAccount),
];

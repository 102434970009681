import {
  Messages,
  Languages,
} from '../types';

export default function getMessages(messages: Messages, language: Languages) {
  const languageWithoutRegionCode = language
    .toLowerCase()
    .split(/[_-]+/)[0];

  return messages[language] || messages[languageWithoutRegionCode];
}

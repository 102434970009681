import { call, put, takeLatest } from "redux-saga/effects";
import { LoyaltiesTypes } from "./types";
import * as actions from "./actions";
import * as api from "./api";

export function* updateCardDesigns(action) {
  try {
    const { payload } = action;

    const res = yield call(api.updateCardDesign, payload);

    yield put(actions.updateCardDesignSucceeded(res.data));
  } catch (ex) {
    yield put(actions.updateCardDesignFailed());
  }
}

export function* fetchCardDesigns(action) {
  try {
    const { payload } = action;

    const res = yield call(api.fetchCardDesign, payload);

    yield put(actions.fetchCardDesignSucceeded(res.data.items));
  } catch (ex) {
    yield put(actions.fetchCardDesignFailed());
  }
}

// Invite
export function* updateInviteDesigns(action) {
  try {
    const { payload } = action;

    const res = yield call(api.updateInviteDesign, payload);

    yield put(actions.updateInviteDesignSucceeded(res.data));
  } catch (ex) {
    yield put(actions.updateInviteDesignFailed());
  }
}

export function* fetchInviteDesigns(action) {
  try {
    const { payload } = action;

    const res = yield call(api.fetchInviteDesign, payload);

    yield put(actions.fetchInviteDesignSucceeded(res.data.items));
  } catch (ex) {
    yield put(actions.fetchInviteDesignFailed());
  }
}

// Email
export function* updateEmailDesigns(action) {
  try {
    const { payload } = action;

    const res = yield call(api.updateEmailDesign, payload);

    yield put(actions.updateEmailDesignSucceeded(res.data));
  } catch (ex) {
    yield put(actions.updateEmailDesignFailed());
  }
}

export function* fetchEmailDesigns(action) {
  try {
    const { payload } = action;

    const res = yield call(api.fetchEmailDesign, payload);

    yield put(actions.fetchEmailDesignSucceeded(res.data.items));
  } catch (ex) {
    yield put(actions.fetchEmailDesignFailed());
  }
}

export default [
  // PASS
  takeLatest(LoyaltiesTypes.UPDATE_CARD_DESIGN, updateCardDesigns),
  takeLatest(LoyaltiesTypes.FETCH_CARD_DESIGN_LIST, fetchCardDesigns),
  // INVITE
  takeLatest(LoyaltiesTypes.UPDATE_INVITE_DESIGN, updateInviteDesigns),
  takeLatest(LoyaltiesTypes.FETCH_INVITE_DESIGN_LIST, fetchInviteDesigns),
  // EMAIL
  takeLatest(LoyaltiesTypes.UPDATE_EMAIL_DESIGN, updateEmailDesigns),
  takeLatest(LoyaltiesTypes.FETCH_EMAIL_DESIGN_LIST, fetchEmailDesigns),
];

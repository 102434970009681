import React, { useState, useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { useForm, FormProvider } from "react-hook-form";
import { WrappedComponentProps } from "react-intl";
import { Grid, Typography } from "@material-ui/core";
import {
  Button,
  ButtonRow,
  TextField,
  Checkbox,
  Alert,
  ColorPicker,
  InputFile,
  ContentLoader,
  Tabs,
  Error,
} from "@components/common";
import { checkHexColor, setImageName } from "@utils/common";
import { COMMON } from "@constants";
import {
  CompanyType,
  LoyaltyType,
  UserProfileType,
  ImagePreviewType,
  EmailDesignListItemType,
} from "@types";
import { v4 as uuid } from "uuid";
import EmailFormPreview from "./EmailPreview";
import DownloadPreview from "./DownloadPreview";
import { getFilesFromS3, uploadFilesToS3 } from "../../../../../s3";
import { UpdateEmailDesignType, FetchEmailDesignType } from "../../../types";
import { FormStyled, FormSubmitStyled, PreviewContainerStyled } from "./styled";

const logo_uuid = uuid();

interface EmailDesignProps {
  selectedLoyalty: LoyaltyType;
  selectedCompany: CompanyType;
  userInfo: UserProfileType;
  emailDesign: EmailDesignListItemType;
  emailDesigns: EmailDesignListItemType[];
  updateEmailDesign(data: UpdateEmailDesignType): void;
  fetchEmailDesign(data: FetchEmailDesignType): void;
  hasError: boolean;
  emailDesignUpdated: boolean;
}

const EmailDesign = ({
  userInfo,
  selectedLoyalty,
  selectedCompany,
  emailDesign,
  fetchEmailDesign,
  updateEmailDesign,
  emailDesignUpdated,
  hasError,
}: EmailDesignProps & RouteComponentProps & WrappedComponentProps) => {
  const methods = useForm({
    defaultValues: {
      senderName: "",
      emailSubject: "",
      contentHeading: "",
      contentText: "",
      // contentFieldIOS: "",
      // contentFieldIOSAnswer: "",
      // contentFieldAndroid: "",
      // contentFieldAndroidAnswer: "",
      contentHelloText: "Hello",
      contentDownloadText: "You can download your card here:",
      contentSaveAsImageText: "Save as image",
      contentButtonText: "",
      contentPostText: "",
      heroLogo: "",
      bodyBgColor: "",
      containerBgColor: "",
      textColor: "",
      borderColor: "",
      showBorder: false,
      buttonBgColor: "",
      buttonTextColor: "",
    },
    shouldUnregister: false,
  });
  const { handleSubmit, errors, watch, setValue, formState } = methods;
  const formValues = watch([
    "senderName",
    "emailSubject",
    "contentHeading",
    "contentText",
    "contentButtonText",
    "contentPostText",
    "heroLogo",
    "bodyBgColor",
    "containerBgColor",
    "textColor",
    "showBorder",
    "borderColor",
    "buttonBgColor",
    "buttonTextColor",
    "contentHelloText",
    "contentDownloadText",
    "contentSaveAsImageText",
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [logoSmallPreview, setLogoSmallPreview] = useState<ImagePreviewType>(
    null
  );
  const [logoSmallUrl, setLogoSmallUrl] = useState("");
  const [uploadPhotoError, setUploadPhotoError] = useState(false);
  const [logosUploaded, setLogosUploaded] = useState(false);

  const logoFormData = [
    {
      key: COMMON.ASSETS_EMAIL_FOLDER,
      fileName: setImageName(
        "email",
        logo_uuid,
        !!logoSmallPreview,
        emailDesign?.images?.header_image_id
      ),
      filePreviewData: logoSmallPreview,
      filePreviewCallback: setLogoSmallUrl,
      public: true,
    },
  ];

  const formData = {
    tenant_id: userInfo.id,
    company_id: selectedCompany.id,
    program_id: selectedLoyalty.id,
    pass_email_design_id: emailDesign?.id,
    background_color_hex: checkHexColor(formValues.bodyBgColor),
    container_background_color_hex: checkHexColor(formValues.containerBgColor),
    container_border_color_hex: checkHexColor(formValues.borderColor),
    label_color_hex: checkHexColor(formValues.textColor),
    download_button_background_color_hex: checkHexColor(
      formValues.buttonBgColor
    ),
    download_button_label_color_hex: checkHexColor(formValues.buttonTextColor),
    display_container_border: formValues.showBorder,
    strings: {
      email_subject_text: formValues.emailSubject,
      title_label_text: formValues.contentHeading,
      description_label_text: formValues.contentText,
      // ios_question_label_text: formValues.contentFieldIOS,
      // ios_answer_label_text: formValues.contentFieldIOSAnswer,
      // android_question_label_text: formValues.contentFieldAndroid,
      // android_answer_label_text: formValues.contentFieldAndroidAnswer,

      download_title_label_text: formValues.contentDownloadText,
      download_hello_title_label_text: formValues.contentHelloText,
      save_as_image_label_text: formValues.contentSaveAsImageText,

      download_button_label_text: formValues.contentButtonText,
      bottom_label_text: formValues.contentPostText,
      email_sender_text: formValues.senderName,
    },
    images: {
      header_image_id: logoFormData[0].fileName,
    },
  };

  function initFormValues() {
    setValue("senderName", emailDesign.strings.email_sender_text);
    setValue("contentHeading", emailDesign.strings.title_label_text);
    setValue("contentText", emailDesign.strings.description_label_text);
    // setValue("contentFieldIOS", emailDesign.strings.ios_question_label_text);
    // setValue(
    //   "contentFieldIOSAnswer",
    //   emailDesign.strings.ios_answer_label_text
    // );
    // setValue(
    //   "contentFieldAndroid",
    //   emailDesign.strings.android_question_label_text
    // );
    // setValue(
    //   "contentFieldAndroidAnswer",
    //   emailDesign.strings.android_answer_label_text
    // );
    setValue(
      "contentButtonText",
      emailDesign.strings.download_button_label_text
    );
    setValue("contentPostText", emailDesign.strings.bottom_label_text);
    setValue("emailSubject", emailDesign.strings.email_subject_text);
    setValue("showBorder", emailDesign.display_container_border);

    setValue("bodyBgColor", emailDesign.background_color_hex);
    setValue("containerBgColor", emailDesign.container_background_color_hex);
    setValue("textColor", emailDesign.label_color_hex);
    setValue("borderColor", emailDesign.container_border_color_hex);
    setValue("buttonBgColor", emailDesign.download_button_background_color_hex);
    setValue("buttonTextColor", emailDesign.download_button_label_color_hex);
  }

  function resetForm() {
    initFormValues();
    setLogoSmallPreview(null);
  }

  const onSubmit = () => {
    setIsLoading(true);

    const logoUploaded = uploadFilesToS3(logoFormData);
    logoUploaded
      .then(() => {
        setLogosUploaded(true);
        setLogoSmallPreview(null);
      })
      .catch(() => {
        setLogosUploaded(false);
        setUploadPhotoError(true);
      });
  };

  useEffect(() => {
    if (logosUploaded && !uploadPhotoError) {
      updateEmailDesign(formData);
    }
  }, [logosUploaded, uploadPhotoError]);

  useEffect(() => {
    fetchEmailDesign({
      tenant_id: userInfo.id,
      company_id: selectedCompany.id,
      program_id: selectedLoyalty.id,
    });
  }, []);

  useEffect(() => {
    if (emailDesign || hasError) {
      setIsLoading(false);
      setLogosUploaded(false);
    }
  }, [hasError, emailDesign]);

  useEffect(() => {
    if (emailDesign) {
      initFormValues();
      getFilesFromS3(logoFormData).then(() => setLogoSmallPreview(null));
    }
  }, [emailDesign]);

  if (hasError) {
    return <Error />;
  }

  if (!emailDesign) {
    return <ContentLoader />;
  }

  return (
    <>
      {uploadPhotoError && (
        <Alert variant="error">
          There was an error while uploading logos. Try later.
        </Alert>
      )}
      {emailDesignUpdated && (
        <Alert variant="success" sticky>
          Email form design successfully updated
        </Alert>
      )}
      <Grid container>
        <Grid item sm={12} md={5} lg={5}>
          <FormProvider {...methods}>
            <FormStyled onSubmit={handleSubmit(onSubmit)}>
              <Tabs
                value={activeTab}
                // @ts-expect-error
                onChange={(_, newValue: number) => setActiveTab(newValue)}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tabs.Item disableRipple label="Meta" />
                <Tabs.Item disableRipple label="Content" />
                <Tabs.Item disableRipple label="Style" />
              </Tabs>
              <Tabs.Panel value={activeTab} index={0}>
                <TextField
                  name="senderName"
                  placeholder="Sender name"
                  label="Sender name"
                  id="form-input-sender-name"
                />
                <TextField
                  name="emailSubject"
                  placeholder="Email subject"
                  label="Email subject"
                  id="form-input-email-subject"
                />
              </Tabs.Panel>
              <Tabs.Panel value={activeTab} index={1}>
                <TextField
                  name="contentHeading"
                  placeholder="Heading"
                  label="Heading"
                  id="form-input-email-heading"
                />
                <TextField
                  name="contentText"
                  placeholder="Opening text"
                  label="Opening text"
                  id="form-input-email-text"
                />
                {/* <TextField
                  name="contentFieldIOS"
                  placeholder="Do you have an iPhone?"
                  label="Do you have an iPhone?"
                  id="form-input-field-ios"
                  error={errors.contentFieldIOS}
                  required
                />
                <TextField
                  name="contentFieldIOSAnswer"
                  placeholder="iPhone answer"
                  label="iPhone answer"
                  id="form-input-field-ios-answer"
                  error={errors.contentFieldIOSAnswer}
                  required
                />
                <TextField
                  name="contentFieldAndroid"
                  placeholder="Android question"
                  label="Android question"
                  id="form-input-field-android"
                  error={errors.contentFieldAndroid}
                  required
                />
                <TextField
                  name="contentFieldAndroidAnswer"
                  placeholder="Android answer"
                  label="Android answer"
                  id="form-input-field-android-answer"
                  error={errors.contentFieldAndroidAnswer}
                  required
                /> */}
                <TextField
                  name="contentButtonText"
                  placeholder="Button text"
                  label="Button text"
                  id="form-input-button-text"
                  error={errors.contentButtonText}
                  required
                />
                <TextField
                  name="contentPostText"
                  placeholder="Footer text"
                  label="Footer text"
                  id="form-input-post-text"
                  error={errors.contentPostText}
                />
                <Typography variant="subtitle2" component="p">
                  Download website
                </Typography>
                <TextField
                  name="contentHelloText"
                  placeholder="“Hello” text"
                  label="“Hello” text"
                  id="form-input-hello-text"
                  error={errors.contentHelloText}
                  required
                />
                <TextField
                  name="contentDownloadText"
                  placeholder="“You can download” text"
                  label="“You can download” text"
                  id="form-input-download-text"
                  error={errors.contentDownloadText}
                />
                <TextField
                  name="contentSaveAsImageText"
                  placeholder="“Save as image” text"
                  label="“Save as image” text"
                  id="form-input-save-as-text"
                  error={errors.contentSaveAsImageText}
                />
              </Tabs.Panel>
              <Tabs.Panel value={activeTab} index={2}>
                <InputFile
                  label="Hero image"
                  helpText="Your picture should be a square image with dimensions of at least 80px. PNG, JPG, and SVG file types are acceptable."
                  name="heroLogo"
                  setPreviewClb={setLogoSmallPreview}
                  aspectRatio={320 / 100}
                  previewClassName="cropped-form-logo"
                  preview={logoSmallPreview}
                />
                <ColorPicker
                  value={formValues.bodyBgColor}
                  name="bodyBgColor"
                  label="Background color"
                />
                <ColorPicker
                  value={formValues.containerBgColor}
                  name="containerBgColor"
                  label="Container background color"
                />
                <ColorPicker
                  value={formValues.textColor}
                  name="textColor"
                  label="Text color"
                />
                <Checkbox
                  name="showBorder"
                  value={formValues.showBorder}
                  label="Show border"
                />
                {formValues.showBorder && (
                  <ColorPicker
                    value={formValues.borderColor}
                    name="borderColor"
                    label="Border color"
                  />
                )}
                <ColorPicker
                  value={formValues.buttonBgColor}
                  name="buttonBgColor"
                  label="Button background color"
                />
                <ColorPicker
                  value={formValues.buttonTextColor}
                  name="buttonTextColor"
                  label="Button text color"
                />
              </Tabs.Panel>
              <FormSubmitStyled>
                <ButtonRow>
                  <Button
                    loading={isLoading}
                    onClick={handleSubmit(onSubmit)}
                    type="submit"
                    disabled={!formState.isDirty}
                  >
                    Save changes
                  </Button>
                  <Button
                    disabled={isLoading || !formState.isDirty}
                    type="reset"
                    color="default"
                    onClick={resetForm}
                  >
                    Reset
                  </Button>
                </ButtonRow>
              </FormSubmitStyled>
            </FormStyled>
          </FormProvider>
        </Grid>
        <Grid item md={7} lg={7}>
          <PreviewContainerStyled>
            <EmailFormPreview
              bodyBgColor={checkHexColor(formValues.bodyBgColor)}
              containerBgColor={checkHexColor(formValues.containerBgColor)}
              textColor={checkHexColor(formValues.textColor)}
              borderColor={checkHexColor(formValues.borderColor)}
              buttonBgColor={checkHexColor(formValues.buttonBgColor)}
              buttonTextColor={checkHexColor(formValues.buttonTextColor)}
              showBorder={formValues.showBorder}
              logoPreview={logoSmallPreview}
              logoUrl={logoSmallUrl}
              // content
              contentTitle={formValues.contentHeading}
              contentText={formValues.contentText}
              buttonText={formValues.contentButtonText}
              contentPostText={formValues.contentPostText}
            />
            <DownloadPreview
              bodyBgColor={checkHexColor(formValues.bodyBgColor)}
              containerBgColor={checkHexColor(formValues.containerBgColor)}
              textColor={checkHexColor(formValues.textColor)}
              borderColor={checkHexColor(formValues.borderColor)}
              buttonBgColor={checkHexColor(formValues.buttonBgColor)}
              buttonTextColor={checkHexColor(formValues.buttonTextColor)}
              showBorder={formValues.showBorder}
              logoPreview={logoSmallPreview}
              logoUrl={logoSmallUrl}
              // content
              contentHelloText={formValues.contentHelloText}
              contentDownloadText={formValues.contentDownloadText}
              contentSaveAsImageText={formValues.contentSaveAsImageText}
            />
          </PreviewContainerStyled>
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(EmailDesign);

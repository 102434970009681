import axios, { AxiosPromise } from "axios";
import CONSTANTS from "@constants";

const { API } = CONSTANTS;

// Customer
export const fetchCustomerList = ({
  tenant_id,
  company_id,
  limit = 10,
  offset = 0,
}): AxiosPromise =>
  axios.get(
    `${API.TENANTS}/${tenant_id}/companies/${company_id}/customers?limit=${limit}&offset=${offset}`
  );

export const fetchCustomerByEmail = ({
  tenant_id,
  company_id,
  email,
}): AxiosPromise =>
  axios.get(
    `${API.TENANTS}/${tenant_id}/companies/${company_id}/customers?email=${email}`
  );

export const updateCustomer = ({
  tenant_id,
  company_id,
  account_id,
  ...data
}): AxiosPromise =>
  axios.patch(
    `${API.TENANTS}/${tenant_id}/companies/${company_id}/customers/${account_id}`,
    data
  );

export const fetchCustomerTransactionList = ({
  tenant_id,
  company_id,
  account_id,
  limit = 10,
  offset = 0,
}): AxiosPromise =>
  axios.get(
    `${API.TENANTS}/${tenant_id}/companies/${company_id}/customers/${account_id}/transactions?limit=${limit}&offset=${offset}`
  );

export const addCustomerTransaction = ({
  tenant_id,
  company_id,
  ...data
}): AxiosPromise =>
  axios.post(
    `${API.TENANTS}/${tenant_id}/companies/${company_id}/loyalty-accounts/transactions`,
    data
  );

export const resendLoyaltyAccountPass = ({
  tenant_id,
  company_id,
  program_id,
  account_id,
}): AxiosPromise =>
  axios.post(
    `${API.TENANTS}/${tenant_id}/companies/${company_id}/programs/${program_id}/loyalty-accounts/${account_id}/resend-pass`,
  );

// Loyalty Account
export const fetchLoyaltyList = ({
  tenant_id,
  company_id,
  customer_id,
  limit = 10,
  offset = 0,
}): AxiosPromise =>
  axios.get(
    `${API.TENANTS}/${tenant_id}/companies/${company_id}/customers/${customer_id}/loyalty-accounts?limit=${limit}&offset=${offset}`
  );

export const fetchLoyaltyTransactionList = ({
  tenant_id,
  company_id,
  program_id,
  account_id,
  limit = 10,
  offset = 0,
}): AxiosPromise =>
  axios.get(
    `${API.TENANTS}/${tenant_id}/companies/${company_id}/programs/${program_id}/loyalty-accounts/${account_id}/transactions?limit=${limit}&offset=${offset}`
  );

export const inviteLoyaltyCustomer = ({
  invitation_id,
  ...data
}): AxiosPromise =>
  axios.post(`/api/invitations/${invitation_id}/loyalty-accounts`, data);

export const addLoyaltyCustomer = ({
  tenant_id,
  company_id,
  program_id,
  ...data
}): AxiosPromise =>
  axios.post(
    `${API.TENANTS}/${tenant_id}/companies/${company_id}/programs/${program_id}/loyalty-accounts`,
    data
  );

// Segments
export const fetchSegmentList = ({ tenant_id, company_id }): AxiosPromise =>
  axios.get(
    `${API.TENANTS}/${tenant_id}/companies/${company_id}/customers/segments`
  );

export const addSegment = ({ tenant_id, company_id, ...data }): AxiosPromise =>
  axios.post(
    `${API.TENANTS}/${tenant_id}/companies/${company_id}/customers/segments`,
    data
  );

export const removeSegment = ({ tenant_id, company_id, id }): AxiosPromise =>
  axios.delete(
    `${API.TENANTS}/${tenant_id}/companies/${company_id}/customers/segments/${id}`
  );

export const fetchInvitationDesigns = ({ invitation_id }): AxiosPromise =>
  axios.get(`/api/invitations/${invitation_id}/invitation-designs`);

export const fetchLoyaltyAccount = ({ loyalty_account_id }): AxiosPromise =>
  axios.get(`/api/loyalty-accounts/${loyalty_account_id}`);

export const fetchPasses = ({ loyalty_account_id }): AxiosPromise =>
  axios.get(`/api/loyalty-accounts/${loyalty_account_id}/passes`);

import { NavLink } from "react-router-dom";
import { Select, MenuItem } from "@material-ui/core";
import styled from "styled-components";

export const SelectStyled = styled(Select)`
  width: 100%;
  margin-bottom: 16px;
  color: #5850ec;
  height: 42px;
  line-height: 42px;
`;

export const TabLinkStyled = styled(NavLink)`
  ${({ theme, variant }) => `
      display: block;
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      color: 
        ${
          variant === "primary"
            ? theme.palette.common.black
            : theme.palette.common.white
        };
      min-width: auto;
      padding: 10px 0;
      text-decoration: none;

    &.active {
      color: ${
        variant === "primary"
          ? theme.palette.primary.main
          : theme.palette.common.white
      };
    }
  `}
`;

export const TabButtonStyled = styled.div<{ active: 1 | 0 }>`
  ${({ theme }) => `
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      color: ${theme.palette.common.black};
      min-width: auto;
      padding: 10px 0;
      text-decoration: none;
    `};
  ${({ theme, active }) => `
    color: ${active ? theme.palette.primary.main : theme.palette.common.black};
  `};
`;

export const TabHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ececec;
  margin-bottom: 24px;
  background-color: ${({ variant }) =>
    variant === "primary" ? "transparent" : "#5850EC"};
  padding: ${({ variant }) => (variant === "primary" ? 0 : "16px 20px")};
  color: ${({ variant }) => (variant === "primary" ? "#000" : "#fff")};
  border-radius: ${({ variant }) => (variant === "primary" ? 0 : "4px")};

  ${TabLinkStyled} {
    ${({ variant }) =>
      variant === "secondary" &&
      `
      border-radius: 4px;
      color: #fff;
      padding: 8px;
      margin: 0;

      &.active {
        background-color: #4038D4;
      }
    `}
  }
`;

export const TabHeaderItemStyled = styled.div`
  margin-right: ${({ variant }) => (variant === "primary" ? "40px" : "0")};
`;

export const TabLinkDisabledStyled = styled.div`
  ${({ theme }) => `
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      color: ${theme.palette.common.black};
      min-width: auto;
      padding: 10px 0;
      opacity: 0.5;
      cursor: default;
  `}
`;

export const TabPanelStyled = styled.div`
  padding-bottom: 24px;
`;

export const NavTitleBefore = styled.div`
  min-width: 200px;
`;

export const NavTitleAfter = styled.div`
  margin-left: auto;
`;

export const NavSubItemStyled = styled(MenuItem)`
  color: ${({ theme, color }) =>
    color === "primary"
      ? theme.palette.primary.main
      : theme.palette.common.black};
`;

import React from "react";
import dayjs from "dayjs";
import { withRouter, RouteComponentProps } from "react-router";
import { WrappedComponentProps } from "react-intl";
import { COMMON } from "@constants";
import { ContentLoader } from "@components/common";
import { formatMoney } from "@utils/common";
import { CustomerType, TransactionListType } from "@types";
import { Container, Grid, Typography } from "@material-ui/core";
import CustomerTransactions from "../../CustomerTransactions";
import { ProfileTopNumbers, TransactionListTitle } from "./styled";

interface OverviewProps {
  selectedCustomer: CustomerType;
  transactionList: TransactionListType;
}

const Overview: React.FC<
  OverviewProps & RouteComponentProps & WrappedComponentProps
> = ({ selectedCustomer, transactionList }) => {
  const lastVisit =
    transactionList?.items?.[0]?.created_at || selectedCustomer.created_at;
  const number_of_visits = selectedCustomer?.number_of_visits;
  const total_sales_value = selectedCustomer?.total_sales_value;

  if (!selectedCustomer) {
    return <ContentLoader />;
  }

  return (
    <>
      <Container>
        <ProfileTopNumbers
          container
          justify="space-between"
          alignItems="flex-start"
        >
          <div>
            <Typography variant="body1">Total visits</Typography>
            <Typography variant="h2">{number_of_visits}</Typography>
          </div>
          <div>
            <Typography variant="body1">Total purchase value</Typography>
            <Typography variant="h2">
              {formatMoney(total_sales_value)}
            </Typography>
          </div>
          <div>
            <Typography variant="body1">Average purchase</Typography>
            <Typography variant="h2">
              {formatMoney(total_sales_value / number_of_visits)}
            </Typography>
          </div>
          <div>
            <Typography variant="body1">Last Interaction</Typography>
            <Typography variant="h2">
              {dayjs(lastVisit).format(COMMON.DEFAULT_DATE_FORMAT)}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Customer since{" "}
              {dayjs(selectedCustomer.created_at).format(
                COMMON.DEFAULT_DATE_FORMAT_SHORT
              )}
            </Typography>
          </div>
        </ProfileTopNumbers>
      </Container>
      <TransactionListTitle variant="body1">
        Recent transactions
      </TransactionListTitle>
      <Grid item lg={10}>
        <CustomerTransactions />
      </Grid>
    </>
  );
};

export default withRouter(Overview);

import { makeStyles } from "@material-ui/styles";

interface checkboxStylesInterface {
  error: boolean;
}

export const checkboxStyles = ({ error }: checkboxStylesInterface) =>
  makeStyles({
    invalid: () => ({
      color: error ? "#E72076!important" : "",
    }),
  });

import { call, put, takeLatest } from "redux-saga/effects";
import { AccountTypes } from "./types";
import * as actions from "./actions";
import * as api from "./api";

export function* addIntegration(action) {
  try {
    const { payload } = action;

    const res = yield call(api.addIntegration, payload);

    yield put(actions.addIntegrationSucceeded(res.data.items));
    yield put(actions.fetchIntegrationList(payload));
  } catch (ex) {
    yield put(actions.addIntegrationFailed());
  }
}

export function* updateIntegration(action) {
  try {
    const { payload } = action;

    const res = yield call(api.updateIntegration, payload);

    yield put(actions.updateIntegrationSucceeded(res.data));
  } catch (ex) {
    yield put(actions.updateIntegrationFailed());
  }
}

export function* fetchIntegrationList(action) {
  try {
    const { payload } = action;

    const res = yield call(api.fetchIntegrationList, payload);

    yield put(actions.fetchIntegrationListSucceeded(res.data.items));
  } catch (ex) {
    yield put(actions.fetchIntegrationListFailed());
  }
}

export default [
  takeLatest(AccountTypes.ADD_INTEGRATION, addIntegration),
  takeLatest(AccountTypes.UPDATE_INTEGRATION, updateIntegration),
  takeLatest(AccountTypes.FETCH_INTEGRATION_LIST, fetchIntegrationList),
];

import { Typography, Container } from "@material-ui/core";
import styled from "styled-components";
import EmptyFilteredIcon from "-!react-svg-loader!../../../../assets/icons/empty-list.svg";

export const WrapperStyled = styled(Container)`
  padding-bottom: 20px;
`;

export const HeaderTopStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 45px 0 30px;
  flex: 1;
`;

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  min-height: 40px;
  position: relative;
`;

export const SubHeadingLeftStyled = styled.div`
  flex: 1;
`;

export const SubHeadingStyled = styled(Typography)`
  display: flex;
  align-items: center;
`;

export const SubHeadingInfoStyled = styled(Typography)`
  font-size: 15px;
  margin: 0 0 0 20px;
  white-space: nowrap;
`;

export const AddCustomersToggleStyled = styled.span`
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-transform: none;
`;

export const EmptyListStyled = styled.div`
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

export const FilterEmptyStyled = styled(Typography)`
  margin-bottom: 24px;
`;

export const FilterEmptyIconStyled = styled(EmptyFilteredIcon)`
  display: inline-flex;
  margin-bottom: 24px;
`;

export const FormSubmitStyled = styled.div`
  margin-top: 16px;
`;

export const FilterToggleStyled = styled.div`
  line-height: 40px;
  height: 40px;
  background-color: #f8f6f4;
  border-radius: 4px;
  padding: 0 12px 0 14px;
  font-size: 15px;
  min-width: 220px;
  margin-right: 16px;
  border: 1px solid transparent;
  border-color: ${({ error }) => (error ? "red" : "transparent")};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TableFilterContainerStyled = styled.div`
  display: flex;
`;

export const FilterListItemStyled = styled.li`
  margin: 0 16px;

  &:first-child {
    border-bottom: 1px solid #ececec;
    margin-top: -8px;
  }

  label {
    margin-bottom: 0;
  }
`;

import { UserProfileType, TenantType } from "../../types";

export type AuthStateType = {
  user: UserProfileType | null;
  isFetching: boolean;
  isSynced: boolean;
  isAuthenticated: boolean;
};

export const AuthTypes = {
  USER_SIGNED_IN_SUCCEEDED: "auth/USER_SIGNED_IN_SUCCEEDED",
  USER_SIGNED_IN_FAILED: "auth/USER_SIGNED_IN_FAILED",

  USER_SIGNED_UP_SUCCEEDED: "auth/USER_SIGNED_UP_SUCCEEDED",

  USER_SIGNED_OUT_SUCCEEDED: "auth/USER_SIGNED_OUT_SUCCEEDED",

  USER_VERIFIED_SUCCEEDED: "auth/USER_VERIFIED_SUCCEEDED",

  CREATE_TENANT: "auth/CREATE_TENANT",
  CREATE_TENANT_SUCCEEDED: "auth/CREATE_TENANT_SUCCEEDED",
  CREATE_TENANT_FAILED: "auth/CREATE_TENANT_FAILED",
} as const;

// Sign In
export type UserSignedInSucceededActionType = {
  type: typeof AuthTypes.USER_SIGNED_IN_SUCCEEDED;
  payload: UserProfileType;
};

export type UserSignedInFailedActionType = {
  type: typeof AuthTypes.USER_SIGNED_IN_FAILED;
};

// Sign Out
export type UserSignedOutSucceededActionType = {
  type: typeof AuthTypes.USER_SIGNED_OUT_SUCCEEDED;
};

// Sign Up
export type UserSignedUpSucceededActionType = {
  type: typeof AuthTypes.USER_SIGNED_UP_SUCCEEDED;
  payload: UserProfileType;
};

// Verification
export type UserVerifiedSucceededActionType = {
  type: typeof AuthTypes.USER_VERIFIED_SUCCEEDED;
  payload: UserProfileType;
};

// Create tenant
export type CreateTenantActionType = {
  type: typeof AuthTypes.CREATE_TENANT;
  payload: TenantType;
};

export type CreateTenantSucceededActionType = {
  type: typeof AuthTypes.CREATE_TENANT_SUCCEEDED;
};

export type CreateTenantFailedActionType = {
  type: typeof AuthTypes.CREATE_TENANT_FAILED;
};

export type AuthActionTypes =
  | UserSignedOutSucceededActionType
  | UserSignedInSucceededActionType
  | UserSignedInFailedActionType
  | UserSignedUpSucceededActionType
  | UserVerifiedSucceededActionType
  | CreateTenantActionType
  | CreateTenantSucceededActionType
  | CreateTenantFailedActionType;

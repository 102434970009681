import { AuthTypes, AuthStateType, AuthActionTypes } from "./types";

export const defaultState: AuthStateType = {
  user: null,
  isFetching: false,
  isSynced: false,
  isAuthenticated: false,
};

export default (
  state = defaultState,
  action: AuthActionTypes
): AuthStateType => {
  switch (action.type) {
    case AuthTypes.USER_SIGNED_IN_SUCCEEDED:
      return {
        ...state,
        isAuthenticated: true,
        isSynced: true,
        user: action.payload,
      };

    case AuthTypes.USER_SIGNED_IN_FAILED:
      return {
        ...state,
        isAuthenticated: false,
        isSynced: true,
        user: null,
      };

    case AuthTypes.USER_SIGNED_OUT_SUCCEEDED:
      return {
        ...state,
        isAuthenticated: false,
        isSynced: true,
        user: null,
      };

    case AuthTypes.USER_SIGNED_UP_SUCCEEDED:
      return {
        ...state,
        user: action.payload,
      };

    case AuthTypes.USER_VERIFIED_SUCCEEDED:
      return {
        ...state,
      };

    default:
      return state;
  }
};

import { Typography } from "@material-ui/core";
import styled from "styled-components";

export const HeaderTopStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 45px 0 30px;
  flex: 1;
`;

export const SubHeading = styled(Typography)``;

import React, { useState } from "react";
import { ROUTES, VALIDATION } from "@constants";
import { TextField, Alert } from "@components/common";
import { Typography, Grid } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { UserProfileType, AuthRouterStateType } from "@types";
import cognitoId from "../../../../../cognitoId";
import {
  WrapperStyled,
  FormStyled,
  TitleStyled,
  SubmitButtonStyled,
  LogoStyled,
  LinkStyled,
} from "../../styled";

interface SignInProps {
  signedInSucceeded: (userData: UserProfileType) => void;
}

const SignIn: React.FC<SignInProps> = ({ signedInSucceeded }) => {
  const methods = useForm();
  const { handleSubmit, errors } = methods;
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const intl = useIntl();
  const location = useLocation<AuthRouterStateType>();
  const history = useHistory();

  const onSubmit = ({ email, password }) => {
    setIsLoading(true);
    location.state = {};

    cognitoId.authenticateUser(
      email,
      password,
      (result) => {
        const {
          email: use_email,
          name,
          sub,
          email_verified,
        } = result.idToken.payload;
        const { jwtToken } = result.idToken;
        axios.defaults.headers.common.Authorization = jwtToken;
        signedInSucceeded({
          id: sub,
          verified: email_verified,
          email: use_email,
          name,
        });
      },
      (err) => {
        setErrorMsg(err.message);
        setIsLoading(false);
        // redirect to Verification screen if user is not confirmed yet
        if (err.code === "UserNotConfirmedException") {
          history.push({
            pathname: ROUTES.USER_VERIFY,
            state: { email, password },
          });
        }
      }
    );
  };

  return (
    <WrapperStyled
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <LogoStyled />
      <TitleStyled variant="h2">
        <FormattedMessage id="sign.in.heading" />
      </TitleStyled>
      <FormProvider {...methods}>
        <FormStyled onSubmit={handleSubmit(onSubmit)} data-test-id="login-form">
          <TextField
            name="email"
            placeholder={intl.formatMessage({
              id: "input.email.placeholder",
            })}
            type="email"
            label={intl.formatMessage({ id: "input.email.label" })}
            error={errors.email}
            pattern={{
              value: VALIDATION.EMAIL_VALIDATION_REGEXP,
              message: intl.formatMessage({ id: "input.email.pattern.error" }),
            }}
            id="form-input-email"
            autoComplete="username"
            required
          />
          <TextField
            name="password"
            placeholder={intl.formatMessage({
              id: "input.password.placeholder",
            })}
            pattern={{
              value: VALIDATION.PASSWORD_VALIDATION_REGEXP,
              message: intl.formatMessage({
                id: "input.password.pattern.error",
              }),
            }}
            type="password"
            label={intl.formatMessage({ id: "input.password.label" })}
            error={errors.password}
            minLength={8}
            id="form-input-password"
            autoComplete="password"
            required
          />
          <SubmitButtonStyled
            loading={isLoading}
            disabled={isLoading}
            onClick={handleSubmit(onSubmit)}
            type="submit"
          >
            <FormattedMessage id="sign.in.submit" />
          </SubmitButtonStyled>
          {errorMsg && <Alert variant="warning">{errorMsg}</Alert>}

          {location.state?.errorMsg && (
            <Alert variant="warning">{location.state.errorMsg}</Alert>
          )}
          {location.state?.successMsg && (
            <Alert variant="success">{location.state.successMsg}</Alert>
          )}

          <Grid
            container
            justifyContent="center"
            direction="column"
            alignItems="center"
          >
            <Typography variant="body1" paragraph>
              <FormattedMessage id="sign.in.noAccount" />
              <LinkStyled color="primary" to={ROUTES.USER_SIGN_UP}>
                <FormattedMessage id="sign.in.linkToSignUp" />
              </LinkStyled>
            </Typography>
            <Typography variant="body1" paragraph>
              <LinkStyled color="secondary" to={ROUTES.USER_FORGOT_PASSWORD}>
                <FormattedMessage id="sign.in.forgotLink" />
              </LinkStyled>
            </Typography>
          </Grid>
        </FormStyled>
      </FormProvider>
    </WrapperStyled>
  );
};

export default SignIn;

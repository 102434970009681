import {
  OnboardingTypes,
  OnboardingActionTypes,
  OnboardingState,
} from "./types";

export const defaultState: OnboardingState = {
  createdCompany: null,
  createdLoyalty: null,
  error: false,
};

export default (
  state = defaultState,
  action: OnboardingActionTypes
): OnboardingState => {
  switch (action.type) {
    case OnboardingTypes.CREATE_COMPANY:
      return {
        ...state,
        createdCompany: null,
        error: false,
      };
    case OnboardingTypes.CREATE_COMPANY_SUCCEEDED:
      return {
        ...state,
        createdCompany: action.payload,
        error: false,
      };

    case OnboardingTypes.CREATE_COMPANY_FAILED:
      return {
        ...state,
        createdCompany: null,
        error: true,
      };

    case OnboardingTypes.CREATE_LOYALTY:
      return {
        ...state,
        createdLoyalty: null,
        error: false,
      };

    case OnboardingTypes.CREATE_LOYALTY_SUCCEEDED:
      return {
        ...state,
        createdLoyalty: action.payload,
        error: false,
      };

    case OnboardingTypes.CREATE_LOYALTY_FAILED:
      return {
        ...state,
        createdLoyalty: null,
        error: true,
      };
    case OnboardingTypes.RESET_ONBOARDING:
      return {
        ...state,
        createdCompany: null,
        createdLoyalty: null,
        error: false,
      };

    default:
      return state;
  }
};

import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { withRouter, RouteComponentProps } from "react-router";
import { useForm, FormProvider } from "react-hook-form";
import { useIntl, WrappedComponentProps, FormattedMessage } from "react-intl";
import { Button, TextField, Alert } from "@components/common";
import { UserProfileType } from "../../../../../types";
import { HeadingStyled, TopFormStyled } from "../../styled";

interface AccountSettingsProps {
  userInfo: UserProfileType;
}

const AccountSettings: React.FC<
  AccountSettingsProps & RouteComponentProps & WrappedComponentProps
> = ({ userInfo }) => {
  const methods = useForm();
  const intl = useIntl();
  const { handleSubmit, errors, reset } = methods;
  const [isLoading, setIsLoading] = useState(false);
  const [updateAttrError] = useState("");
  const [attrUpdated] = useState(false);

  const onSubmit = () => {
    // TODO: Add account update
    setIsLoading(true);
    reset();
    setIsLoading(false);
  };

  return (
    <>
      <HeadingStyled variant="body1">
        <FormattedMessage id="account.user.settings.title" />
      </HeadingStyled>
      <Grid item md={6} lg={4}>
        <FormProvider {...methods}>
          <TopFormStyled onSubmit={handleSubmit(onSubmit)}>
            <TextField
              name="fullName"
              placeholder={intl.formatMessage({
                id: "account.user.settings.form.fullName.placeholder",
              })}
              label={intl.formatMessage({
                id: "account.user.settings.form.fullName.label",
              })}
              error={errors.fullName}
              required
              disabled
              defaultValue={userInfo.name}
            />
            <TextField
              name="email"
              placeholder={intl.formatMessage({
                id: "input.email.placeholder",
              })}
              label={intl.formatMessage({ id: "input.email.label" })}
              type="email"
              disabled
              defaultValue={userInfo.email}
            />
            <Button
              disabled
              onClick={handleSubmit(onSubmit)}
              loading={isLoading}
            >
              <FormattedMessage id="account.user.settings.form.submit" />
            </Button>
          </TopFormStyled>
          {updateAttrError && (
            <Alert variant="warning">
              <FormattedMessage id="general.notifications.error" />
            </Alert>
          )}
          {attrUpdated && (
            <Alert variant="success">
              <FormattedMessage id="account.user.settings.form.success" />
            </Alert>
          )}
        </FormProvider>
      </Grid>
    </>
  );
};

export default withRouter(AccountSettings);

import React from "react";
import { SelectProps } from "@material-ui/core";
import { useFormContext, Controller, FieldError } from "react-hook-form";
import {
  WrapperStyled,
  FormLabelStyled,
  SelectStyled,
  RequiredTitleStyled,
} from "./styled";

interface SelectComponentProps {
  label: React.ReactNode;
  error?: FieldError;
  name: string;
  hooked?: boolean;
  required?: boolean;
  id: string;
}

const SelectComponent = ({
  disabled,
  label,
  name,
  error,
  required = false,
  defaultValue,
  hooked = true,
  children,
  id,
}: SelectComponentProps & SelectProps) => {
  return (
    <WrapperStyled>
      {hooked ? (
        <>
          <FormLabelStyled invalid={error ? 1 : 0} htmlFor={id}>
            {label}{" "}
            {required && <RequiredTitleStyled>(required)</RequiredTitleStyled>}
          </FormLabelStyled>
          <Controller
            render={(props) => (
              <SelectStyled
                defaultValue={defaultValue}
                onChange={(e) => props.onChange(e.target.value)}
                name={name}
                id={id}
                disableUnderline
                displayEmpty
                invalid={error ? 1 : 0}
                empty={!props.value ? 1 : 0}
              >
                {children}
              </SelectStyled>
            )}
            name={name}
            control={useFormContext().control}
            fullWidth
            InputProps={{ disableUnderline: true }}
            disabled={disabled}
            defaultValue={defaultValue}
            rules={{ required }}
          />
        </>
      ) : (
        <>
          <FormLabelStyled htmlFor={id}>{label}</FormLabelStyled>
          <SelectStyled id={id} defaultValue={defaultValue} name={name}>
            {children}
          </SelectStyled>
        </>
      )}
    </WrapperStyled>
  );
};

export default SelectComponent;

import React from "react";
import { Box } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { ImagePreviewType } from "@types";
import {
  CardStyled,
  CardHeadStyled,
  CardLogoHorizontalStyled,
  CardNumberStyled,
  CardLogoMainStyled,
  CardInfoTitleStyled,
  CardInfoValueSmallStyled,
  CardInfoRow,
  CardVersionStyled,
  CardContainerStyled,
  CardCodePlaceholder,
  CardLogoPreview,
} from "./styled";

interface CardIOSPreviewProps {
  bgColor: string;
  textColor: string;
  labelColor: string;
  logoLargePreview?: ImagePreviewType;
  logoHorizontalPreview?: ImagePreviewType;
  hidePoints?: boolean;
  logoLargeUrl?: string;
  logoHorizontalUrl?: string;
  cardNumberTitle: string;
  cardNameTitle: string;
  cardPointsTitle: string;
  passNumber?: string;
  assetsLoading?: boolean;
  name?: string;
  children?: React.ReactChild;
  showTitle?: boolean;
}

const CardIOSPreview: React.FC<CardIOSPreviewProps> = ({
  bgColor,
  textColor,
  labelColor,
  logoLargePreview,
  logoHorizontalPreview,
  hidePoints,
  logoLargeUrl,
  logoHorizontalUrl,
  cardNumberTitle,
  cardNameTitle,
  cardPointsTitle,
  assetsLoading,
  passNumber,
  children,
  name = "Customer Name",
  showTitle = true,
}) => {
  return (
    <CardContainerStyled>
      {showTitle && <CardVersionStyled>iOS version</CardVersionStyled>}
      <CardStyled
        style={{
          backgroundColor: bgColor,
        }}
      >
        <CardHeadStyled>
          {assetsLoading ? (
            <Skeleton variant="text" width={160} height={50} />
          ) : (
            <CardLogoHorizontalStyled>
              <CardLogoPreview
                style={
                  logoHorizontalPreview
                    ? undefined
                    : {
                        backgroundImage: `url('data:image/png;base64,${logoHorizontalUrl}')`,
                      }
                }
                className={
                  logoHorizontalPreview
                    ? "cropped-logo-horizontal"
                    : "hide-preview"
                }
              />
            </CardLogoHorizontalStyled>
          )}

          {!hidePoints && (
            <CardNumberStyled>
              <CardInfoTitleStyled
                style={{
                  color: labelColor,
                }}
              >
                {cardPointsTitle}
              </CardInfoTitleStyled>
              <CardInfoValueSmallStyled
                style={{
                  color: textColor,
                }}
              >
                100
              </CardInfoValueSmallStyled>
            </CardNumberStyled>
          )}
        </CardHeadStyled>
        {assetsLoading ? (
          <Skeleton variant="text" height={100} />
        ) : (
          <CardLogoMainStyled>
            <CardLogoPreview
              style={
                logoLargePreview
                  ? undefined
                  : {
                      backgroundImage: `url('data:image/png;base64,${logoLargeUrl}')`,
                    }
              }
              className={
                logoLargePreview ? "cropped-logo-cover" : "hide-preview"
              }
            />
          </CardLogoMainStyled>
        )}
        <CardInfoRow>
          <div>
            <CardInfoTitleStyled
              style={{
                color: labelColor,
              }}
            >
              {cardNameTitle}
            </CardInfoTitleStyled>
            <CardInfoValueSmallStyled
              style={{
                color: textColor,
              }}
            >
              {name}
            </CardInfoValueSmallStyled>
          </div>
          <Box display="flex" flexDirection="column" alignItems="flex-end">
            <CardInfoTitleStyled
              style={{
                color: labelColor,
              }}
            >
              {cardNumberTitle}
            </CardInfoTitleStyled>
            <CardInfoValueSmallStyled
              style={{
                color: textColor,
              }}
            >
              {passNumber || 100}
            </CardInfoValueSmallStyled>
          </Box>
        </CardInfoRow>
        {React.Children.count(children) ? (
          children
        ) : (
          <CardCodePlaceholder width="147" height="74" />
        )}
      </CardStyled>
    </CardContainerStyled>
  );
};

export default CardIOSPreview;

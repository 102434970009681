import React from "react";
import styled from "styled-components";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { Link, LinkProps } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";

const LinkStyled = styled(Link)`
  ${({ theme, color = "primary" }) => `
    color: ${
      // eslint-disable-next-line
      color === "primary"
        ? theme.palette[color].main
        : color === "secondary"
        ? theme.palette.grey[200]
        : "inherit"
    };
    display: inline-flex;
    align-items: center;
  `}
`;

const ArrowBackStyled = styled(ArrowBack)`
  font-size: 14px;
  margin-right: 6px;
`;

interface LinkComponentProps {
  back?: boolean;
}

const LinkComponent = ({
  children,
  back,
  underline = "hover",
  ...props
}: RouterLinkProps & LinkProps & LinkComponentProps) => {
  return (
    <LinkStyled component={RouterLink} underline={underline} {...props}>
      {back && <ArrowBackStyled />}
      {children}
    </LinkStyled>
  );
};

export default LinkComponent;

import styled from "styled-components";
import { FormControl, FormLabel, TextField } from "@material-ui/core";

interface TextFieldProps {
  secondary?: boolean;
}

interface WrapperProps {
  offsetbottom: "true" | "false";
}

export const TextFieldStyled = styled(TextField)<TextFieldProps>`
  ${({ secondary }) =>
    secondary &&
    `
    background: #f4f4f4;
    box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    height: 48px;

    input {
      background-color: transparent;
      height: 48px;
    }
  `}
`;

export const WrapperStyled = styled(FormControl)<WrapperProps>`
  ${({ offsetbottom }) =>
    offsetbottom === "true" &&
    `
    margin-bottom: 24px;
  `}
`;

export const LabelStyled = styled(FormLabel)`
  span {
    color: #b9b9b9;
    padding-left: 6px;
  }
`;

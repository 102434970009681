import React, { useEffect, useState } from "react";
import { AlertStyled } from "./styled";

interface AlertProps {
  variant?: "success" | "warning" | "error";
  sticky?: boolean;
  disappear?: boolean;
  fullWidth?: boolean;
}

const AlertComponent: React.FC<AlertProps> = ({
  sticky = false,
  fullWidth = false,
  disappear = false,
  ...props
}) => {
  const [hideAlert, setHideAlert] = useState(false);
  const [timeLeft, setTimeLeft] = useState(5);

  // @ts-ignore-start
  useEffect(() => {
    if (sticky || disappear) {
      if (!timeLeft) {
        setHideAlert(true);
        return;
      }

      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      // eslint-disable-next-line
      return () => clearInterval(intervalId);
    }
  }, [timeLeft, sticky]);
  // @ts-ignore-end

  return (
    <AlertStyled
      {...props}
      sticky={sticky}
      hideAlert={hideAlert}
      fullWidth={fullWidth}
    />
  );
};

export default AlertComponent;

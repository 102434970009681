import styled from "styled-components";
import { FormControl } from "@material-ui/core";

export const ColorPickerStyled = styled(FormControl)`
  display: block;
  margin-bottom: 16px;

  button {
    margin: 6px;
    position: absolute;
    left: 3px;
    top: 4px;
    z-index: 10;
  }

  input {
    padding-left: 40px;
    width: 100%;
  }

  .MuiInput-underline:before,
  .MuiInput-underline:after {
    display: none;
  }

  .MuiFormControl-root {
    flex: 1;
  }

  > div {
    position: relative;
    width: 100%;
  }
`;

// @ts-nocheck
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  FormLabel,
  Radio,
  RadioProps,
  RadioGroup,
  RadioGroupProps,
  FormControlLabel,
} from "@material-ui/core";
import { FormControlStyled } from "./styled";

interface RadioItemProps {
  value: string;
  label: string;
  disabled?: boolean;
}

interface RadioButtonsProps {
  label?: string;
  items: RadioItemProps[];
}

const useStyles = (theme) => ({
  icon: {
    position: "relative",
    borderRadius: "50%",
    width: 14,
    height: 14,
    backgroundColor: theme.palette.grey[400],
  },
  checkedIcon: {
    backgroundColor: theme.palette.grey[400],
    "&:before": {
      display: "block",
      width: 8,
      height: 8,
      borderRadius: "50%",
      position: "absolute",
      left: 3,
      top: 3,
      content: '""',
      backgroundColor: theme.palette.primary.main,
    },
  },
});

const CustomizedRadios = React.forwardRef(
  (
    {
      onChange,
      defaultValue,
      label,
      items,
      color = "primary",
      name,
      row,
      classes,
    }: RadioProps & RadioButtonsProps & RadioGroupProps,
    ref
  ) => {
    return (
      <FormControlStyled component="fieldset">
        <FormLabel component="legend">{label}</FormLabel>
        <RadioGroup
          name={name}
          row={row}
          defaultValue={defaultValue}
          onChange={onChange}
        >
          {items.map((item) => (
            <FormControlLabel
              key={item.value}
              value={item.value}
              control={
                <Radio
                  color={color}
                  checkedIcon={
                    <span
                      className={`${classes?.icon} ${classes?.checkedIcon}`}
                    />
                  }
                  icon={<span className={classes?.icon} />}
                />
              }
              label={item.label}
              disabled={item.disabled}
              ref={ref}
            />
          ))}
        </RadioGroup>
      </FormControlStyled>
    );
  }
);

export default withStyles(useStyles)(CustomizedRadios);

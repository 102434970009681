import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { injectIntl } from "react-intl";
import { transactionsSelectors } from "../../selectors";
import { mainSelectors } from "../../../main/selectors";
import { authSelectors } from "../../../auth/selectors";
import * as transactionActions from "../../actions";
import Component from "./components";

const mapStateToProps = (state) => ({
  selectedCompany: mainSelectors.getSelectedCompany(state),
  selectedLoyalty: mainSelectors.getSelectedLoyalty(state),
  userInfo: authSelectors.getUser(state),
  hasError: transactionsSelectors.getError(state),
  transactionList: transactionsSelectors.getTransactionList(state),
  programs: mainSelectors.getLoyaltyList(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchTransactionList: transactionActions.fetchTransactionList,
    },
    dispatch
  );

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default injectIntl(ConnectedComponent);

import {
  StatusType,
  AddIntegrationType,
  UpdateIntegrationType,
  FetchIntegrationType,
  IntegrationType,
} from "../../types";

export type AccountStateType = {
  integrations?: IntegrationType[];
  currentIntegration?: IntegrationType;
  integrationsAdded: boolean;
  integrationsUpdated: boolean;
  status: StatusType;
  error: boolean;
};

export const AccountTypes = {
  FETCH_INTEGRATION_LIST: "account/FETCH_INTEGRATION_LIST",
  FETCH_INTEGRATION_LIST_SUCCEEDED: "account/FETCH_INTEGRATION_LIST_SUCCEEDED",
  FETCH_INTEGRATION_LIST_FAILED: "account/FETCH_INTEGRATION_LIST_FAILED",

  ADD_INTEGRATION: "account/ADD_INTEGRATION",
  ADD_INTEGRATION_SUCCEEDED: "account/ADD_INTEGRATION_SUCCEEDED",
  ADD_INTEGRATION_FAILED: "account/ADD_INTEGRATION_FAILED",

  RESET_INTEGRATION_FORM: "account/RESET_INTEGRATION_FORM",

  UPDATE_INTEGRATION: "account/UPDATE_INTEGRATION",
  UPDATE_INTEGRATION_SUCCEEDED: "account/UPDATE_INTEGRATION_SUCCEEDED",
  UPDATE_INTEGRATION_FAILED: "account/UPDATE_INTEGRATION_FAILED",
} as const;

// Add Integration
export type addIntegrationActionType = {
  type: typeof AccountTypes.ADD_INTEGRATION;
  payload: AddIntegrationType;
};

export type addIntegrationSucceededActionType = {
  type: typeof AccountTypes.ADD_INTEGRATION_SUCCEEDED;
  payload: IntegrationType;
};

export type addIntegrationFailedActionType = {
  type: typeof AccountTypes.ADD_INTEGRATION_FAILED;
};

// Update Integration
export type updateIntegrationActionType = {
  type: typeof AccountTypes.UPDATE_INTEGRATION;
  payload: UpdateIntegrationType;
};

export type updateIntegrationSucceededActionType = {
  type: typeof AccountTypes.UPDATE_INTEGRATION_SUCCEEDED;
  payload: IntegrationType;
};

export type updateIntegrationFailedActionType = {
  type: typeof AccountTypes.UPDATE_INTEGRATION_FAILED;
};

// Fetch Integration list
export type fetchIntegrationListActionType = {
  type: typeof AccountTypes.FETCH_INTEGRATION_LIST;
  payload: FetchIntegrationType;
};

export type fetchIntegrationListSucceededActionType = {
  type: typeof AccountTypes.FETCH_INTEGRATION_LIST_SUCCEEDED;
  payload: IntegrationType[];
};

export type fetchIntegrationListFailedActionType = {
  type: typeof AccountTypes.FETCH_INTEGRATION_LIST_FAILED;
};

export type resetIntegrationFormActionType = {
  type: typeof AccountTypes.RESET_INTEGRATION_FORM;
}

export type accountActionTypes =
  | addIntegrationActionType
  | addIntegrationSucceededActionType
  | addIntegrationFailedActionType
  | fetchIntegrationListActionType
  | fetchIntegrationListSucceededActionType
  | fetchIntegrationListFailedActionType
  | updateIntegrationActionType
  | updateIntegrationSucceededActionType
  | updateIntegrationFailedActionType
  | resetIntegrationFormActionType;

import React from "react";
import { Button } from "@components/common";
import { ImagePreviewType } from "@types";
import { Typography } from "@material-ui/core";
import {
  FormContainerStyled,
  FormBodyStyled,
  FormTitleStyled,
  FormTextStyled,
  FormInnerStyled,
  FormLogoContainerStyled,
  FormLogoPreviewStyled,
  FormTextRowStyled,
} from "./styled";

interface EmailPreviewProps {
  bodyBgColor: string;
  containerBgColor: string;
  textColor: string;
  borderColor: string;
  buttonBgColor: string;
  buttonTextColor: string;
  showBorder: boolean;

  contentTitle: string;
  contentText: string;
  // contentFieldIOS: string;
  // contentFieldIOSAnswer: string;
  // contentFieldAndroid: string;
  // contentFieldAndroidAnswer: string;
  contentPostText: string;
  buttonText: string;
  logoPreview: ImagePreviewType;
  logoUrl: string;
}

const EmailPreview: React.FC<EmailPreviewProps> = ({
  // styles
  bodyBgColor,
  containerBgColor,
  borderColor,
  buttonBgColor,
  buttonTextColor,
  showBorder,
  textColor,

  // content
  contentTitle,
  contentText,
  buttonText,
  // contentFieldIOS,
  // contentFieldIOSAnswer,
  // contentFieldAndroid,
  // contentFieldAndroidAnswer,
  contentPostText,
  logoPreview,
  logoUrl,
}) => {
  return (
    <FormContainerStyled style={{ backgroundColor: bodyBgColor }}>
      {(logoPreview || logoUrl) && (
        <FormLogoContainerStyled>
          <FormLogoPreviewStyled
            style={
              logoPreview
                ? undefined
                : {
                    backgroundImage: `url('data:image/png;base64,${logoUrl}')`,
                  }
            }
            className={logoPreview ? "cropped-form-logo" : "hide-preview"}
          />
        </FormLogoContainerStyled>
      )}
      <FormBodyStyled
        style={{
          backgroundColor: containerBgColor,
          borderColor: showBorder ? borderColor : "transparent",
          maxWidth: "580px",
        }}
      >
        <FormTitleStyled style={{ color: textColor }}>
          {contentTitle}
        </FormTitleStyled>
        <FormTextRowStyled>
          <FormTextStyled style={{ color: textColor }}>
            {contentText}
          </FormTextStyled>
        </FormTextRowStyled>
        <FormInnerStyled style={{ color: textColor }}>
          <Button
            style={{
              backgroundColor: buttonBgColor,
              color: buttonTextColor,
              width: "260px",
              marginBottom: "24px",
              fontWeight: "bold",
            }}
            size="large"
          >
            {buttonText}
          </Button>
          <FormTextStyled style={{ color: textColor }}>
            {contentPostText}
          </FormTextStyled>
        </FormInnerStyled>
      </FormBodyStyled>
      <Typography variant="body1">Powered by Talisman</Typography>
    </FormContainerStyled>
  );
};

export default EmailPreview;

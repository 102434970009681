import {
  TransactionsStateType,
  TransactionsTypes,
  customersActionTypes,
} from "./types";

export const defaultState: TransactionsStateType = {
  transactions: undefined,
  error: false,
};

export default (
  state = defaultState,
  action: customersActionTypes
): TransactionsStateType => {
  switch (action.type) {
    case TransactionsTypes.FETCH_TRANSACTION_LIST:
      return {
        ...state,
        error: false,
      };
    case TransactionsTypes.FETCH_TRANSACTION_LIST_SUCCEEDED:
      return {
        ...state,
        transactions: action.payload,
        error: false,
      };
    case TransactionsTypes.FETCH_TRANSACTION_LIST_FAILED:
      return {
        ...state,
        transactions: undefined,
        error: true,
      };

    default:
      return state;
  }
};

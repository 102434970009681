import React from "react";
import { ImagePreviewType } from "@types";
import { Typography } from "@material-ui/core";
import {
  FormContainerStyled,
  FormBodyStyled,
  FormInnerStyled,
  FormLogoContainerStyled,
  FormLogoPreviewStyled,
  FormTextRowStyled,
  IconWrapper,
  FormTextSmallStyled,
} from "./styled";

import IOSIcon from "-!react-svg-loader!../../../../../assets/icons/ios-download.svg";
import AndroidIcon from "-!react-svg-loader!../../../../../assets/icons/android-download.svg";
import SaveAsIcon from "-!react-svg-loader!../../../../../assets/icons/download.svg";

interface DownloadPreviewProps {
  bodyBgColor: string;
  containerBgColor: string;
  textColor: string;
  borderColor: string;
  buttonBgColor: string;
  buttonTextColor: string;
  showBorder: boolean;

  contentHelloText: string;
  contentDownloadText: string;
  contentSaveAsImageText: string;
  logoPreview: ImagePreviewType;
  logoUrl: string;
}

const DownloadPreview: React.FC<DownloadPreviewProps> = ({
  // styles
  bodyBgColor,
  containerBgColor,
  borderColor,
  showBorder,
  textColor,

  // content
  logoPreview,
  logoUrl,

  contentHelloText,
  contentDownloadText,
  contentSaveAsImageText,
}) => {
  return (
    <FormContainerStyled style={{ backgroundColor: bodyBgColor }}>
      {(logoPreview || logoUrl) && (
        <FormLogoContainerStyled>
          <FormLogoPreviewStyled
            style={
              logoPreview
                ? undefined
                : {
                    backgroundImage: `url('data:image/png;base64,${logoUrl}')`,
                  }
            }
            className={logoPreview ? "cropped-form-logo" : "hide-preview"}
          />
        </FormLogoContainerStyled>
      )}
      <FormBodyStyled
        style={{
          backgroundColor: containerBgColor,
          borderColor: showBorder ? borderColor : "transparent",
          maxWidth: "372px",
        }}
      >
        <FormTextSmallStyled style={{ color: textColor }}>
          {contentHelloText}
        </FormTextSmallStyled>
        <FormTextRowStyled>
          <FormTextSmallStyled style={{ color: textColor }}>
            {contentDownloadText}
          </FormTextSmallStyled>
        </FormTextRowStyled>
        <FormInnerStyled style={{ color: textColor }}>
          <IconWrapper style={{ backgroundColor: "#000" }}>
            <IOSIcon />
          </IconWrapper>
          <IconWrapper>
            <AndroidIcon />
          </IconWrapper>
          <IconWrapper>
            <SaveAsIcon />
            <span>{contentSaveAsImageText}</span>
          </IconWrapper>
        </FormInnerStyled>
      </FormBodyStyled>
      <Typography variant="body1">Powered by Talisman</Typography>
    </FormContainerStyled>
  );
};

export default DownloadPreview;

import { Typography } from "@material-ui/core";
import styled from "styled-components";

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  min-height: 40px;
  position: relative;
`;

export const SubHeadingStyled = styled(Typography)`
  display: flex;
  align-items: center;
`;

export const FormSubmitStyled = styled.div`
  margin-top: 16px;
`;

import {
  CompanyType,
  CreateCompanyType,
  CreateLoyaltyType,
  LoyaltyType,
} from "../../types";

export type OnboardingState = {
  createdCompany: CompanyType | null;
  createdLoyalty: LoyaltyType | null;
  error: boolean;
};

export const OnboardingTypes = {
  CREATE_COMPANY: "onboarding/CREATE_COMPANY",
  CREATE_COMPANY_SUCCEEDED: "onboarding/CREATE_COMPANY_SUCCEEDED",
  CREATE_COMPANY_FAILED: "onboarding/CREATE_COMPANY_FAILED:",

  RESET_ONBOARDING: "onboarding/RESET_ONBOARDING",

  CREATE_LOYALTY: "onboarding/CREATE_LOYALTY",
  CREATE_LOYALTY_SUCCEEDED: "onboarding/CREATE_LOYALTY_SUCCEEDED",
  CREATE_LOYALTY_FAILED: "onboarding/CREATE_LOYALTY_FAILED",
} as const;

// Create Company
export type createCompanyActionType = {
  type: typeof OnboardingTypes.CREATE_COMPANY;
  payload: CreateCompanyType;
};

export type createCompanySucceededActionType = {
  type: typeof OnboardingTypes.CREATE_COMPANY_SUCCEEDED;
  payload: CompanyType;
};

export type createCompanyFailedActionType = {
  type: typeof OnboardingTypes.CREATE_COMPANY_FAILED;
};

// Create Loyalty
export type CreateLoyaltyActionType = {
  type: typeof OnboardingTypes.CREATE_LOYALTY;
  payload: CreateLoyaltyType;
};

export type CreateLoyaltySucceededActionType = {
  type: typeof OnboardingTypes.CREATE_LOYALTY_SUCCEEDED;
  payload: LoyaltyType;
};

export type CreateLoyaltyFailedActionType = {
  type: typeof OnboardingTypes.CREATE_LOYALTY_FAILED;
};

export type ResetOnboardingActionType = {
  type: typeof OnboardingTypes.RESET_ONBOARDING;
};

export type OnboardingActionTypes =
  | createCompanyActionType
  | createCompanySucceededActionType
  | createCompanyFailedActionType
  | CreateLoyaltyActionType
  | CreateLoyaltySucceededActionType
  | CreateLoyaltyFailedActionType
  | ResetOnboardingActionType;

import styled from "styled-components";
import { TableCell, Grid, IconButton } from "@material-ui/core";

export const TableWrapperStyled = styled(Grid)`
  margin-bottom: 42px;
`;

export const TableCellStyled = styled(TableCell)`
  padding: 8px 16px;
`;

export const IconButtonStyled = styled(IconButton)`
  &:hover {
    color: #e72076;
  }
`;

import styled from "styled-components";
import { FormControl, Typography } from "@material-ui/core";
import Button from "../Button";

export const FormControlStyled = styled(FormControl)`
  margin-bottom: 24px;
`;

export const TypographyStyled = styled(Typography)`
  margin-bottom: 8px;
  font-size: 13px;
`;

export const ButtonStyled = styled(Button)`
  display: inline-flex;
  align-self: flex-start;
`;

export const ButtonClearStyled = styled(Button)`
  margin-left: 8px;
`;

export const ButtonRowStyled = styled.div`
  + div {
    margin-top: 16px;
  }
`;

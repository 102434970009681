import React, { useState, useEffect, useMemo } from "react";
import dayjs from "dayjs";
import { FormattedMessage, useIntl } from "react-intl";
import { COMMON, ROUTES } from "@constants";
import {
  Search,
  ContentLoader,
  Link,
  Pagination,
  CTABox,
  Dropdown,
  Checkbox,
} from "@components/common";
import { ExpandMore } from "@material-ui/icons";
import { formatMoney } from "@utils/common";
import useTable from "@components/common/TableHook";
import {
  UserProfileType,
  CompanyType,
  CustomerListType,
  LoyaltyType,
  SegmentType,
} from "@types";
import { FetchCustomerListType, FetchSegmentsType } from "../../../../../types";
import AddProgramCTAIcon from "-!react-svg-loader!../../../../../../../assets/icons/customers.svg";
import {
  SubHeadingStyled,
  EmptyListStyled,
  FilterEmptyStyled,
  FilterEmptyIconStyled,
  HeaderStyled,
  FilterToggleStyled,
  FilterListItemStyled,
  TableFilterContainerStyled,
} from "../../../styled";

type FilterItem = {
  label: string;
  checked: boolean;
};

interface CustomerListProps {
  userInfo: UserProfileType;
  fetchCustomerList(data: FetchCustomerListType): void;
  fetchSegments(data: FetchSegmentsType): void;
  customerList: CustomerListType;
  selectedCompany: CompanyType;
  programs: LoyaltyType[];
  segments: SegmentType[];
}

const EmptyListComponent = ({ searchValue }) => (
  <EmptyListStyled>
    <FilterEmptyIconStyled />
    <FilterEmptyStyled align="center">
      <FormattedMessage
        id="customers.list.search.empty"
        values={{ name: searchValue, br: <br /> }}
      />
    </FilterEmptyStyled>
    <Link color="primary" to={ROUTES.CUSTOMERS_ADD_FORM}>
      <FormattedMessage id="customers.main.add" />
    </Link>
  </EmptyListStyled>
);

const CustomerList: React.FC<CustomerListProps> = ({
  userInfo,
  customerList,
  fetchCustomerList,
  fetchSegments,
  selectedCompany,
  programs,
  segments,
}) => {
  const intl = useIntl();
  const [paginationPage, setPaginationPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const customers = customerList?.items;
  const total = customerList?.total_count;
  const [searchValue, setSearchValue] = useState("");
  const allSegmentsTitle = "All segments";

  const [filterData, setFilterData] = useState<FilterItem[]>([]);
  const [filterSelected, setFilterSelected] = useState(0);

  const onFilter = (event) => {
    const newFilterState = filterData.map((item) => {
      if (event.target.name === allSegmentsTitle) {
        return { ...item, checked: !(event.target.value === "true") };
      }
      if (item.label === event.target.name) {
        return { ...item, checked: !item.checked };
      }
      if (item.label === allSegmentsTitle && event.target.value === "true") {
        return { ...item, checked: false };
      }
      return item;
    });
    const selectedItems = newFilterState.filter((item) => {
      return item.checked && item.label !== allSegmentsTitle;
    }).length;
    setFilterData(newFilterState);
    setFilterSelected(selectedItems);
  };

  const renderFilterTitle = (selectedItems, count) => {
    if (selectedItems === count) return allSegmentsTitle;
    if (selectedItems === 0) return "No segments selected";
    return `${selectedItems} segments selected`;
  };

  const tableHeadData = useMemo(
    () => [
      {
        field: "name",
        title: intl.formatMessage({
          id: "customers.list.name",
        }),
        sortable: true,
      },
      {
        field: "total",
        title: intl.formatMessage({
          id: "customers.list.total",
        }),
        sortable: true,
      },
      {
        field: "visits",
        title: intl.formatMessage({
          id: "customers.list.visits",
        }),
        sortable: true,
      },
      {
        field: "visit",
        title: intl.formatMessage({
          id: "customers.list.visit",
        }),
        sortable: true,
      },
      {
        field: "created",
        title: intl.formatMessage({
          id: "customers.list.created",
        }),
        sortable: true,
      },
    ],
    []
  );

  const tableBodyData = useMemo(() => {
    if (customers) {
      const fs = filterData.filter((item) => item.checked);
      let filteredCustomers;

      if (fs.find((item) => item.label === allSegmentsTitle)) {
        filteredCustomers = customers;
      } else {
        filteredCustomers = customers.filter((customer) => {
          return customer.segments.some((segment) => {
            return fs.some((filterItem) => filterItem.label === segment.name);
          });
        });
      }

      return filteredCustomers.reduceRight((acc, customer) => {
        return [
          {
            name: {
              title: customer.name,
              link: `/customers/${customer.id}/overview`,
            },
            total: {
              title: formatMoney(customer.total_sales_value),
            },
            visits: { title: customer.number_of_visits },
            visit: {
              title: dayjs(customer.created_at).format(
                COMMON.DEFAULT_DATE_FORMAT
              ),
            },
            created: {
              title: dayjs(customer.created_at).format(
                COMMON.DEFAULT_DATE_FORMAT
              ),
            },
          },
          ...acc,
        ];
      }, []);
    }
    return [];
  }, [customers, filterData]);

  const [RenderTable] = useTable({
    tableHeadData,
    tableBodyData,
    searchValue,
    isLoading,
    emptyMessage: (
      <FormattedMessage id="customers.list.empty" values={{ br: <br /> }} />
    ),
    EmptyList: EmptyListComponent,
  });

  useEffect(() => {
    setIsLoading(true);
    fetchCustomerList({
      tenant_id: userInfo.id,
      company_id: selectedCompany.id,
      offset: paginationPage,
    });
  }, [paginationPage]);

  useEffect(() => {
    if (customerList) {
      setIsLoading(false);
    }
  }, [customerList]);

  useEffect(() => {
    if (segments && filterSelected === segments.length) {
      setFilterData((state) => {
        const newData = state.map((item) => {
          if (item.label === allSegmentsTitle) {
            return { ...item, checked: true };
          }
          return item;
        });
        return newData;
      });
    }
  }, [filterSelected, segments]);

  // prepare Filter data
  useEffect(() => {
    if (segments) {
      const data = segments.map((segment) => {
        const state = {
          label: segment.name,
          checked: true,
        };
        return state;
      });
      data.unshift({
        label: allSegmentsTitle,
        checked: true,
      });
      setFilterData(data);
      setFilterSelected(data.length - 1);
    } else {
      fetchSegments({
        tenant_id: userInfo.id,
        company_id: selectedCompany.id,
      });
    }
  }, [segments]);

  if (!customerList) {
    return <ContentLoader />;
  }

  if (programs?.length === 0) {
    return (
      <CTABox
        icon={<AddProgramCTAIcon />}
        heading="Start signing up your customers today"
        subHeading="Create your first Loyalty Program so you can acquire your first
        customers"
        link="/add-program"
        linkTitle="Create Loyalty Program"
      />
    );
  }

  return (
    <>
      <HeaderStyled>
        <SubHeadingStyled variant="h3">
          <FormattedMessage id="customers.list.title" />
        </SubHeadingStyled>
        <TableFilterContainerStyled>
          {segments?.length > 0 && (
            <Dropdown
              toggleComponent={
                <FilterToggleStyled error={filterSelected === 0}>
                  {renderFilterTitle(filterSelected, segments.length)}
                  <ExpandMore />
                </FilterToggleStyled>
              }
              toggleButtonBase
              dropdownAlign="left"
              menuOverlapped="false"
              showArrow={false}
            >
              {filterData?.map((item) => (
                <FilterListItemStyled key={item.label}>
                  <Checkbox
                    name={item.label}
                    label={item.label}
                    checked={item.checked}
                    value={item.checked}
                    hooked={false}
                    onChange={onFilter}
                  />
                </FilterListItemStyled>
              ))}
            </Dropdown>
          )}
          <Search value={searchValue} onChange={setSearchValue} />
        </TableFilterContainerStyled>
      </HeaderStyled>
      <RenderTable />
      <Pagination total={total} onChange={setPaginationPage} />
    </>
  );
};

export default CustomerList;

import { Table } from "@material-ui/core";
import styled from "styled-components";

export const TableStyled = styled(Table)`
  tr > td {
    text-align: center;

    @media (min-width: 992px) {
      text-align: left;
    }
  }
  tr > td:nth-child(1) {
    min-width: 200px;
    text-align: left;
  }
`;

import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { mainSelectors } from "../../main/selectors";
import { authSelectors } from "../../auth/selectors";
import * as authActions from "../../auth/actions";
import * as mainActions from "../../main/actions";
import Component from "./header";

const mapStateToProps = (state) => ({
  userData: authSelectors.getUser(state),
  selectedCompany: mainSelectors.getSelectedCompany(state),
  selectedLoyalty: mainSelectors.getSelectedLoyalty(state),
  loyaltyList: mainSelectors.getLoyaltyList(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      signedOutSucceeded: authActions.signedOutSucceeded,
      selectLoyalty: mainActions.selectLoyalty,
    },
    dispatch
  );

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default ConnectedComponent;

import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { injectIntl } from "react-intl";
import { customersSelectors } from "../../../selectors";
import { loyaltySelectors } from "../../../../loyalties/selectors";
import * as loyaltiesActions from "../../../../loyalties/actions";
import * as customersActions from "../../../actions";
import Component from "./DownloadPage";

const mapStateToProps = (state) => ({
  passDesigns: loyaltySelectors.getEmailDesignItem(state),
  passes: customersSelectors.getPasses(state),
  loyaltyAccountPass: customersSelectors.getLoyaltyAccountDownloadPass(state),
  hasError: customersSelectors.hasError(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchEmailDesign: loyaltiesActions.fetchEmailDesign,
      fetchPasses: customersActions.fetchPasses,
      fetchLoyaltyAccount: customersActions.fetchLoyaltyAccount,
    },
    dispatch
  );

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default injectIntl(ConnectedComponent);

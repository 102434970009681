import { Grid, Typography, LinearProgress } from "@material-ui/core";
import { Button, ButtonLink, Link } from "@components/common";
import { styled } from "@material-ui/styles";

export const WrapperStyled = styled(Grid)({
  padding: "40px 16px 46px",

  "@media (min-width:768px)": {
    paddingTop: 50,
  },
});

export const FormStyled = styled("form")({
  paddingTop: 24,
});

export const TitleStyled = styled(Typography)({
  marginBottom: 38,
});

export const SubmitButtonStyled = styled(Button)({
  flex: 1,

  "@media (min-width:768px)": {
    flex: "inherit",
    marginLeft: "auto",
  },
});

export const ButtonStyled = styled(Button)({
  marginRight: 16,
});

export const ButtonLinkStyled = styled(ButtonLink)({
  marginRight: 16,
});

export const LinkStyled = styled(Link)({
  textDecoration: "none",
  marginLeft: 6,
});

export const LinearProgressStyled = styled(LinearProgress)({
  height: 8,
  borderRadius: 4,
  width: 162,
  marginBottom: 44,

  "&.MuiLinearProgress-colorPrimary": {
    backgroundColor: "#F8F6F4",
  },
});

import { LoyaltiesTypes, LoyaltyStateType, loyaltyActionTypes } from "./types";

export const defaultState: LoyaltyStateType = {
  cardDesignList: [],
  cardDesignItem: undefined,
  inviteDesignList: [],
  inviteDesignItem: undefined,
  emailDesignList: [],
  emailDesignItem: undefined,
  cardDesignUpdated: false,
  inviteDesignUpdated: false,
  emailDesignUpdated: false,
  status: "idle",
  error: false,
};

export default (
  state = defaultState,
  action: loyaltyActionTypes
): LoyaltyStateType => {
  switch (action.type) {
    case LoyaltiesTypes.FETCH_CARD_DESIGN_LIST:
      return {
        ...state,
        cardDesignUpdated: false,
        error: false,
      };

    case LoyaltiesTypes.FETCH_CARD_DESIGN_LIST_SUCCEEDED:
      return {
        ...state,
        cardDesignList: action.payload,
        cardDesignItem: action.payload[0],
        error: false,
      };

    case LoyaltiesTypes.FETCH_CARD_DESIGN_LIST_FAILED:
      return {
        ...state,
        cardDesignList: [],
        error: true,
      };

    case LoyaltiesTypes.UPDATE_CARD_DESIGN:
      return {
        ...state,
        cardDesignUpdated: false,
        error: false,
      };

    case LoyaltiesTypes.UPDATE_CARD_DESIGN_SUCCEEDED:
      return {
        ...state,
        cardDesignItem: action.payload,
        cardDesignUpdated: true,
        error: false,
      };

    case LoyaltiesTypes.UPDATE_CARD_DESIGN_FAILED:
      return {
        ...state,
        cardDesignItem: undefined,
        cardDesignUpdated: false,
        error: true,
      };

    // INVITE DESIGN
    case LoyaltiesTypes.UPDATE_INVITE_DESIGN:
      return {
        ...state,
        error: false,
        inviteDesignUpdated: false,
        status: "loading",
      };

    case LoyaltiesTypes.UPDATE_INVITE_DESIGN_SUCCEEDED:
      return {
        ...state,
        inviteDesignItem: action.payload,
        inviteDesignUpdated: true,
        status: "succeeded",
        error: false,
      };

    case LoyaltiesTypes.UPDATE_INVITE_DESIGN_FAILED:
      return {
        ...state,
        cardDesignItem: undefined,
        inviteDesignUpdated: false,
        status: "failed",
        error: true,
      };

    case LoyaltiesTypes.FETCH_INVITE_DESIGN_LIST:
      return {
        ...state,
        status: "loading",
        inviteDesignUpdated: false,
        error: false,
      };

    case LoyaltiesTypes.FETCH_INVITE_DESIGN_LIST_SUCCEEDED:
      return {
        ...state,
        inviteDesignList: action.payload,
        inviteDesignItem: action.payload[0],
        status: "succeeded",
        error: false,
      };

    case LoyaltiesTypes.FETCH_INVITE_DESIGN_LIST_FAILED:
      return {
        ...state,
        status: "failed",
        error: true,
      };

    case LoyaltiesTypes.UPDATE_EMAIL_DESIGN:
      return {
        ...state,
        error: false,
        emailDesignUpdated: false,
        status: "loading",
      };

    case LoyaltiesTypes.UPDATE_EMAIL_DESIGN_SUCCEEDED:
      return {
        ...state,
        emailDesignItem: action.payload,
        emailDesignUpdated: true,
        status: "succeeded",
        error: false,
      };

    case LoyaltiesTypes.UPDATE_EMAIL_DESIGN_FAILED:
      return {
        ...state,
        emailDesignItem: undefined,
        emailDesignUpdated: false,
        status: "failed",
        error: true,
      };

    case LoyaltiesTypes.FETCH_EMAIL_DESIGN_LIST:
      return {
        ...state,
        status: "loading",
        emailDesignUpdated: false,
        error: false,
      };

    case LoyaltiesTypes.FETCH_EMAIL_DESIGN_LIST_SUCCEEDED:
      return {
        ...state,
        emailDesignList: action.payload,
        emailDesignItem: action.payload[0],
        status: "succeeded",
        error: false,
      };

    case LoyaltiesTypes.FETCH_EMAIL_DESIGN_LIST_FAILED:
      return {
        ...state,
        status: "failed",
        error: true,
      };

    case LoyaltiesTypes.CLEAR_STATE:
      return defaultState;

    default:
      return state;
  }
};

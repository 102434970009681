import React, { useState, useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { useIntl } from "react-intl";
import { Divider, Hidden, AccordionDetails } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import {
  Menu as MenuIcon,
  Close as MenuExpandedIcon,
  ArrowDropDown as ArrowDropDownIcon,
} from "@material-ui/icons";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { COMMON, ROUTES } from "@constants";
import { CompanyType, LoyaltyType, UserProfileType } from "@types";
import Dropdown from "../Dropdown";
import Link from "../Link";
import Logo from "../Logo";
import {
  NavItemStyled,
  NavStyled,
  HeaderWrapperStyled,
  HeaderStyled,
  LogoLinkStyled,
  AvatarWrapper,
  AvatarNameStyled,
  CompanyNameStyled,
  AvatarStyled,
  MobileNavStyled,
  MobileUserNavItem,
  MobileNavItemStyled,
  DividerStyled,
  AvatarStyledMobile,
  NavSubItemStyled,
  NavButtonStyled,
  AccordionStyled,
  AccordionSummaryStyled,
  CreateProgramLinkStyled,
} from "./styled";

const isRouteLinkActive = (matcher: string) => (_, location) =>
  location.pathname.split("/")[1].includes(matcher);

const isUrlActive = (matcher: string, location) =>
  location.pathname.split("/")[1].includes(matcher);

interface HeaderProps {
  handleSignOut: () => void;
  companyDetails: CompanyType;
  program: LoyaltyType;
  programs: LoyaltyType[];
  userData?: UserProfileType;
  navTitle: string;
}

const Header: React.FC<HeaderProps & RouteComponentProps> = ({
  handleSignOut,
  companyDetails,
  program,
  programs,
  userData,
  navTitle,
  location,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [menuExpanded, setMenuExpanded] = useState(false);
  const [settingsRouteActive, setSettingsRouteActive] = useState(false);
  const intl = useIntl();
  // Mobile menu hamburger
  const MobileMenuToggler = ({ expanded }) =>
    expanded ? (
      <MenuExpandedIcon color="primary" aria-label="menu" />
    ) : (
      <MenuIcon color="primary" aria-label="menu" />
    );

  // Avatar
  const AvatarComponent = isMobile ? (
    <MobileMenuToggler expanded={menuExpanded} />
  ) : (
    <AvatarWrapper active={settingsRouteActive}>
      <AvatarStyled>
        {userData?.name?.split(" ").map((char) => char.charAt(0))}
      </AvatarStyled>
      <AvatarNameStyled>{userData?.name}</AvatarNameStyled>
    </AvatarWrapper>
  );

  // Mobile Nav
  const MobileNavSection = React.forwardRef<HTMLDivElement>((props, ref) => (
    <div ref={ref} {...props}>
      <MobileNavStyled>
        {COMMON.HEADER_NAV(programs).map((item) => (
          <span key={item.text}>
            {item.link ? (
              <MobileNavItemStyled key={item.text}>
                <Link to={item.link} color="inherit">
                  {item.text}
                </Link>
              </MobileNavItemStyled>
            ) : (
              item.sub && (
                <AccordionStyled key={item.text} square>
                  <AccordionSummaryStyled component={MobileNavItemStyled}>
                    {item.text}
                    <ArrowDropDownIcon />
                  </AccordionSummaryStyled>
                  <AccordionDetails>
                    <div>
                      {item.sub.map((subItem) => (
                        <div key={subItem.link}>
                          <MobileUserNavItem
                            selected={subItem.id === program?.id}
                            disabled={subItem.disabled}
                            component={NavSubItemStyled}
                            to={subItem.link}
                          >
                            {subItem.text}
                          </MobileUserNavItem>
                        </div>
                      ))}
                    </div>
                  </AccordionDetails>
                </AccordionStyled>
              )
            )}
          </span>
        ))}
      </MobileNavStyled>
      <MobileUserNavItem disabled>
        <AvatarWrapper>
          <AvatarStyledMobile>
            {userData?.name?.split(" ").map((char) => char.charAt(0))}
          </AvatarStyledMobile>
          <AvatarNameStyled>{userData?.name}</AvatarNameStyled>
        </AvatarWrapper>
      </MobileUserNavItem>
    </div>
  ));

  // active state for deep links
  useEffect(() => {
    setSettingsRouteActive(isUrlActive("settings", location));
  }, [location]);

  return (
    <HeaderWrapperStyled>
      <HeaderStyled>
        <LogoLinkStyled to={ROUTES.MAIN_WELCOME} aria-label="home-link">
          <Logo />
        </LogoLinkStyled>
        <Hidden mdDown>
          <CompanyNameStyled>{companyDetails?.name}</CompanyNameStyled>
        </Hidden>
        <Hidden mdUp>
          <CompanyNameStyled>{navTitle}</CompanyNameStyled>
        </Hidden>
        {companyDetails && !isMobile && (
          <NavStyled>
            {COMMON.HEADER_NAV(programs).map((item) => {
              const isActive = isRouteLinkActive(item.section);

              return item.link ? (
                <NavItemStyled
                  activeClassName="active"
                  isActive={isActive}
                  key={item.text}
                  to={item.link}
                >
                  {item.text}
                </NavItemStyled>
              ) : (
                item.sub && (
                  <Dropdown
                    key={item.text}
                    toggleComponent={
                      <NavButtonStyled
                        className={
                          isActive(item.link, location) ? "active" : ""
                        }
                      >
                        {item.text}
                      </NavButtonStyled>
                    }
                    toggleButtonBase
                    dropdownAlign="left"
                  >
                    {item.sub.map((subItem) => (
                      <li key={subItem.text}>
                        <MobileUserNavItem
                          selected={subItem.link === location.pathname}
                          disabled={subItem.disabled}
                          component={NavSubItemStyled}
                          color={subItem.primary ? "primary" : "secondary"}
                          to={subItem.link}
                        >
                          {subItem.text}
                        </MobileUserNavItem>
                        {subItem.divider && <Divider variant="middle" />}
                      </li>
                    ))}
                  </Dropdown>
                )
              );
            })}
          </NavStyled>
        )}
        {companyDetails && programs?.length === 0 && (
          <Hidden mdDown>
            <CreateProgramLinkStyled to={ROUTES.MAIN_PROGRAMS_ADD}>
              Create Loyalty Program
            </CreateProgramLinkStyled>
          </Hidden>
        )}
        <Dropdown
          toggleComponent={AvatarComponent}
          isExpanded={menuExpanded}
          toggleCallback={setMenuExpanded}
        >
          {isMobile && <MobileNavSection />}
          <MobileUserNavItem disabled>{userData?.email}</MobileUserNavItem>
          <MobileUserNavItem
            component={NavSubItemStyled}
            to={ROUTES.SETTINGS_PERSONAL_ROUTE}
            color="inherit"
            disabled={!companyDetails || programs?.length === 0}
          >
            {intl.formatMessage({
              id: "header.dropdown.settings",
            })}
          </MobileUserNavItem>
          <MobileUserNavItem disabled>
            {intl.formatMessage({
              id: "header.dropdown.changelog",
            })}
          </MobileUserNavItem>
          <DividerStyled variant={isMobile ? "fullWidth" : "middle"} light />
          <MobileUserNavItem onClick={handleSignOut}>
            {intl.formatMessage({
              id: "header.dropdown.logout",
            })}
          </MobileUserNavItem>
        </Dropdown>
      </HeaderStyled>
    </HeaderWrapperStyled>
  );
};

export default withRouter(Header);

import { ModalStateType, ModalTypes, ModalActionTypes } from "./types";

export const defaultState: ModalStateType = {
  type: null,
  data: null,
};

export default (state = defaultState, action: ModalActionTypes) => {
  switch (action.type) {
    case ModalTypes.SHOW_MODAL:
      return {
        ...state,
        type: action.payload.type,
        data: action.payload.data,
      };

    case ModalTypes.HIDE_MODAL:
      return {
        ...state,
        type: null,
        data: null,
      };

    default:
      return state;
  }
};

import {
  CustomerType,
  TransactionType,
  InviteDesignListItemType,
  InviteDesignType,
  SegmentType,
  LoyaltyType,
  FetchLoyaltyListType,
  LoyaltyCustomerProfileType,
  SelectLoyaltyType,
} from "@types";

export type CustomersStateType = {
  customers?: CustomerType[];
  selectedCustomer?: CustomerType | null;
  addedCustomer?: CustomerType | null;
  customerExist: boolean;
  customerUpdated: boolean;
  loyaltyAccounts?: LoyaltyCustomerProfileType[];
  loyaltyAccount?: LoyaltyCustomerProfileType;
  retrievedByEmail?: CustomerType[] | null;
  invitationDesigns?: InviteDesignType;
  segments?: SegmentType[];
  segmentAdded: boolean;
  segmentRemoved: boolean;
  loyaltyTransactions?: TransactionType[];
  customerTransactions?: TransactionType[];
  customerTransactionAdded?: boolean;
  customerInviteResend?: boolean;
  loyaltyInvited?: boolean;
  passes?: PassesType[];
  loyaltyAccountDownloadPass?: LoyaltyAccountType[];
  error?: boolean;
};

type LoyaltyAccountNameType = {
  name: string;
};

export type LoyaltyAccountType = {
  id: string;
  balance: string;
  pass_number: string;
  customer: LoyaltyAccountNameType;
};

export type PassesType = {
  ios_href: string;
  android_href: string;
};

export type FetchLoyaltyAccountType = {
  loyalty_account_id: string;
};

// Fetch Customers List
export type FetchCustomerListType = {
  tenant_id: string;
  company_id: string;
  offset?: number;
  limit?: number;
};

// Fetch Loyalty List
export type FetchLoyaltyAccountsListType = {
  tenant_id: string;
  company_id: string;
  program_id: string;
  offset?: number;
  limit?: number;
};

export type FetchCustomerByEmail = {
  tenant_id: string;
  company_id: string;
  email: string;
};

export type FetchCustomersType = {
  tenant_id: string;
  company_id: string;
};

// Fetch Customer Loyalty Accounts (Cards)
export type FetchCustomerLoyaltyAccountsType = {
  tenant_id: string;
  company_id: string;
  customer_id: string;
};

// Add Loyalty
export type AddLoyaltyAccountType = {
  tenant_id: string;
  company_id: string;
  program_id: string;
  email: string;
  name: string;
  segment_id?: string;
};

// Invite Loyalty
export type InviteLoyaltyType = {
  invitation_id: string;
  email: string;
  name: string;
  registration_note?: string;
  registration_source?: "web_admin";
};

// Update Customer Profile
export type UpdateCustomerType = {
  tenant_id: string;
  company_id: string;
  account_id: string;
  name?: string;
  email?: string;
  segments?: [];
};

// Resend Customer Pass
export type ResendLoyaltyAccountPassType = {
  tenant_id: string;
  company_id: string;
  program_id: string;
  account_id: string;
  email: string;
};

// Add Customer Transaction
export type AddLoyaltyTransaction = {
  tenant_id: string;
  company_id: string;
};

// Fetch Loyalty Transaction
export type FetchLoyaltyTransactionListType = {
  tenant_id: string;
  company_id: string;
  program_id: string;
  account_id: string;
  offset?: number;
  limit?: number;
};

// Fetch Segments
export type FetchSegmentsType = {
  tenant_id: string;
  company_id: string;
};

// Add segment
export type AddSegmentType = {
  tenant_id: string;
  company_id: string;
  name: string;
  description: string;
  is_public?: boolean;
};

// Add segment
export type RemoveSegmentType = {
  tenant_id: string;
  company_id: string;
  id: string;
};

export type SelectCustomerType = {
  id: string;
};

// Invitation designs
export type FetchInvitationDesignsType = {
  invitation_id: string;
};

export const CustomersTypes = {
  FETCH_CUSTOMER_LIST: "customers/FETCH_CUSTOMER_LIST",
  FETCH_CUSTOMER_LIST_SUCCEEDED: "customers/FETCH_CUSTOMER_LIST_SUCCEEDED",
  FETCH_CUSTOMER_LIST_FAILED: "customers/FETCH_CUSTOMER_LIST_FAILED",

  ADD_LOYALTY: "customers/ADD_LOYALTY",
  ADD_LOYALTY_SUCCEEDED: "customers/ADD_LOYALTY_SUCCEEDED",
  ADD_LOYALTY_FAILED: "customers/ADD_LOYALTY_FAILED",

  INVITE_LOYALTY: "customers/INVITE_LOYALTY",
  INVITE_LOYALTY_SUCCEEDED: "customers/INVITE_LOYALTY_SUCCEEDED",
  INVITE_LOYALTY_FAILED: "customers/INVITE_LOYALTY_FAILED",

  SELECT_CUSTOMER: "customers/SELECT_CUSTOMER",
  SELECT_LOYALTY: "customers/SELECT_LOYALTY",

  RESET_ADD_CUSTOMER_FORM: "customers/RESET_ADD_CUSTOMER_FORM",
  RESET_ADD_TRANSACTION_FORM: "customers/RESET_ADD_TRANSACTION_FORM",
  RESET_ADD_SEGMENT_FORM: "customers/RESET_ADD_SEGMENT_FORM",
  RESET_REMOVE_SEGMENT_FORM: "customers/RESET_REMOVE_SEGMENT_FORM",
  RESET_LOYALTY_SETTINGS_FORM: "customers/RESET_LOYALTY_SETTINGS_FORM",

  ADD_LOYALTY_TRANSACTION: "customers/ADD_LOYALTY_TRANSACTION",
  ADD_LOYALTY_TRANSACTION_SUCCEEDED:
    "customers/ADD_LOYALTY_TRANSACTION_SUCCEEDED",
  ADD_LOYALTY_TRANSACTION_FAILED: "customers/ADD_LOYALTY_TRANSACTION_FAILED",

  FETCH_LOYALTIES: "customers/FETCH_LOYALTIES",
  FETCH_LOYALTIES_SUCCEEDED: "customers/FETCH_LOYALTIES_SUCCEEDED",
  FETCH_LOYALTIES_FAILED: "customers/FETCH_LOYALTIES_FAILED",

  FETCH_CUSTOMER_BY_EMAIL: "customers/FETCH_CUSTOMER_BY_EMAIL",
  FETCH_CUSTOMER_BY_EMAIL_SUCCEEDED:
    "customers/FETCH_CUSTOMER_BY_EMAIL_SUCCEEDED",
  FETCH_CUSTOMER_BY_EMAIL_FAILED: "customers/FETCH_CUSTOMER_BY_EMAIL_FAILED",

  UPDATE_CUSTOMER: "customers/UPDATE_CUSTOMER",
  UPDATE_CUSTOMER_SUCCEEDED: "customers/UPDATE_CUSTOMER_SUCCEEDED",
  UPDATE_CUSTOMER_FAILED: "customers/UPDATE_CUSTOMER_FAILED",

  RESEND_LOYALTY_PASS: "customers/RESEND_LOYALTY_PASS",
  RESEND_LOYALTY_PASS_SUCCEEDED: "customers/RESEND_LOYALTY_PASS_SUCCEEDED",
  RESEND_LOYALTY_PASS_FAILED: "customers/RESEND_LOYALTY_PASS_FAILED",

  FETCH_LOYALTY_TRANSACTION_LIST: "customers/FETCH_LOYALTY_TRANSACTION_LIST",
  FETCH_LOYALTY_TRANSACTION_LIST_SUCCEEDED:
    "customers/FETCH_LOYALTY_TRANSACTION_LIST_SUCCEEDED",
  FETCH_LOYALTY_TRANSACTION_LIST_FAILED:
    "customers/FETCH_LOYALTY_TRANSACTION_LIST_FAILED",

  FETCH_CUSTOMER_TRANSACTION_LIST: "customers/FETCH_CUSTOMER_TRANSACTION_LIST",
  FETCH_CUSTOMER_TRANSACTION_LIST_SUCCEEDED:
    "customers/FETCH_CUSTOMER_TRANSACTION_LIST_SUCCEEDED",
  FETCH_CUSTOMER_TRANSACTION_LIST_FAILED:
    "customers/FETCH_CUSTOMER_TRANSACTION_LIST_FAILED",

  FETCH_CUSTOMER_SEGMENT_LIST: "customers/FETCH_CUSTOMER_SEGMENT_LIST",
  FETCH_CUSTOMER_SEGMENT_LIST_SUCCEEDED:
    "customers/FETCH_CUSTOMER_SEGMENT_LIST_SUCCEEDED",
  FETCH_CUSTOMER_SEGMENT_LIST_FAILED:
    "customers/FETCH_CUSTOMER_SEGMENT_LIST_FAILED",

  ADD_CUSTOMER_SEGMENT: "customers/ADD_CUSTOMER_SEGMENT",
  ADD_CUSTOMER_SEGMENT_SUCCEEDED: "customers/ADD_CUSTOMER_SEGMENT_SUCCEEDED",
  ADD_CUSTOMER_SEGMENT_FAILED: "customers/ADD_CUSTOMER_SEGMENT_FAILED",

  REMOVE_LOYALTY_SEGMENT: "customers/REMOVE_LOYALTY_SEGMENT",
  REMOVE_LOYALTY_SEGMENT_SUCCEEDED:
    "customers/REMOVE_LOYALTY_SEGMENT_SUCCEEDED",
  REMOVE_LOYALTY_SEGMENT_FAILED: "customers/REMOVE_LOYALTY_SEGMENT_FAILED",

  FETCH_INVITATION_DESIGNS: "customers/FETCH_INVITATION_DESIGNS",
  FETCH_INVITATION_DESIGNS_SUCCEEDED:
    "customers/FETCH_INVITATION_DESIGNS_SUCCEEDED",
  FETCH_INVITATION_DESIGNS_FAILED: "customers/FETCH_INVITATION_DESIGNS_FAILED",

  CLEAN_FORM_ERROR: "customers/CLEAN_FORM_ERROR",

  FETCH_PASSES: "customers/FETCH_PASSES",
  FETCH_PASSES_SUCCEEDED: "customers/FETCH_PASSES_SUCCEEDED",
  FETCH_PASSES_FAILED: "customers/FETCH_PASSES_FAILED",

  FETCH_LOYALTY_ACCOUNT: "customers/FETCH_LOYALTY_ACCOUNT",
  FETCH_LOYALTY_ACCOUNT_SUCCEEDED: "customers/FETCH_LOYALTY_ACCOUNT_SUCCEEDED",
  FETCH_LOYALTY_ACCOUNT_FAILED: "customers/FETCH_LOYALTY_ACCOUNT_FAILED",
} as const;

// Add loyalty customer
export type addLoyaltyActionType = {
  type: typeof CustomersTypes.ADD_LOYALTY;
  payload: AddLoyaltyAccountType;
};

export type addLoyaltySucceededActionType = {
  type: typeof CustomersTypes.ADD_LOYALTY_SUCCEEDED;
  payload: CustomerType;
};

export type addLoyaltyFailedActionType = {
  type: typeof CustomersTypes.ADD_LOYALTY_FAILED;
};

// Invite loyalty
export type inviteLoyaltyActionType = {
  type: typeof CustomersTypes.INVITE_LOYALTY;
  payload: InviteLoyaltyType;
};

export type inviteLoyaltySucceededActionType = {
  type: typeof CustomersTypes.INVITE_LOYALTY_SUCCEEDED;
  payload: CustomerType;
};

export type inviteLoyaltyFailedActionType = {
  type: typeof CustomersTypes.INVITE_LOYALTY_FAILED;
};

// Add loyalty transaction
export type addLoyaltyTransactionActionType = {
  type: typeof CustomersTypes.ADD_LOYALTY_TRANSACTION;
  payload: AddLoyaltyTransaction;
};

export type addLoyaltyTransactionSucceededActionType = {
  type: typeof CustomersTypes.ADD_LOYALTY_TRANSACTION_SUCCEEDED;
  payload: TransactionType;
};

export type addLoyaltyTransactionFailedActionType = {
  type: typeof CustomersTypes.ADD_LOYALTY_TRANSACTION_FAILED;
};

// Fetch Customers
export type fetchCustomerListActionType = {
  type: typeof CustomersTypes.FETCH_CUSTOMER_LIST;
  payload: FetchCustomersType;
};

export type fetchCustomerListSucceededActionType = {
  type: typeof CustomersTypes.FETCH_CUSTOMER_LIST_SUCCEEDED;
  payload: CustomerType[];
};

export type fetchCustomerListFailedActionType = {
  type: typeof CustomersTypes.FETCH_CUSTOMER_LIST_FAILED;
};

// Fetch loyalties
export type fetchLoyaltyListActionType = {
  type: typeof CustomersTypes.FETCH_LOYALTIES;
  payload: FetchLoyaltyListType;
};

export type fetchLoyaltyListSucceededActionType = {
  type: typeof CustomersTypes.FETCH_LOYALTIES_SUCCEEDED;
  payload: LoyaltyType[];
};

export type fetchLoyaltyListFailedActionType = {
  type: typeof CustomersTypes.FETCH_LOYALTIES_FAILED;
};

// Fetch loyalty by email
export type fetchCustomerByEmailActionType = {
  type: typeof CustomersTypes.FETCH_CUSTOMER_BY_EMAIL;
  payload: FetchCustomerByEmail;
};

export type fetchCustomerByEmailSucceededActionType = {
  type: typeof CustomersTypes.FETCH_CUSTOMER_BY_EMAIL_SUCCEEDED;
  payload: CustomerType[];
};

export type fetchCustomerByEmailFailedActionType = {
  type: typeof CustomersTypes.FETCH_CUSTOMER_BY_EMAIL_FAILED;
};

// fetch Loyalty customer profile
// export type fetchLoyaltyCustomerProfileActionType = {
//   type: typeof CustomersTypes.FETCH_LOYALTY_CUSTOMER_PROFILE;
//   payload: FetchLoyaltyCustomerProfileType;
// };

// export type fetchLoyaltyCustomerProfileSucceededActionType = {
//   type: typeof CustomersTypes.FETCH_LOYALTY_CUSTOMER_PROFILE_SUCCEEDED;
//   payload: CustomerType;
// };

// export type fetchLoyaltyCustomerProfileFailedActionType = {
//   type: typeof CustomersTypes.FETCH_LOYALTY_CUSTOMER_PROFILE_FAILED;
// };

// Update Customer
export type updateCustomerActionType = {
  type: typeof CustomersTypes.UPDATE_CUSTOMER;
  payload: UpdateCustomerType;
};

export type updateCustomerSucceededActionType = {
  type: typeof CustomersTypes.UPDATE_CUSTOMER_SUCCEEDED;
  payload: CustomerType;
};

export type updateCustomerFailedActionType = {
  type: typeof CustomersTypes.UPDATE_CUSTOMER_FAILED;
};

// Resend Loyalty pass
export type resendLoyaltyPassActionType = {
  type: typeof CustomersTypes.RESEND_LOYALTY_PASS;
  payload: ResendLoyaltyAccountPassType;
};

export type resendLoyaltyPassSucceededActionType = {
  type: typeof CustomersTypes.RESEND_LOYALTY_PASS_SUCCEEDED;
};

export type resendLoyaltyPassFailedActionType = {
  type: typeof CustomersTypes.RESEND_LOYALTY_PASS_FAILED;
};

// Fetch Loyalty Transaction list
export type fetchLoyaltyTransactionListActionType = {
  type: typeof CustomersTypes.FETCH_LOYALTY_TRANSACTION_LIST;
  payload: FetchLoyaltyTransactionListType;
};

export type fetchLoyaltyTransactionListSucceededActionType = {
  type: typeof CustomersTypes.FETCH_LOYALTY_TRANSACTION_LIST_SUCCEEDED;
  payload: TransactionType[];
};

export type fetchLoyaltyTransactionListFailedActionType = {
  type: typeof CustomersTypes.FETCH_LOYALTY_TRANSACTION_LIST_FAILED;
};

// Fetch Customer Transaction list
export type fetchCustomerTransactionListActionType = {
  type: typeof CustomersTypes.FETCH_CUSTOMER_TRANSACTION_LIST;
  payload: FetchLoyaltyTransactionListType;
};

export type fetchCustomerTransactionListSucceededActionType = {
  type: typeof CustomersTypes.FETCH_CUSTOMER_TRANSACTION_LIST_SUCCEEDED;
  payload: TransactionType[];
};

export type fetchCustomerTransactionListFailedActionType = {
  type: typeof CustomersTypes.FETCH_CUSTOMER_TRANSACTION_LIST_FAILED;
};

// Fetch Customer Segment list
export type fetchCustomerSegmentListActionType = {
  type: typeof CustomersTypes.FETCH_CUSTOMER_SEGMENT_LIST;
  payload: FetchSegmentsType;
};

export type fetchCustomerSegmentListSucceededActionType = {
  type: typeof CustomersTypes.FETCH_CUSTOMER_SEGMENT_LIST_SUCCEEDED;
  payload: SegmentType[];
};

export type fetchCustomerSegmentListFailedActionType = {
  type: typeof CustomersTypes.FETCH_CUSTOMER_SEGMENT_LIST_FAILED;
};

// Add Customer Segment
export type addCustomerSegmentActionType = {
  type: typeof CustomersTypes.ADD_CUSTOMER_SEGMENT;
  payload: AddSegmentType;
};

export type addCustomerSegmentSucceededActionType = {
  type: typeof CustomersTypes.ADD_CUSTOMER_SEGMENT_SUCCEEDED;
  payload: SegmentType;
};

export type addCustomerSegmentFailedActionType = {
  type: typeof CustomersTypes.ADD_CUSTOMER_SEGMENT_FAILED;
};

// Remove Customer Segment
export type removeCustomerSegmentActionType = {
  type: typeof CustomersTypes.REMOVE_LOYALTY_SEGMENT;
  payload: RemoveSegmentType;
};

export type removeCustomerSegmentSucceededActionType = {
  type: typeof CustomersTypes.REMOVE_LOYALTY_SEGMENT_SUCCEEDED;
};

export type removeCustomerSegmentFailedActionType = {
  type: typeof CustomersTypes.REMOVE_LOYALTY_SEGMENT_FAILED;
};

// Get Invitation designs
export type fetchInvitationDesignsActionType = {
  type: typeof CustomersTypes.FETCH_INVITATION_DESIGNS;
  payload: FetchInvitationDesignsType;
};

export type fetchInvitationDesignsSucceededActionType = {
  type: typeof CustomersTypes.FETCH_INVITATION_DESIGNS_SUCCEEDED;
  payload: InviteDesignListItemType;
};

export type fetchInvitationDesignsFailedActionType = {
  type: typeof CustomersTypes.FETCH_INVITATION_DESIGNS_FAILED;
};

export type selectCustomerActionType = {
  type: typeof CustomersTypes.SELECT_CUSTOMER;
  payload: SelectCustomerType;
};

export type selectLoyaltyActionType = {
  type: typeof CustomersTypes.SELECT_LOYALTY;
  payload: SelectLoyaltyType;
};

export type resetAddTransactionForm = {
  type: typeof CustomersTypes.RESET_ADD_TRANSACTION_FORM;
};

export type resetAddCustomerForm = {
  type: typeof CustomersTypes.RESET_ADD_CUSTOMER_FORM;
};

export type resetAddSegmentForm = {
  type: typeof CustomersTypes.RESET_ADD_SEGMENT_FORM;
};

export type resetRemoveSegmentForm = {
  type: typeof CustomersTypes.RESET_REMOVE_SEGMENT_FORM;
};

export type resetLoyaltySettingsForm = {
  type: typeof CustomersTypes.RESET_LOYALTY_SETTINGS_FORM;
};

export type cleanFromError = {
  type: typeof CustomersTypes.CLEAN_FORM_ERROR;
};

// Fetch Loyalty Account
export type fetchLoyaltyAccountActionType = {
  type: typeof CustomersTypes.FETCH_LOYALTY_ACCOUNT;
  payload: FetchLoyaltyAccountType;
};

export type fetchLoyaltyAccountSucceededActionType = {
  type: typeof CustomersTypes.FETCH_LOYALTY_ACCOUNT_SUCCEEDED;
  payload: LoyaltyAccountType[];
};

export type fetchLoyaltyAccountFailedActionType = {
  type: typeof CustomersTypes.FETCH_LOYALTY_ACCOUNT_FAILED;
};

// Fetch Passes
export type fetchPassesActionType = {
  type: typeof CustomersTypes.FETCH_PASSES;
  payload: FetchLoyaltyAccountType;
};

export type fetchPassesSucceededActionType = {
  type: typeof CustomersTypes.FETCH_PASSES_SUCCEEDED;
  payload: PassesType[];
};

export type fetchPassesFailedActionType = {
  type: typeof CustomersTypes.FETCH_PASSES_FAILED;
};

export type customersActionTypes =
  | addLoyaltyActionType
  | addLoyaltySucceededActionType
  | addLoyaltyFailedActionType
  | inviteLoyaltyActionType
  | inviteLoyaltySucceededActionType
  | inviteLoyaltyFailedActionType
  | addLoyaltyTransactionActionType
  | addLoyaltyTransactionSucceededActionType
  | addLoyaltyTransactionFailedActionType
  | fetchCustomerListActionType
  | fetchCustomerListSucceededActionType
  | fetchCustomerListFailedActionType
  | updateCustomerActionType
  | updateCustomerSucceededActionType
  | updateCustomerFailedActionType
  | fetchLoyaltyListActionType
  | fetchLoyaltyListSucceededActionType
  | fetchLoyaltyListFailedActionType
  | fetchLoyaltyTransactionListActionType
  | fetchLoyaltyTransactionListSucceededActionType
  | fetchLoyaltyTransactionListFailedActionType
  | resendLoyaltyPassActionType
  | resendLoyaltyPassSucceededActionType
  | resendLoyaltyPassFailedActionType
  | fetchCustomerSegmentListActionType
  | fetchCustomerSegmentListSucceededActionType
  | fetchCustomerSegmentListFailedActionType
  | addCustomerSegmentActionType
  | addCustomerSegmentSucceededActionType
  | addCustomerSegmentFailedActionType
  | removeCustomerSegmentActionType
  | removeCustomerSegmentSucceededActionType
  | removeCustomerSegmentFailedActionType
  | fetchInvitationDesignsActionType
  | fetchInvitationDesignsSucceededActionType
  | fetchInvitationDesignsFailedActionType
  | selectCustomerActionType
  | fetchCustomerTransactionListActionType
  | fetchCustomerTransactionListSucceededActionType
  | fetchCustomerTransactionListFailedActionType
  | selectLoyaltyActionType
  | fetchCustomerByEmailActionType
  | fetchCustomerByEmailSucceededActionType
  | fetchCustomerByEmailFailedActionType
  | resetAddTransactionForm
  | resetAddCustomerForm
  | resetAddSegmentForm
  | resetRemoveSegmentForm
  | resetLoyaltySettingsForm
  | cleanFromError
  | fetchPassesActionType
  | fetchPassesSucceededActionType
  | fetchPassesFailedActionType
  | fetchLoyaltyAccountActionType
  | fetchLoyaltyAccountSucceededActionType
  | fetchLoyaltyAccountFailedActionType;

import React, { useState, useRef } from "react";
import { useIntl, injectIntl, WrappedComponentProps } from "react-intl";
import {
  InputBase,
  InputBaseProps,
  InputAdornment,
  Theme,
} from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

interface SearchStylesProps {
  expanded?: boolean;
}

const useStyles = makeStyles<Theme, SearchStylesProps>(
  ({ palette, breakpoints }) => ({
    root: {
      border: "1px solid transparent",
      backgroundColor: palette.grey[50],
      padding: "0 4px",
      borderRadius: "4px",
      height: "40px",
      margin: 0,

      [breakpoints.up("md")]: {
        width: "402px",
      },

      "&.Mui-focused .MuiInputAdornment-root": {
        color: palette.primary.main,
        margin: 0,
      },
    },
    input: ({ expanded }) => ({
      width: expanded ? "100%" : 0,
      [breakpoints.up("md")]: {
        width: "100%",
      },
    }),
    focused: {
      borderColor: palette.primary.main,

      [breakpoints.down("sm")]: {
        position: "absolute",
        left: 0,
        right: 0,
        zIndex: 1,
        transition: "250ms",
      },
    },
  })
);

interface SearchComponentProps extends Omit<InputBaseProps, "onChange"> {
  onChange(value: string): void;
}

const SearchComponent: React.FC<SearchComponentProps & WrappedComponentProps> =
  ({ value, onChange }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const classes = useStyles({ expanded: isExpanded });
    const inputRef = useRef<HTMLInputElement>();
    const intl = useIntl();
    const handleIconClick = () => {
      inputRef.current?.focus();
      setIsExpanded(true);
    };

    return (
      <InputBase
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onChange(e.target.value)
        }
        onBlur={() => setIsExpanded(false)}
        placeholder={intl.formatMessage({ id: "input.search.placeholder" })}
        type="search"
        classes={classes}
        inputRef={inputRef}
        startAdornment={
          <InputAdornment
            onClick={handleIconClick}
            position="start"
            style={{ margin: 0, padding: "0 8px" }}
          >
            <SearchIcon />
          </InputAdornment>
        }
      />
    );
  };

export default injectIntl(SearchComponent);

import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { injectIntl } from "react-intl";
import { customersSelectors } from "../../../../selectors";
import { mainSelectors } from "../../../../../main/selectors";
import { authSelectors } from "../../../../../auth/selectors";
import * as customersActions from "../../../../actions";
import Component from "./components";

const mapStateToProps = (state) => ({
  userInfo: authSelectors.getUser(state),
  selectedCompany: mainSelectors.getSelectedCompany(state),
  selectedProgram: mainSelectors.getSelectedLoyalty(state),
  loyaltyList: mainSelectors.getLoyaltyList(state),
  customerList: customersSelectors.getCustomerList(state),
  selectedCustomer: customersSelectors.getSelectedCustomer(state),
  addedCustomer: customersSelectors.getAddedCustomer(state),
  loyaltyAccounts: customersSelectors.getLoyaltyAccounts(state),
  isProfileUpdated: customersSelectors.getCustomerUpdated(state),
  isTransactionAdded: customersSelectors.isTransactionAdded(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      addLoyaltyCustomer: customersActions.addLoyalty,
      fetchLoyaltyList: customersActions.fetchLoyaltyList,
      fetchCustomerList: customersActions.fetchCustomerList,
      selectCustomer: customersActions.selectCustomer,
    },
    dispatch
  );

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default injectIntl(ConnectedComponent);

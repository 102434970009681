import {
  FetchTransactionsType,
  customersActionTypes,
  TransactionsTypes,
} from "./types";

import { TransactionType } from "../../types";

export const fetchTransactionList = (
  payload: FetchTransactionsType
): customersActionTypes => ({
  type: TransactionsTypes.FETCH_TRANSACTION_LIST,
  payload,
});

export const fetchTransactionListSucceeded = (
  payload: TransactionType[]
): customersActionTypes => ({
  type: TransactionsTypes.FETCH_TRANSACTION_LIST_SUCCEEDED,
  payload,
});

export const fetchTransactionListFailed = (): customersActionTypes => ({
  type: TransactionsTypes.FETCH_TRANSACTION_LIST_FAILED,
});

import React, { useEffect, useMemo, useState } from "react";
import { ContentLoader, Link } from "@components/common";
import {
  CompanyType,
  UserProfileType,
  LoyaltyType,
  CustomerListType,
  CardDesignType,
  InviteDesignType,
  EmailDesignType,
} from "@types";
import { useHistory } from "react-router";
import { Grid, Box } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { ROUTES } from "@constants";
import {
  ContainerStyled,
  HeadingStyled,
  BlockStyled,
  BlockRowStyled,
  StatusIconStyled,
  StepTitleStyled,
  StepTimeStyled,
  BlockInfoTitleStyled,
  BlockInfoStyled,
  BlockInfoLinkStyled,
  StepMainTitleStyled,
  InfoImage,
} from "./styled";
import {
  FetchCardDesignType,
  FetchEmailDesignType,
  FetchInviteDesignType,
} from "../../../../loyalties/types";
import { FetchCustomerListType } from "../../../../customers/types";

interface WelcomeProps {
  companies: CompanyType[];
  selectedCompany: CompanyType;
  selectedLoyalty: LoyaltyType;
  customers: CustomerListType;
  userInfo: UserProfileType;
  programs: LoyaltyType[];
  cardDesign: CardDesignType;
  inviteDesign: InviteDesignType;
  emailDesign: EmailDesignType;
  fetchCustomerList(data: FetchCustomerListType): void;
  fetchInviteDesign(data: FetchInviteDesignType): void;
  fetchCardDesign(data: FetchCardDesignType): void;
  fetchEmailDesign(data: FetchEmailDesignType): void;
}

const Welcome: React.FC<WelcomeProps> = ({
  companies,
  selectedCompany,
  selectedLoyalty,
  customers,
  userInfo,
  programs,
  cardDesign,
  inviteDesign,
  emailDesign,
  fetchCustomerList,
  fetchInviteDesign,
  fetchCardDesign,
  fetchEmailDesign,
}) => {
  const [cardCount, setCardCount] = useState(0);
  const [emailCount, setEmailCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [onboarded, setOnboarded] = useState(false);
  const history = useHistory();

  const SETUP_STEPS = useMemo(() => {
    return [
      {
        title: "Basic account settings",
        time: "3 minutes",
        completed: !!selectedCompany,
        link: ROUTES.MAIN_ADD_COMPANY,
      },
      {
        title: "Create your first Loyalty program",
        time: "3 minutes",
        completed: programs?.length > 0,
        link: ROUTES.MAIN_PROGRAMS_ADD,
        disabled: !selectedCompany,
      },
      {
        title: "Set up your branding and card design",
        time: "3 minutes",
        completed: !!cardDesign?.last_updated,
        link: `programs/${programs?.[0]?.id}/pass-design`,
        disabled: programs?.length === 0 || !selectedCompany,
      },
      {
        title: "Customize your invitation email and form",
        time: "5 minutes",
        completed: !!inviteDesign?.last_updated || !!emailDesign?.last_updated,
        link: `programs/${programs?.[0]?.id}/invite-design`,
        disabled: programs?.length === 0 || !selectedCompany,
      },
      {
        title: "Acquire your first customer",
        completed: customers?.items.length,
        link: `/customers/add`,
        disabled: programs?.length === 0 || !selectedCompany,
      },
    ];
  }, [
    selectedCompany,
    customers,
    selectedLoyalty,
    programs,
    cardDesign,
    inviteDesign,
    emailDesign,
  ]);

  useEffect(() => {
    if (selectedCompany && !customers) {
      fetchCustomerList({
        tenant_id: userInfo.id,
        company_id: selectedCompany.id,
      });
    }
  }, [selectedCompany, customers]);

  useEffect(() => {
    if (programs?.length) {
      fetchCardDesign({
        tenant_id: userInfo.id,
        company_id: selectedCompany.id,
        program_id: programs[cardCount].id,
      });
      fetchInviteDesign({
        tenant_id: userInfo.id,
        company_id: selectedCompany.id,
        program_id: programs[emailCount].id,
      });
      fetchEmailDesign({
        tenant_id: userInfo.id,
        company_id: selectedCompany.id,
        program_id: programs[emailCount].id,
      });
    }
  }, [programs]);

  useEffect(() => {
    if (programs?.length) {
      if (cardCount < programs.length - 1) {
        fetchCardDesign({
          tenant_id: userInfo.id,
          company_id: selectedCompany.id,
          program_id: programs[cardCount].id,
        });
      }
    }
  }, [programs, cardCount]);

  useEffect(() => {
    if (programs?.length) {
      if (emailCount < programs.length - 1) {
        fetchInviteDesign({
          tenant_id: userInfo.id,
          company_id: selectedCompany.id,
          program_id: programs[emailCount].id,
        });
        fetchEmailDesign({
          tenant_id: userInfo.id,
          company_id: selectedCompany.id,
          program_id: programs[emailCount].id,
        });
      }
    }
  }, [programs, emailCount]);

  useEffect(() => {
    if (
      cardDesign &&
      !cardDesign.last_updated &&
      programs?.length - 1 > cardCount
    ) {
      setCardCount((count) => count + 1);
    }
  }, [cardDesign]);

  useEffect(() => {
    if (
      inviteDesign &&
      !inviteDesign.last_updated &&
      emailDesign &&
      !emailDesign.last_updated &&
      programs?.length - 1 > emailCount
    ) {
      setEmailCount((count) => count + 1);
    }
  }, [inviteDesign, emailDesign]);

  useEffect(() => {
    if (
      companies?.length === 0 ||
      programs?.length === 0 ||
      (customers?.items &&
        (cardDesign?.last_updated ||
          (cardCount === programs?.length - 1 && !!cardDesign)) &&
        (emailDesign?.last_updated ||
          inviteDesign?.last_updated ||
          (emailCount === programs?.length - 1 &&
            !!emailDesign &&
            !!inviteDesign)))
    ) {
      setIsLoading(false);
    }
  }, [
    cardDesign,
    emailDesign,
    inviteDesign,
    emailCount,
    cardCount,
    customers,
    companies,
    programs,
  ]);

  useEffect(() => {
    if (!SETUP_STEPS.some((item) => !item.completed)) {
      setOnboarded(true);
    }
  }, [SETUP_STEPS]);

  useEffect(() => {
    return () => {
      setIsLoading(true);
    };
  }, []);

  if (isLoading) {
    return <ContentLoader />;
  }

  if (!isLoading && onboarded) {
    history.push("/customers/overview");
    return null;
  }

  return (
    <ContainerStyled>
      <HeadingStyled variant="h2">Welcome to Talisman!</HeadingStyled>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6}>
          <BlockStyled>
            <BlockRowStyled>
              <StepMainTitleStyled variant="h3">
                Finish setup
              </StepMainTitleStyled>
            </BlockRowStyled>
            {SETUP_STEPS.map((step) => (
              <BlockRowStyled key={step.title}>
                {/* eslint-disable */}
                <Box display="flex">
                  <StatusIconStyled
                    fill={
                      step.completed
                        ? isLoading
                          ? "#B9B9B9"
                          : "#00CF8F"
                        : "#B9B9B9"
                    }
                  />
                  <div style={{ flexGrow: 1 }}>
                    {isLoading ? (
                      <Skeleton
                        variant="text"
                        height={43}
                        style={{ top: "-6px", position: "relative" }}
                      />
                    ) : step.completed ? (
                      <>
                        <StepTitleStyled completed={1}>
                          {step.title}
                        </StepTitleStyled>
                        <StepTimeStyled completed={1}>
                          {step.time}
                        </StepTimeStyled>
                      </>
                    ) : step.disabled ? (
                      <>
                        <StepTitleStyled>{step.title}</StepTitleStyled>
                        <StepTimeStyled>{step.time}</StepTimeStyled>
                      </>
                    ) : (
                      <>
                        <Link to={step.link}>{step.title}</Link>
                        <StepTimeStyled>{step.time}</StepTimeStyled>
                      </>
                    )}
                  </div>
                </Box>
                {/* eslint-enable */}
              </BlockRowStyled>
            ))}
          </BlockStyled>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <BlockStyled>
            <BlockInfoStyled>
              <BlockInfoTitleStyled variant="h2">
                New to loyalty marketing for physical stores?
              </BlockInfoTitleStyled>
              <BlockInfoLinkStyled>
                <Link to="#knowledgebase">Visit our Knowledge Base </Link> and
                learn more
              </BlockInfoLinkStyled>
              <InfoImage />
            </BlockInfoStyled>
          </BlockStyled>
        </Grid>
      </Grid>
    </ContainerStyled>
  );
};

export default Welcome;

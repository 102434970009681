// Auth
export const USER_SIGN_IN = "/";
export const USER_SIGN_UP = "/sign-up";
export const USER_VERIFY = "/verify";
export const USER_RESET_PASSWORD = "/reset-password";
export const USER_FORGOT_PASSWORD = "/forgot-password";

// Account settings
export const SETTINGS_PERSONAL_ROUTE = "/settings/personal-settings";
export const SETTINGS_COMPANY_ROUTE = "/settings/company-settings";
export const SETTINGS_INTEGRATION_ROUTE = "/settings/integrations";

// Main
export const MAIN_SETTINGS = "/settings/:tab";
export const MAIN_CUSTOMERS = "/customers/:tab";
export const MAIN_CUSTOMER_PROFILE = "/customers/:id/:tab";
export const MAIN_PROGRAMS = "/programs/:id/:tab";
export const MAIN_PROGRAMS_ADD = "/add-program";
export const MAIN_TRANSACTIONS = "/transactions/:tab";
export const MAIN_ADD_COMPANY = "/add-company";
export const MAIN_WELCOME = "/welcome";

// Transactions
export const TRANSACTIONS_OVERVIEW = "/transactions/overview";

// Customers
export const CUSTOMERS_PROFILE_OVERVIEW = "/customers/:id/overview";
export const CUSTOMERS_ADD_FORM = "/customers/add";
export const CUSTOMERS_OVERVIEW = "/customers/overview";
export const CUSTOMERS_SEGMENTS = "/customers/segments";
export const CUSTOMERS_PROFILE_SETTINGS = "/customers/:id/settings";
export const CUSTOMERS_ADD_LOYALTY_CARD = "/customers/:id/add-card";

export const CUSTOMERS_PROFILE_CARD_OVERVIEW =
  "/customers/:id/:loyaltyId/:section";
export const CUSTOMERS_PROFILE_TRANSACTIONS =
  "/customers/:id/:loyaltyId/card-transactions";
export const CUSTOMERS_PROFILE_ADD_TRANSACTIONS =
  "/customers/:id/:loyaltyId/add-card-transaction";
export const CUSTOMERS_PROFILE_CARD_SETTINGS =
  "/customers/:id/:loyaltyId/card-settings";

// Programs
export const PROGRAMS_PASS_DESIGNS = "/programs/:id/pass-design";
export const PROGRAMS_INVITE_DESIGNS = "/programs/:id/invite-design";
export const PROGRAMS_EMAIL_DESIGNS = "/programs/:id/email-design";

// Invite form
export const INVITE_FORM = "/join/:id";
export const DOWNLOAD_PASS = "/download/:id";
export const INVITE_WITH_PARAMS = "/add/:cardNumber/:email/:successURL";

// use to show location state title on mobile in header
export const APP_ROUTES_TITLES = [
  {
    title: "Settings",
    link: "settings",
  },
  {
    title: "Transactions",
    link: "transactions",
  },
  {
    title: "Customers",
    link: "customers",
  },
  {
    title: "Programs",
    link: "programs",
  },
];

import React, { useState } from "react";
import { Error } from "@components/common";
import { ProgramCardType } from "@types";
import Step1 from "../Step1";
import Step2 from "../Step2";

interface AddProgramWrapperProps {
  hasError: boolean;
}

const AddProgramWrapper = ({ hasError }: AddProgramWrapperProps) => {
  const [formStep, setFormStep] = useState(1);
  const [cardType, setCardType] = useState<ProgramCardType | null>(null);

  const stepForward = () => setFormStep((step) => step + 1);
  const stepBack = () => setFormStep((step) => step - 1);

  if (hasError) {
    return <Error container />;
  }

  return (
    <>
      {formStep === 1 ? (
        <Step1
          cardType={cardType}
          setCardType={setCardType}
          stepForward={stepForward}
        />
      ) : (
        <Step2 cardType={cardType || "points"} stepBack={stepBack} />
      )}
    </>
  );
};

export default AddProgramWrapper;

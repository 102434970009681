import styled from "styled-components";

export const FormStyled = styled.div`
  padding: 0;
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
  border-radius: 4px;
  margin-bottom: 24px;

  @media (min-width: 992px) {
    margin-right: 24px;
  }

  @media (min-width: 1200px) {
    margin-right: 90px;
  }
`;

export const FormSubmitStyled = styled.div`
  padding: 0 24px 24px;
`;

// Form Preview
export const FormContainerStyled = styled.div`
  position: sticky;
  padding: 24px;
  top: 32px;
`;

export const FormLogoContainerStyled = styled.div`
  display: block;
  margin: 0 auto 24px;
  width: 320px;
  height: 100px;
`;

export const FormLogoPreviewStyled = styled.div`
  width: 100% !important;
  height: 100% !important;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;

  &.hide-preview {
    img {
      display: none !important;
    }
  }
`;

export const FormBodyStyled = styled.div`
  background-color: transparent;
  border: 1px solid transparent;
  padding: 50px;
  border-radius: 4px;
`;

export const FormTitleStyled = styled.h3`
  font-size: 40px;
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
`;

export const FormTextStyled = styled.p`
  font-size: 18px;
  text-align: center;
  max-width: 570px;
  margin: 0 auto 40px;
`;

export const FormInnerStyled = styled.div`
  max-width: 340px;
  margin: 0 auto 24px;

  label,
  label span,
  label span.Mui-checked {
    color: currentColor;
  }

  label span {
    opacity: 0.9;
  }

  label a {
    color: currentColor !important;
  }
`;

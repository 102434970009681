import React, { useEffect } from "react";
import { Typography, Box } from "@material-ui/core";
import { ButtonRow, Button, Link } from "@components/common";
import styled from "styled-components";
import SuccessIcon from "-!react-svg-loader!../../../../../../../assets/icons/success.svg";

const TitleStyled = styled(Typography)`
  margin-top: 24px;
  margin-bottom: 16px;
`;

const SubTitleStyled = styled(Typography)`
  margin-bottom: 18px;
  max-width: 340px;
`;

const LinkStyled = styled(Link)`
  font-size: 15px;
  margin-bottom: 26px;
  text-decoration: underlined;
`;

interface WelcomeModalProps {
  data: string;
  hideModal: any;
  resetOnboarding: any;
}

const WelcomeModal: React.FC<WelcomeModalProps> = ({
  data,
  hideModal,
  resetOnboarding,
}) => {
  useEffect(() => {
    return resetOnboarding();
  }, []);
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <SuccessIcon />
      <TitleStyled component="h3" variant="h3">
        {`Welcome to ${data}!`}
      </TitleStyled>
      <SubTitleStyled align="center">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
        imperdiet nulla at justo egestas ullamcorper.
      </SubTitleStyled>
      <LinkStyled target="_blank" to="/">
        Learn more in our Knowledge Center
      </LinkStyled>
      <ButtonRow>
        <Button onClick={hideModal}>Start designing!</Button>
      </ButtonRow>
    </Box>
  );
};

export default WelcomeModal;

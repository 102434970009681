import React from "react";
import { Container, Grid } from "@material-ui/core";
import { withRouter, Switch, Route, RouteComponentProps } from "react-router";
import { WrappedComponentProps, FormattedMessage } from "react-intl";
import { SectionNav } from "@components/common";
import { COMMON, ROUTES } from "@constants";
import Overview from "../../AccountSettings";
import Settings from "../../CompanySettings";
import Integrations from "../../Integrations";
import { SubHeading, HeaderTopStyled } from "../../styled";

const Account: React.FC<RouteComponentProps & WrappedComponentProps> = () => {
  return (
    <Container>
      <Grid container justifyContent="space-between" alignItems="center">
        <HeaderTopStyled>
          <SubHeading variant="h2">
            <FormattedMessage id="account.main.title" />
          </SubHeading>
        </HeaderTopStyled>
      </Grid>
      <SectionNav navItems={COMMON.ACCOUNT_NAV} />
      <Switch>
        <Route path={ROUTES.SETTINGS_PERSONAL_ROUTE} component={Overview} />
        <Route path={ROUTES.SETTINGS_COMPANY_ROUTE} component={Settings} />
        <Route
          path={ROUTES.SETTINGS_INTEGRATION_ROUTE}
          component={Integrations}
        />
      </Switch>
    </Container>
  );
};

export default withRouter(Account);

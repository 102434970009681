import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { injectIntl } from "react-intl";
import { authSelectors } from "../../../auth/selectors";
import { onboardingSelectors } from "../../selectors";
import * as onboardingActions from "../../actions";
import Component from "./components";

const mapStateToProps = (state) => ({
  userData: authSelectors.getUser(state),
  createdCompany: onboardingSelectors.getCreatedCompany(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      createCompany: onboardingActions.createCompany,
    },
    dispatch
  );

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default injectIntl(ConnectedComponent);

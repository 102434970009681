import React, { useState, useEffect } from "react";
import {
  Grid,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Tooltip,
} from "@material-ui/core";
import {
  DeleteOutline,
  CheckOutlined,
  CloseOutlined,
} from "@material-ui/icons";
import { withRouter, RouteComponentProps } from "react-router";
import { useForm, FormProvider } from "react-hook-form";
import { useIntl, WrappedComponentProps, FormattedMessage } from "react-intl";
import {
  Button,
  TextField,
  Alert,
  ContentLoader,
  NoData,
  Checkbox,
} from "@components/common";
import { CompanyType, UserProfileType, SegmentType } from "@types";
import { AddSegmentType, FetchSegmentsType } from "../../../../../types";
import { ModalStateType } from "../../../../../../modal/types";
import {
  SubHeadingStyled,
  HeaderStyled,
  FormSubmitStyled,
} from "../../../styled";
import {
  TableWrapperStyled,
  TableCellStyled,
  IconButtonStyled,
} from "./styled";

interface AddCustomerProps {
  selectedCompany: CompanyType;
  userInfo: UserProfileType;
  segments: SegmentType[];
  fetchSegments(data: FetchSegmentsType): void;
  addSegment(data: AddSegmentType): void;
  isSegmentAdded: boolean;
  isSegmentRemoved: boolean;
  hasError: boolean;
  resetAddSegmentForm(): void;
  resetRemoveSegmentForm(): void;
  showModal(data: ModalStateType): void;
}

const Segments = ({
  selectedCompany,
  fetchSegments,
  addSegment,
  segments,
  userInfo,
  isSegmentAdded,
  isSegmentRemoved,
  hasError,
  showModal,
  resetAddSegmentForm,
  resetRemoveSegmentForm,
}: AddCustomerProps & RouteComponentProps & WrappedComponentProps) => {
  const methods = useForm();
  const intl = useIntl();
  const { handleSubmit, errors, reset } = methods;
  const [isLoading, setIsLoading] = useState(false);

  const resetFormState = () => {
    resetAddSegmentForm();
    resetRemoveSegmentForm();
  };

  const onSubmit = ({ name, description }) => {
    setIsLoading(true);

    addSegment({
      tenant_id: userInfo.id,
      company_id: selectedCompany.id,
      name,
      description,
      // is_public: isPublic,
    });
    reset();
  };

  useEffect(() => {
    fetchSegments({
      tenant_id: userInfo.id,
      company_id: selectedCompany.id,
    });
  }, [isSegmentAdded, isSegmentRemoved]);

  useEffect(() => {
    if (isSegmentAdded || hasError || isSegmentRemoved) {
      setIsLoading(false);
    }
  }, [isSegmentAdded, hasError, isSegmentRemoved]);

  useEffect(() => {
    return resetFormState();
  }, []);

  if (!segments) {
    return <ContentLoader />;
  }

  return (
    <>
      <HeaderStyled>
        {/* @ts-ignore-start */}
        <SubHeadingStyled component="h3" variant="h3">
          <FormattedMessage id="customers.segments.title" />
        </SubHeadingStyled>
        {/* @ts-ignore-end */}
      </HeaderStyled>
      <TableWrapperStyled item md={8} lg={7}>
        <Table>
          <TableHead>
            <TableRow>
              {/* <TableCellStyled>Public</TableCellStyled> */}
              <TableCellStyled>
                <FormattedMessage id="customers.segments.table.name" />
              </TableCellStyled>
              <TableCellStyled>
                <FormattedMessage id="customers.segments.table.description" />
              </TableCellStyled>
              <TableCell />
            </TableRow>
          </TableHead>
          {!!segments.length && (
            <TableBody>
              {segments.map((segment) => (
                <TableRow key={segment.id}>
                  {/* <TableCellStyled>
                    {segment.is_public ? <CheckOutlined /> : <CloseOutlined />}
                  </TableCellStyled> */}
                  <TableCellStyled>{segment.name}</TableCellStyled>
                  <TableCellStyled>{segment.description}</TableCellStyled>
                  <TableCellStyled>
                    <Tooltip title="Delete" placement="right">
                      <IconButtonStyled
                        aria-label="delete"
                        onClick={() => {
                          resetRemoveSegmentForm();
                          showModal({
                            type: "REMOVE_SEGMENT_MODAL",
                            data: segment,
                          });
                        }}
                      >
                        <DeleteOutline />
                      </IconButtonStyled>
                    </Tooltip>
                  </TableCellStyled>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {segments.length === 0 && (
          <NoData>
            <FormattedMessage id="customers.segments.table.empty" />
          </NoData>
        )}
      </TableWrapperStyled>
      <HeaderStyled>
        {/* @ts-ignore-start */}
        <SubHeadingStyled component="h3" variant="h3">
          <FormattedMessage id="customers.segments.add.title" />
        </SubHeadingStyled>
        {/* @ts-ignore-end */}
      </HeaderStyled>
      <Grid item md={6} lg={4}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              name="name"
              placeholder={intl.formatMessage({
                id: "customers.segments.add.input.title.label",
              })}
              label={intl.formatMessage({
                id: "customers.segments.add.input.title.label",
              })}
              error={errors.name}
              validate={(value) =>
                !segments.find((item) => item.name === value)
              }
              validateMessage={intl.formatMessage({
                id: "customers.segments.add.input.title.error",
              })}
              required
              id="form-input-title"
              // offsetbottom="false"
            />
            {/* <Checkbox name="isPublic" label="This segment is public" /> */}
            <TextField
              name="description"
              placeholder={intl.formatMessage({
                id: "customers.segments.add.input.description.label",
              })}
              label={intl.formatMessage({
                id: "customers.segments.add.input.description.label",
              })}
              error={errors.description}
              id="form-input-description"
            />
            <FormSubmitStyled>
              <Button
                disabled={isLoading}
                loading={isLoading}
                onClick={handleSubmit(onSubmit)}
                type="submit"
              >
                <FormattedMessage id="customers.segments.add.submit" />
              </Button>
            </FormSubmitStyled>
          </form>
        </FormProvider>
        {isSegmentAdded && (
          <Alert sticky>
            <FormattedMessage id="customers.segments.add.success" />
          </Alert>
        )}
        {isSegmentRemoved && (
          <Alert sticky>
            <FormattedMessage id="customers.segments.remove.success" />
          </Alert>
        )}
        {hasError && (
          <Alert sticky variant="error">
            <FormattedMessage id="general.notifications.error" />
          </Alert>
        )}
      </Grid>
    </>
  );
};

export default withRouter(Segments);

import React, { useEffect, useState, useMemo } from "react";
import { Grid } from "@material-ui/core";
import QRCode from "react-qr-code";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { useIntl, WrappedComponentProps } from "react-intl";
import { Button, TextField, Alert, ContentLoader } from "@components/common";
import { COMMON } from "@constants";
import { setImageName } from "@utils/common";
import {
  CompanyType,
  LoyaltyCustomerProfileType,
  UserProfileType,
  SegmentType,
  CustomerType,
  CardDesignType,
} from "@types";
import { FetchCardDesignType } from "modules/loyalties/types";
import { IOSCard } from "../../../../../../loyalties";
import {
  ResendLoyaltyAccountPassType,
  FetchSegmentsType,
} from "../../../../../types";
import { HeadingStyled, BottomFormStyled, QRContainer } from "./styled";
import { getFilesFromS3 } from "../../../../../../../s3";

interface AddCustomerProps {
  userInfo: UserProfileType;
  profileInfo: LoyaltyCustomerProfileType;
  account: LoyaltyCustomerProfileType;
  isProfileUpdated: boolean;
  resendLoyaltyPass(data: ResendLoyaltyAccountPassType): void;
  segments: SegmentType[];
  fetchSegments(data: FetchSegmentsType): void;
  cleanFormError(): void;
  selectedCompany: CompanyType;
  selectedLoyalty: LoyaltyCustomerProfileType;
  hasError: boolean;
  inviteResend: boolean;
  selectedCustomer: CustomerType;
  cardDesign: CardDesignType;
  fetchCardDesign(data: FetchCardDesignType): void;
  clearDesigns(): void;
}

const CardSettings = ({
  userInfo,
  selectedCustomer,
  selectedLoyalty,
  selectedCompany,
  resendLoyaltyPass,
  hasError,
  inviteResend,
  cleanFormError,
  account,
  cardDesign,
  fetchCardDesign,
  clearDesigns,
}: AddCustomerProps & RouteComponentProps & WrappedComponentProps) => {
  const methods = useForm();
  const intl = useIntl();
  const { handleSubmit } = methods;
  const [isResendLoading, setIsResendLoading] = useState(false);
  const [isCardLoading, setIsCardLoading] = useState(true);
  const [passResendSuccess, setPassResendSuccess] = useState(false);
  const [logoLargeUrl, setLogoLargeUrl] = useState("");
  const [logoHorizontalUrl, setLogoHorizontalUrl] = useState("");
  const [isAssetsLoading, setIsAssetsLoading] = useState(true);

  const logosNames = useMemo(() => {
    return {
      logo_horizontal_id: setImageName(
        "logo_horizontal",
        "",
        false,
        cardDesign?.images?.logo_horizontal_id
      ),
      hero_image_id: setImageName(
        "hero_image",
        "",
        false,
        cardDesign?.images?.hero_image_id
      ),
    };
  }, [cardDesign]);

  const assets = [
    {
      key: COMMON.ASSETS_PASS_FOLDER,
      fileName: logosNames.logo_horizontal_id,
      filePreviewData: null,
      filePreviewCallback: setLogoHorizontalUrl,
    },
    {
      key: COMMON.ASSETS_PASS_FOLDER,
      fileName: logosNames.hero_image_id,
      filePreviewData: null,
      filePreviewCallback: setLogoLargeUrl,
    },
  ];

  const onResendPassSubmit = ({ email }) => {
    setIsResendLoading(true);
    resendLoyaltyPass({
      tenant_id: userInfo.id,
      company_id: selectedCompany.id,
      program_id: selectedLoyalty.id,
      account_id: account.id,
      email,
    });
  };

  useEffect(() => {
    setPassResendSuccess(inviteResend);
    setIsResendLoading(false);
  }, [inviteResend]);

  useEffect(() => {
    setIsResendLoading(false);
  }, [hasError]);

  useEffect(() => {
    return () => {
      cleanFormError();
    };
  }, []);

  if (!selectedCustomer || !selectedLoyalty) {
    return <ContentLoader />;
  }

  useEffect(() => {
    if (selectedLoyalty) {
      fetchCardDesign({
        tenant_id: userInfo.id,
        company_id: selectedCompany.id,
        program_id: selectedLoyalty.program.id,
      });
      setIsCardLoading(true);
    }
    return () => clearDesigns();
  }, [selectedLoyalty]);

  useEffect(() => {
    if (cardDesign) {
      setIsAssetsLoading(true);
      getFilesFromS3(assets).then(() => {
        setIsAssetsLoading(false);
        setIsCardLoading(false);
      });
    }
  }, [cardDesign]);

  return (
    <Grid container>
      <Grid item md={6} lg={4}>
        <HeadingStyled variant="h3">Resend pass</HeadingStyled>
        <FormProvider {...methods}>
          <BottomFormStyled onSubmit={handleSubmit(onResendPassSubmit)}>
            <TextField
              name="email"
              placeholder={intl.formatMessage({
                id: "input.email.placeholder",
              })}
              type="email"
              label={intl.formatMessage({ id: "input.email.label" })}
              disabled
              defaultValue={selectedCustomer.email}
            />
            <TextField
              name="custom"
              placeholder="Additional field"
              type="text"
              label="Additional field"
              disabled
              defaultValue=""
            />
            <div>
              <Button
                loading={isResendLoading}
                onClick={handleSubmit(onResendPassSubmit)}
                type="submit"
              >
                Resend pass
              </Button>
            </div>
          </BottomFormStyled>
        </FormProvider>
        {passResendSuccess && <Alert>Email was successfully resent</Alert>}
        {hasError && (
          <Alert variant="warning">
            Something went wrong, try again later.
          </Alert>
        )}
      </Grid>
      <Grid item md={1} lg={1} />
      <Grid item md={5} lg={7}>
        {cardDesign && (
          <IOSCard
            bgColor={cardDesign.background_color_hex}
            textColor={cardDesign.title_color_hex}
            labelColor={cardDesign.label_color_hex}
            logoLargeUrl={logoLargeUrl}
            logoHorizontalUrl={logoHorizontalUrl}
            cardNumberTitle="number"
            cardNameTitle="name"
            cardPointsTitle="points"
            assetsLoading={isAssetsLoading || isCardLoading}
            passNumber={selectedLoyalty.pass_number}
            name={selectedLoyalty.customer.name}
            hidePoints
            showTitle={false}
          >
            <QRContainer>
              <QRCode value="hey" size={86} />
            </QRContainer>
          </IOSCard>
        )}
      </Grid>
    </Grid>
  );
};

export default withRouter(CardSettings);

import { styled } from "@material-ui/styles";
import { Box, Typography } from "@material-ui/core";

export const BoxStyled = styled(Box)(() => ({
  border: "1px solid #ececec",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  borderRadius: 4,
  padding: 36,
  margin: "0 auto",
}));

export const HeadingStyled = styled(Typography)({
  marginTop: 16,
  marginBottom: 16,
});

export const SubHeadingStyled = styled(Typography)({
  marginBottom: 16,
  fontSize: 15,
});

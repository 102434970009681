import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    background: {
      default: "#fff",
    },
    common: {
      black: "#000000",
      white: "#ffffff",
    },
    primary: {
      main: "#5850EC",
      light: "#000000",
      dark: "#3933AD",
    },
    secondary: {
      main: "#E72076",
    },
    text: {
      primary: "#000000",
      secondary: "#b9b9b9",
    },
    error: {
      main: "#E72076",
    },
    grey: {
      50: "#F8F6F4", // butter
      100: "#E3E0DD",
      200: "#B9B9B9",
      300: "#ececec",
      400: "#c4c4c4",
    },
  },
  typography: {
    fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    h1: {
      fontWeight: 600,
      fontSize: "1.5rem",
    },
    h2: {
      fontWeight: 600,
      fontSize: "28px",
    },
    h3: {
      fontWeight: 600,
      fontSize: "20px",
    },
    h4: {
      fontWeight: 600,
    },
    h5: {
      fontWeight: 600,
    },
    h6: {
      fontWeight: 600,
      fontSize: 18,
    },
    button: {
      fontSize: "1rem",
      fontWeight: "bold",
    },
    body1: {
      fontSize: "0.875rem",
      marginBottom: "16px",
    },
    subtitle2: {
      fontWeight: 600,
      marginBottom: "8px",
    },
  },
  overrides: {
    MuiButton: {
      contained: {
        height: 38,
        borderRadius: 4,
        boxShadow: "none",
        fontWeight: 500,
        fontSize: "0.925rem",
        textTransform: "none",
      },
      containedSizeLarge: {
        height: 48,
        fontSize: 18,
      },
    },
    MuiInput: {
      root: {
        margin: 0,
      },
      input: {
        position: "relative",
        backgroundColor: "#F8F6F4",
        height: "42px",
        fontSize: "1rem",
        lineHeight: "1rem",
        padding: "0 1rem",
        borderRadius: "4px",
        borderWidth: "1px",
        borderColor: "transparent",
        borderStyle: "solid",
        boxShadow: "none",

        "&::placeholder": {
          color: "rgba(0,0,0, 0.7)",
        },
      },
    },
    MuiFilledInput: {
      root: {
        borderRadius: 4,
        marginBottom: 0,
      },
      input: {
        position: "relative",
        backgroundColor: "#F4F4F4",
        height: "42px",
        fontSize: "1rem",
        lineHeight: "1rem",
        padding: "0 1rem",
        borderRadius: "4px",
        borderWidth: "1px",
        borderColor: "transparent",
        borderStyle: "solid",
        boxShadow: "inset 0px 1px 1px rgba(0, 0, 0, 0.25)",
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: "0.875rem",
        color: "#263238",
        marginBottom: 10,
        fontWeight: 500,
        display: "inline-flex",
      },
    },
    MuiFormControlLabel: {
      root: {
        marginBottom: 8,
      },
      label: {
        marginBottom: 0,
      },
    },
    MuiCheckbox: {
      root: {
        color: "#ECECEC",
      },
      colorPrimary: {
        color: "#ECECEC",
      },
      colorSecondary: {
        color: "#5850EC",
      },
    },
    MuiTable: {
      root: {
        position: "relative",
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: 0,
        fontSize: 15,
      },
      head: {
        borderBottom: 0,
        fontSize: 14,
      },
    },
    MuiTableRow: {
      root: {
        border: "1px solid #ECECEC",
      },
      head: {
        border: 0,
      },
    },
    MuiSelect: {
      selectMenu: {
        height: "42px",
        width: "100%",
      },
      select: {
        border: "1px solid #5850EC",
        color: "#5850EC",
        borderRadius: "4px",
        lineHeight: "42px",
      },
    },
    MuiMenu: {
      paper: {
        minWidth: "200px",
        border: "1px solid #ececec",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: "4px",
      },
    },
    MuiMenuItem: {
      root: {
        marginBottom: 0,
      },
    },
    MuiChip: {
      root: {
        borderRadius: 4,
        fontWeight: 400,
        margin: 4,
        backgroundColor: "#F8F6F4",
      },
    },
  },
});

export default theme;

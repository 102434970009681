import React from "react";
import { FormattedMessage } from "react-intl";
import { NoDataStyled } from "./styled";

const NoData = ({ children, ...props }) => {
  return (
    <NoDataStyled {...props}>
      {children || <FormattedMessage id="general.placeholders.noData" />}
    </NoDataStyled>
  );
};

export default NoData;

import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { injectIntl } from "react-intl";
import { authSelectors } from "../../selectors";
import * as authActions from "../../actions";
import Component from "./components";

const mapStateToProps = (state) => ({
  isSynced: authSelectors.getIsUserSynced(state),
  isAuthenticated: authSelectors.getIsUserAuthenticated(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      userSignedUp: authActions.signedUpSucceeded,
      createTenant: authActions.createTenant,
    },
    dispatch
  );

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default injectIntl(ConnectedComponent);

import { styled } from "@material-ui/styles";
import { ButtonBase, LinearProgress, Select } from "@material-ui/core";

export const ButtonBaseStyled = styled(ButtonBase)({
  fontSize: 14,
  "& svg": {
    marginRight: 8,
  },
});

export const SelectStyled = styled(Select)({
  "& .MuiSelect-selectMenu": {
    border: 0,
    backgroundColor: "transparent",
    color: "#000",
    paddingLeft: 42,
    fontSize: 14,
  },
  "& svg": {
    left: 8,
  },
});

export const EmptyListStyled = styled("div")(() => ({
  padding: "40px 0",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  textAlign: "center",
}));

export const TableWrapperStyled = styled("div")({
  position: "relative",
});

export const LinearProgressStyled = styled(LinearProgress)({
  position: "absolute",
  width: "100%",
  top: "55px",
});

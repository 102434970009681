import React from "react";
import { Box } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { ImagePreviewType, AssetType } from "@types";
import {
  CardStyled,
  CardHeadStyled,
  CardLogoHorizontalStyled,
  CardLogoMainStyled,
  CardInfoTitleStyled,
  CardInfoValueSmallStyled,
  CardInfoRow,
  CardVersionStyled,
  CardContainerStyled,
  CardCodePlaceholder,
  CardLogoPreview,
} from "./styled";

interface CardIOSPreviewProps {
  bgColor: string;
  textColor: string;
  labelColor: string;
  stampsLogosData: AssetType[];
  logoHorizontalPreview: ImagePreviewType;
  hidePoints: boolean;
  logoLargeUrl?: string;
  logoHorizontalUrl?: string;
  cardNumberTitle: string;
  cardNameTitle: string;
  cardPointsTitle: string;
  assetsLoading: boolean;
  step: number;
  steps: number;
}

const CardIOSPreview: React.FC<CardIOSPreviewProps> = ({
  bgColor,
  textColor,
  labelColor,
  stampsLogosData,
  logoHorizontalPreview,
  logoHorizontalUrl,
  cardNumberTitle,
  cardNameTitle,
  assetsLoading,
  step,
}) => {
  return (
    <CardContainerStyled>
      <CardVersionStyled>iOS version</CardVersionStyled>
      <CardStyled
        style={{
          backgroundColor: bgColor,
        }}
      >
        <CardHeadStyled>
          {assetsLoading ? (
            <Skeleton variant="text" width={160} height={50} />
          ) : (
            <CardLogoHorizontalStyled>
              <CardLogoPreview
                style={
                  logoHorizontalPreview
                    ? undefined
                    : {
                        backgroundImage: `url('data:image/png;base64,${logoHorizontalUrl}')`,
                      }
                }
                className={
                  logoHorizontalPreview
                    ? "cropped-logo-horizontal"
                    : "hide-preview"
                }
              />
            </CardLogoHorizontalStyled>
          )}
        </CardHeadStyled>
        {assetsLoading ? (
          <Skeleton variant="text" height={100} />
        ) : (
          stampsLogosData.map((item, i) => (
            <CardLogoMainStyled
              style={{ display: i === step ? "block" : "none" }}
              key={item.fileName}
            >
              <CardLogoPreview
                style={
                  item.filePreviewData
                    ? undefined
                    : {
                        backgroundImage: `url('data:image/png;base64,${item.url}')`,
                      }
                }
                className={
                  item.filePreviewData
                    ? `stamp-logo-preview-${i}`
                    : "hide-preview"
                }
              />
            </CardLogoMainStyled>
          ))
        )}

        <CardInfoRow>
          <div>
            <CardInfoTitleStyled
              style={{
                color: labelColor,
              }}
            >
              {cardNameTitle}
            </CardInfoTitleStyled>
            <CardInfoValueSmallStyled
              style={{
                color: textColor,
              }}
            >
              Alexandra Nováková
            </CardInfoValueSmallStyled>
          </div>
          <Box display="flex" flexDirection="column" alignItems="flex-end">
            <CardInfoTitleStyled
              style={{
                color: labelColor,
              }}
            >
              {cardNumberTitle}
            </CardInfoTitleStyled>
            <CardInfoValueSmallStyled
              style={{
                color: textColor,
              }}
            >
              01
            </CardInfoValueSmallStyled>
          </Box>
        </CardInfoRow>
        <CardCodePlaceholder width="147" height="74" />
      </CardStyled>
    </CardContainerStyled>
  );
};

export default CardIOSPreview;

import { NavLink, Link } from "react-router-dom";
import styled from "styled-components";
import {
  Container,
  Avatar,
  MenuItem,
  Divider,
  Accordion,
  AccordionSummary,
} from "@material-ui/core";
import ButtonLink from "../ButtonLink";

export const HeaderWrapperStyled = styled("div")`
  background-color: ${({ theme }) => theme.palette.common.white};
  height: 66px;
  line-height: 66px;
  display: flex;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
`;

export const HeaderStyled = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
`;

export const LogoLinkStyled = styled(Link)`
  padding-bottom: 2px;
  display: inline-flex;
`;

// Main nav
export const NavStyled = styled.nav`
  margin-left: 80px;
  margin-right: auto;
  display: flex;
`;

export const NavSubMenuStyled = styled.div`
  padding: 16px;

  @media (min-width: 992px) {
    position: absolute;
    top: 100%;
    left: 0;
    opacity: 0;
    visibility: hidden;
    background-color: #fff;
    border: 1px solid #ececec;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    min-width: 230px;
    margin-top: -8px;
    z-index: 100;
    transition: 250ms;
    line-height: normal;
  }
`;

export const NavItemStyled = styled(NavLink)`
  display: block;
  margin-right: 20px;
  text-decoration: none;
  color: #000;
  position: relative;
  font-weight: 500;
  font-size: 15px;

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    height: 2px;
    width: 0;
    transition: 250ms;
    background-color: #bdbdbd;
    transform: translateX(-50%);
  }

  &.active:after {
    width: 100%;
  }
`;

export const NavButtonStyled = styled.div`
  display: block;
  text-decoration: none;
  color: #000;
  position: relative;
  font-weight: 500;
  font-size: 15px;
  line-height: 66px;

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    height: 2px;
    width: 0;
    transition: 250ms;
    background-color: #bdbdbd;
    transform: translateX(-50%);
  }

  &.active:after {
    width: 100%;
  }
`;

export const NavSubItemStyled = styled(NavLink)`
  font-weight: normal;
  white-space: nowrap;
  text-decoration: none;
  display: block;
  width: 100%;
  color: ${({ theme, color }) =>
    color === "primary"
      ? theme.palette.primary.main
      : theme.palette.common.black};
`;

export const CreateProgramLinkStyled = styled(ButtonLink)`
  margin-left: auto;
  margin-right: 38px;
`;

interface AvatarWrapperProps {
  active?: boolean;
}

export const AvatarWrapper = styled.div<AvatarWrapperProps>`
  display: flex;
  align-items: center;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -13px;
    height: 2px;
    width: ${({ active }) => (active ? "calc(100% + 16px)" : "0")};
    transition: 250ms;
    background-color: #bdbdbd;
    transform: translateX(-50%);
  }
`;

export const AvatarStyled = styled(Avatar)`
  color: #bfbfbf;
  background-color: #f8f6f4;
  font-weight: normal;
  font-size: 1rem;
`;

export const AvatarStyledMobile = styled(AvatarStyled)`
  & {
    background-color: ${({ theme }) => theme.palette.grey[50]};
  }
`;

export const AvatarNameStyled = styled.span`
  margin-left: 10px;
  font-weight: 500;
  font-size: 14px;
  text-transform: none;
`;

export const CompanyNameStyled = styled.div`
  font-size: 15px;
  font-weight: 600;
  padding: 0 30px;
  position: relative;
  margin-left: auto;
  margin-right: 6px;

  @media (min-width: 960px) {
    margin-left: 0;
  }

  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 10px;
    bottom: 10px;
    width: 1px;
    background-color: ${({ theme }) => theme.palette.grey[300]};
  }
`;

export const MobileNavStyled = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[300]};
  padding: 10px 0;
`;

export const MobileNavItemStyled = styled(MenuItem)`
  font-size: 20px;
`;

export const MobileUserNavItem = styled(MenuItem)`
  font-size: 15px;
  font-weight: 400;
  flex: 1;
  min-height: auto;

  &:hover a {
    text-decoration: none;
  }
`;

export const DividerStyled = styled(Divider)`
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const AccordionStyled = styled(Accordion)`
  box-shadow: none;

  &:before {
    display: none;
  }

  &.Mui-expanded {
    margin: 0;
  }
`;

export const AccordionSummaryStyled = styled(AccordionSummary)`
  &.Mui-expanded {
    min-height: inherit;
  }
  .MuiAccordionSummary-content {
    margin: 0 !important;
  }
`;

import React, { useState, useEffect, useMemo } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { useForm, FormProvider } from "react-hook-form";
import { useIntl, WrappedComponentProps } from "react-intl";
import { Grid, Typography } from "@material-ui/core";
import { ArrowForwardIos } from "@material-ui/icons";
import { v4 as uuid } from "uuid";
import {
  Button,
  ButtonRow,
  TextField,
  Alert,
  ColorPicker,
  InputFile,
  ContentLoader,
  Tabs,
  Error,
} from "@components/common";
import { VALIDATION, COMMON } from "@constants";
import { checkHexColor, setImageName } from "@utils/common";
import {
  CompanyType,
  LoyaltyType,
  UserProfileType,
  ImagePreviewType,
  CardDesignListItemType,
} from "@types";
import {
  getFilesFromS3,
  uploadFilesToS3,
  getFileFromS3,
} from "../../../../../s3";
import { FetchCardDesignType, UpdateCardDesignType } from "../../../types";
import CardIOS from "./CardIOS";
import CardAndroid from "./CardAndroid";
import {
  FormStyled,
  CardRow,
  CardStickyStyled,
  FormSubmitStyled,
  StepButtonStyled,
  StepsSwitchContainer,
  AccordionStyled,
  AccordionSummaryStyled,
  AccordionDetailsStyled,
  FormStepsContainerStyled,
  GridStickyStyled,
} from "./styled";

const logo_uuid = uuid();
const missedDataPlaceholder = {
  geofence: {
    latitude: 25.123457,
    longitude: 145.09661,
    message: "Your store is near!",
  },
};

interface CardDesignProps {
  selectedLoyalty: LoyaltyType;
  selectedCompany: CompanyType;
  userInfo: UserProfileType;
  cardDesign: CardDesignListItemType;
  updateCardDesign(data: UpdateCardDesignType): void;
  fetchCardDesign(data: FetchCardDesignType): void;
  hasError: boolean;
  cardDesignsUpdated: boolean;
  clearState(): void;
}

type AssetType = {
  id: string;
  fileName: string;
  filePreviewData: string;
  url: string;
  key: string;
};

const CardDesign = ({
  userInfo,
  selectedLoyalty,
  selectedCompany,
  updateCardDesign,
  hasError,
  cardDesign,
  fetchCardDesign,
  cardDesignsUpdated,
  clearState,
}: CardDesignProps & RouteComponentProps & WrappedComponentProps) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [isAssetsLoading, setIsAssetsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const methods = useForm({
    defaultValues: {
      cardColor: "#ffffff",
      labelColor: "#000000",
      textColor: "#000000",
      hidePoints: false,
      cardNumberTitle: "Card number",
      cardNameTitle: "Name",
      cardPointsTitle: "My Points",
      email: userInfo.email,
    },
    shouldUnregister: false,
  });
  const { handleSubmit, errors, watch, setValue, formState } = methods;
  const formValues = watch([
    "cardColor",
    "labelColor",
    "textColor",
    "hidePoints",
    "cardNumberTitle",
    "cardNameTitle",
    "cardPointsTitle",
  ]);
  const [step, setStep] = useState(0);
  // pass preview state
  const [logoSmallPreview, setLogoSmallPreview] = useState<ImagePreviewType>(
    null
  );
  const [
    logoHorizontalPreview,
    setLogoHorizontalPreview,
  ] = useState<ImagePreviewType>(null);
  const [logoLargePreview, setLogoLargePreview] = useState<ImagePreviewType>(
    null
  );
  const stamps_images = cardDesign?.stamps_images;

  const [logoSmallUrl, setLogoSmallUrl] = useState("");
  const [logoLargeUrl, setLogoLargeUrl] = useState("");
  const [logoHorizontalUrl, setLogoHorizontalUrl] = useState("");
  const [uploadPhotoError, setUploadPhotoError] = useState(false);

  // stamps logos data
  const [stampsPreviews, setStampsPreviews] = useState({});
  const [stampsURLs, setStampsURLs] = useState({});
  const [stampsLogos, setStampsLogos] = useState<AssetType[]>([]);

  const logosNames = useMemo(() => {
    return {
      logo_square_id: setImageName(
        "logo_square",
        logo_uuid,
        !!logoSmallPreview,
        cardDesign?.images?.logo_square_id
      ),
      logo_horizontal_id: setImageName(
        "logo_horizontal",
        logo_uuid,
        !!logoHorizontalPreview,
        cardDesign?.images?.logo_horizontal_id
      ),
      hero_image_id: setImageName(
        "hero_image",
        logo_uuid,
        !!logoLargePreview,
        cardDesign?.images?.hero_image_id
      ),
    };
  }, [
    logo_uuid,
    cardDesign,
    logoSmallPreview,
    logoHorizontalPreview,
    logoLargePreview,
  ]);

  const assets = [
    {
      key: COMMON.ASSETS_PASS_FOLDER,
      fileName: logosNames.logo_square_id,
      filePreviewData: logoSmallPreview,
      filePreviewCallback: setLogoSmallUrl,
    },
    {
      key: COMMON.ASSETS_PASS_FOLDER,
      fileName: logosNames.logo_horizontal_id,
      filePreviewData: logoHorizontalPreview,
      filePreviewCallback: setLogoHorizontalUrl,
    },
    {
      key: COMMON.ASSETS_PASS_FOLDER,
      fileName: logosNames.hero_image_id,
      filePreviewData: logoLargePreview,
      filePreviewCallback: setLogoLargeUrl,
    },
  ];

  const formData = {
    tenant_id: userInfo.id,
    company_id: selectedCompany.id,
    program_id: selectedLoyalty.id,
    pass_design_id: cardDesign?.id,
    background_color_hex: checkHexColor(formValues.cardColor),
    label_color_hex: checkHexColor(formValues.labelColor),
    title_color_hex: checkHexColor(formValues.textColor),
    display_balance: !formValues.hidePoints,
    images: {
      ...logosNames,
    },
    strings: {
      name_title_text: formValues.cardNameTitle,
      pass_number_title_text: formValues.cardNumberTitle,
      balance_title_text: formValues.cardPointsTitle,
    },
    stamps_images: stampsLogos.map((item, i) => {
      return {
        image_id: item.fileName,
        stamp_number: i,
      };
    }),
    ...missedDataPlaceholder,
  };

  const resetPreviews = () => {
    setLogoSmallPreview(null);
    setLogoHorizontalPreview(null);
    setLogoLargePreview(null);
  };

  function initFormValues() {
    setValue("cardColor", cardDesign.background_color_hex);
    setValue("labelColor", cardDesign.label_color_hex);
    setValue("textColor", cardDesign.title_color_hex);
    setValue("cardNameTitle", cardDesign.strings?.name_title_text);
    setValue("cardNumberTitle", cardDesign.strings?.pass_number_title_text);
    setValue("cardPointsTitle", cardDesign.strings?.balance_title_text);
    setValue("hidePoints", !cardDesign.display_balance);
  }

  function resetForm() {
    resetPreviews();
    initFormValues();
  }

  useEffect(() => {
    stamps_images?.forEach((image, i) => {
      setStampsLogos((items) => {
        const newState = items;
        newState[i] = {
          id: `stamp-image-${i}`,
          fileName: setImageName(
            `stamp-image-${i}`,
            logo_uuid,
            !!stampsPreviews[i],
            image.image_id
          ),
          filePreviewData: stampsPreviews[i],
          url: stampsURLs[image.image_id],
          key: COMMON.ASSETS_PASS_FOLDER,
        };
        return [...newState];
      });
    });
  }, [stamps_images, stampsPreviews, stampsURLs]);

  useEffect(() => {
    if (cardDesign) {
      setIsAssetsLoading(true);
      initFormValues();
      getFilesFromS3(assets).then(() => {
        resetPreviews();
      });

      const pr = [];
      const urls = {};
      stamps_images.forEach((file) => {
        const res = getFileFromS3(
          file.image_id,
          COMMON.ASSETS_PASS_FOLDER
        ).then((url) => {
          urls[file.image_id] = url;
        });
        pr.push(res);
      });
      Promise.all(pr).finally(() => {
        setIsAssetsLoading(false);
        setStampsURLs(urls);
      });
    }
  }, [cardDesign]);

  useEffect(() => {
    if (!cardDesign) {
      fetchCardDesign({
        tenant_id: userInfo.id,
        company_id: selectedCompany.id,
        program_id: selectedLoyalty.id,
      });
    }
  }, [cardDesign, selectedLoyalty]);

  useEffect(() => {
    if (cardDesignsUpdated || hasError) {
      setIsLoading(false);
      resetPreviews();
      setStampsPreviews({});
    }
  }, [cardDesignsUpdated, hasError]);

  useEffect(() => {
    return clearState();
  }, []);

  const onSubmit = () => {
    setIsLoading(true);

    const uploadAssets = uploadFilesToS3(assets);
    const uploadStampsAssets = uploadFilesToS3(stampsLogos);

    uploadAssets.catch(() => {
      setUploadPhotoError(true);
    });

    uploadStampsAssets
      .catch(() => {
        setUploadPhotoError(true);
      })
      .finally(() => {
        updateCardDesign(formData);
      });
  };

  if (hasError) {
    return <Error />;
  }

  if (!cardDesign) {
    return <ContentLoader />;
  }

  return (
    <>
      {uploadPhotoError && (
        <Alert variant="error">
          There was an error while uploading logos. Try later, please.
        </Alert>
      )}
      {cardDesignsUpdated && (
        <Alert sticky>Pass designs successfully updated</Alert>
      )}
      <Grid container alignItems="flex-start">
        <Grid item sm={12} md={5} lg={5}>
          <FormProvider {...methods}>
            <FormStyled onSubmit={handleSubmit(onSubmit)}>
              <Tabs
                value={activeTab}
                // @ts-ignore-start
                onChange={(_, newValue) => setActiveTab(newValue)}
                // @ts-ignore-end
                indicatorColor="primary"
                textColor="primary"
              >
                <Tabs.Item disableRipple label="Content" />
                <Tabs.Item disableRipple label="Cycle settings" />
                <Tabs.Item disableRipple label="Style" />
              </Tabs>
              <Tabs.Panel value={activeTab} index={0}>
                <TextField
                  name="cardNameTitle"
                  placeholder="Name"
                  label="Name"
                  error={errors.cardNameTitle}
                  id="form-input-card-name-title"
                  required
                  disabled={isLoading}
                />
                <TextField
                  name="cardNumberTitle"
                  placeholder="Pass number"
                  label="Pass number"
                  error={errors.cardNumberTitle}
                  id="form-input-card-number-title"
                  required
                  disabled={isLoading}
                />
                <TextField
                  name="cardPointsTitle"
                  placeholder="Stamps"
                  label="Stamps"
                  error={errors.cardPointsTitle}
                  id="form-input-card-stamps-title"
                  required
                  disabled={isLoading}
                />
              </Tabs.Panel>
              <Tabs.Panel value={activeTab} index={2}>
                <ColorPicker
                  value={formValues.cardColor}
                  name="cardColor"
                  label="Card color"
                  required
                  disabled={isLoading}
                />
                <ColorPicker
                  value={formValues.labelColor}
                  name="labelColor"
                  label="Label color"
                  required
                  disabled={isLoading}
                />
                <ColorPicker
                  value={formValues.textColor}
                  name="textColor"
                  label="Text color"
                  required
                  disabled={isLoading}
                />
                <InputFile
                  label="Android Logo"
                  helpText="Your picture should be a square image with dimensions of at least 80px. PNG, JPG, and SVG file types are acceptable."
                  name="logo-square"
                  setPreviewClb={setLogoSmallPreview}
                  aspectRatio={1}
                  previewClassName="cropped-logo-square"
                  preview={logoSmallPreview}
                  disabled={isLoading}
                />
                <InputFile
                  label="iOS Logo"
                  helpText="Your picture should be a square image with dimensions of at least 80px. PNG, JPG, and SVG file types are acceptable."
                  name="logo-horizontal"
                  setPreviewClb={setLogoHorizontalPreview}
                  aspectRatio={160 / 50}
                  previewClassName="cropped-logo-horizontal"
                  preview={logoHorizontalPreview}
                  disabled={isLoading}
                />
                <TextField
                  name="email"
                  placeholder={intl.formatMessage({
                    id: "input.email.placeholder",
                  })}
                  type="email"
                  label="Support email"
                  helperText="This email will be used for communication with the customers"
                  error={errors.email}
                  pattern={{
                    value: VALIDATION.EMAIL_VALIDATION_REGEXP,
                    message: intl.formatMessage({
                      id: "input.email.pattern.error",
                    }),
                  }}
                  id="form-input-email"
                  disabled={isLoading}
                />
              </Tabs.Panel>
              <Tabs.Panel value={activeTab} index={1}>
                <Typography style={{ marginBottom: "6px" }}>
                  iOS image settings
                </Typography>
                <Typography color="textSecondary" variant="body1">
                  Lorem ipsum dolor sit amet
                </Typography>
                <FormStepsContainerStyled>
                  {cardDesign &&
                    stampsLogos?.map((item, key) => (
                      <AccordionStyled
                        expanded={step === key}
                        onChange={() => {
                          setStep(key);
                        }}
                        key={item.id}
                      >
                        <AccordionSummaryStyled
                          expandIcon={<ArrowForwardIos />}
                          disableRipple
                        >
                          Step {key}
                        </AccordionSummaryStyled>
                        <AccordionDetailsStyled>
                          <InputFile
                            label=""
                            name={item.id}
                            setPreviewClb={(preview) =>
                              setStampsPreviews((state) => {
                                return {
                                  ...state,
                                  [key]: preview,
                                };
                              })
                            }
                            aspectRatio={160 / 50}
                            previewClassName={`stamp-logo-preview-${key}`}
                            preview={item.filePreviewData}
                            disabled={isLoading}
                          />
                        </AccordionDetailsStyled>
                      </AccordionStyled>
                    ))}
                </FormStepsContainerStyled>
                <InputFile
                  label="Android image settings"
                  helpText="Your picture should be a square image with dimensions of at least 80px. PNG, JPG, and SVG file types are acceptable."
                  name="logo-large"
                  setPreviewClb={setLogoLargePreview}
                  aspectRatio={160 / 50}
                  previewClassName="cropped-logo-cover"
                  preview={logoLargePreview}
                  disabled={isLoading}
                />
              </Tabs.Panel>
              <FormSubmitStyled>
                <ButtonRow>
                  <Button
                    loading={isLoading}
                    onClick={handleSubmit(onSubmit)}
                    type="submit"
                    disabled={!formState.isDirty}
                  >
                    Save changes
                  </Button>
                  <Button
                    disabled={isLoading || !formState.isDirty}
                    onClick={resetForm}
                    type="reset"
                    color="default"
                  >
                    Reset
                  </Button>
                </ButtonRow>
              </FormSubmitStyled>
            </FormStyled>
          </FormProvider>
        </Grid>
        <GridStickyStyled item xs={12} md={6}>
          <CardRow container>
            <StepsSwitchContainer item xs={12}>
              <Typography>Switch steps</Typography>
              {Array.from(
                Array(selectedLoyalty.maximum_stamps_count).keys()
              ).map((stamp) => (
                <StepButtonStyled
                  variant="outlined"
                  color="default"
                  onClick={() => setStep(stamp)}
                  key={stamp}
                  active={step === stamp ? 1 : 0}
                  disableRipple
                >
                  {stamp}
                </StepButtonStyled>
              ))}
            </StepsSwitchContainer>
            <Grid item xs={12}>
              <CardStickyStyled>
                <CardIOS
                  bgColor={checkHexColor(formValues.cardColor)}
                  labelColor={checkHexColor(formValues.labelColor)}
                  textColor={checkHexColor(formValues.textColor)}
                  hidePoints={formValues.hidePoints}
                  stampsLogosData={stampsLogos}
                  logoHorizontalPreview={logoHorizontalPreview}
                  logoHorizontalUrl={logoHorizontalUrl}
                  cardNumberTitle={formValues.cardNumberTitle}
                  cardNameTitle={formValues.cardNameTitle}
                  cardPointsTitle={formValues.cardPointsTitle}
                  assetsLoading={isAssetsLoading}
                  step={step}
                  steps={selectedLoyalty.maximum_stamps_count}
                />
                <CardAndroid
                  bgColor={checkHexColor(formValues.cardColor)}
                  labelColor={checkHexColor(formValues.labelColor)}
                  textColor={checkHexColor(formValues.textColor)}
                  hidePoints={formValues.hidePoints}
                  logoLargePreview={logoLargePreview}
                  logoLargeUrl={logoLargeUrl}
                  logoSmallPreview={logoSmallPreview}
                  loyaltyName={selectedLoyalty.name}
                  companyName={selectedCompany.name}
                  logoSmallUrl={logoSmallUrl}
                  cardNumberTitle={formValues.cardNumberTitle}
                  cardNameTitle={formValues.cardNameTitle}
                  cardPointsTitle="Stamps"
                  assetsLoading={isAssetsLoading}
                  step={step}
                  steps={selectedLoyalty.maximum_stamps_count}
                />
              </CardStickyStyled>
            </Grid>
          </CardRow>
        </GridStickyStyled>
      </Grid>
    </>
  );
};

export default withRouter(CardDesign);

import { styled } from "@material-ui/styles";
import { ButtonBase, Typography, Grid } from "@material-ui/core";

export const FormContainerStyled = styled(Grid)({
  width: "100%",

  "@media (min-width: 992px)": {
    width: "402px",
  },
});

export const FormBodyStyled = styled("div")({
  width: "100%",
  maxWidth: 402,
  margin: "0 auto",
});

type CardTypeButtonProps = {
  selected: boolean;
  disabled?: boolean;
  direction?: "column" | "row";
};

export const CardTypeButtonIconStyled = styled("div")({
  minWidth: 50,
});

export const CardTypeButtonStyled = styled(ButtonBase)(
  ({ selected, disabled, direction = "row" }: CardTypeButtonProps) => ({
    display: "flex",
    padding: direction === "row" ? "24px 30px" : "10px 0",
    flexDirection: direction,
    marginBottom: 24,
    border: selected ? "2px solid #5850EC" : "2px solid #F8F6F4",
    borderRadius: 4,
    cursor: "pointer",
    opacity: disabled ? 0.45 : 1,
    textAlign: "left",
    flex: direction === "row" ? "1 0 100%" : "1 0 105px",
    maxWidth: 105,

    "@media (min-width: 768px)": {
      flex: direction === "row" ? "1 0 100%" : "1 0 122px",
      maxWidth: 122,
    },
  })
);

export const CardTypeButtonTitleStyled = styled(Typography)({
  marginBottom: 0,
  fontWeight: "bold",
  fontSize: 16,
});

export const CardTypeButtonSubTitleStyled = styled(Typography)({
  marginBottom: 0,
  fontSize: 15,
});

export const CardTypeExternalLink = styled("div")({
  paddingBottom: 30,
  borderBottom: "2px solid #F8F6F4",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: 28,

  "& p": {
    display: "inline-flex",
    alignItems: "center",
    margin: 0,
  },

  "& a": {
    display: "inline-flex",
    alignItems: "center",
    color: "#000",
    marginLeft: 6,
  },
});

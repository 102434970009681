import styled from "styled-components";
import { Typography } from "@material-ui/core";

export const HeadingStyled = styled(Typography)`
  margin-bottom: 24px;
`;

export const FormStyled = styled.form`
  margin-bottom: 16px;
`;

import { MainTypes, MainStateType, mainActionTypes } from "./types";

export const defaultState: MainStateType = {
  companies: undefined,
  loyalties: undefined,
  selectedCompany: undefined,
  selectedLoyalty: undefined,
};

export default (
  state = defaultState,
  action: mainActionTypes
): MainStateType => {
  switch (action.type) {
    case MainTypes.FETCH_COMPANY_LIST:
      return {
        ...state,
      };

    case MainTypes.FETCH_COMPANY_LIST_SUCCEEDED:
      return {
        ...state,
        companies: action.payload,
        selectedCompany: action.payload[0],
      };

    case MainTypes.UPDATE_COMPANY_SUCCEEDED:
      return {
        ...state,
        selectedCompany: action.payload,
      };

    case MainTypes.FETCH_COMPANY_LIST_FAILED:
      return {
        ...state,
        companies: null,
        selectedCompany: null,
      };

    case MainTypes.FETCH_LOYALTY_LIST:
      return {
        ...state,
      };

    case MainTypes.FETCH_LOYALTY_LIST_SUCCEEDED:
      return {
        ...state,
        loyalties: action.payload,
      };

    case MainTypes.FETCH_LOYALTY_LIST_FAILED:
      return {
        ...state,
        loyalties: null,
        selectedLoyalty: null,
      };

    case MainTypes.SELECT_LOYALTY:
      return {
        ...state,
        selectedLoyalty: state.loyalties?.find(
          (loyalty) => loyalty.id === action.payload.id
        ),
      };

    default:
      return state;
  }
};

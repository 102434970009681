import React from "react";
import { Grid, Hidden } from "@material-ui/core";
import {
  withRouter,
  Route,
  Switch,
  Redirect,
  RouteComponentProps,
} from "react-router";
import { WrappedComponentProps, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Button, SectionNav } from "@components/common";
import { COMMON, ROUTES } from "@constants";
import { LoyaltyType } from "@types";
import AddCustomer from "../../AddCustomer";
import CustomerList from "../../CustomerList";
import Segments from "../../Segments";
import {
  WrapperStyled,
  SubHeadingStyled,
  HeaderTopStyled,
} from "../../../styled";

interface CustomersProps {
  programList: LoyaltyType[];
}

const Customers: React.FC<
  CustomersProps & RouteComponentProps & WrappedComponentProps
> = ({ programList }) => {
  return (
    <WrapperStyled>
      <Grid container justifyContent="space-between" alignItems="center">
        <HeaderTopStyled>
          <SubHeadingStyled variant="h2">
            <FormattedMessage id="customers.main.title" />
          </SubHeadingStyled>
          {programList?.length !== 0 && (
            <Hidden xsDown>
              <Button component={Link} to={ROUTES.CUSTOMERS_ADD_FORM}>
                <FormattedMessage id="customers.main.add" />
              </Button>
            </Hidden>
          )}
        </HeaderTopStyled>
      </Grid>
      <SectionNav
        disabled={programList?.length === 0}
        navItems={COMMON.CUSTOMERS_NAV}
      />
      <Switch>
        <Route
          exact
          path={ROUTES.CUSTOMERS_OVERVIEW}
          component={CustomerList}
        />
        <Route exact path={ROUTES.CUSTOMERS_ADD_FORM} component={AddCustomer} />
        <Route exact path={ROUTES.CUSTOMERS_SEGMENTS} component={Segments} />
        <Redirect to={ROUTES.CUSTOMERS_OVERVIEW} />
      </Switch>
    </WrapperStyled>
  );
};

export default withRouter(Customers);

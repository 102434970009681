import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Link as LinkIcon } from "@material-ui/icons";
import { WrappedComponentProps } from "react-intl";
import { Link } from "react-router-dom";
import { ProgramCardType } from "@types";
import CashbackCardIcon from "-!react-svg-loader!../../../../../../../assets/icons/cashback-card.svg";
import StampCardIcon from "-!react-svg-loader!../../../../../../../assets/icons/stamp-card.svg";
import {
  TitleStyled,
  SubmitButtonStyled,
  WrapperStyled,
  LinearProgressStyled,
  ButtonLinkStyled,
} from "../../../../styled";
import {
  FormBodyContainer,
  CardTypeButtonStyled,
  CardTypeButtonTitleStyled,
  CardTypeButtonSubTitleStyled,
  CardTypeButtonIconStyled,
  CardTypeExternalLink,
} from "./styled";

interface ProgramTypeFormProps {
  cardType: ProgramCardType | null;
  setCardType(data: ProgramCardType): void;
  stepForward(): void;
}
const ProgramTypeForm: React.FC<
  ProgramTypeFormProps & WrappedComponentProps
> = ({ cardType, setCardType, stepForward }) => {
  return (
    <WrapperStyled
      container
      justifyContent="center"
      direction="column"
      alignItems="center"
    >
      <LinearProgressStyled variant="determinate" value={33} />
      <Grid item xs={12} sm={8} md={6} lg={5}>
        <TitleStyled align="center" variant="h3">
          What kind of loyalty program do you want to create?
        </TitleStyled>
      </Grid>
      <FormBodyContainer item xs={12} sm={8} md={6} lg={5}>
        <CardTypeButtonStyled
          selected={cardType === "points"}
          onClick={() => setCardType("points")}
          disableRipple
        >
          <CardTypeButtonIconStyled>
            <CashbackCardIcon />
          </CardTypeButtonIconStyled>
          <div>
            <CardTypeButtonTitleStyled>Cashback card</CardTypeButtonTitleStyled>
            <CardTypeButtonSubTitleStyled>
              Your customers will get a credit cashback everytime they make a
              purchase.
            </CardTypeButtonSubTitleStyled>
          </div>
        </CardTypeButtonStyled>
        <CardTypeButtonStyled
          selected={cardType === "stamps"}
          onClick={() => setCardType("stamps")}
          disableRipple
          disabled
        >
          <CardTypeButtonIconStyled>
            <StampCardIcon />
          </CardTypeButtonIconStyled>
          <div>
            <CardTypeButtonTitleStyled>Stamp card</CardTypeButtonTitleStyled>
            <CardTypeButtonSubTitleStyled>
              Reward your customer purchases with a stamp. Give them free
              product when they reach a certain threshold
            </CardTypeButtonSubTitleStyled>
          </div>
        </CardTypeButtonStyled>
        <CardTypeExternalLink>
          <Typography align="center">
            Not sure yet?{" "}
            <Link to="./">
              <LinkIcon /> Learn more about Program types
            </Link>
          </Typography>
        </CardTypeExternalLink>

        <Grid container justifyContent="space-between" alignItems="center">
          <ButtonLinkStyled to="./" color="default">
            Cancel
          </ButtonLinkStyled>
          <SubmitButtonStyled onClick={stepForward} disabled={!cardType}>
            Next
          </SubmitButtonStyled>
        </Grid>
      </FormBodyContainer>
    </WrapperStyled>
  );
};

export default ProgramTypeForm;

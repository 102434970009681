import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { withRouter, RouteComponentProps } from "react-router";
import { FormattedMessage, WrappedComponentProps, useIntl } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import { Button, TextField } from "@components/common";
import {
  UserProfileType,
  CompanyType,
  LoyaltyType,
  UpdateCompanyType,
} from "@types";
import { HeadingStyled, TopFormStyled } from "../../styled";

interface CompanySettingsProps {
  userInfo: UserProfileType;
  selectedLoyalty: LoyaltyType;
  selectedCompany: CompanyType;
  updateCompany(data: UpdateCompanyType): void;
  // hasError: boolean;
}

const CompanySettings: React.FC<
  CompanySettingsProps & RouteComponentProps & WrappedComponentProps
> = ({ userInfo, selectedCompany, updateCompany }) => {
  const methods = useForm();
  const intl = useIntl();
  const { handleSubmit, errors } = methods;
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = ({ fullName, email }) => {
    setIsLoading(true);
    updateCompany({
      tenant_id: userInfo.id,
      company_id: selectedCompany.id,
      name: fullName,
      email,
    });
  };

  useEffect(() => {
    setIsLoading(false);
  }, [selectedCompany]);

  return (
    <>
      <HeadingStyled variant="body1">
        <FormattedMessage id="account.company.settings.title" />
      </HeadingStyled>
      <Grid item md={6} lg={4}>
        <FormProvider {...methods}>
          <TopFormStyled onSubmit={handleSubmit(onSubmit)}>
            <TextField
              name="fullName"
              placeholder={intl.formatMessage({
                id: "account.company.settings.form.fullName.placeholder",
              })}
              label={intl.formatMessage({
                id: "account.company.settings.form.fullName.label",
              })}
              error={errors.fullName}
              required
              defaultValue={selectedCompany?.name}
              disabled
            />
            <TextField
              name="website"
              placeholder={intl.formatMessage({
                id: "account.company.settings.form.website.placeholder",
              })}
              label={intl.formatMessage({
                id: "account.company.settings.form.website.label",
              })}
              defaultValue={selectedCompany?.website_url}
              disabled
            />
            <Button
              disabled={isLoading || true}
              onClick={handleSubmit(onSubmit)}
            >
              <FormattedMessage id="account.company.settings.form.submit" />
            </Button>
          </TopFormStyled>
        </FormProvider>
        {/* {hasError && (
          <Alert variant="warning">
            <FormattedMessage id="general.notifications.error" />
          </Alert>
        )} */}
      </Grid>
    </>
  );
};

export default withRouter(CompanySettings);
